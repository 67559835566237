import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { MultiSelect } from "../../../component/CheckOut/Intake/MultiSelect";
import BottomBtnSection from "../../NewIntake/components/BottomBtnSection";
import { MixPanelTest } from "../../../component/API/automation_api";
export const BookSlotMCycle = ({
  pagevalues,
  setpagevalues,
  setBeginBtn,
  setRangeCount,
  setPages,
  setBeginPage,
  singleBeginRange,
  AllData,
  FurtherDataMixPanel,
}) => {
  const [menstral, setmenstral] = useState([
    "I’m on my period now",
    "I’m getting my period later",
    "I don’t get a period",
  ]);
  const store = useSelector((state) => state);

  return (
    <>
      <div className="bookslot_body">
        <div className="container b_s_mcycle">
          <div className="b_s_mcycle_title">
            When is your next menstrual cycle,{" "}
            <span>{store.checktoken_data.customer.user.first_name}?</span>
          </div>
          <div className="b_s_mcycle_desc">
            Based on your next menstrual period start date, we ask so we can
            collect your sample on time.
          </div>
          <MultiSelect
            {...{
              list: menstral,
              onMultiClick: (el) => {
                // setMultiSelectData(el);
                console.log(el());
                setpagevalues((prev) => ({
                  ...prev,
                  menstralstate: el(),
                }));
              },
              MultiSelectData: pagevalues?.menstralstate || "",
              multisel: false,
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              if (pagevalues.menstralstate.length <= 0) {
                return;
              }
              setRangeCount(1);
              MixPanelTest({
                Preferred_time_period: pagevalues.menstralstate,
              });
              if (pagevalues.menstralstate == "I’m getting my period later") {
                setPages("commingperiod");
                // setPages("commingperiod");
              } else {
                setPages("addressedit");
                // setPages("samplecolledate");
              }
              // if (pagevalues.menstralstate == "I’m getting my period later") {
              //   setPages("commingperiod");
              // } else {
              //   setPages("samplecolledate");
              // }
            },
            goBackClick: () => {
              // setBeginBtn(false);
              setRangeCount(-1);
              // setPages("labslist");
              setPages("begin");
            },
          },
        }}
      />
    </>
  );
};
