import React from "react";

// import { get_unique } from "../../../utils/generators";

let unique_id = 0;
export const get_unique = () => {
  return (unique_id += 1);
};

const CustInputMuiClone = ({ attr, data }) => {
  const curr_id = "inpid_" + get_unique();
  const current_inp_obj = {
    readonly: false,
  };
  return (
    <>
      <label
        className={
          "cust_input with_prefix" +
          (attr?.class ? ` ${attr.class}` : "") +
          (attr?.error ? " err_active" : "")
        }
        htmlFor={curr_id}
      >
        <div className="input_group_main">
          {data?.prefix ? (
            <div className="input_prefix">{data.prefix}</div>
          ) : (
            ""
          )}
          <div className={"input_group2 "}>
            <input
              autoFocus={attr?.autofocus || false}
              type={attr?.type || "text"}
              value={
                attr?.value
                  ? attr?.value
                  : Number(attr?.value) == 0
                  ? attr?.value
                  : ""
              }
              // autoComplete="false"
              onChange={attr.event.change}
              onKeyDown={attr.event.onkeydown}
              id={curr_id}
              placeholder={data?.placeholder}
              inputMode={attr?.inputMode || "email"}
              // type="password"
              className={data.title ? " active_pad" : " unactive_pad"}
              readOnly={attr.readonly || current_inp_obj.readonly}
            />
            {data?.title ? (
              <div
                className="cust_input_title"
                active={attr.value ? "true" : ""}
              >
                {/* <div className="hidden_prefix">{data?.prefix || ""}</div> */}
                <div>{data.title}</div>
              </div>
            ) : (
              ""
            )}
          </div>
          {data?.suffix ? (
            <div className="input_suffix">{data.suffix}</div>
          ) : (
            ""
          )}
        </div>

        {attr?.error_msg ? (
          <div className="input_error">
            <div>{attr.error_msg}</div>
          </div>
        ) : (
          ""
        )}
      </label>
    </>
  );
};

export default CustInputMuiClone;
