import React, { useState } from "react";
import { ReactComponent as Logo } from "../img/ARVA.svg";
import { useLocation, useNavigate } from "react-router-dom";

export const NavbarMain = ({
  NavBarActive,
  setNavBarActive,
  NavDropActive,
  setMiniNavi,
  MiniNavi,
  setActiveProfile,
  ActiveProfile,
}) => {
  const [NavListState, setNavListState] = useState(false);
  const [NavBarList, setNavBarList] = useState([
    {
      name: "Profile",
      linkto: "/profile",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M5.121 17.804C7.21942 16.6179 9.58958 15.9963 12 16C14.5 16 16.847 16.655 18.879 17.804M15 10C15 10.7956 14.6839 11.5587 14.1213 12.1213C13.5587 12.6839 12.7956 13 12 13C11.2044 13 10.4413 12.6839 9.87868 12.1213C9.31607 11.5587 9 10.7956 9 10C9 9.20435 9.31607 8.44129 9.87868 7.87868C10.4413 7.31607 11.2044 7 12 7C12.7956 7 13.5587 7.31607 14.1213 7.87868C14.6839 8.44129 15 9.20435 15 10ZM21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
            stroke="#777777"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Community",
      linkto: "/learn",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M2.54583 9.16667H4.16667C4.60869 9.16667 5.03262 9.34226 5.34518 9.65482C5.65774 9.96738 5.83333 10.3913 5.83333 10.8333V11.6667C5.83333 12.1087 6.00893 12.5326 6.32149 12.8452C6.63405 13.1577 7.05797 13.3333 7.5 13.3333C7.94203 13.3333 8.36595 13.5089 8.67851 13.8215C8.99107 14.134 9.16667 14.558 9.16667 15V17.4542M6.66667 3.27917V4.58333C6.66667 5.13587 6.88616 5.66577 7.27686 6.05647C7.66756 6.44717 8.19747 6.66667 8.75 6.66667H9.16667C9.60869 6.66667 10.0326 6.84226 10.3452 7.15482C10.6577 7.46738 10.8333 7.89131 10.8333 8.33333C10.8333 8.77536 11.0089 9.19928 11.3215 9.51184C11.634 9.8244 12.058 10 12.5 10C12.942 10 13.366 9.8244 13.6785 9.51184C13.9911 9.19928 14.1667 8.77536 14.1667 8.33333C14.1667 7.89131 14.3423 7.46738 14.6548 7.15482C14.9674 6.84226 15.3913 6.66667 15.8333 6.66667H16.72M12.5 17.0733V15C12.5 14.558 12.6756 14.134 12.9882 13.8215C13.3007 13.5089 13.7246 13.3333 14.1667 13.3333H16.72M17.5 10C17.5 10.9849 17.306 11.9602 16.9291 12.8701C16.5522 13.7801 15.9997 14.6069 15.3033 15.3033C14.6069 15.9997 13.7801 16.5522 12.8701 16.9291C11.9602 17.306 10.9849 17.5 10 17.5C9.01509 17.5 8.03982 17.306 7.12987 16.9291C6.21993 16.5522 5.39314 15.9997 4.6967 15.3033C4.00026 14.6069 3.44781 13.7801 3.0709 12.8701C2.69399 11.9602 2.5 10.9849 2.5 10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
            stroke="#777777"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    //     {
    //       name: "Community",
    //       linkto: "/community",
    //       svg: (
    //         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    // <path d="M2.54583 9.16667H4.16667C4.60869 9.16667 5.03262 9.34226 5.34518 9.65482C5.65774 9.96738 5.83333 10.3913 5.83333 10.8333V11.6667C5.83333 12.1087 6.00893 12.5326 6.32149 12.8452C6.63405 13.1577 7.05797 13.3333 7.5 13.3333C7.94203 13.3333 8.36595 13.5089 8.67851 13.8215C8.99107 14.134 9.16667 14.558 9.16667 15V17.4542M6.66667 3.27917V4.58333C6.66667 5.13587 6.88616 5.66577 7.27686 6.05647C7.66756 6.44717 8.19747 6.66667 8.75 6.66667H9.16667C9.60869 6.66667 10.0326 6.84226 10.3452 7.15482C10.6577 7.46738 10.8333 7.89131 10.8333 8.33333C10.8333 8.77536 11.0089 9.19928 11.3215 9.51184C11.634 9.8244 12.058 10 12.5 10C12.942 10 13.366 9.8244 13.6785 9.51184C13.9911 9.19928 14.1667 8.77536 14.1667 8.33333C14.1667 7.89131 14.3423 7.46738 14.6548 7.15482C14.9674 6.84226 15.3913 6.66667 15.8333 6.66667H16.72M12.5 17.0733V15C12.5 14.558 12.6756 14.134 12.9882 13.8215C13.3007 13.5089 13.7246 13.3333 14.1667 13.3333H16.72M17.5 10C17.5 10.9849 17.306 11.9602 16.9291 12.8701C16.5522 13.7801 15.9997 14.6069 15.3033 15.3033C14.6069 15.9997 13.7801 16.5522 12.8701 16.9291C11.9602 17.306 10.9849 17.5 10 17.5C9.01509 17.5 8.03982 17.306 7.12987 16.9291C6.21993 16.5522 5.39314 15.9997 4.6967 15.3033C4.00026 14.6069 3.44781 13.7801 3.0709 12.8701C2.69399 11.9602 2.5 10.9849 2.5 10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z" stroke="#777777" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    // </svg>
    //       ),
    //     },
    {
      name: "Refer a friend",
      linkto: "/referral",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4.31804 6.31804C3.90017 6.7359 3.5687 7.23198 3.34255 7.77795C3.1164 8.32392 3 8.90909 3 9.50004C3 10.091 3.1164 10.6762 3.34255 11.2221C3.5687 11.7681 3.90017 12.2642 4.31804 12.682L12 20.364L19.682 12.682C20.526 11.8381 21.0001 10.6935 21.0001 9.50004C21.0001 8.30656 20.526 7.16196 19.682 6.31804C18.8381 5.47412 17.6935 5.00001 16.5 5.00001C15.3066 5.00001 14.162 5.47412 13.318 6.31804L12 7.63604L10.682 6.31804C10.2642 5.90017 9.7681 5.5687 9.22213 5.34255C8.67616 5.1164 8.09099 5 7.50004 5C6.90909 5 6.32392 5.1164 5.77795 5.34255C5.23198 5.5687 4.7359 5.90017 4.31804 6.31804Z"
            stroke="#1D1D1D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ]);
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const location = useLocation();
  // let history = unstable_HistoryRouter();

  return (
    <>
      <div className="user_navbar">
        <div className="inner_user_navbar">
          {/* {MiniNavi || ActiveProfile ? (
            <div
              className="go_back2"
              onClick={() => {
                if (ActiveProfile) {
                  navigate("/home");
                } else {
                  setMiniNavi(false);
                  scrollToTop();
                  if (location?.pathname?.split("/")[1] === "learn") {
                    if (window.history.state.idx == 0) {
                      navigate("/learn/all");
                    } else {
                      navigate(-1);
                    }
                  }
                  if (location.pathname === "/profile") {
                    let data = localStorage.getItem("add_goal_from_home");
                    if (data) {
                      data = JSON.parse(data);
                      try {
                        if (data) {
                          navigate("/home");
                          localStorage.setItem("add_goal_from_home", "");
                        } else {
                          setActiveProfile(true);
                        }
                      } catch (error) {
                        setActiveProfile(true);
                      }
                    } else {
                      setActiveProfile(true);
                    }
                  }
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M8.33333 15.8334L2.5 10.0001M2.5 10.0001L8.33333 4.16675M2.5 10.0001H17.5"
                  stroke="#5F1D1B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          ) : (
            <div className="go_back3"></div>
          )} */}
          <div
            className="user_nav_icon"
            onClick={() => {
              navigate("/home");
            }}
          >
            <Logo
            // onClick={() => window.location.replace("http://arva.health")}
            />
          </div>
          {/* {NavDropActive ? (
            <div
              className="user_nav_hamburger"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setNavListState((prev) => !prev);
              }}
            >
              {NavListState ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 18L18 6M6 6L18 18"
                    stroke="#5F1D1B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20 6H4M20 12H4M20 18H13"
                    stroke="#1D1D1D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
          ) : (
            <div className="user_nav_hamburger_duny"></div>
          )} */}
        </div>

        <div
          className={
            "inner_user_navbar_opener " +
            (NavListState ? " active_user_nav" : "")
          }
        >
          {NavBarList.map((el, i) => (
            <div
              className={
                "inner_user_navbar_box " +
                (NavBarActive === el.name ? " inner_active_nav_box" : "")
              }
              key={i}
              onClick={() => {
                // setNavBarActive(el.name);
                // navigate(el.linkto);

                if (el.name == "Community") {
                  window.open(
                    "https://chat.whatsapp.com/HCpqQhJQLpx0vMEO6sEjT4"
                  );
                } else {
                  setNavBarActive(el.name);
                  navigate(el.linkto);
                }
                // if (el.linkto === "/profile") {
                // }
                // if (el.linkto === "/blogs") {
                //   navigate("/blogs");
                // }
              }}
            >
              {el.svg}
              {el.name}
            </div>
          )).reverse()}
        </div>
      </div>
      {NavListState && (
        <div
          className="user_navbar_bottom"
          onClick={() => {
            setNavListState(false);
          }}
        ></div>
      )}
    </>
  );
};
