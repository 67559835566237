import React, { useEffect, useState } from "react";
import {
  addAppoiuntment,
  dateFormatter,
  getGuides,
} from "../../../../component/API/new_api";
import CalendlyModal from "../../../../component/Calendly/CalendlyModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { MixPanelArticle } from "../../../../component/API/automation_api";

const GuideCard = ({ type, data, guide_data }) => {
  const store = useSelector((state) => state);

  const navigate = useNavigate();
  // console.log(data, guide_data);
  return (
    <div className="home_card" type="test">
      {data?.chip ? <div className="title">{data.chip}</div> : ""}
      <div
        className="img_holder"
        style={{
          backgroundImage: "url(" + data.img + ")",
        }}
      >
        {data?.img ? (
          ""
        ) : (
          <div className="svg_floater_main">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 64 65"
              fill="none"
            >
              <path
                d="M64 32.9972C64 50.6712 49.674 64.9972 32 64.9972C14.326 64.9972 0 50.6712 0 32.9972C0 15.3232 14.326 0.997192 32 0.997192V4.99719C16.536 4.99719 4 17.5332 4 32.9972C4 48.4612 16.536 60.9972 32 60.9972C47.464 60.9972 60 48.4612 60 32.9972H64Z"
                fill="#5F1D1B"
                // fill="#1D1D1D"
              />
            </svg>
          </div>
        )}
      </div>
      <div className="details">
        {/* <div className="product_name">Egg Freezing Guide 101</div> */}
        <div className="txt">{data.title || guide_data.title}</div>
        <div className="time">{data.description || guide_data.description}</div>
        <div
          className={"btn " + (data.btnType || "")}
          onClick={
            data.goto
              ? data.goto
              : () => {
                  if (guide_data?.bought) {
                    MixPanelArticle({
                      content_name: guide_data?.title,
                      screen_name: "Home",
                      section_name: "Next",
                      is_paid_content: true,
                      content_type: "Guide",
                    });
                    navigate(`/guide/${guide_data.slug}`);
                  } else {
                    window.open("https://shop.arva.health/cart?ProductId=GU01");
                  }
                }
          }
        >
          {data.btnTxt || "Read"}
          {/* {JSON.stringify(guide_data)} */}
        </div>
      </div>
    </div>
  );
  return (
    <div className="home_card guide_card" type="test">
      {/* <div
        className="img_holder"
        style={{
          backgroundImage: `url(https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/ARVA/images/c75014259dc8fd3570bde57d9ea58e81.png)`,
          }}
          ></div> */}
      <div className="details">
        <div
          className="title"
          style={{
            backgroundImage:
              "url(https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/ARVA/images/guide.png)",
          }}
        >
          <div className="chip">Guide</div>
        </div>

        <div className="txt">{guide_data?.title}</div>
        {/* <div className="img_group">
          {guide_data?.product_images?.slice(0, 2)?.map((img, i) => (
            <img key={i} src={img} alt="" />
          ))}
        </div> */}
        <div className="time">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g opacity="0.6">
              <path
                d="M8 5.33333V8L10 10M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                stroke="#5F1D1B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          8 min read
        </div>
        <div
          className="btn type1"
          onClick={() => {
            MixPanelArticle({
              // ar_name: el.name,
              // article_id: el.slug,
              // screen_name: screen,
              // remaining: FurtherDataMixPanel,
              content_name: guide_data?.title,
              screen_name: "Home",
              section_name: "Next",
              content_type: "Article",
            });
            if (guide_data?.bought) {
              navigate(`/guide/${guide_data.slug}`);
            } else {
              window.open("https://shop.arva.health/cart?ProductId=GU01");
            }
          }}
        >
          Take a look
        </div>
        {/* {
          [
            <>
              <div className="time">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g opacity="0.6">
                    <path
                      d="M8 5.33333V8L10 10M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                      stroke="#5F1D1B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
                Less than a minute
              </div>
              <div className="btn type1" onClick={() => setcurr_state(1)}>
                Pick a time
              </div>
            </>,
            <>
              <div className="product_name">Egg Freezing bundle</div>
              <div className="txt">Collection is scheduled for tomorrow</div>
              <div className="time">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g opacity="0.6">
                    <path
                      d="M8 5.33333V8L10 10M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                      stroke="#5F1D1B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
                Saturday, May 16, 11:00 AM
              </div>
              <div className="btn type1" onClick={() => setcurr_state(2)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <path
                    d="M13.5 1.75H12.25V1.5C12.25 1.30109 12.171 1.11032 12.0303 0.96967C11.8897 0.829018 11.6989 0.75 11.5 0.75C11.3011 0.75 11.1103 0.829018 10.9697 0.96967C10.829 1.11032 10.75 1.30109 10.75 1.5V1.75H6.25V1.5C6.25 1.30109 6.17098 1.11032 6.03033 0.96967C5.88968 0.829018 5.69891 0.75 5.5 0.75C5.30109 0.75 5.11032 0.829018 4.96967 0.96967C4.82902 1.11032 4.75 1.30109 4.75 1.5V1.75H3.5C3.16848 1.75 2.85054 1.8817 2.61612 2.11612C2.3817 2.35054 2.25 2.66848 2.25 3V13C2.25 13.3315 2.3817 13.6495 2.61612 13.8839C2.85054 14.1183 3.16848 14.25 3.5 14.25H13.5C13.8315 14.25 14.1495 14.1183 14.3839 13.8839C14.6183 13.6495 14.75 13.3315 14.75 13V3C14.75 2.66848 14.6183 2.35054 14.3839 2.11612C14.1495 1.8817 13.8315 1.75 13.5 1.75ZM4.75 3.25C4.75 3.44891 4.82902 3.63968 4.96967 3.78033C5.11032 3.92098 5.30109 4 5.5 4C5.69891 4 5.88968 3.92098 6.03033 3.78033C6.17098 3.63968 6.25 3.44891 6.25 3.25H10.75C10.75 3.44891 10.829 3.63968 10.9697 3.78033C11.1103 3.92098 11.3011 4 11.5 4C11.6989 4 11.8897 3.92098 12.0303 3.78033C12.171 3.63968 12.25 3.44891 12.25 3.25H13.25V4.75H3.75V3.25H4.75ZM3.75 12.75V6.25H13.25V12.75H3.75Z"
                    fill="white"
                  />
                </svg>
                Add to calendar
              </div>
              <div className="btn type2">Reschedule</div>
            </>,

            <>
              <div className="product_name">Egg Freezing bundle</div>
              <div className="txt">You have an appointment tomorrow</div>
              <div className="time">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g opacity="0.6">
                    <path
                      d="M8 5.33333V8L10 10M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                      stroke="#5F1D1B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
                Saturday, May 16, 11:00 AM
              </div>
              <div className="btn type1" onClick={() => setcurr_state(3)}>
                Reschedule
              </div>
            </>,
            <>
              <div className="time">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g opacity="0.6">
                    <path
                      d="M8 5.33333V8L10 10M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                      stroke="#5F1D1B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
                Less than 48 hours
              </div>
              <div className="btn type1" onClick={() => setcurr_state(4)}>
                Contact us
              </div>
            </>,
            <>
              <div className="product_name">Egg Freezing bundle</div>
              <div className="txt">Your report is ready</div>
              <div className="btn type1" onClick={() => setcurr_state(0)}>
                View report
              </div>
            </>,
          ][curr_state]
        } */}
        {/* {data?.product_name ? (
          <div className="product_name">{data.product_name}</div>
        ) : (
          ""
        )} */}
        {/* <div className="txt">
          Your call is scheduled for{" "}
          {new Date().toString().split(" ").slice(1, 4) ===
          new Date(data.appointment[0].calendlyData.startTime)
            .toString()
            .split(" ")
            .slice(1, 4)
            ? "Today"
            : dateFormatter(
                data.appointment[0].calendlyData.startTime,
                "DD/MM/YYYY"
              )}
        </div> */}
        {/* <div className="time">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g opacity="0.6">
              <path
                d="M8 5.33333V8L10 10M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                stroke="#5F1D1B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          {dateFormatter(
            data?.appointment[0]?.calendlyData?.startTime,
            "dddd, MMF DD, hh:mm A"
          )}
        </div> */}
        {/* <div
          className="btn type1"
          onClick={() =>
            window.open(data?.appointment[0].calendlyData.location)
          }
        >
          Join
        </div> */}
        {/* <div
          className="btn type2"
          onClick={() => {
            setcalendlyModalState(true);
            setcalendlyData({
              // url: items.calendlyData.eventUrl,
              url: "https://calendly.com/arvahealth/testing-events",
              onSubmit: (evenetData) => {
                addAppoiuntment(
                  store.checktoken_data.token,
                  data.appointment[0]._id,
                  evenetData,
                  (success) => {},
                  (err) => {
                    toast.error("calendy not updated");
                  }
                );
              },
            });
          }}
        >
          Reschedule
        </div> */}
      </div>
    </div>
  );
};

export default GuideCard;
