import React, { useEffect, useRef, useState } from "react";
import { Faq } from "./Faq";
import { LevelList } from "./LevelList";
import Slider from "react-slick";
import { LevelListForBmi } from "./LevelListForBmi";
import { MixPanelResultClickExpanded } from "../API/automation_api";

export const BaseOnResultFertity = ({
  list,
  accordianData,
  ControlList,
  setControlList,
  age,
  FullList,
  FurtherDataMixPanel,
  allreport,
  reports,
}) => {
  const [FilterArray, setFilterArray] = useState([]);
  const [CountBoxes, setCountBoxes] = useState(1);
  const [ArrayForHgighLow, setArrayForHgighLow] = useState([]);
  var SectionFertility = {
    dots: false,
    infinite: false,
    // slidesToShow: 2,
    // slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    arrows: false,
    focusOnSelect: false,
    variableWidth: true,
  };
  useEffect(() => {
    setControlList(list[CountBoxes - 1]);
    MixPanelResultClickExpanded({
      reportname: "Fertility (Egg Count)",
      name: list[CountBoxes - 1]?.name,
      remaining: FurtherDataMixPanel,
    });
  }, [CountBoxes]);
  const SliderMenuFilter = useRef();
  useEffect(() => {
    let relatedBiomarkers = reports[0]?.cards?.fertility?.basedOnYourResults
      ?.map((el) => el.relatedBiomarkers)
      .flat(1);
    const filteredArray1 = reports[0].biomarker?.filter((item1) => {
      // return relatedBiomarkers?.some(
      //   (item2) => item2.nameShort === item1.biomarkerCode
      // );
      return ControlList?.relatedBiomarkers?.some(
        (item2) => item2.nameShort === item1.biomarkerCode
      );
    });
    setFilterArray(filteredArray1);
  }, [
    ControlList?.relatedBiomarkers,
    FullList?.last_order?.order_details?.level_list,
  ]);
  console.log({ FilterArray });
  return (
    <div className="inner_baseonresult_fertiltiy">
      <div className="inner_baseonresult_fertiltiy_title">
        Based on your results
      </div>
      <div
        className={
          list.length < 5 && window.innerWidth > 500
            ? "baseonresult_list"
            : "baseonresult_list2"
        }
      >
        {list.length < 5 && window.innerWidth > 500 ? (
          list?.map((el, i) => (
            <div
              key={i}
              className={
                "baseonresult_list_box " +
                (CountBoxes === i + 1 ? " baseonresult_list_box_active" : "")
              }
              onClick={() => {
                setControlList(el);
                setCountBoxes(i + 1);
                MixPanelResultClickExpanded({
                  reportname: "Fertility (Egg Count)",
                  name: el.name,
                  remaining: FurtherDataMixPanel,
                });
              }}
            >
              {el.name}
            </div>
          ))
        ) : (
          <Slider
            ref={SliderMenuFilter}
            {...{
              ...SectionFertility,
              // afterChange: (i) => setControlList(list[i] || {}),
            }}
          >
            {list?.map((el, i) => (
              <div>
                <div
                  key={i}
                  className={
                    "baseonresult_list_box " +
                    (ControlList.name === el.name
                      ? " baseonresult_list_box_active"
                      : "")
                  }
                  onClick={() => {
                    setControlList(el);
                    MixPanelResultClickExpanded({
                      reportname: "Fertility (Egg Count)",
                      name: el.name,
                      remaining: FurtherDataMixPanel,
                    });
                    setCountBoxes(i + 1);
                  }}
                >
                  {el.name}
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
      <div className="baseonresult_accordain">
        <Faq
          {...{
            openAcc: true,
            unique: "fertitlty_base_result",
            titleDis: false,
            data: [
              ...accordianData.map((el, i) => ({
                que: el.name,
                ans: el.desc,
              })),
            ],
          }}
        />
      </div>
      {
        // ControlList.name === "BMI" ? (
        //   <LevelListForBmi
        //     {...{
        //       title: "",
        //       titleDate: "",
        //       age: age,
        //       unique_id: "forbmi4",
        //       pagevalues: FullList?.last_intake?.data,
        //       list: [
        //         ...FilterArray.map((el, i) => ({
        //           name: el.nameShort,
        //           desc: el.name,
        //           color: "#F05E45",
        //           count: el.count,
        //           range: "",
        //           count_qunt: el?.unit,
        //           desc2: el.description,
        //           insights_list: el.insights,
        //           range_list: el.labRange,
        //         })),
        //       ],
        //       AccordainWork: false,
        //       setArrayForHgighLow: setArrayForHgighLow,
        //       ArrayForHgighLow: ArrayForHgighLow,
        //     }}
        //   />
        // ) : (
        <LevelList
          {...{
            title: "",
            titleDate: "",
            age: age,
            FurtherDataMixPanel: FurtherDataMixPanel,
            screen: "Fertility (Egg Count)",
            list: [
              ...FilterArray?.map((el, i) => ({
                ...el,
                // name: el.short_name,
                desc: el.value_desc,
                desc2: el.desc,
                color: "",
                count: el.value,

                // ??
                score: el?.score
                  ? `${el?.score[0].toUpperCase()}${el?.score.slice(1)}`
                  : "Pending",
                insights_list: el.insights,
                range_list: el.labRange,
                // ??
              })),
            ],
            AccordainWork: false,
            setArrayForHgighLow: setArrayForHgighLow,
            ArrayForHgighLow: ArrayForHgighLow,
          }}
        />
      }

      <div className="prev_next">
        <div
          className={"prev_btn " + (CountBoxes === 1 ? "" : "active_prev_btn")}
          onClick={() => {
            // setTomorrayActive(true);
            if (CountBoxes != 1 && list.length < !5) {
              SliderMenuFilter.current.slickPrev();
            }
            setCountBoxes((prev) => (1 === prev ? prev : prev - 1));
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.5 5L7.5 10L12.5 15"
              stroke="#C5C5C5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div>Previous</div>
        </div>
        <div
          className={
            "next_btn " + (CountBoxes === list.length ? "" : "active_prev_btn")
          }
          onClick={() => {
            // setTomorrayActive(false);
            if (CountBoxes != list.length && list.length < !5) {
              SliderMenuFilter.current.slickNext();
            }
            setCountBoxes((prev) => (list.length === prev ? prev : prev + 1));
          }}
        >
          <div>Next</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M7.5 15L12.5 10L7.5 5"
              stroke="#C5C5C5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
