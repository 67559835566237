import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { date_formatter } from "../CustInput/CustInput";
import CustInputMuiClone from "../CustInput/CustInputMuiClone";
import { MixPanelBiomarker } from "../API/automation_api";
import { BarPlot } from "./BarPlot";

export const LevelList = ({
  title,
  titleDate,
  unique_id,
  list,
  AccordainWork,
  setAccordainWork,
  editLevel,
  setlistupdate,
  listupdate,
  age,
  FurtherDataMixPanel,
  screen,
  firstopen,
}) => {
  // console.log(list);
  const BigAccordina = useRef();
  const [height, setheight] = useState([]);
  const [current_ques, setcurrent_ques] = useState("");
  const [BigAccordain, setBigAccordain] = useState(false);
  const [YourDetail_height, setYourDetail_height] = useState("");
  const [YourDetail_height_animate, setYourDetail_height_animate] =
    useState(false);
  const [UserResultArray, setUserResultArray] = useState([]);

  useLayoutEffect(() => {
    //Big Accordain
    if (AccordainWork) {
      let temp_height = UserResultArray.map((el, i) => {
        return document
          ?.getElementById(`our_test_description_${unique_id}_${i + 1}`)
          ?.getBoundingClientRect().height;
      });
      setheight(temp_height);
    } else {
      let temp_height = list.map((el, i) => {
        return document
          ?.getElementById(`our_test_description_${unique_id}_${i + 1}`)
          ?.getBoundingClientRect().height;
      });
      setheight(temp_height);
    }
  }, [current_ques, UserResultArray]);
  //
  useEffect(() => {
    setYourDetail_height(
      BigAccordina?.current?.getBoundingClientRect()?.height + 50
    );
    setYourDetail_height_animate(true);
  }, [BigAccordain]);

  const updateCount = (index, newCount, el) => {
    // Make a copy of the state
    //
    const updatedPageValues = [...listupdate];

    // // Update the count property of the specific item
    updatedPageValues[index].count = newCount;

    // // Set the updated state
    // const array = [];
    // array[index] = {
    //   name: el.name,
    //   desc: el.desc,
    //   // color: "",
    //   count: newCount,
    //   range: "",
    //   desc2: el.desc2,
    //   insights_list: el.insights_list,
    //   range_list: el.range_list,
    // };
    setlistupdate(updatedPageValues);
  };

  const [resultArray, setresultArray] = useState([]);

  useEffect(() => {
    //
    //
    const tresultArray = list?.map((data) => {
      // Step 1: Filter the range_list based on age criteria
      let ageFilteredRanges = [];
      if (data?.range_list?.length >= 2) {
        //
        ageFilteredRanges = data?.range_list?.filter((range) => {
          // const age = 50; // Replace with the age you want to filter by
          //
          return range.ageMin <= age && range.ageMax >= age;
        });
      } else {
        //
        ageFilteredRanges = data?.range_list;
      }
      // Step 2: Extract highMin and highMax values from the filtered range_list
      const highMin = ageFilteredRanges
        ? ageFilteredRanges[0]?.highMin
        : 0 || 0;
      const highMax = ageFilteredRanges
        ? ageFilteredRanges[0]?.highMax
        : 0 || 0;
      const lowMax = ageFilteredRanges ? ageFilteredRanges[0]?.lowMax : 0 || 0;
      const lowMin = ageFilteredRanges ? ageFilteredRanges[0]?.lowMin : 0 || 0;
      const optimalMin = ageFilteredRanges
        ? ageFilteredRanges[0]?.optimalMin
        : 0 || 0;
      const optimalMax = ageFilteredRanges
        ? ageFilteredRanges[0]?.optimalMax
        : 0 || 0;
      const unit = ageFilteredRanges ? ageFilteredRanges[0]?.unit : 0 || 0;
      //
      // Step 3: Create new properties "high" and "low" in the insights object
      // const insights = {
      //   ...data.insights,
      //   high: highMax || 0, // Assign highMax value
      //   low: highMin || 0, // Assign highMin value
      // };
      const desc = data?.insights_list;
      const lowValue = {
        min: data?.range?.start || 0,
        max: data?.range?.min || 0,
        name: "Low",
        desc: data.desc,
        // desc: desc ? desc?.filter((el) => el.score === "Low")[0] : "-",
      };

      const optimalValue = {
        min: data?.range?.min || 0,
        max: data?.range?.max || 0,
        name: "Optimal",
        desc: data.desc,
        // desc: desc ? desc.filter((el) => el.score === "Optimal")[0] : "-",
      };

      const highValue = {
        min: data?.range?.max || 0,
        max: data?.range?.end || 0,
        name: "High",
        desc: data.desc,
        // desc: desc ? desc.filter((el) => el.score === "High")[0] : "-",
      };

      // Create an array containing these values
      const newArray = [lowValue, optimalValue, highValue];

      //
      // Step 4: Create a new object with filtered range_list and updated insights
      let newData = {
        ...data,
        range_list: ageFilteredRanges,
        insights: [...newArray],
        range: data?.count ? data.score : "Pending",
        range_desc: data?.count ? data.desc : "-",
        unit: data?.range?.unit || "",
        desc: data?.name || "",
        // desc2: 2,
        // range_desc: 3,
      };
      //
      newData = {
        ...newData,
        color: [
          {
            name: "Low",
            color: "#F05E45",
          },
          {
            name: "Optimal",
            color: "#64BE45",
          },
          {
            name: "High",
            color: "#B418D1",
          },
        ].filter((el) => el.name === newData.range)[0]?.color,
        bg_color: [
          {
            name: "Low",
            color: "#FDEFEC",
          },
          {
            name: "Optimal",
            color: "#EFF8EC",
          },
          {
            name: "High",
            color: "#F8E8FA",
          },
        ].filter((el) => el.name === newData.range)[0]?.color,
      };

      return newData;
    });
    setresultArray(tresultArray);
    // setArrayForHgighLow(tresultArray);
    //
  }, [list]);

  let ListBMI = [
    {
      name: "Underweight",
      range: "<18.5",
      color: "#F05E45",
      bgcolor: "#FDEFEC",
    },
    {
      name: "Normal ",
      range: "18.5–25",
      color: "#64BE45",
      bgcolor: "#EFF8EC",
    },
    {
      name: "Overweight",
      range: ">25",
      color: "#B418D1",
      bgcolor: "#F8E8FA",
    },
    // {
    //   name: "Obese",
    //   range: ">30",
    //   color: "#B418D1",
    //   bgcolor: "#F8E8FA",
    // },
  ];

  let BMIRange = (value) => {
    let num = value;

    if (!Number(num)) {
      num = Number(value);
    }
    //
    let filteredData;

    if (num >= 20) {
      filteredData = ListBMI.slice(-3); // Take the last 3 objects
    } else {
      filteredData = ListBMI.slice(0, 3); // Take the first 3 objects
    }
    return filteredData;
  };

  let BMIColor = (value) => {
    // const rangeValue = 12.0;
    let rangeValue = value;

    if (!Number(rangeValue)) {
      rangeValue = Number(value ? value : 0);
    }

    const filteredListBMI = ListBMI.filter((item) => {
      const range = item.range;
      if (range.startsWith("<")) {
        const upperLimit = parseFloat(range.substring(1));
        return rangeValue < upperLimit;
      } else if (range.startsWith(">")) {
        const lowerLimit = parseFloat(range.substring(1));
        return rangeValue >= lowerLimit;
      } else if (range.includes("–")) {
        const [lower, upper] = range.split("–").map(parseFloat);
        return rangeValue >= lower && rangeValue <= upper;
      } else {
        // If the range format is not recognized, you can handle it accordingly
        return false;
      }
    });

    return filteredListBMI;
  };

  window.addEventListener("click", function () {
    setcurrent_ques(false);
  });
  useLayoutEffect(() => {
    if (firstopen) {
      setTimeout(() => {
        setcurrent_ques(1);
      }, 100);
    }
  }, [firstopen]);

  useEffect(() => {
    if (resultArray.length && AccordainWork) {
      setUserResultArray(
        [
          ...resultArray.filter((el, i) => {
            if (el.name === "AMH") {
              return el;
            }
          }),
          ...resultArray.filter((el, i) => {
            if (
              (el.range === "Low" || el.range === "High") &&
              el.name != "AMH" &&
              el.name != "BMI"
            ) {
              return el;
            }
          }),
          ...resultArray.filter((el, i) => {
            if (
              el.range === "Optimal" &&
              el.name != "AMH" &&
              el.name != "BMI"
            ) {
              return el;
            }
          }),
          ...resultArray.filter((el, i) => {
            if (
              el.range === "Pending" &&
              el.name != "AMH" &&
              el.name != "BMI"
            ) {
              return el;
            }
          }),
          ...resultArray.filter((el, i) => {
            if (el.name === "BMI") {
              return el;
            }
          }),
        ].splice(0, 3)
      );
    }
  }, [resultArray, AccordainWork]);

  // switch (editLevel) {
  // case false:
  //   return (
  //     <>
  //       <div className="main_levelList">
  //         <div className="main_levelList_top">
  //           <div className="main_levelList_title">{title}</div>
  //           {titleDate && (
  //             <div className="main_levelList_date">
  //               {date_formatter(titleDate, "date / month / year_long")}
  //             </div>
  //           )}
  //         </div>
  //         {AccordainWork ? (
  //           <div>
  //             <div className="mainlevel_list_data" ref={BigAccordina}>
  //               {list
  //                 .filter((el, i) => {
  //                   if (
  //                     AccordainWork &&
  //                     el.range != "Good" &&
  //                     el.range != "Optimal"
  //                   ) {
  //                     return el;
  //                   }
  //                 })
  //                 .splice(0, 3)
  //                 .map((el, i) => (
  //                   <div
  //                     key={i}
  //                     className="mainlevel_list_data_box"
  //                     onClick={(e) => {
  //                       setcurrent_ques((prev) =>
  //                         prev === i + 1 ? "" : i + 1
  //                       );
  //                       e.preventDefault();
  //                       e.stopPropagation();
  //                     }}
  //                   >
  //                     <div className="mainlevel_list_data_box_vis">
  //                       <div className="mainlevel_list_data_box_left">
  //                         <div className="mainlevel_list_data_box_left_title">
  //                           {el.name}
  //                         </div>
  //                         <div className="mainlevel_list_data_box_left_desc">
  //                           {el.desc}
  //                         </div>
  //                       </div>
  //                       <div
  //                         className="mainlevel_list_data_box_right"
  //                         style={{ color: el.color }}
  //                       >
  //                         <div
  //                           className={
  //                             el.range === "Pending"
  //                               ? "mainlevel_list_data_box_right_title_pending"
  //                               : "mainlevel_list_data_box_right_title"
  //                           }
  //                         >
  //                           {el.range}
  //                         </div>
  //                         <div className="mainlevel_list_data_box_right_desc">
  //                           {el.count}
  //                         </div>
  //                       </div>
  //                     </div>
  //                     <div
  //                       className="list_level_acccordin"
  //                       style={{
  //                         height:
  //                           current_ques === i + 1 ? `${height[i]}px` : 0,
  //                       }}
  //                     >
  //                       <div
  //                         id={`our_test_description_${unique_id}_${i + 1}`}
  //                       >
  //                         <div className="list_level_line_spacer"></div>
  //                         <div className="list_level_line">
  //                           <svg
  //                             xmlns="http://www.w3.org/2000/svg"
  //                             width="20"
  //                             height="20"
  //                             viewBox="0 0 20 20"
  //                             fill="none"
  //                           >
  //                             <path
  //                               d="M13.334 6.66683V13.3335M10.0007 9.16683V13.3335M6.66732 11.6668V13.3335M5.00065 16.6668H15.0007C15.4427 16.6668 15.8666 16.4912 16.1792 16.1787C16.4917 15.8661 16.6673 15.4422 16.6673 15.0002V5.00016C16.6673 4.55814 16.4917 4.13421 16.1792 3.82165C15.8666 3.50909 15.4427 3.3335 15.0007 3.3335H5.00065C4.55862 3.3335 4.1347 3.50909 3.82214 3.82165C3.50958 4.13421 3.33398 4.55814 3.33398 5.00016V15.0002C3.33398 15.4422 3.50958 15.8661 3.82214 16.1787C4.1347 16.4912 4.55862 16.6668 5.00065 16.6668Z"
  //                               stroke="#777777"
  //                               strokeWidth="2"
  //                               strokeLinecap="round"
  //                               strokeLinejoin="round"
  //                             />
  //                           </svg>
  //                           <div>RANGE</div>
  //                         </div>
  //                         <div className="range_type">
  //                           {[
  //                             {
  //                               name: "Low",
  //                               range: "<0.35",
  //                               color: "#F05E45",
  //                               bgcolor: "#FDEFEC",
  //                             },
  //                             {
  //                               name: "Optimal",
  //                               range: "0.35-5",
  //                               color: "#64BE45",
  //                               bgcolor: "#EFF8EC",
  //                             },
  //                             {
  //                               name: "High",
  //                               range: "5-6",
  //                               color: "#B418D1",
  //                               bgcolor: "#F8E8FA",
  //                             },
  //                           ].map((el1, i) => (
  //                             <div
  //                               key={i}
  //                               style={{
  //                                 color:
  //                                   el1.name === el.range ? el1.color : "",
  //                               }}
  //                               className="range_type_box"
  //                             >
  //                               {el1.name}:<br></br>
  //                               {el1.range}
  //                             </div>
  //                           ))}
  //                         </div>
  //                         <div className="list_level_bottom">
  //                           <div className="list_level_bottom_title">
  //                             <svg
  //                               xmlns="http://www.w3.org/2000/svg"
  //                               width="20"
  //                               height="20"
  //                               viewBox="0 0 20 20"
  //                               fill="none"
  //                             >
  //                               <path
  //                                 d="M10 2.5V3.33333M10 16.6667V17.5M17.5 10H16.6667M3.33333 10H2.5M15.3033 15.3033L14.7142 14.7142M5.28583 5.28583L4.69667 4.69667M15.3033 4.69667L14.7142 5.28583M5.28583 14.7142L4.69667 15.3033M13.3333 10C13.3333 10.8841 12.9821 11.7319 12.357 12.357C11.7319 12.9821 10.8841 13.3333 10 13.3333C9.11594 13.3333 8.2681 12.9821 7.64298 12.357C7.01786 11.7319 6.66667 10.8841 6.66667 10C6.66667 9.11594 7.01786 8.2681 7.64298 7.64298C8.2681 7.01786 9.11594 6.66667 10 6.66667C10.8841 6.66667 11.7319 7.01786 12.357 7.64298C12.9821 8.2681 13.3333 9.11594 13.3333 10Z"
  //                                 stroke="#777777"
  //                                 strokeWidth="2"
  //                                 strokeLinecap="round"
  //                                 strokeLinejoin="round"
  //                               />
  //                             </svg>
  //                             WHAT THIS MEANS
  //                           </div>
  //                           <div className="list_level_bottom_desc">
  //                             {el.desc2}
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 ))}
  //             </div>
  //           </div>
  //         ) : (
  //           <div>
  //             <div className="mainlevel_list_data">
  //               {resultArray?.map((el, i) => (
  //                 <div
  //                   key={i}
  //                   className="mainlevel_list_data_box"
  //                   onClick={(e) => {
  //                     setcurrent_ques((prev) =>
  //                       prev === i + 1 ? "" : i + 1
  //                     );
  //                     e.preventDefault();
  //                     e.stopPropagation();
  //                   }}
  //                 >
  //                   <div className="mainlevel_list_data_box_vis">
  //                     <div className="mainlevel_list_data_box_left">
  //                       <div className="mainlevel_list_data_box_left_title">

  //                         {el.name}
  //                       </div>
  //                       <div className="mainlevel_list_data_box_left_desc">
  //                         {el.desc}
  //                       </div>
  //                     </div>
  //                     <div
  //                       className="mainlevel_list_data_box_right_input"
  //                       style={{ color: el.color }}
  //                     >
  //                       {/* <div className="mainlevel_list_data_box_right_title">
  //                         {el.range}
  //                       </div> */}
  //                       <CustInputMuiClone
  //                         {...{
  //                           attr: {
  //                             // error: err_pagevalues.tall_feet,
  //                             error_msg: "",
  //                             inputMode: "numeric",
  //                             type: "number",
  //                             value: el.count,
  //                             event: {
  //                               change: ({ target }) => {
  //                                 updateCount(i, target.value, el);

  //                                 // handle_pagevalues({
  //                                 //   tall_feet: target.value,
  //                                 // });
  //                                 // handle_errpagevalues({
  //                                 //   tall_feet: target.value
  //                                 //     ? ""
  //                                 //     : "Error message",
  //                                 // });
  //                               },
  //                             },
  //                           },
  //                           data: {
  //                             title: "",
  //                             suffix: el?.unit,
  //                           },
  //                         }}
  //                       />
  //                       {/*
  //                       <div className="mainlevel_list_data_box_right_desc">
  //                         {el.count}
  //                       </div> */}
  //                     </div>
  //                   </div>
  //                   {/* <div
  //                     className="list_level_acccordin"
  //                     style={{
  //                       height: current_ques === i + 1 ? `${height[i]}px` : 0,
  //                     }}
  //                   >
  //                     <div id={`our_test_description_${i + 1}`}>
  //                       <div className="list_level_line_spacer"></div>
  //                       <div className="list_level_line">
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width="20"
  //                           height="20"
  //                           viewBox="0 0 20 20"
  //                           fill="none"
  //                         >
  //                           <path
  //                             d="M13.334 6.66683V13.3335M10.0007 9.16683V13.3335M6.66732 11.6668V13.3335M5.00065 16.6668H15.0007C15.4427 16.6668 15.8666 16.4912 16.1792 16.1787C16.4917 15.8661 16.6673 15.4422 16.6673 15.0002V5.00016C16.6673 4.55814 16.4917 4.13421 16.1792 3.82165C15.8666 3.50909 15.4427 3.3335 15.0007 3.3335H5.00065C4.55862 3.3335 4.1347 3.50909 3.82214 3.82165C3.50958 4.13421 3.33398 4.55814 3.33398 5.00016V15.0002C3.33398 15.4422 3.50958 15.8661 3.82214 16.1787C4.1347 16.4912 4.55862 16.6668 5.00065 16.6668Z"
  //                             stroke="#777777"
  //                             strokeWidth="2"
  //                             strokeLinecap="round"
  //                             strokeLinejoin="round"
  //                           />
  //                         </svg>
  //                         <div>RANGE</div>
  //                       </div>
  //                       <div className="range_type">
  //                         {[
  //                           {
  //                             name: "Low",
  //                             range: "<0.35",
  //                             color: "#F05E45",
  //                           },
  //                           {
  //                             name: "Optimal",
  //                             range: "0.35-5",
  //                             color: "#64BE45",
  //                           },
  //                           {
  //                             name: "High",
  //                             range: "5-6",
  //                             color: "#B418D1",
  //                           },
  //                         ].map((el1, i) => (
  //                           <div
  //                             key={i}
  //                             style={{
  //                               color: el1.name === el.range ? el1.color : "",
  //                             }}
  //                             className="range_type_box"
  //                           >
  //                             {el1.name}:<br></br>
  //                             {el1.range}
  //                           </div>
  //                         ))}
  //                       </div>
  //                       <div className="list_level_bottom">
  //                         <div className="list_level_bottom_title">
  //                           <svg
  //                             xmlns="http://www.w3.org/2000/svg"
  //                             width="20"
  //                             height="20"
  //                             viewBox="0 0 20 20"
  //                             fill="none"
  //                           >
  //                             <path
  //                               d="M10 2.5V3.33333M10 16.6667V17.5M17.5 10H16.6667M3.33333 10H2.5M15.3033 15.3033L14.7142 14.7142M5.28583 5.28583L4.69667 4.69667M15.3033 4.69667L14.7142 5.28583M5.28583 14.7142L4.69667 15.3033M13.3333 10C13.3333 10.8841 12.9821 11.7319 12.357 12.357C11.7319 12.9821 10.8841 13.3333 10 13.3333C9.11594 13.3333 8.2681 12.9821 7.64298 12.357C7.01786 11.7319 6.66667 10.8841 6.66667 10C6.66667 9.11594 7.01786 8.2681 7.64298 7.64298C8.2681 7.01786 9.11594 6.66667 10 6.66667C10.8841 6.66667 11.7319 7.01786 12.357 7.64298C12.9821 8.2681 13.3333 9.11594 13.3333 10Z"
  //                               stroke="#777777"
  //                               strokeWidth="2"
  //                               strokeLinecap="round"
  //                               strokeLinejoin="round"
  //                             />
  //                           </svg>
  //                           WHAT THIS MEANS
  //                         </div>
  //                         <div className="list_level_bottom_desc">
  //                           {el.desc2}
  //                         </div>
  //                         <div className="list_level_bottom_desc">
  //                           {el.desc3}
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div> */}
  //                 </div>
  //               ))}
  //             </div>
  //           </div>
  //         )}

  //         {AccordainWork ? (
  //           <div
  //             className="see_all_level"
  //             onClick={() => {
  //               setAccordainWork("Your levels");
  //             }}
  //           >
  //             See all levels
  //           </div>
  //         ) : (
  //           ""
  //         )}
  //       </div>
  //     </>
  //   );

  // default:
  return (
    <>
      <div className="main_levelList">
        <div className="main_levelList_top">
          <div className="main_levelList_title">{title}</div>
          {titleDate && (
            <div className="main_levelList_date">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M12.6667 2.66602H3.33333C2.59695 2.66602 2 3.26297 2 3.99935V13.3327C2 14.0691 2.59695 14.666 3.33333 14.666H12.6667C13.403 14.666 14 14.0691 14 13.3327V3.99935C14 3.26297 13.403 2.66602 12.6667 2.66602Z"
                  stroke="#777777"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.6665 1.33398V4.00065"
                  stroke="#777777"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.3335 1.33398V4.00065"
                  stroke="#777777"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2 6.66602H14"
                  stroke="#777777"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {date_formatter(titleDate, "date / month / year_long")}
            </div>
          )}
        </div>
        <div>
          <div className="mainlevel_list_data">
            {resultArray
              // ?.filter((el1) => el1.range != "Pending")
              .map((el, i) => (
                <div
                  key={i}
                  className="mainlevel_list_data_box"
                  onClick={(e) => {
                    setcurrent_ques((prev) => (prev === i + 1 ? "" : i + 1));
                    if (el.name === "BMI") {
                      const bmi_range = BMIColor(el.count)[0]?.name.trim("");
                      MixPanelBiomarker({
                        name: el.name,
                        range:
                          JSON.parse(JSON.stringify(bmi_range)) == "Normal",
                        screen: screen,
                        remaining: FurtherDataMixPanel,
                      });
                    } else {
                      MixPanelBiomarker({
                        name: el.name,
                        range: ["Optimal", "Normal"].includes(el.range),
                        screen: screen,
                        remaining: FurtherDataMixPanel,
                      });
                    }
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <div className="mainlevel_list_data_box_vis">
                    <div className="mainlevel_list_data_box_left">
                      <div className="mainlevel_list_data_box_left_title">
                        {el.name}
                      </div>
                      <div className="mainlevel_list_data_box_left_desc">
                        {el.desc}
                      </div>
                    </div>
                    <div
                      className="mainlevel_list_data_box_right"
                      style={{
                        color:
                          el.name === "BMI"
                            ? BMIColor(el.count)[0]?.color
                            : el.color,
                        backgroundColor:
                          el.name == BMIColor(el.count)[0]?.name
                            ? BMIColor(el.count)[0]?.bgcolor
                            : "",
                      }}
                    >
                      <div
                        className={
                          el.range === "Pending"
                            ? "mainlevel_list_data_box_right_title_pending"
                            : "mainlevel_list_data_box_right_title"
                        }
                      >
                        {el.name === "BMI"
                          ? BMIColor(el.count)[0]?.name
                          : el.range}
                      </div>
                      <div
                        className={
                          el.range === "Pending"
                            ? "mainlevel_list_data_box_right_desc_pending"
                            : "mainlevel_list_data_box_right_desc"
                        }
                        // "mainlevel_list_data_box_right_desc"
                      >
                        {el.range === "Pending" ? (
                          "N/A"
                        ) : (
                          <span>{el?.count} </span>
                        )}
                        {el.range === "Pending" ? "" : el?.unit}
                      </div>
                    </div>
                  </div>

                  {/* <div className="range_bar_contain">
                          <div className="range_bar">
                            <div
                              style={{
                                width: `${
                                  el.name == "BMI"
                                    ? Number(el.count) >
                                      Number(
                                        BMIRange(el.count)
                                          .filter(
                                            (el1) => el1.name == "Overweight"
                                          )[0]
                                          .range.split(">")[1]
                                      )
                                      ? "100%"
                                      : (Number(el.count) /
                                          Number(
                                            BMIRange(el.count)
                                              .filter(
                                                (el1) =>
                                                  el1.name == "Overweight"
                                              )[0]
                                              .range.split(">")[1]
                                          )) *
                                        100
                                    : (Number(el.count) >
                                      el?.insights?.filter(
                                        (el) => el?.name == "High"
                                      )[0]?.max
                                        ? "100%"
                                        : Number(el.count) /
                                          el?.insights?.filter(
                                            (el) => el?.name == "High"
                                          )[0]?.max) * 100
                                }%`,
                                backgroundColor:
                                  el.name == "BMI"
                                    ? BMIColor(el.count)[0].color
                                    : el.color,
                              }}
                            ></div>
                          </div>
                          <div className="range_bar_num">
                            <div>
                              {el.name == "BMI"
                                ? BMIRange(el.count).filter(
                                    (el1) => el1.name == "Underweight"
                                  )[0].range
                                : el?.insights?.filter(
                                    (el) => el?.name == "Low"
                                  )[0]?.min}
                            </div>
                            <div>
                              {el.name == "BMI"
                                ?
                                Number(el?.count) >
                                BMIRange(el.count).filter(
                                    (el1) => el1.name == "Overweight"
                                  )[0].range.split(">")[1] ?
                                  el?.count:
                                  BMIRange(el.count).filter(
                                    (el1) => el1.name == "Overweight"
                                  )[0].range
                                : 
                                
                                Number(el?.count) >
                                el?.insights?.filter(
                                    (el) => el?.name == "High"
                                  )[0]?.max ?
                                  el?.count :
                                  el?.insights?.filter(
                                    (el) => el?.name == "High"
                                  )[0]?.max
                                  }
                            </div>
                          </div>
                        </div> */}
                  <BarPlot {...{ el, BMIRange, BMIColor }} />

                  <div
                    className="list_level_acccordin"
                    style={{
                      height: current_ques === i + 1 ? `${height[i]}px` : 0,
                    }}
                  >
                    <div id={`our_test_description_${unique_id}_${i + 1}`}>
                      <div className="list_level_line_spacer"></div>
                      <div className="list_level_line">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M13.334 6.66683V13.3335M10.0007 9.16683V13.3335M6.66732 11.6668V13.3335M5.00065 16.6668H15.0007C15.4427 16.6668 15.8666 16.4912 16.1792 16.1787C16.4917 15.8661 16.6673 15.4422 16.6673 15.0002V5.00016C16.6673 4.55814 16.4917 4.13421 16.1792 3.82165C15.8666 3.50909 15.4427 3.3335 15.0007 3.3335H5.00065C4.55862 3.3335 4.1347 3.50909 3.82214 3.82165C3.50958 4.13421 3.33398 4.55814 3.33398 5.00016V15.0002C3.33398 15.4422 3.50958 15.8661 3.82214 16.1787C4.1347 16.4912 4.55862 16.6668 5.00065 16.6668Z"
                            stroke="#777777"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div>RANGE</div>
                      </div>
                      <div className="range_type">
                        {el.name === "BMI"
                          ? BMIRange(el.count)?.map((el1, i) => (
                              <div
                                key={i}
                                style={{
                                  color:
                                    el1.name == BMIColor(el.count)[0]?.name
                                      ? BMIColor(el.count)[0]?.color
                                      : "",
                                  backgroundColor:
                                    el1.name == BMIColor(el.count)[0]?.name
                                      ? BMIColor(el.count)[0]?.bgcolor
                                      : "",
                                }}
                                className="range_type_box"
                              >
                                {el1.name}:<br></br>
                                {el1.range}
                              </div>
                            ))
                          : [
                              ...el?.insights?.map((el1) => ({
                                name: el1.name,
                                range: `${el1.min}-${el1.max}`,
                                color: el.color,
                                bg_color: el.bg_color,
                              })),
                            ].map((el1, i) => (
                              <div
                                key={i}
                                style={{
                                  color: el1.name === el.range ? el1.color : "",
                                  backgroundColor:
                                    el1.name === el.range ? el1.bg_color : "",
                                }}
                                className="range_type_box"
                              >
                                {el1.name}:<br></br>
                                {el1.range}
                              </div>
                            ))}
                      </div>
                      {el.range_desc && (
                        <div className="list_level_bottom">
                          <div className="list_level_bottom_title">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M10 2.5V3.33333M10 16.6667V17.5M17.5 10H16.6667M3.33333 10H2.5M15.3033 15.3033L14.7142 14.7142M5.28583 5.28583L4.69667 4.69667M15.3033 4.69667L14.7142 5.28583M5.28583 14.7142L4.69667 15.3033M13.3333 10C13.3333 10.8841 12.9821 11.7319 12.357 12.357C11.7319 12.9821 10.8841 13.3333 10 13.3333C9.11594 13.3333 8.2681 12.9821 7.64298 12.357C7.01786 11.7319 6.66667 10.8841 6.66667 10C6.66667 9.11594 7.01786 8.2681 7.64298 7.64298C8.2681 7.01786 9.11594 6.66667 10 6.66667C10.8841 6.66667 11.7319 7.01786 12.357 7.64298C12.9821 8.2681 13.3333 9.11594 13.3333 10Z"
                                stroke="#777777"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            WHAT THIS MEANS
                          </div>
                          <div className="list_level_bottom_desc">
                            {el.range_desc || "-"}
                          </div>
                        </div>
                      )}

                      {el?.desc2 ? (
                        <div className="list_level_bottom2">
                          <div className="list_level_bottom_title">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M10 2.5V3.33333M10 16.6667V17.5M17.5 10H16.6667M3.33333 10H2.5M15.3033 15.3033L14.7142 14.7142M5.28583 5.28583L4.69667 4.69667M15.3033 4.69667L14.7142 5.28583M5.28583 14.7142L4.69667 15.3033M13.3333 10C13.3333 10.8841 12.9821 11.7319 12.357 12.357C11.7319 12.9821 10.8841 13.3333 10 13.3333C9.11594 13.3333 8.2681 12.9821 7.64298 12.357C7.01786 11.7319 6.66667 10.8841 6.66667 10C6.66667 9.11594 7.01786 8.2681 7.64298 7.64298C8.2681 7.01786 9.11594 6.66667 10 6.66667C10.8841 6.66667 11.7319 7.01786 12.357 7.64298C12.9821 8.2681 13.3333 9.11594 13.3333 10Z"
                                stroke="#777777"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            ABOUT
                          </div>
                          <div className="list_level_bottom_desc">
                            {el?.desc2 || "-"}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))}

            {false &&
              resultArray
                ?.filter((el1) => el1.range == "Pending")
                .map((el, i) => (
                  <div key={i} className="mainlevel_list_data_box">
                    <div
                      className="mainlevel_list_data_box_vis"
                      onClick={(e) => {
                        setcurrent_ques((prev) =>
                          prev === i + 1 ? "" : i + 1
                        );
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <div className="mainlevel_list_data_box_left">
                        <div className="mainlevel_list_data_box_left_title">
                          {el.name}
                        </div>
                        <div className="mainlevel_list_data_box_left_desc">
                          {el.desc}
                        </div>
                      </div>
                      <div
                        className="mainlevel_list_data_box_right"
                        style={{
                          color:
                            el.name === "BMI"
                              ? BMIColor(el.count)[0]?.color
                              : el.color,
                          backgroundColor:
                            el.name == BMIColor(el.count)[0]?.name
                              ? BMIColor(el.count)[0]?.bgcolor
                              : "",
                        }}
                      >
                        <div
                          className={
                            el.range === "Pending"
                              ? "mainlevel_list_data_box_right_title_pending"
                              : "mainlevel_list_data_box_right_title"
                          }
                        >
                          {el.name === "BMI"
                            ? BMIColor(el.count)[0]?.name
                            : el.range}
                        </div>
                        <div
                          className={
                            el.range === "Pending"
                              ? "mainlevel_list_data_box_right_desc_pending"
                              : "mainlevel_list_data_box_right_desc"
                          }
                          // "mainlevel_list_data_box_right_desc"
                        >
                          {el.range === "Pending"
                            ? "N/A"
                            : `${el?.count} ${el?.unit || ""}`}
                        </div>
                      </div>
                    </div>
                    <div
                      className="list_level_acccordin"
                      style={{
                        height: current_ques === i + 1 ? `${height[i]}px` : 0,
                      }}
                    >
                      <div id={`our_test_description_${unique_id}_${i + 1}`}>
                        <div className="list_level_line_spacer"></div>
                        <div className="list_level_line">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M13.334 6.66683V13.3335M10.0007 9.16683V13.3335M6.66732 11.6668V13.3335M5.00065 16.6668H15.0007C15.4427 16.6668 15.8666 16.4912 16.1792 16.1787C16.4917 15.8661 16.6673 15.4422 16.6673 15.0002V5.00016C16.6673 4.55814 16.4917 4.13421 16.1792 3.82165C15.8666 3.50909 15.4427 3.3335 15.0007 3.3335H5.00065C4.55862 3.3335 4.1347 3.50909 3.82214 3.82165C3.50958 4.13421 3.33398 4.55814 3.33398 5.00016V15.0002C3.33398 15.4422 3.50958 15.8661 3.82214 16.1787C4.1347 16.4912 4.55862 16.6668 5.00065 16.6668Z"
                              stroke="#777777"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <div>RANGE</div>
                        </div>
                        <div className="range_type">
                          {el.name === "BMI"
                            ? BMIRange(el.count)?.map((el1, i) => (
                                <div
                                  key={i}
                                  style={{
                                    color:
                                      el1.name == BMIColor(el.count)[0]?.name
                                        ? BMIColor(el.count)[0]?.color
                                        : "",
                                    backgroundColor:
                                      el1.name == BMIColor(el.count)[0]?.name
                                        ? BMIColor(el.count)[0]?.bgcolor
                                        : "",
                                  }}
                                  className="range_type_box"
                                >
                                  {el1.name}:<br></br>
                                  {el1.range}
                                </div>
                              ))
                            : [
                                ...el?.insights?.map((el1) => ({
                                  name: el1.name,
                                  range: `${el1.min}-${el1.max}`,
                                  color: el.color,
                                  bg_color: el.bg_color,
                                })),
                              ].map((el1, i) => (
                                <div
                                  key={i}
                                  style={{
                                    color:
                                      el1.name === el.range ? el1.color : "",
                                    backgroundColor:
                                      el1.name === el.range ? el1.bg_color : "",
                                  }}
                                  className="range_type_box"
                                >
                                  {el1.name}:<br></br>
                                  {el1.range}
                                </div>
                              ))}
                        </div>
                        <div className="list_level_bottom">
                          <div className="list_level_bottom_title">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M10 2.5V3.33333M10 16.6667V17.5M17.5 10H16.6667M3.33333 10H2.5M15.3033 15.3033L14.7142 14.7142M5.28583 5.28583L4.69667 4.69667M15.3033 4.69667L14.7142 5.28583M5.28583 14.7142L4.69667 15.3033M13.3333 10C13.3333 10.8841 12.9821 11.7319 12.357 12.357C11.7319 12.9821 10.8841 13.3333 10 13.3333C9.11594 13.3333 8.2681 12.9821 7.64298 12.357C7.01786 11.7319 6.66667 10.8841 6.66667 10C6.66667 9.11594 7.01786 8.2681 7.64298 7.64298C8.2681 7.01786 9.11594 6.66667 10 6.66667C10.8841 6.66667 11.7319 7.01786 12.357 7.64298C12.9821 8.2681 13.3333 9.11594 13.3333 10Z"
                                stroke="#777777"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            WHAT THIS MEANS
                          </div>
                          <div className="list_level_bottom_desc">
                            {el.range_desc}
                          </div>
                        </div>
                        {el?.desc2 ? (
                          <div className="list_level_bottom2">
                            <div className="list_level_bottom_title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M10 2.5V3.33333M10 16.6667V17.5M17.5 10H16.6667M3.33333 10H2.5M15.3033 15.3033L14.7142 14.7142M5.28583 5.28583L4.69667 4.69667M15.3033 4.69667L14.7142 5.28583M5.28583 14.7142L4.69667 15.3033M13.3333 10C13.3333 10.8841 12.9821 11.7319 12.357 12.357C11.7319 12.9821 10.8841 13.3333 10 13.3333C9.11594 13.3333 8.2681 12.9821 7.64298 12.357C7.01786 11.7319 6.66667 10.8841 6.66667 10C6.66667 9.11594 7.01786 8.2681 7.64298 7.64298C8.2681 7.01786 9.11594 6.66667 10 6.66667C10.8841 6.66667 11.7319 7.01786 12.357 7.64298C12.9821 8.2681 13.3333 9.11594 13.3333 10Z"
                                  stroke="#777777"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              ABOUT
                            </div>
                            <div className="list_level_bottom_desc">
                              {el?.desc2}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>

        {AccordainWork ? (
          <div
            className="see_all_level"
            onClick={() => {
              setAccordainWork("Your levels");
            }}
          >
            See all levels
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
  // }
};
