import React, { useEffect } from "react";
import { useState } from "react";

export const BarPlot = ({ el, BMIRange, BMIColor }) => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    let stroke_length = 100;
    let plot = Number(el.count);

    let newArray = {
      Low: {
        ...el?.insights?.filter((el) => el?.name == "Low")[0],
        toadd: 0,
      },
      Optimal: {
        ...el?.insights?.filter((el) => el?.name == "Optimal")[0],
        toadd: stroke_length / 3,
      },
      High: {
        ...el?.insights?.filter((el) => el?.name == "High")[0],
        toadd: stroke_length / 1.5,
      },
    };
    let plot_text = el.range;
    if (el.name == "BMI") {
      // plot_text = ["Low" , "Optimal",""]el.range;
      newArray = {
        Low: {
          min: 0,
          max: 18.5,
          toadd: 0,
        },
        Optimal: {
          min: 18.5,
          max: 25,
          toadd: stroke_length / 3,
        },
        High: {
          min: 25,
          max: 30,

          toadd: stroke_length / 1.5,
        },
      };
      if (plot <= newArray.Low.max) {
        plot_text = "Low";
      }
      if (plot > newArray.Optimal.min && plot <= newArray.Optimal.max) {
        plot_text = "Optimal";
      }
      if (plot >= newArray.High.min) {
        plot_text = "High";
      }
      // setWidth((plot / 25) * 100);
      // return;
    }

    let divide_stroke = stroke_length / 3;
    let start = newArray[plot_text]?.min;
    let end = newArray[plot_text]?.max;
    let precent = (plot - start) / (end - start);
    let main_percent = newArray[plot_text]?.toadd + precent * divide_stroke;
    if (main_percent > stroke_length) {
      main_percent = stroke_length;
    } else if (main_percent < 0) {
      main_percent = 0;
    }
    setWidth(main_percent);
  }, [el]);

  return (
    <div className="range_bar_contain">
      <div className="range_bar">
        <div
          style={{
            width: `${width}%`,
            backgroundColor:
              el.name == "BMI" ? BMIColor(el.count)[0].color : el.color,
          }}
        ></div>
      </div>
    </div>
  );
};
export const BarPlot1 = ({ el, BMIRange, BMIColor }) => {
  return (
    <div className="range_bar_contain">
      <div className="range_bar">
        <div
          style={{
            width: `${
              el.name == "BMI"
                ? Number(el.count) >
                  Number(
                    BMIRange(el.count)
                      .filter((el1) => el1.name == "Overweight")[0]
                      .range.split(">")[1]
                  )
                  ? 100
                  : (Number(el.count) /
                      Number(
                        BMIRange(el.count)
                          .filter((el1) => el1.name == "Overweight")[0]
                          .range.split(">")[1]
                      )) *
                    100
                : Number(el.count) >
                  el?.insights?.filter((el) => el?.name == "High")[0]?.max
                ? 100
                : (Number(el.count) /
                    el?.insights?.filter((el) => el?.name == "High")[0]?.max) *
                  100
            }%`,
            backgroundColor:
              el.name == "BMI" ? BMIColor(el.count)[0].color : el.color,
          }}
        ></div>
      </div>
      <div className="range_bar_num">
        <div>
          {el.name == "BMI"
            ? 0
            : // ? BMIRange(el.count).filter((el1) => el1.name == "Underweight")[0]
              //     .range
              el?.insights?.filter((el) => el?.name == "Low")[0]?.min}
        </div>
        <div>
          {el.name == "BMI"
            ? Number(el?.count) >
              BMIRange(el.count)
                .filter((el1) => el1.name == "Overweight")[0]
                .range.split(">")[1]
              ? el?.count
              : BMIRange(el.count).filter((el1) => el1.name == "Overweight")[0]
                  .range
            : Number(el?.count) >
              el?.insights?.filter((el) => el?.name == "High")[0]?.max
            ? el?.count
            : el?.insights?.filter((el) => el?.name == "High")[0]?.max}
        </div>
      </div>
    </div>
  );
};
