import React, { useLayoutEffect, useRef, useState } from "react";
import { AllBlogsMaterial } from "../../pages/Blogs/AllBlogsMaterial";
import { ArticleContent } from "../../pages/Blogs/ArticleContent";

export const AccordianList = ({ list, CurrentListData, keysep }) => {
  const [openQuestions, setOpenQuestions] = useState(() =>
    Array(list.length).fill(false)
  );
  const [questionHeights, setQuestionHeights] = useState(() =>
    Array(list.length).fill(0)
  );

  const AccCtr = (index) => {
    let temp_height = [...list].map((el, i) => {
      return document.getElementById(`${keysep}_${i}`).getBoundingClientRect()
        .height;
    });
    setQuestionHeights(temp_height);
    toggleQuestion(index);
    // if (true) {
    //   toggleQuestion(0);
    // }
  };

  const toggleQuestion = (index) => {
    setOpenQuestions((prevOpenQuestions) => {
      const updatedOpenQuestions = [...prevOpenQuestions];
      updatedOpenQuestions[index] = !updatedOpenQuestions[index];
      return updatedOpenQuestions;
    });
  };
  useLayoutEffect(() => {
    // setOpenQuestions(Array(list.length).fill(false));
    // setTimeout(() => {
    //   AccCtr();
    // }, 5);
    setOpenQuestions(Array(list.length).fill(false));
  }, [list]);

  return (
    <div className="main_accordion">
      {list?.map((el, i) => (
        <div
          key={i}
          className="inner_accordion_list"
          onClick={() => {
            AccCtr(i);
            // toggleQuestion(i);
            // toggleQuestion(i);
            // toggleQuestion(i);
          }}
        >
          <div className="inner_accordion_ctr">
            {el.question}
            <span
              style={
                openQuestions[i]
                  ? {
                      transform: "rotate(90deg)",
                    }
                  : {}
              }
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.29279 14.707C7.10532 14.5195 7 14.2652 7 14C7 13.7349 7.10532 13.4806 7.29279 13.293L10.5858 10L7.29279 6.70704C7.11063 6.51844 7.00983 6.26584 7.01211 6.00364C7.01439 5.74144 7.11956 5.49063 7.30497 5.30522C7.49038 5.11981 7.74119 5.01465 8.00339 5.01237C8.26558 5.01009 8.51818 5.11088 8.70679 5.29304L12.7068 9.29304C12.8943 9.48057 12.9996 9.73488 12.9996 10C12.9996 10.2652 12.8943 10.5195 12.7068 10.707L8.70679 14.707C8.51926 14.8945 8.26495 14.9998 7.99979 14.9998C7.73462 14.9998 7.48031 14.8945 7.29279 14.707Z"
                  fill="#2B3A33"
                />
              </svg>
            </span>
          </div>
          <div
            className="inner_accordion_ans"
            style={{
              height: openQuestions[i] ? `${questionHeights[i]}px` : 0,
              transition: "height 0.5s linear",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div id={`${keysep}_${i}`} className="qandacolor">
              <div
                style={{
                  padding: "20px 0 0 0",
                  borderBottom: "solid 1px var(--gray-g-100)",
                }}
              ></div>
              {el?.answer?.map((el1, i) => {
                return <ArticleContent content={el1} />;
              })}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
