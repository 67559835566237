import React from "react";
import { useNavigate } from "react-router-dom";
import { MixPanelArticle } from "../API/automation_api";

export const HomeSection3 = ({ title, data, FurtherDataMixPanel, screen }) => {
  function asset(url) {
    if (url) {
      let a = url.split("-");
      return `https://cdn.sanity.io/${a[0]}s/nao51hyy/production/${a
        .slice(1, -1)
        .join("-")}.${a[a.length - 1]}`;
    }
    return "";
  }
  const nav = useNavigate();
  return (
    <>
      <div className="main_homesection3">
        <div className="main_homesection3_title">{title}</div>
        <div className="main_homesection3_data">
          {data.map((el, i) => (
            <div
              className="main_homesection3_box"
              key={i}
              onClick={() => {
                MixPanelArticle({
                  // ar_name: el.name,
                  // article_id: el.slug,
                  // screen_name: screen,
                  // remaining: FurtherDataMixPanel,
                  content_name: el.name,
                  screen_name: "Result",
                  section_name: screen,
                  content_type: "Article",
                });
                nav(`/article/${el.slug}`);
                // window.open(`${window.location.origin}/learn/${el.slug}`);
              }}
            >
              <div className="main_homesection3_box_img">
                <img src={el?.img} />
              </div>
              <div className="main_homesection3_box_data">
                <div className="main_homesection3_box_data_title">
                  {el.name}
                </div>
                <div className="main_homesection3_box_data_desc">
                  {el.descti}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
