import { Outlet, useLocation, useRoutes } from "react-router-dom";
import ReactGA from "react-ga4";
import { ToastContainer } from "react-toastify";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "react-toastify/dist/ReactToastify.css";

import { Error } from "./pages/Error";
import { useEffect, useState } from "react";
import { Loader } from "./component/Loader";
import { Signin } from "./pages/Signin";
import { ToDefaul } from "./pages/ToDefaul";
// import { PrintInvoice } from "./pages/Invoices/PrintInvoice";
import { PrintInvoice } from "./pages/Invoices/PrintInvoice1";
import Intake from "./pages/Intake";
import { Result } from "./pages/Result";
import { Profile } from "./pages/Profile";
import { LoginHomeUser } from "./component/Home/LoginHomeUser";
import { ReferPage } from "./pages/ProfileType/ReferPage";
import { Community } from "./pages/Community";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-loading-skeleton/dist/skeleton.css";
import "@glideapps/glide-data-grid/dist/index.css";
import { ImagesCol } from "./pages/ImagesCollection/ImagesCol";
import { Provider } from "react-redux";
import store from "./store/store";
import { CheckoutV2 } from "./pages/CheckoutV2";
import { GoogleSignRedirect } from "./pages/GoogleSignRedirect";
import { BookSlotV2 } from "./component/Home/BookSlotV2";
import { HomeV2 } from "./pages/HomeV2/HomeV2";

import { Dashboardv2 } from "./pages/AdminV2/DashboardV2";
import { GuidePage } from "./pages/Explore/Guide/GuidePage";
import { QandA } from "./pages/Explore/Q&a/QandA";
import { Article } from "./pages/Explore/Article/Article";
import { ConsultationPay } from "./pages/ConsultationPay";
import { ClinicDetail } from "./pages/HomeV2/AccordianHomeInside/Clinics/ClinicDetail";
import { CheckoutComplete } from "./pages/CheckoutComplete";
import { CompletedOrders } from "./pages/AdminV2/Pages/CompletedOrders";
// import Reports from "./pages/Reports";
import { HomeNew } from "./pages/HomeNew/HomeNew";
import CareHistory from "./style/pages/CareHistory/CareHistory";
import AutoLogin from "./pages/AutoLogin/AutoLogin";
import { Clinic } from "./pages/Explore/Clinic/Clinic";
import { Upcomming } from "./pages/HomeNew/Upcomming";
import { BookAppointment } from "./component/Home/BookAppointment";
import { getProducts } from "./component/API/new_api";
import NewIntake from "./pages/NewIntake/NewIntake";
import NewBookingSlot from "./pages/NewBookingSlot/NewBookingSlot";
import { SendOtp } from "./component/API/api";
import { SendOtpPage } from "./component/Home/SendOtpPage";

// ReactGA.initialize("G-1G7KCK6Q5Y");
function App() {
  const [loading, setloading] = useState(false);
  const [ProductImages, setProductImages] = useState({});

  const [storeAdmin, setstoreadmin] = useState({
    token: "",
    email: "",
    image: "",
    ledger: 0,
  });
  useEffect(() => {
    getProducts((success) => {
      setProductImages(success);
    });
  }, []);

  const routes = [
    {
      path: "/auto-login",
      element: <AutoLogin setloading={setloading} />,
    },
    // {
    //   path: "/",
    //   element: <ToDefaul setloading={setloading} />,
    // },
    // {
    //   path: "/checkout-complete",
    //   element: <CheckoutComplete />,
    // },
    // {
    //   path: "/google-sign-in",
    //   element: <GoogleSignRedirect setloading={setloading} />,
    // },
    {
      path: "/intake",
      element: <NewIntake setloading={setloading} />,
    },
    // {
    //   path: "/intake",
    //   element: <Intake setloading={setloading} />,
    // },
    {
      path: "/",
      element: <LoginHomeUser setloading={setloading} />,
    },
    {
      path: "/login",
      element: <LoginHomeUser setloading={setloading} />,
    },
    {
      path: "/send-otp",
      element: <SendOtpPage setloading={setloading} />,
    },
    {
      path: "/care-history",
      element: (
        <CareHistory setloading={setloading} ProductImages={ProductImages} />
      ),
    },
    {
      path: "/home",
      element: (
        <HomeNew setloading={setloading} ProductImages={ProductImages} />
      ),
    },
    {
      path: "/upcomming",
      element: <Upcomming setloading={setloading} />,
    },
    {
      path: "/result",
      element: <Result setloading={setloading} />,
    },
    // {
    //   path: "/bookingslot",
    //   element: <BookSlotV2 setloading={setloading} />,
    // },
    {
      path: "/bookingslot",
      element: <NewBookingSlot setloading={setloading} />,
    },
    {
      path: "/bookingsapplointment",
      element: <BookAppointment setloading={setloading} />,
      // element: <>bookingsapplointment</>,
    },
    {
      path: "/community",
      element: <Community setloading={setloading} />,
    },
    // {
    //   path: "/coaching",
    //   element: <ConsultationPay setloading={setloading} />,
    // },
    // {
    //   path: "/referral",
    //   element: <ReferPage setloading={setloading} />,
    // },
    {
      path: "/profile",
      element: <Profile setloading={setloading} />,
    },
    {
      path: "/article/:slug",
      element: <Article setloading={setloading} />,
    },
    {
      path: "/clinic/:slug",
      element: <Clinic setloading={setloading} />,
    },
    {
      path: "/guide/:slug",
      element: <GuidePage setloading={setloading} />,
    },
    {
      path: "/faq/:slug",
      element: <QandA setloading={setloading} />,
    },
    {
      path: "/faq/:slug/:slug",
      element: <QandA setloading={setloading} />,
    },
    // {
    //   path: "/admin/completed-orders",
    //   element: <CompletedOrders />,
    // },
    // {
    //   path: "/:type",
    //   element: <CheckoutV2 setloading={setloading} />,
    //   // element: <Checkout setloading={setloading} />,
    // },
    // {
    //   path: "/invoice/:invoice_id",
    //   element: <PrintInvoice setloading={setloading} />,
    // },
    {
      path: "/clinicdetail/:slug",
      element: <ClinicDetail setloading={setloading} />,
    },
    // {
    //   path: "/admin/",
    //   element: (
    //     <>
    //       <Outlet />
    //     </>
    //   ),
    //   children: [
    //     {
    //       path: "",
    //       element: (
    //         <Signin
    //           setloading={setloading}
    //           {...{ setloading, store: storeAdmin, setstore: setstoreadmin }}
    //         />
    //       ),
    //     },
    //     {
    //       path: "dashboard",
    //       element: (
    //         <>
    //           <Dashboardv2
    //             {...{ setloading, store: storeAdmin, setstore: setstoreadmin }}
    //           />
    //         </>
    //       ),
    //     },
    //     {
    //       path: "reports",
    //       element: (
    //         <>
    //           <Reports
    //             {...{ setloading, store: storeAdmin, setstore: setstoreadmin }}
    //           />
    //         </>
    //       ),
    //     },
    //   ],
    // },
    {
      path: "/*",
      element: <Error />,
    },
  ];
  const route = useRoutes(routes);
  const location = useLocation();

  return (
    <>
      <Provider store={store}>
        {loading ? <Loader {...{ loading }} /> : ""}
        {route}
        {[
          "/result",
          "/article/all",
          "/clinic/all",
          "/faq/all",
          "/guide/all",
          "/home",
        ].includes(location.pathname) ? (
          <div
            className="watsapp_main"
            style={{
              top: [
                "/article/all",
                "/clinic/all",
                "/faq/all",
                "/guide/all",
              ].includes(location.pathname)
                ? "10px"
                : "20px",
            }}
            onClick={() => {
              window.open("https://wa.me/919731107947");
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.74889 4.99889C7.50645 3.24133 9.88943 2.25273 12.375 2.25C14.8614 2.25 17.246 3.23772 19.0041 4.99587C20.7623 6.75403 21.75 9.1386 21.75 11.625C21.75 14.1114 20.7623 16.496 19.0041 18.2541C17.246 20.0123 14.8614 21 12.375 21H4.46906C4.0796 20.9995 3.70622 20.8446 3.43083 20.5692C3.15543 20.2938 3.0005 19.9204 3 19.5309V11.625C3.00273 9.13943 3.99133 6.75645 5.74889 4.99889ZM16.7304 14.4357C15.6992 16.2226 13.9751 17.2482 12.0063 17.2482C10.0376 17.2482 8.31634 16.2235 7.28228 14.4357C7.13953 14.1778 7.10374 13.8741 7.18261 13.59C7.26148 13.306 7.44871 13.0642 7.70401 12.9168C7.95931 12.7693 8.26229 12.728 8.54775 12.8017C8.83321 12.8754 9.07834 13.0582 9.2304 13.3107C9.59509 13.9417 10.4407 14.9982 12.0063 14.9982C13.572 14.9982 14.4176 13.9407 14.7823 13.3107C14.8547 13.18 14.9524 13.0649 15.0697 12.9723C15.187 12.8797 15.3216 12.8115 15.4656 12.7715C15.6097 12.7315 15.7602 12.7206 15.9085 12.7394C16.0567 12.7583 16.1998 12.8065 16.3292 12.8812C16.4586 12.956 16.5719 13.0558 16.6623 13.1748C16.7527 13.2938 16.8186 13.4296 16.8559 13.5743C16.8933 13.719 16.9014 13.8698 16.8799 14.0177C16.8583 14.1656 16.8075 14.3077 16.7304 14.4357Z"
                fill="#5F1D1B"
              />
            </svg>
            Help
          </div>
        ) : (
          // ["/profile"].includes(location.pathname) && (
          //   <div
          //     className="watsapp_main"
          //     style={{ top: "20px" }}
          //     onClick={() => {
          //       window.open("https://wa.me/919731107947");
          //     }}
          //   >
          //     <svg
          //       width="24"
          //       height="24"
          //       viewBox="0 0 24 24"
          //       fill="none"
          //       xmlns="http://www.w3.org/2000/svg"
          //     >
          //       <path
          //         fill-rule="evenodd"
          //         clip-rule="evenodd"
          //         d="M5.74889 4.99889C7.50645 3.24133 9.88943 2.25273 12.375 2.25C14.8614 2.25 17.246 3.23772 19.0041 4.99587C20.7623 6.75403 21.75 9.1386 21.75 11.625C21.75 14.1114 20.7623 16.496 19.0041 18.2541C17.246 20.0123 14.8614 21 12.375 21H4.46906C4.0796 20.9995 3.70622 20.8446 3.43083 20.5692C3.15543 20.2938 3.0005 19.9204 3 19.5309V11.625C3.00273 9.13943 3.99133 6.75645 5.74889 4.99889ZM16.7304 14.4357C15.6992 16.2226 13.9751 17.2482 12.0063 17.2482C10.0376 17.2482 8.31634 16.2235 7.28228 14.4357C7.13953 14.1778 7.10374 13.8741 7.18261 13.59C7.26148 13.306 7.44871 13.0642 7.70401 12.9168C7.95931 12.7693 8.26229 12.728 8.54775 12.8017C8.83321 12.8754 9.07834 13.0582 9.2304 13.3107C9.59509 13.9417 10.4407 14.9982 12.0063 14.9982C13.572 14.9982 14.4176 13.9407 14.7823 13.3107C14.8547 13.18 14.9524 13.0649 15.0697 12.9723C15.187 12.8797 15.3216 12.8115 15.4656 12.7715C15.6097 12.7315 15.7602 12.7206 15.9085 12.7394C16.0567 12.7583 16.1998 12.8065 16.3292 12.8812C16.4586 12.956 16.5719 13.0558 16.6623 13.1748C16.7527 13.2938 16.8186 13.4296 16.8559 13.5743C16.8933 13.719 16.9014 13.8698 16.8799 14.0177C16.8583 14.1656 16.8075 14.3077 16.7304 14.4357Z"
          //         fill="#5F1D1B"
          //       />
          //     </svg>
          //     Help
          //   </div>
          // )
          ""
        )}

        <ImagesCol />
        {/* <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        /> */}
      </Provider>
    </>
  );
}
export default App;
