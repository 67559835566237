import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { useNavigate, useParams } from "react-router-dom";
import { ArticleContent } from "../../Blogs/ArticleContent";
export const GuideBuyingPage = ({
  AllData,
  setAllData,
  GuideData,
  setPages,
  setOpenCurated,
}) => {
  const navi = useNavigate();
  //
  //
  let token = localStorage.getItem("user_token");
  const [IsAnim, setIsAnim] = useState(true);
  const [CurrentCount, setCurrentCount] = useState(0);
  const ratingBox = useRef();
  var ratingSettingBox = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: IsAnim,
    speed: 1000,
    autoplaySpeed: 2500,
    swipeToSlide: true,
    arrows: false,
    beforeChange: (current, next) => {
      setCurrentCount(next);
    },
    onSwipe: () => {
      setIsAnim(false);
    },
  };

  return GuideData?.price ? (
    <div className="inner_giude_page_1">
      <div
        className="inner_giude_backbtn"
        onClick={() => {
          setOpenCurated(false);
          setPages("");
          navi("/guide/all");
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3996_376)">
            <path
              d="M8.33333 15.8334L2.5 10.0001M2.5 10.0001L8.33333 4.16675M2.5 10.0001H17.5"
              stroke="#5F1D1B"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3996_376">
              <rect
                width="20"
                height="20"
                rx="10"
                transform="matrix(-1 0 0 1 20 0)"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div className="inner_giude_container">
        <Slider ref={ratingBox} {...ratingSettingBox}>
          {GuideData?.product_images?.map((el, i) => {
            return (
              <div className="inner_giude_box" key={i}>
                <img src={el} />
              </div>
            );
          })}
        </Slider>
        <div className="rating_dots_container">
          {GuideData?.product_images?.map((el, i) => {
            return (
              <div
                className={
                  "rating_dots " +
                  (CurrentCount == i ? "rating_dots_active" : "")
                }
                onMouseEnter={() => {
                  ratingBox.current.slickPause();
                }}
                onMouseLeave={() => {
                  ratingBox.current.slickPlay();
                }}
                onClick={() => {
                  setCurrentCount(i);
                  ratingBox.current.slickGoTo(i);
                  setIsAnim(false);
                  // rating.current.slickPause(i);
                }}
              ></div>
            );
          })}
        </div>
      </div>

      <div className="inner_giude_page_1_data">
        <div className="inner_giude_page_1_data_title">{GuideData?.title}</div>
        <div className="inner_giude_page_1_data_list">
          {/* <span>[Description] Includes:</span> */}
          <ArticleContent content={GuideData?.description} />
          {/* {AllData?.goal_plan?.list?.map((el, i) => (
            <div key={i} className="inner_giude_page_1_data_list_dots">
              {el}
            </div>
          ))} */}
        </div>
      </div>
      <div className="buy_btn_box">
        <div
          className="buy_btn"
          onClick={() => {
            // if (token) {
            //   setPages("payment");
            // } else {
            //   setPages("login");
            // }
          }}
        >
          {/* {token ? `Buy for ${GuideData?.price}` : "Login"} */}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};
