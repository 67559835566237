import React from "react";
import TrackingArva from "../../component/CheckOut/Intake/TrackingArva";
import { ProfileMedical } from "./ProfileMedical";
import { Notification } from "./Notification";
import { LavelReport } from "./LavelReport";
import { ReferPage } from "./ReferPage";

export const ProfileType = ({ page_name, otherData }) => {
  //
  const page_type = {
    tracking: (
      <TrackingArva
        {...{
          setRangeCount: 0,
          pagevalues: otherData?.PageValueIntake,
          setPagevalues: otherData?.setPageValueIntake,
          pagedetail: otherData?.PageValue2,
          singleBeginRange: false,
          profile: true,
          lastdata: otherData.lastdata,
          setlastdata: otherData.setlastdata,
          AllData: otherData.AllData,
        }}
      />
    ),
    profilemedical: (
      <ProfileMedical
        {...{
          pagevalues: otherData?.PageValueIntake,
          setPagevalues: otherData?.setPageValueIntake,
          pagedetail: otherData?.PageValue2,
          singleBeginRange: false,
          profile: true,
          alldata: otherData.AllData,
        }}
      />
    ),
    notifications: (
      <Notification
        {...{
          setRangeCount: 0,
          pagevalues: otherData?.PageValueIntake,
          setPagevalues: otherData?.setPageValueIntake,
          pagedetail: otherData?.PageValue2,
          singleBeginRange: false,
          profile: true,
        }}
      />
    ),
    labreports: (
      <LavelReport
        {...{
          setRangeCount: 0,
          pagevalues: otherData?.PageValueIntake,
          setPagevalues: otherData?.setPageValueIntake,
          pagedetail: otherData?.PageValue2,
          AllData: otherData?.AllData,
          singleBeginRange: false,
          profile: true,
        }}
      />
    ),
  };

  if (page_type[page_name]) {
    return page_type[page_name];
  } else {
    return <div>No Pages</div>;
  }
};
