import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const BottomNav = ({ current }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [BottomNavList, setBottomNavList] = useState([
    {
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
        >
          <path
            d="M3 14L5.44444 11.5556M5.44444 11.5556L14 3L22.5556 11.5556M5.44444 11.5556V23.7778C5.44444 24.1019 5.57321 24.4128 5.80243 24.642C6.03164 24.8712 6.34251 25 6.66667 25H10.3333M22.5556 11.5556L25 14M22.5556 11.5556V23.7778C22.5556 24.1019 22.4268 24.4128 22.1976 24.642C21.9684 24.8712 21.6575 25 21.3333 25H17.6667M10.3333 25C10.6575 25 10.9684 24.8712 11.1976 24.642C11.4268 24.4128 11.5556 24.1019 11.5556 23.7778V18.8889C11.5556 18.5647 11.6843 18.2539 11.9135 18.0246C12.1427 17.7954 12.4536 17.6667 12.7778 17.6667H15.2222C15.5464 17.6667 15.8573 17.7954 16.0865 18.0246C16.3157 18.2539 16.4444 18.5647 16.4444 18.8889V23.7778C16.4444 24.1019 16.5732 24.4128 16.8024 24.642C17.0316 24.8712 17.3425 25 17.6667 25M10.3333 25H17.6667"
            stroke="#1D1D1D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      sep: false,

      name: "Home",
      linkto: "/home /care-history",
    },

    {
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
        >
          <path
            d="M15.1667 8.1665H24.5M24.5 8.1665V17.4998M24.5 8.1665L15.1667 17.4998L10.5 12.8332L3.5 19.8332"
            stroke="#777777"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      sep: false,

      name: "Results",
      linkto: "/result",
    },
    {
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9.663 17H14.336M12 3V4M18.364 5.636L17.657 6.343M21 12H20M4 12H3M6.343 6.343L5.636 5.636M8.464 15.536C7.76487 14.8367 7.2888 13.9458 7.09598 12.9759C6.90316 12.006 7.00225 11.0008 7.38073 10.0872C7.75921 9.17366 8.40007 8.39284 9.22229 7.84349C10.0445 7.29414 11.0111 7.00093 12 7.00093C12.9889 7.00093 13.9555 7.29414 14.7777 7.84349C15.5999 8.39284 16.2408 9.17366 16.6193 10.0872C16.9977 11.0008 17.0968 12.006 16.904 12.9759C16.7112 13.9458 16.2351 14.8367 15.536 15.536L14.988 16.083C14.6747 16.3963 14.4262 16.7683 14.2567 17.1777C14.0872 17.5871 13.9999 18.0259 14 18.469V19C14 19.5304 13.7893 20.0391 13.4142 20.4142C13.0391 20.7893 12.5304 21 12 21C11.4696 21 10.9609 20.7893 10.5858 20.4142C10.2107 20.0391 10 19.5304 10 19V18.469C10 17.574 9.644 16.715 9.012 16.083L8.464 15.536Z"
            stroke="#777777"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      sep: true,
      name: "Explore",
      linkto: ["/article/all", "/guide/all", "/clinic/all", "/faq/all"],
      common: ["article", "guide", "faq", "clinic"],
    },
    {
      name: "Profile",
      linkto: "/profile",
      sep: false,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M5.121 17.804C7.21942 16.6179 9.58958 15.9963 12 16C14.5 16 16.847 16.655 18.879 17.804M15 10C15 10.7956 14.6839 11.5587 14.1213 12.1213C13.5587 12.6839 12.7956 13 12 13C11.2044 13 10.4413 12.6839 9.87868 12.1213C9.31607 11.5587 9 10.7956 9 10C9 9.20435 9.31607 8.44129 9.87868 7.87868C10.4413 7.31607 11.2044 7 12 7C12.7956 7 13.5587 7.31607 14.1213 7.87868C14.6839 8.44129 15 9.20435 15 10ZM21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
            stroke="#777777"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ]);
  //   const [BottomNavSate, setBottomNavSate] = useState(false);
  const [BottomNavActive, setBottomNavActive] = useState("Home");
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom_nav_main">
        <div className="inner_bottom_nav_main">
          {BottomNavList.map((el, i) => (
            <div
              key={i}
              className={
                "inner_bottom_nav_box " +
                ((
                  el.sep
                    ? el.common.includes(location?.pathname?.split("/")[1])
                    : el.linkto.includes(location?.pathname)
                )
                  ? // (location.pathname === el.linkto
                    " inner_bottom_nav_box_active"
                  : "")
              }
              onClick={() => {
                setBottomNavActive(el.name);
                if (el.linkto.includes("/article/all")) {
                  if (!el.linkto.includes(window.location.pathname)) {
                    navigate("/article/all");
                  }
                  scrollToTop();
                  return;
                }
                navigate(el?.linkto?.split(" ")[0]);

                scrollToTop();
              }}
            >
              {el.svg}
              {el.name}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
