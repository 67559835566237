import React, { useEffect, useState } from "react";
import { useRef } from "react";
import Slider from "react-slick";

export const StoryContainer = ({ data }) => {
  const story = useRef();
  const videoCtr = useRef();
  const [Count, setCount] = useState(0);
  const [Type, setType] = useState(data?.storyArray[0]?.type);
  const [CurrentStory, setCurrentStory] = useState(0);
  const [Stop, setStop] = useState(false);

  var storySetting = {
    dots: false,
    waitForAnimate: false,
    fade: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 2500,
    swipeToSlide: false,
    arrows: false,
    beforeChange: (current, next) => {
      setCurrentStory(next);
      setCount(0);
    },
  };

  useEffect(() => {
    const targetNumber = 100;

    if (!Stop) {
      //
      if (Count < targetNumber) {
        if (Type == "img") {
          //
          const interval = setInterval(() => {
            setCount(Count + 1);
          }, 20);
          return () => {
            clearInterval(interval);
          };
        } else {
          //
          if (videoCtr.current) {
            videoCtr.current.addEventListener("timeupdate", () => {
              setCount(
                (videoCtr?.current?.currentTime / videoCtr?.current?.duration) *
                  100 || 0
              );
            });
          }
          return () => {
            if (videoCtr.current) {
              videoCtr.current.removeEventListener("timeupdate", () => {
                setCount(
                  (videoCtr?.current?.currentTime /
                    videoCtr?.current?.duration) *
                    100 || 0
                );
              });
            }
          };
        }
      } else {
        setCount(0);
        if (data?.storyArray?.length > CurrentStory + 1) {
          setCurrentStory(CurrentStory + 1);
          story.current.slickGoTo(CurrentStory + 1);
          setType(data?.storyArray[CurrentStory + 1]?.type);
        } else {
          setCount(100);
        }
      }
    }
  }, [Count, CurrentStory, data?.storyArray, Stop, Type]);
  //
  const togglePlay = () => {
    if (videoCtr.current) {
      if (!Stop) {
        videoCtr?.current?.pause();
      } else {
        videoCtr?.current?.play();
      }
    }
  };
  return (
    <div className="story_container">
      <div className="story_close">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            data.setOpenPages("");
          }}
        >
          <path
            d="M19.5488 17.954C19.7602 18.1653 19.8789 18.452 19.8789 18.7509C19.8789 19.0497 19.7602 19.3364 19.5488 19.5477C19.3375 19.7591 19.0508 19.8778 18.752 19.8778C18.4531 19.8778 18.1664 19.7591 17.9551 19.5477L12.0029 13.5937L6.04883 19.5459C5.83748 19.7572 5.55084 19.8759 5.25195 19.8759C4.95307 19.8759 4.66642 19.7572 4.45508 19.5459C4.24373 19.3345 4.125 19.0479 4.125 18.749C4.125 18.4501 4.24373 18.1635 4.45508 17.9521L10.4091 11.9999L4.45695 6.04586C4.24561 5.83451 4.12688 5.54787 4.12688 5.24898C4.12688 4.9501 4.24561 4.66345 4.45695 4.45211C4.6683 4.24076 4.95494 4.12203 5.25383 4.12203C5.55271 4.12203 5.83936 4.24076 6.0507 4.45211L12.0029 10.4062L17.957 4.45117C18.1683 4.23983 18.4549 4.12109 18.7538 4.12109C19.0527 4.12109 19.3394 4.23983 19.5507 4.45117C19.762 4.66251 19.8808 4.94916 19.8808 5.24804C19.8808 5.54693 19.762 5.83358 19.5507 6.04492L13.5966 11.9999L19.5488 17.954Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="story_control">
        <div
          className="story_prev"
          //   style={{ backgroundColor: "green" }}
          onClick={() => {
            story.current.slickPrev();
            setStop(false);
            setCount(0);
            setType(
              data?.storyArray[CurrentStory == 0 ? 0 : CurrentStory - 1]?.type
            );
          }}
        ></div>
        <div
          className="story_pause"
          //   style={{ backgroundColor: "pink" }}
          onClick={() => {
            setStop((prev) => !prev);

            togglePlay();
          }}
        ></div>
        <div
          className="story_next"
          //   style={{ backgroundColor: "orange" }}
          onClick={() => {
            story.current.slickNext();
            setStop(false);
            setCount(0);
            setType(
              data?.storyArray[
                CurrentStory == data?.storyArray.length
                  ? data?.storyArray.length
                  : CurrentStory + 1
              ]?.type
            );
          }}
        ></div>
      </div>
      <div className="story_img_section">
        <div className="story_img_section_data">
          <div className="story_name">{data?.ClinicsDetail?.name}</div>
          <div className="story_address">{data?.ClinicsDetail?.address}</div>
          <div className="story_phone">{data?.ClinicsDetail?.phone_number}</div>
          <div className="story_area">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.3">
                <path
                  d="M11.7687 11.1045L8.94006 13.9331C8.81636 14.057 8.66946 14.1552 8.50777 14.2222C8.34608 14.2892 8.17276 14.3237 7.99773 14.3237C7.8227 14.3237 7.64938 14.2892 7.48769 14.2222C7.326 14.1552 7.1791 14.057 7.0554 13.9331L4.22607 11.1045C3.48021 10.3586 2.97229 9.40827 2.76652 8.37371C2.56076 7.33916 2.66639 6.26681 3.07007 5.29229C3.47374 4.31777 4.15733 3.48483 5.03439 2.89881C5.91144 2.31279 6.94258 2 7.9974 2C9.05222 2 10.0834 2.31279 10.9604 2.89881C11.8375 3.48483 12.5211 4.31777 12.9247 5.29229C13.3284 6.26681 13.434 7.33916 13.2283 8.37371C13.0225 9.40827 12.5146 10.3586 11.7687 11.1045Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 7.33203C10 7.86246 9.78929 8.37117 9.41421 8.74625C9.03914 9.12132 8.53043 9.33203 8 9.33203C7.46957 9.33203 6.96086 9.12132 6.58579 8.74625C6.21071 8.37117 6 7.86246 6 7.33203C6 6.8016 6.21071 6.29289 6.58579 5.91782C6.96086 5.54274 7.46957 5.33203 8 5.33203C8.53043 5.33203 9.03914 5.54274 9.41421 5.91782C9.78929 6.29289 10 6.8016 10 7.33203Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
            {data?.ClinicsDetail?.area}
          </div>
        </div>
        <Slider ref={story} {...storySetting}>
          {data?.storyArray?.map((el, i) => (
            <div key={i} className="story_box">
              <div className="story_box_gradient"></div>
              {el.type == "video" && CurrentStory == i ? (
                <video autoPlay ref={videoCtr}>
                  <source src={el.img} />
                </video>
              ) : (
                <img src={el.img} />
              )}
            </div>
          ))}
        </Slider>
      </div>
      <div className="story_lines_cont">
        {data?.storyArray?.map((el, i) => (
          <div
            key={i}
            className="story_lines"
            onClick={() => {
              setCurrentStory(i);
              story.current.slickGoTo(i);
            }}
          >
            <div
              style={
                CurrentStory >= i
                  ? {
                      width: CurrentStory > i ? "100%" : `${Count}%`,
                      backgroundColor: "white",
                      height: "2px",
                    }
                  : {}
              }
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};
