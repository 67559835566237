import React, { useEffect, useState } from "react";
import { GetInvoice } from "../../component/API/def_api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Arva_logo from "../../img/ARVA.svg";
import sign from "../../img/signature.png";
import { Table } from "../../component/Table";
import axios from "axios";

var a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
var b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

function inWords(num) {
  if ((num = num.toString()).length > 9) return "overflow";
  let n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        "INR only /-"
      : "zero INR only /-";
  return str;
}

export const PrintInvoice = ({ setloading }) => {
  const params = useParams();
  const [pagedata, setpagedata] = useState({
    // invoice_no: "{invoice_id}",
    // created_on: "{created_on}",
    // table_data: ["{Product}", "{hsn}", "{quantity}", "{Price}", "{Total}"],
    // final_amount: "{grand_total}",
    // company: {
    //   address: "{company_address}",
    //   img: "{comapny_img}",
    // },
    // user: {
    //   address: "",
    //   apt: "",
    //   city: "",
    //   zipcode: "",
    // },
  });
  const [state, setstate] = useState("");
  const [tax_calc, settax_calc] = useState({
    base_price: 0,
    discount: 0,
    tax: 0,
    final: 0,
  });

  useEffect(() => {
    setloading("type1");
    GetInvoice({ type: params.invoice_id }, (res) => {
      if (!res.status) {
        setTimeout(() => {
          window.location.replace("https://arva.health/404");
        }, 3000);
        return toast(
          <div dangerouslySetInnerHTML={{ __html: res.response }} />
        );
      }
      getstate(res.response.data?.data?.sheet_data[6]);
      let base_price_of_order = res.response.data.data.sheet_data[16];
      let final = res.response.data.data.sheet_data[18];
      let base_price = Math.round(base_price_of_order / 1.18);
      let t_base_price = {
        base_price: base_price,
        // discount: -1 * base_price + final /  1.18,
        tax: Math.round(final * (0.18 / 1.18)),
        final: final,
      };
      settax_calc({
        ...t_base_price,
        discount:
          t_base_price.final - t_base_price.base_price - t_base_price.tax,
      });
      setpagedata((prev) => ({ ...prev, ...res.response }));
      setloading(false);
    });
  }, []);

  async function getstate(pincode) {
    return await axios
      .get(`https://api.postalpincode.in/pincode/` + pincode)
      .then((response) => response.data)
      .then((res) => {
        try {
          let state = res[0]?.PostOffice[0]?.State || "";
          setstate(state);
        } catch (err) {}
      });
    // const karnatakaPincodeRange = [
    //   [560001, 569999],
    //   [580001, 589999],
    //   [591001, 591999],
    // ];
    // return karnatakaPincodeRange.some(
    //   (range) => pincode >= range[0] && pincode <= range[1]
    // );
  }

  return (
    <div className="print_invoice1">
      <h1 style={{ textAlign: "center", fontWeight: 600, fontSize: 25 }}>
        Tax Invoice
      </h1>
      <div className="section1">
        <div className="section1_left">
          <div>
            <span className="font_bold">Invoice No: </span>
            {String(pagedata?.invoice?.invoice_no).split("_")[0]}
          </div>
          <div>
            <span className="font_bold">Invoice date: </span>
            {pagedata?.invoice?.created_on}
          </div>
          <div>
            <span className="font_bold">Booking No: </span>
            {pagedata?.invoice?.invoice_no}
          </div>
          <div>
            <span className="font_bold">Place of supply: </span>
            {/* {isPincodeFromKarnataka(pagedata?.data?.data?.sheet_data[6])
              ? "Karnataka"
              : "Outisde of Karnataka"} */}
            {state}
          </div>
        </div>
        <div className="section1_right">
          <img src={Arva_logo} alt="arva_logo" />
        </div>
      </div>
      <div className="section2">
        <div>
          <div className="title">Deliver by</div>
          <div className="mb">
            <div className="font_bold">Arva Health Pvt Ltd</div>
            No. 55, 17th Main, KHB Colony, 5th Block, Bangalore, Karnataka,
            560095
          </div>
          <div className="mb">
            <div>
              <span className="font_bold">Phone: </span>+91 88676 08376
            </div>
            <div>
              <span className="font_bold">Email: </span>support@arva.health
            </div>
          </div>
          <div>
            <span className="font_bold">GSTIN: </span>29AAWCA9717B1Z8
          </div>
          <div>
            <span className="font_bold">CIN: </span>
            U85199KA2022PTC161053
          </div>
        </div>
        <div>
          <div className="title">Invoice to</div>
          <div className="db">
            <span className="font_bold">
              {pagedata?.data?.data?.sheet_data[2]}
            </span>
            <div>
              {/* <div>
            <div className="font_bold">Addres:</div> */}
              {/* {pagedata?.data?.data?.sheet_data[5]} */}
              {[
                pagedata?.data?.data?.sheet_data[10],
                pagedata?.data?.data?.sheet_data[5],
                pagedata?.data?.data?.sheet_data[19],
                pagedata?.data?.data?.sheet_data[11],
                pagedata?.data?.data?.sheet_data[6],
              ].join(", ")}
              {/* </div> */}
            </div>
          </div>
          <div className="mb">
            <div>
              <span className="font_bold">Phone: </span>
              {pagedata?.data?.data?.sheet_data[4].slice(0, 3) +
                " " +
                pagedata?.data?.data?.sheet_data[4].slice(3, 8) +
                " " +
                pagedata?.data?.data?.sheet_data[4].slice(8)}
            </div>
            <div>
              <span className="font_bold">Email: </span>
              {pagedata?.data?.data?.sheet_data[3]}
            </div>
          </div>
          <div className="mb">
            <div>
              <span className="font_bold">GSTIN: </span>N/A
            </div>
          </div>
        </div>
      </div>
      {/* <div className="section3 table">
        <div className="table_header">
          <div className="col1">Description</div>
          <div className="col2">SAC</div>
          <div className="col3">Qty</div>
          <div className="col4">
            Unit price
            <br />
            (excl GST)
          </div>
          <div className="col3">Discount</div>
          <div className="col4">
            Net Unit price
            <br />
            (excl GST)
          </div>
          <div className="col3">CGST</div>
          <div className="col3">SGST</div>
          <div className="col3">IGST</div>
          <div className="col5">
            Total
            <br />
            (incl GST)
          </div>
        </div>
        <div className="table_body">
          <div className="row">
            <div className="col1">
              <div>Healthcare services</div>
              <div>{pagedata?.data?.data?.sheet_data[9]}</div>
            </div>
            <div className="col2">99319</div>
            <div className="col3">1</div>
            <div className="col4">{tax_calc.base_price}</div>
            <div className="col3">-{Math.abs(tax_calc.discount)}</div>
            <div className="col4">
              {tax_calc.base_price - Math.abs(tax_calc.discount)}
            </div>

            <div className="col3">
              {state?.toLowerCase() == "karnataka" ? tax_calc.tax / 2 : 0}
            </div>
            <div className="col3">
              {state?.toLowerCase() == "karnataka" ? tax_calc.tax / 2 : 0}
            </div>
            <div className="col3">
              {state?.toLowerCase() != "karnataka" ? tax_calc.tax : 0}
            </div>
            <div className="col5">{tax_calc.final}</div>
           
          </div>
        </div>
      </div> */}
      <table className="invoice_table">
        <tr className="table_header">
          <td className="col1">Description</td>
          <td className="col2">SAC</td>
          <td className="col3">Qty</td>
          <td className="col4">
            Unit price
            <br />
            (excl GST)
          </td>
          <td className="col3">Discount</td>
          <td className="col4">
            Net Unit price
            <br />
            (excl GST)
          </td>
          <td className="col3">
            CGST
            <br />
            (9%)
          </td>
          <td className="col3">
            SGST
            <br />
            (9%)
          </td>
          <td className="col3">
            IGST
            <br />
            (18%)
          </td>
          <td className="col5">
            Total
            <br />
            (incl GST)
          </td>
        </tr>
        <tr className="table_body">
          <td className="col1">
            <div>Healthcare services</div>
            <div>{pagedata?.data?.data?.sheet_data[9]}</div>
          </td>
          <td className="col2">999319</td>
          <td className="col3">1</td>
          <td className="col4">{tax_calc.base_price}</td>
          <td className="col3">-{Math.abs(tax_calc.discount)}</td>
          <td className="col4">
            {tax_calc.base_price - Math.abs(tax_calc.discount)}
          </td>

          <td className="col3">
            {state?.toLowerCase() == "karnataka" ? tax_calc.tax / 2 : 0}
          </td>
          <td className="col3">
            {state?.toLowerCase() == "karnataka" ? tax_calc.tax / 2 : 0}
          </td>
          <td className="col3">
            {state?.toLowerCase() != "karnataka" ? tax_calc.tax : 0}
          </td>
          <td className="col5">{tax_calc.final}</td>
        </tr>
      </table>
      {/* <div className="section4">*Healthcare services are exempt from GST</div> */}
      <div className="section5">
        {false && (
          <>
            <div>
              <div className="font_bold">
                Total amount before tax GST (SGST / UGST / CGST / IGST)
              </div>
              <div>
                {/* Rs.{Math.round(pagedata?.data?.data?.sheet_data[16] / 1)} */}
                {/* Rs.{Math.round(pagedata?.data?.data?.sheet_data[16] / 1.18)} */}
                {tax_calc.base_price}
              </div>
            </div>
            <div className="discount">
              <div className="font_bold">Discount</div>
              <div>{pagedata?.data?.data?.sheet_data[14]}</div>
              <div>
                -{Math.abs(tax_calc.discount)}
                {/* {pagedata?.data?.data?.sheet_data[17] < 0
              ? 0
              : pagedata?.data?.data?.sheet_data[17]} */}
              </div>
            </div>

            {state?.toLowerCase() == "karnataka" ? (
              <>
                <div>
                  <div className="font_bold">Tax (SGST)</div>
                  <div>
                    +{tax_calc.tax / 2}
                    {/* {Math.round(
                  (pagedata?.data?.data?.sheet_data[16] / 1.18) * 0.18
                ) / 2} */}
                    {/* {Math.round(pagedata?.data?.data?.sheet_data[16] / 0.18)} */}
                  </div>
                </div>
                <div>
                  <div className="font_bold">Tax (CGST)</div>
                  <div>
                    +{tax_calc.tax / 2}
                    {/* {Math.round(
                  (pagedata?.data?.data?.sheet_data[16] / 1.18) * 0.18
                ) / 2} */}
                    {/* {Math.round(pagedata?.data?.data?.sheet_data[16] / 0.18)} */}
                  </div>
                </div>
              </>
            ) : (
              <div>
                <div className="font_bold">Tax (IGST)</div>
                <div>
                  +{tax_calc.tax}
                  {/* {Math.round((pagedata?.data?.data?.sheet_data[16] / 1.18) * 0.18)} */}
                  {/* {Math.round(pagedata?.data?.data?.sheet_data[16] / 0.18)} */}
                </div>
              </div>
            )}
          </>
        )}
        <div>
          <div className="font_bold">
            Total amount payable
            {/* inclusive of GST tax (SGST / UGST / CGST /
            IGST) */}
          </div>
          <div>Rs.{tax_calc.final}</div>
          {/* <div>Rs.{pagedata?.data?.data?.sheet_data[18]}</div> */}
        </div>
        {/* <div>
          <div className="font_bold">
            Amount of tax to be paid under Reverse Charge
          </div>
          <div>NiL</div>
        </div> */}
      </div>
      <div className="section6">
        "I/ We certify that my/ our registration under the CGST, SGST & IGST Act
        (s) is in force on the date on which the services as specified in this
        "Bill of Supply" has been provided by me/ us and it shall be accounted
        for in the turnover of services while filling of return and the due tax,
        if any, payable on the services has been paid or shall be paid.
      </div>

      <div className="section7 footer_lite">
        <div>
          {/* This is a computer generated invoice. No signatory is required. */}
          <img style={{ width: 50, margin: "8px auto 0 0" }} src={sign} />
          <div className="mb">
            <div className="font_bold">Nidhi Panchmal</div>
            {/* Founder */}
          </div>
        </div>
        <div
          style={{
            margin: "auto 0 0 0",
          }}
        >
          Original for recipient.
        </div>
      </div>
      <div className="footer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="47"
          height="13"
          viewBox="0 0 47 13"
          fill="none"
        >
          <path
            d="M1.10025 12H2.79625C2.90825 12 3.03625 11.936 3.08425 11.808L4.02825 9.392H9.42025L10.3643 11.808C10.4123 11.936 10.5403 12 10.6523 12H12.3483C12.6203 12 12.8123 11.76 12.7003 11.488L8.57225 0.992C8.52425 0.863999 8.39625 0.799999 8.28425 0.799999H5.16425C5.05225 0.799999 4.92425 0.863999 4.87625 0.992L0.74825 11.488C0.65225 11.744 0.82825 12 1.10025 12ZM4.86025 7.312L6.73225 2.448L8.62025 7.312H4.86025ZM23.2244 12.096C23.4964 12.096 23.7044 12.048 23.7044 11.616V10.336C23.7044 10.096 23.6404 10.016 23.3844 10C22.6004 9.968 22.5684 8.464 21.5444 7.52C22.5524 6.896 23.1124 5.856 23.1124 4.528C23.1124 2.288 21.5284 0.799999 18.9844 0.799999H14.1044C13.9604 0.799999 13.8644 0.879999 13.8644 1.04V11.76C13.8644 11.904 13.9604 12 14.1044 12H15.8644C16.0244 12 16.1044 11.904 16.1044 11.76V8.256H18.5844C21.0324 8.256 19.9604 12.096 23.2244 12.096ZM16.1044 6.176V2.88H19.0644C20.1844 2.88 20.8724 3.456 20.8724 4.528C20.8724 5.6 20.1844 6.176 19.0644 6.176H16.1044ZM28.6211 12H30.7651C30.8771 12 31.0051 11.936 31.0531 11.808L35.5011 1.312C35.6131 1.04 35.4211 0.799999 35.1491 0.799999H33.3411C33.2291 0.799999 33.1011 0.863999 33.0531 0.992L29.6931 9.424L26.3331 0.992C26.2851 0.863999 26.1571 0.799999 26.0451 0.799999H24.2371C23.9651 0.799999 23.7731 1.04 23.8851 1.312L28.3331 11.808C28.3811 11.936 28.5091 12 28.6211 12ZM34.6471 12H36.3431C36.4551 12 36.5831 11.936 36.6311 11.808L37.5751 9.392H42.9671L43.9111 11.808C43.9591 11.936 44.0871 12 44.1991 12H45.8951C46.1671 12 46.3591 11.76 46.2471 11.488L42.1191 0.992C42.0711 0.863999 41.9431 0.799999 41.8311 0.799999H38.7111C38.5991 0.799999 38.4711 0.863999 38.4231 0.992L34.2951 11.488C34.1991 11.744 34.3751 12 34.6471 12ZM38.4071 7.312L40.2791 2.448L42.1671 7.312H38.4071Z"
            fill="white"
          />
        </svg>
        <div className="phone">
          <a href="tel:+918867608376">+91 88676 08376</a>
        </div>
        <div className="email">
          <a href="mail:support@arva.health">support@arva.health</a>
        </div>
        <div className="website">
          <a href="https://www.arva.health">www.arva.health</a>
        </div>
      </div>
    </div>
  );
};
