import React from "react";

export const CommonFooter = () => {
  return (
    <div className="CommonFooterContainer">
      {/* Certain features, content, and technology are subject to copyright with
      all rights reserved. © 2023 Arva Health Private Limited */}
    </div>
  );
};
