import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { NavbarMain } from "../NavbarMain";
// import CustInputMuiClone from "../CustInput/CustInputMuiClone";
import OtpStand from "./CheckOut/OtpStand/OtpStand";
import CustInputMuiClone from "./CustInput/CustInputMuiClone";
// import { SendOtp } from "../API/api";
// import { MixPanelOtp, MixPanelUsertype } from "../API/automation_api";
// import { CommonFooter } from "../CommonFooter";
import { ReactComponent as Flag } from "../img/flag.svg";
import { SendOtp } from "./API/api";
import { MixPanelOtp, MixPanelUsertype } from "./API/automation_api";

export const CommonLogin = ({
  setAllData,
  GuideData,
  setPages,
  setOpenCurated,
}) => {
  const handle_pagevalues = (updated_data) => {
    return setpagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  const handle_errpagevalues = (updated_data) => {
    return seterr_pagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  const navigate = useNavigate();
  const [NavBarActive, setNavBarActive] = useState("");
  const [NavDropActive, setNavDropActive] = useState(false);
  const [OtpStandVis, setOtpStandVis] = useState(false);
  const [OtpNumber, setOtpNumber] = useState("");
  const [OtpVerified, setOtpVerified] = useState(false);
  const [Token, setToken] = useState("");
  const [pagevalues, setpagevalues] = useState({
    phone: "",
  });
  const [err_pagevalues, seterr_pagevalues] = useState({
    phone: false,
  });
  const [GoBackHome, setGoBackHome] = useState(false);
  // const [MiniNavi, setMiniNavi] = useState(false);
  const [EditPhone, setEditPhone] = useState(false);
  const [Resend, setResend] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "setCheckToken",
      payload: {
        updated: false,
      },
    });
    if (OtpVerified) {
      localStorage.setItem("user_token", Token);
      //   navigate("/home");
      // setPleaseLogin(false)
      setPages("payment");
    }
  }, [OtpVerified]);

  const handel_login = () => {
    if (!/^(?:\d{5}\s\d{5})$/.test(pagevalues.phone)) {
      handle_errpagevalues({
        phone: !/^(?:\d{5}\s\d{5})$/.test(pagevalues.phone),
      });
      return;
    }
    localStorage.setItem("OrderPhoneNo", pagevalues.phone);
    if (EditPhone) {
      MixPanelOtp({
        edit: true,
        resend: Resend,
        remaining: {
          sign_up_date: "N/A",
          user_id: "N/A",
          last_order_type: "N/A",
          name: "N/A",
          birthdate: "N/A",
          is_test_user: false,
          user_current_goal: "N/A",
          is_paid_user: false,
          reported_symptoms: "N/A",
          reported_conditions: "N/A",
          is_ultrasound_done: false,
          is_birth_control: false,
        },
      });
    }

    SendOtp({ pagevalues }, (res) => {
      setOtpNumber(res.response.otp);
      setToken(res.response.token);
      if (res.response.status) {
        setOtpStandVis(true);
        MixPanelUsertype({
          first_order: res.response.is_new_user,
          remaining: {
            sign_up_date: "N/A",
            user_id: "N/A",
            last_order_type: "N/A",
            name: "N/A",
            birthdate: "N/A",
            is_test_user: false,
            user_current_goal: "N/A",
            is_paid_user: false,
            reported_symptoms: "N/A",
            reported_conditions: "N/A",
            is_ultrasound_done: false,
            is_birth_control: false,
          },
        });
      }
    });
  };

  return (
    <>
      <div className="loginhomeuser_main">
        <div className="inner_loginhomeuser_main">
          {/* <div className="inner_loginhomeuser_title">Welcome to Arva</div> */}
          <div className="inner_loginhomeuser_desc">Login with your phone.</div>
          {!OtpStandVis ? (
            <div className="phone_number">
              <CustInputMuiClone
                {...{
                  attr: {
                    error: err_pagevalues.phone,
                    error_msg: "",
                    value: pagevalues.phone,
                    inputMode: "numeric",
                    event: {
                      change: ({ target }) => {
                        if (
                          Number(target.value.slice(-1)).toString() == "NaN"
                        ) {
                          return;
                        }
                        const cleanedInput = target.value.replace(/\D/g, "");
                        let formattedInput = "";
                        if (cleanedInput.length > 5) {
                          formattedInput =
                            cleanedInput.slice(0, 5) +
                            " " +
                            cleanedInput.slice(5);
                        } else {
                          formattedInput = cleanedInput;
                        }
                        if (cleanedInput.length === 11) {
                          return;
                        }
                        handle_pagevalues({
                          phone: formattedInput.slice(0, 11),
                        });
                        handle_errpagevalues({
                          phone: target.value ? "" : "Error message",
                        });
                      },
                      onkeydown: (e) => {
                        if (e.key == "Enter") {
                          handel_login();
                        }
                      },
                    },
                  },
                  data: {
                    title: "Phone",
                    prefix: <Flag />,
                  },
                }}
              />
              {/* <CustInputMuiClone
                {...{
                  attr: {
                    error: err_pagevalues.phone,
                    error_msg: "",
                    value: pagevalues.phone,
                    event: {
                      change: ({ target }) => {
                        // if (
                        //   Number(target.value.slice(-1)).toString() == "NaN"
                        // ) {
                        //   return;
                        // }
                        // const cleanedInput = target.value.replace(/\D/g, "");
                        // let formattedInput = "";
                        // if (cleanedInput.length > 5) {
                        //   formattedInput =
                        //     cleanedInput.slice(0, 5) +
                        //     " " +
                        //     cleanedInput.slice(5);
                        // } else {
                        //   formattedInput = cleanedInput;
                        // }
                        // if (cleanedInput.length === 11) {
                        //   return;
                        // }
                        // handle_pagevalues({
                        //   phone: formattedInput,
                        // });
                        // handle_errpagevalues({
                        //   phone: target.value ? "" : "Error message",
                        // });
                        handle_pagevalues({
                          phone: target.value.toLowerCase(),
                        });
                        handle_errpagevalues({
                          phone:
                            !target.value ||
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(
                              target.value
                            ),
                        });
                      },
                    },
                  },
                  data: {
                    title: "Email",
                    // prefix: "",
                  },
                }}
              /> */}
            </div>
          ) : (
            <OtpStand
              {...{
                phone_no: pagevalues.phone,
                setOtpStandVis: setOtpStandVis,
                setloading: "",
                setOtpVerified: setOtpVerified,
                pagevalues: pagevalues,
                OtpNumber: OtpNumber,
                setOtpNumber: setOtpNumber,
                BreadCrumNavHome: false,
                setpagevalues: setpagevalues,
                setGoBackHome: setGoBackHome,
                setResend: setResend,
                setEditPhone: setEditPhone,
                EditPhone: EditPhone,
                Token: Token,
              }}
            />
          )}

          {/* <GoogleLogin
            {...{
              OtpNumber: OtpNumber,
              setOtpNumber: setOtpNumber,
              setOtpVerified: setOtpVerified,
              cb: (email) => {
                localStorage.setItem("OrderPhoneNo", email);
              },
            }}
          /> */}
        </div>
      </div>
      {!OtpStandVis ? (
        <div className="login_main_bottom">
          <div
            className="login_main_btn"
            onClick={() => {
              handel_login();
            }}
          >
            Login
          </div>

          {/* <div className="login_main_bottom_title">
            By proceeding, I accept the <span>T&C</span> and{" "}
            <span>Privacy Policy</span>
            <CommonFooter />
          </div> */}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
