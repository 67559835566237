import React, { useEffect, useState } from "react";
import { HighLevel } from "../../component/Results/HighLevel";
import { LevelList } from "../../component/Results/LevelList";
import { ListReports } from "../../component/Results/ListReports";
import { Fertilty } from "../../component/Results/Fertilty";
import { Pcos } from "../../component/Results/Pcos";
import { ThyroidHealth } from "../../component/Results/ThyroidHealth";
import {
  CheckTokenUser,
  GetSanity,
  HideGoto,
  get_result_list,
} from "../../component/API/api";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LevelListForBmi } from "../../component/Results/LevelListForBmi";
import {
  MixPanelResult,
  userProperties,
} from "../../component/API/automation_api";
import { useDispatch, useSelector } from "react-redux";
import { CommonFooter } from "../../component/CommonFooter";
import { HighLevel1 } from "../../component/Results/HighLevel1";
import { LevelResultV2 } from "../../component/Results/LevelResultV2";
import { date_formatter } from "../../component/CustInput/CustInput";
import { logDOM } from "@testing-library/react";
import { result } from "lodash";

export const Level = ({
  setMiniNavi,
  MiniNavi,
  setloading,
  ListDates,
  CurrentShowingOrder,
  setCurrentShowingOrder,
  reports,
  allreport,
  setcurrreports,
}) => {
  const navigate = useNavigate();
  const [DataSelect, setDataSelect] = useState("");
  const [ArrayForHgighLow, setArrayForHgighLow] = useState([]);

  const [LevelHighLight, setLevelHighLight] = useState([
    {
      name: "Highlights",
      title: "At a glance",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M10.834 8.33333V2.5L3.33398 11.6667H9.16732V17.5L16.6673 8.33333H10.834Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Levels",
      title: "Your levels",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M10.8333 5.83325H17.5M17.5 5.83325V12.4999M17.5 5.83325L10.8333 12.4999L7.5 9.16659L2.5 14.1666"
            stroke="#454545"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ]);
  const [LevelLight, setLevelLight] = useState([
    {
      name: "Insights",
      title: "At a glance",
    },
    {
      name: "Levels",
      title: "Your levels",
    },
  ]);
  const [LevelHighClick, setLevelHighClick] = useState("At a glance");

  const [AllData, setAllData] = useState({
    last_recorder_order: {},
    last_order: {
      // ...CurrentShowingOrder,
      published: false,
      order_details: {
        level_list: [],
      },
    },
    last_intake: {},
    user_data: {},
    all_order: [],
  });
  useEffect(() => {
    setAllData((prev) => ({
      ...prev,
      last_order: {
        ...CurrentShowingOrder,
        // published: false,
        // order_details: {
        //   level_list: [],
        // },
      },
    }));
  }, [CurrentShowingOrder]);
  // useEffect(() => {

  // setAllData({

  // })
  //   return () => {
  //   }
  // }, [])

  const [BiomatrixData, setBiomatrixData] = useState({});

  const [DataListAfterFilter, setDataListAfterFilter] = useState([]);

  const [FilterArrayTest, setFilterArrayTest] = useState([]);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const fertility_main = "fertility-test-for-women";
  const current_store = useSelector((state) => state);
  const [FurtherDataMixPanel, setFurtherDataMixPanel] = useState({
    sign_up_date: "",
    user_id: "",
    last_order_type: "",
    name: "",
    birthdate: "",
    is_test_user: false,
    user_current_goal: "",
    is_paid_user: false,
    reported_symptoms: "",
    reported_conditions: "",
    is_ultrasound_done: false,
    is_birth_control: false,
  });
  const [CloseLevel, setCloseLevel] = useState(false);
  // useEffect(() => {
  //   setloading("type1");
  //   if (current_store?.checktoken_data?.updated) {
  //     if (current_store?.checktoken_data?.user_data?.hide_go_to) {
  //       setCloseLevel(true);
  //     }
  //     setloading(false);
  //     // setBlankScreen(false);
  //     setFurtherDataMixPanel((prev) => ({
  //       ...prev,
  //       sign_up_date: current_store?.checktoken_data?.user_data?.created_at,
  //       user_id: current_store?.checktoken_data?.user_data?._id,
  //       last_order_type: current_store?.checktoken_data?.last_order?.data
  //         ?.endPoint
  //         ? current_store?.checktoken_data?.last_order?.data?.endPoint ==
  //           fertility_main
  //           ? "During Menstrual Cycle"
  //           : "Any Day"
  //         : "",
  //       name: current_store?.checktoken_data?.last_order?.data?.pagevaluesinput
  //         ?.f_name
  //         ? current_store?.checktoken_data?.last_order?.data?.pagevaluesinput
  //             ?.f_name +
  //           " " +
  //           current_store?.checktoken_data?.last_order?.data?.pagevaluesinput
  //             ?.l_name
  //         : "",
  //       birthdate:
  //         current_store?.checktoken_data?.last_order?.data?.pagevaluesinput
  //           ?.birthdate || "",
  //       user_current_goal:
  //         current_store?.checktoken_data?.user_data?.tracking_arva ||
  //         current_store?.checktoken_data?.last_intake?.data?.tracking_arva ||
  //         "",
  //       is_ultrasound_done: current_store?.checktoken_data?.last_intake?.data
  //         ?.prev_dignosed_with_PCOS
  //         ? current_store?.checktoken_data?.last_intake?.data
  //             ?.prev_dignosed_with_PCOS == "Yes"
  //         : false || false,
  //       is_birth_control: current_store?.checktoken_data?.last_intake?.data
  //         ?.birthcontrol
  //         ? current_store?.checktoken_data?.last_intake?.data?.birthcontrol ==
  //           "Yes"
  //         : false || false,
  //       reported_symptoms:
  //         current_store?.checktoken_data?.last_intake?.data?.experience_symptoms
  //           .length > 0
  //           ? current_store?.checktoken_data?.last_intake?.data?.experience_symptoms.join(
  //               ","
  //             )
  //           : "",
  //       reported_conditions:
  //         current_store?.checktoken_data?.last_intake?.data?.dr_prev_dignosed
  //           .length > 0
  //           ? current_store?.checktoken_data?.last_intake?.data?.dr_prev_dignosed?.join(
  //               ","
  //             )
  //           : "",
  //     }));
  //     setAllData((prev) => ({
  //       ...prev,
  //       ...current_store?.checktoken_data,
  //       last_order:
  //         current_store?.checktoken_data?.all_order
  //           .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  //           .filter((el) => el.published)[0] || {},

  //       // last_order: current_store?.checktoken_data?.last_order?.published
  //       //   ? current_store?.checktoken_data?.last_order
  //       //   : current_store?.checktoken_data.prev_order_data || {},
  //     }));
  //     // let t_arr = current_store?.checktoken_data?.all_order
  //     //   .filter((el) => el.published)
  //     //   .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  //     // setListDates(t_arr);
  //     // setCurrentShowingOrder(t_arr.length ? t_arr.reverse()[0] : {});
  //   }
  // }, [current_store?.checktoken_data?.updated]);
  const token = localStorage.getItem("user_token");
  // useEffect(() => {
  //   scrollToTop();
  //   if (token) {
  //     if (!current_store?.checktoken_data?.updated) {
  //       setloading("type1");

  //       CheckTokenUser(
  //         {
  //           token: token,
  //         },
  //         (res) => {
  //           // setloading(false);
  //           //
  //           if (res.status) {
  //             setloading(false);
  //             //
  //             setFurtherDataMixPanel((prev) => ({
  //               ...prev,
  //               sign_up_date: res?.response?.user_data?.created_at,
  //               user_id: res?.response?.user_data?._id,
  //               last_order_type: res?.response?.last_order?.data?.endPoint
  //                 ? res?.response?.last_order?.data?.endPoint == fertility_main
  //                   ? "During Menstrual Cycle"
  //                   : "Any Day"
  //                 : "",
  //               name: res?.response?.last_order?.data?.pagevaluesinput?.f_name
  //                 ? res?.response?.last_order?.data?.pagevaluesinput?.f_name +
  //                   " " +
  //                   res?.response?.last_order?.data?.pagevaluesinput?.l_name
  //                 : "",
  //               birthdate:
  //                 res?.response?.last_order?.data?.pagevaluesinput?.birthdate ||
  //                 "",
  //               user_current_goal:
  //                 res?.response?.user_data?.tracking_arva ||
  //                 res?.response?.last_intake?.data?.tracking_arva ||
  //                 "",
  //               is_ultrasound_done: res?.response?.last_intake?.data
  //                 ?.prev_dignosed_with_PCOS
  //                 ? res?.response?.last_intake?.data?.prev_dignosed_with_PCOS ==
  //                   "Yes"
  //                 : false || false,
  //               is_birth_control: res?.response?.last_intake?.data?.birthcontrol
  //                 ? res?.response?.last_intake?.data?.birthcontrol == "Yes"
  //                 : false || false,
  //               reported_symptoms:
  //                 res?.response?.last_intake?.data?.experience_symptoms.length >
  //                 0
  //                   ? res?.response?.last_intake?.data?.experience_symptoms.join(
  //                       ","
  //                     )
  //                   : "",
  //               reported_conditions:
  //                 res?.response?.last_intake?.data?.dr_prev_dignosed.length > 0
  //                   ? res?.response?.last_intake?.data?.dr_prev_dignosed?.join(
  //                       ","
  //                     )
  //                   : "",
  //             }));
  //             userProperties({
  //               sign_up_date: res?.response?.user_data?.created_at,
  //               first_visit_date: res?.response?.user_data?.created_at,
  //               last_order_type: res?.response?.last_order?.data?.endPoint
  //                 ? res?.response?.last_order?.data?.endPoint == fertility_main
  //                   ? "During Menstrual Cycle"
  //                   : "Any Day"
  //                 : "",
  //               user_id: res?.response?.user_data?._id,
  //               name: res?.response?.last_order?.data?.pagevaluesinput?.f_name
  //                 ? res?.response?.last_order?.data?.pagevaluesinput?.f_name +
  //                   " " +
  //                   res?.response?.last_order?.data?.pagevaluesinput?.l_name
  //                 : "",
  //               gender: "Female",
  //               date_of_birth:
  //                 res?.response?.last_order?.data?.pagevaluesinput?.birthdate ||
  //                 "",
  //               is_test_user: false,
  //               is_paid_user: res?.response?.last_order ? true : false,

  //               user_current_goal:
  //                 res?.response?.last_intake?.data?.tracking_arva || "",
  //               is_ultrasound_done: res?.response?.last_intake?.data
  //                 ?.prev_dignosed_with_PCOS
  //                 ? res?.response?.last_intake?.data?.prev_dignosed_with_PCOS ==
  //                   "Yes"
  //                 : false || false,
  //               is_birth_control: res?.response?.last_intake?.data?.birthcontrol
  //                 ? res?.response?.last_intake?.data?.birthcontrol == "Yes"
  //                 : false || false,
  //               reported_symptoms:
  //                 res?.response?.last_intake?.data?.experience_symptoms.length >
  //                 0
  //                   ? res?.response?.last_intake?.data?.experience_symptoms.join(
  //                       ","
  //                     )
  //                   : "",

  //               reported_conditions:
  //                 res?.response?.last_intake?.data?.dr_prev_dignosed.length > 0
  //                   ? res?.response?.last_intake?.data?.dr_prev_dignosed?.join(
  //                       ","
  //                     )
  //                   : "",
  //             });
  //           }
  //           if (res.status) {
  //             setloading(false);
  //           }
  //           if (!res.status) {
  //             setloading(false);
  //             localStorage.clear();
  //             return navigate("/login");
  //           }
  //           const LastIndex =
  //             res.response.all_order.findIndex(
  //               (el) =>
  //                 // el.custom_id == res?.response?.last_recorder_order?.custom_id
  //                 el?.published
  //             ) - 1;
  //           const PublishedOrder = res.response.all_order.filter(
  //             (el) =>
  //               // el?.custom_id == res?.response?.last_order?.custom_id &&
  //               el?.published
  //           );

  //           setAllData((prev) => ({
  //             ...prev,
  //             all_order: res.response.all_order || [],
  //             prev_order_data: res.response.all_order[LastIndex + 1] || {},
  //             last_order: PublishedOrder[0] || {},
  //             // last_order: res?.response?.last_order?.published
  //             //   ? res?.response?.last_order
  //             //   : res.response.all_order[LastIndex + 1] || {},
  //             // last_order: res?.response?.last_order || {},
  //             last_recorder_order: res?.response?.last_recorder_order || {},
  //             last_intake: res?.response?.last_intake || {},
  //             user_data: res?.response?.user_data || {},
  //             all_order: res?.response?.all_order || [],
  //           }));
  //           dispatch({
  //             type: "setCheckToken",
  //             payload: {
  //               // last_order: res?.response?.last_order || {},
  //               prev_order_data: res.response.all_order[LastIndex + 1],
  //               last_order: PublishedOrder[0] || {},
  //               // last_order: res?.response?.last_order || {},
  //               // last_order: res?.response?.last_order?.published
  //               //   ? res?.response?.last_order
  //               //   : res.response.all_order[LastIndex + 1] || {},
  //               last_recorder_order: res?.response?.last_recorder_order || {},
  //               last_intake: res?.response?.last_intake || {},
  //               user_data: res?.response?.user_data || {},
  //               discount: res.response.discount || {},
  //               all_order: res?.response?.all_order || [],
  //               updated: true,
  //               version: res?.response?.version || "",
  //             },
  //           });
  //           // setstore({
  //           //   token:
  //           //     "eyJhbGciOiJIUzI1NiJ9.eyJwaG9uZSI6IjExMTExMTExMTEifQ.hMFoZxcnyQGD2QONzteuJBG2vcX3nL-VjySoe8BOhq4",
  //           //   // email: res.response.user.email,
  //           //   // image: res.response.user.image,
  //           // });
  //         }
  //       );
  //     }
  //   } else {
  //     setloading(false);
  //     navigate("/login");
  //   }
  //   GetSanity((res) => {
  //     //
  //     setBiomatrixData(res?.response);
  //   });
  // }, []);

  // useEffect(() => {
  //   get_result_list((data) => {
  //     setList(data.response);
  //   });
  // }, []);

  const selectedComponent = DataSelect;
  // const selectedComponent = BiomatrixData?.content?.find(
  //   (report) => report._id === DataSelect._id
  // );
  //
  // useEffect(() => {
  //   setPageValue((prev) => ({
  //     ...prev,
  //     date: AllData?.last_order?.order_details?.date || new Date(),
  //     name: AllData?.last_order?.data?.sheet_data[12],
  //     report_list: [
  //       {
  //         name: "Fertility (Egg Count)",
  //         color: "#FFEFEF",
  //         navi: "fertility",
  //         comp: (
  //           <Fertilty
  //             {...{
  //               PageValue: AllData,
  //               data: selectedComponent,
  //             }}
  //           />
  //         ),
  //       },
  //       {
  //         name: "Signs of PCOS",
  //         color: "#F8E8FA",
  //         navi: "pcos",
  //         comp: (
  //           <Pcos
  //             {...{
  //               PageValue: AllData,
  //               data: selectedComponent,
  //             }}
  //           />
  //         ),
  //       },
  //       {
  //         name: "Thyroid Health",
  //         color: "#EFF8EC",
  //         navi: "thyroid",
  //         comp: (
  //           <ThyroidHealth
  //             {...{
  //               PageValue: AllData,
  //               data: selectedComponent,
  //             }}
  //           />
  //         ),
  //       },
  //     ],
  //   }));
  //   // }
  // }, [LevelHighClick === "Insights", AllData]);

  useEffect(() => {
    MixPanelResult({
      remaining: FurtherDataMixPanel,
    });
  }, [FurtherDataMixPanel]);

  useEffect(() => {
    const newDataArray = BiomatrixData?.biomarker?.map((item1) => {
      // Find the corresponding item in data2 based on the "name" property
      const item2 = AllData?.last_order?.order_details?.level_list?.find(
        (item2) => item2.name === item1.nameShort
      );

      // Merge the relevant properties from both data1 and data2
      if (item2) {
        return {
          labRange: item1.labRange,
          insights: item1.insights,
          description: item1.description,
          // Merge properties from data2
          ...item2,
        };
      }

      // If no corresponding item is found in data2, return only data1 properties
      return {
        labRange: item1.labRange,
        insights: item1.insights,
        description: item1.description,
      };
    });
    setDataListAfterFilter(newDataArray);
  }, [AllData?.last_order?.order_details?.level_list, BiomatrixData.biomarker]);

  const birthdateString = AllData?.last_intake?.data?.birth_date;

  // Parse your birthdate string into a Date object
  const birthdate = new Date(birthdateString);

  // Get the current date
  const currentDate = new Date();

  // Calculate the age in years
  const ageInMilliseconds = currentDate - birthdate;
  const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);

  useEffect(() => {
    const AllDataId = AllData?.last_order?.order_details?.testArray?.map(
      (el) => el._id
    );

    let filteredArray1 = BiomatrixData?.content?.filter((item1) => {
      // return AllData?.last_order?.order_details?.testArray?.some(
      //   (item2) => item2._id === item1._id
      // );
      return AllDataId?.includes(item1._id);
    });

    let t_obj = AllData?.last_order?.order_details?.testChipdata || {};
    let dep_obj = AllData?.last_order?.order_details?.level_list || [];
    let t_key = [{ key: "fertility" }, { key: "pcos" }, { key: "thyroid" }];

    filteredArray1 = filteredArray1?.map((obj1) => {
      return {
        ...obj1,
        key:
          obj1.name == "Fertility (Egg Count)"
            ? t_key[0].key
            : obj1.name == "Thyroid Health"
            ? t_key[2].key
            : obj1.name == "Signs of PCOS"
            ? t_key[1].key
            : null,
      };
    });

    filteredArray1 = filteredArray1?.map((el1, i) => ({
      ...el1,
      ...t_obj[el1.key],
      dependent_range: dep_obj?.filter((el) => el?.biomarkerCode == "AMH")[0],
    }));

    setFilterArrayTest(filteredArray1);
  }, [
    BiomatrixData.content,
    AllData?.last_order?.order_details?.testArray,
    AllData?.last_order?.order_details?.testChipdata,
  ]);

  const [AcitveLevel, setAcitveLevel] = useState("At a glance");
  // const [AcitveLevel, setAcitveLevel] = useState("Your levels");
  const [DropDownDates, setDropDownDates] = useState(false);
  console.log(reports[0].cards);
  return (
    <>
      {MiniNavi ? (
        <>
          <div className="main_reports_insights">
            <div
              className="result_back_btn"
              onClick={() => {
                setMiniNavi(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M8.33333 15.8334L2.5 10.0001M2.5 10.0001L8.33333 4.16675M2.5 10.0001H17.5"
                  stroke="#5f1d1b"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {selectedComponent.name}
            </div>
            <div>
              {selectedComponent.name === "Fertility (Egg Count)" ? (
                <Fertilty
                  {...{
                    PageValue: AllData,
                    data: selectedComponent,
                    DataSelect: DataSelect,
                    // filteredData :
                    list: FilterArrayTest,
                    FurtherDataMixPanel: FurtherDataMixPanel,
                    // amh
                    amh: reports[0].biomarker.filter(
                      (el) => el.biomarkerCode === "AMH"
                    )[0],
                    age: reports[0]?.age || 0,
                    allreport,
                    reports,
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <div>
              {selectedComponent.name === "Signs of PCOS" ? (
                <Pcos
                  {...{
                    PageValue: AllData,
                    data: selectedComponent,
                    DataSelect: DataSelect,
                    FurtherDataMixPanel: FurtherDataMixPanel,
                    list_bioData: DataListAfterFilter
                      ? DataListAfterFilter
                      : [],
                    allreport,
                    reports,
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <div>
              {selectedComponent.name === "Thyroid Health" ? (
                <ThyroidHealth
                  {...{
                    PageValue: AllData,
                    data: selectedComponent,
                    DataSelect: DataSelect,
                    FurtherDataMixPanel: FurtherDataMixPanel,
                    allreport,
                    reports,
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            margin: "158px 0 0 0",
          }}
        >
          <div className="main_level_section1">
            <div className="main_level_filter_dates">
              <div
                className="filter_dates_current"
                onClick={() => {
                  setDropDownDates((prev) => !prev);
                }}
              >
                Result:{" "}
                {new Date().valueOf() == new Date(reports[0].date).valueOf()
                  ? "Today"
                  : date_formatter(
                      new Date(reports[0].date),
                      "date mo_short year_long"
                    )}
                <div className="filter_dates_current_svg">
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={
                      DropDownDates
                        ? {
                            transform: "rotate(180deg)",
                            transition: "transform .3s linear",
                          }
                        : {
                            transition: "transform .3s linear",
                          }
                    }
                  >
                    <path
                      d="M11.5326 1.53061L6.53255 6.53061C6.46287 6.60053 6.38008 6.65601 6.28892 6.69386C6.19775 6.73172 6.10001 6.7512 6.0013 6.7512C5.90259 6.7512 5.80485 6.73172 5.71369 6.69386C5.62252 6.65601 5.53973 6.60053 5.47005 6.53061L0.470051 1.53061C0.329155 1.38972 0.25 1.19862 0.25 0.999362C0.25 0.800105 0.329155 0.609009 0.470051 0.468112C0.610947 0.327216 0.802044 0.248062 1.0013 0.248062C1.20056 0.248062 1.39165 0.327216 1.53255 0.468112L6.00193 4.93749L10.4713 0.467488C10.6122 0.326592 10.8033 0.247437 11.0026 0.247437C11.2018 0.247437 11.3929 0.326592 11.5338 0.467488C11.6747 0.608384 11.7539 0.79948 11.7539 0.998738C11.7539 1.198 11.6747 1.38909 11.5338 1.52999L11.5326 1.53061Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
              {DropDownDates ? (
                <div className="inner_level_filter_dates">
                  {ListDates.map((el) => (
                    <div
                      onClick={() => {
                        // setCurrentShowingOrder(el);
                        setcurrreports(el);
                        setDropDownDates((prev) => !prev);
                      }}
                      style={
                        el?.date == reports[0].date
                          ? {
                              color: "var(--base)",
                            }
                          : {}
                      }
                    >
                      Result:{" "}
                      {date_formatter(
                        new Date(el?.date),
                        "date mo_short year_long"
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
            <HighLevel1
              {...{
                list: LevelLight,
                onClickHL: (el) => setAcitveLevel(el),
                check: AcitveLevel,
                FurtherDataMixPanel: FurtherDataMixPanel,
              }}
            />
          </div>
          {/* {CloseLevel || AcitveLevel == "Your levels" ? (
            ""
          ) : (
            <div className="main_level_desc_top">
              <div className="main_level_desc">
                <div>
                  Go to <span>Levels</span> to view your hormone levels.
                </div>
                <div
                  className="close_svg"
                  onClick={() => {
                    HideGoto(token, (res) => {
                      if (res.response.status) {
                        setCloseLevel(true);
                      }
                    });
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.86361 3.8646C4.03238 3.69588 4.26126 3.6011 4.49991 3.6011C4.73855 3.6011 4.96743 3.69588 5.13621 3.8646L8.99991 7.7283L12.8636 3.8646C12.9466 3.77864 13.0459 3.71008 13.1557 3.66291C13.2655 3.61574 13.3836 3.59092 13.5031 3.58988C13.6226 3.58884 13.7412 3.61161 13.8518 3.65686C13.9624 3.70212 14.0629 3.76894 14.1474 3.85345C14.2319 3.93795 14.2987 4.03844 14.3439 4.14904C14.3892 4.25965 14.412 4.37816 14.4109 4.49766C14.4099 4.61717 14.3851 4.73526 14.3379 4.84507C14.2907 4.95487 14.2222 5.05418 14.1362 5.1372L10.2725 9.0009L14.1362 12.8646C14.3001 13.0343 14.3909 13.2617 14.3888 13.4977C14.3868 13.7336 14.2921 13.9594 14.1252 14.1262C13.9584 14.2931 13.7326 14.3878 13.4967 14.3898C13.2607 14.3919 13.0333 14.3011 12.8636 14.1372L8.99991 10.2735L5.13621 14.1372C4.96646 14.3011 4.73912 14.3919 4.50314 14.3898C4.26717 14.3878 4.04144 14.2931 3.87457 14.1262C3.7077 13.9594 3.61305 13.7336 3.611 13.4977C3.60895 13.2617 3.69966 13.0343 3.86361 12.8646L7.72731 9.0009L3.86361 5.1372C3.69488 4.96843 3.6001 4.73955 3.6001 4.5009C3.6001 4.26226 3.69488 4.03338 3.86361 3.8646Z"
                      fill="#454545"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )} */}
          <div className="main_level">
            {/* <div className="main_level_section1">
            <HighLevel
              {...{
                list: LevelHighLight,
                onClickHL: (el) => setLevelHighClick(el),
                check: LevelHighClick,
                FurtherDataMixPanel: FurtherDataMixPanel,
              }}
            />
          </div> */}

            {AcitveLevel == "Your levels" ? (
              <div className="main_level_section2">
                <LevelList
                  {...{
                    title: AcitveLevel,
                    titleDate: reports[0].date
                      ? new Date(reports[0].date)
                      : new Date(),
                    unique_id: "list_data",
                    screen: "Result",
                    FurtherDataMixPanel: FurtherDataMixPanel,
                    age: Math.floor(reports[0]?.age || 0),
                    firstopen: true,
                    // list: [
                    //   ...(AllData?.last_order?.order_details?.level_list
                    //     ? AllData?.last_order?.order_details?.level_list
                    //     : []
                    //   )?.map((el, i) => ({
                    //     name: el.nameShort,
                    //     desc: el.name,
                    //     color: "",
                    //     count: el.count,

                    //     range: el?.range,
                    //     desc2: el.description,
                    //     insights_list: el.insights,
                    //     range_list: el.labRange,
                    //   })),
                    // ],
                    list: [
                      ...reports[0].biomarker?.map((el, i) => ({
                        ...el,
                        // name: el.short_name,
                        desc: el.value_desc,
                        desc2: el.desc,
                        color: "",
                        count: el.value,

                        // ??
                        score: el?.score
                          ? `${el?.score[0].toUpperCase()}${el?.score.slice(1)}`
                          : "Pending",
                        insights_list: el.insights,
                        range_list: el.labRange,
                        // ??
                      })),
                    ],
                    AccordainWork: AcitveLevel === "At a glance" ? true : false,
                    setAccordainWork: setLevelHighClick,
                    setArrayForHgighLow: setArrayForHgighLow,
                    ArrayForHgighLow: ArrayForHgighLow,
                  }}
                />
              </div>
            ) : (
              ""
            )}

            {AcitveLevel === "At a glance" ? (
              <div className="main_level_glance">
                <LevelResultV2
                  {...{
                    // list: FilterArrayTest?.filter(
                    //   (el) => el.key == "fertility"
                    // ),
                    list: [reports[0]?.cards?.fertility].filter((el) => el),
                    setMiniNavi: setMiniNavi,
                    setDataSelect: setDataSelect,
                    data: AllData?.last_order?.order_details?.testChipdata,
                    FurtherDataMixPanel: FurtherDataMixPanel,
                    insidetest: false,
                    title: "",
                    age: Math.floor(ageInYears),
                    amh: reports[0].biomarker.filter(
                      (el) => el.biomarkerCode === "AMH"
                    )[0],
                    age: reports[0]?.age || 0,
                  }}
                />
              </div>
            ) : (
              ""
            )}
            {AcitveLevel === "At a glance" ? (
              <div className="main_level_glance">
                <ListReports
                  {...{
                    // list: FilterArrayTest,
                    // list: FilterArrayTest?.filter(
                    //   (el) => el.key != "fertility"
                    // ),
                    list: [
                      reports[0]?.cards?.signs_of_pcos || "",
                      reports[0]?.cards?.thyroid_health || "",
                    ].filter((el) => el),
                    setMiniNavi: setMiniNavi,
                    setDataSelect: setDataSelect,
                    data: AllData?.last_order?.order_details?.testChipdata,
                    FurtherDataMixPanel: FurtherDataMixPanel,
                  }}
                />
              </div>
            ) : (
              ""
            )}

            <div className="main_level_botm">
              <span>
                Your results were processed by Thyrocare Technologies and were
                approved by an Arva Health physician. The Arva Health tests do
                not predict fertility, or diagnose PCOS and Thyroid issues. They
                serve as an important data point in understanding fertility and
                overall health and are meant to facilitate a conversation with a
                healthcare provider.
              </span>{" "}
              <div
                className="full_disc"
                onClick={() => {
                  window.open("https://www.arva.health/legal/terms-of-service");
                }}
              >
                Full disclosure
              </div>
            </div>
            <>
              <CommonFooter />
            </>
          </div>
        </div>
      )}
    </>
  );
};
