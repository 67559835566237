import React from "react";
import CustInputMuiClone from "../../../component/CustInput/CustInputMuiClone";
import BottomBtnSection from "../components/BottomBtnSection";
import {
  MixPanelIntake,
  MixPanelIntakeCompleted,
} from "../../../component/API/automation_api";

const WaistSection = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  setBeginBtn,
  lastdata,
  goto,
  startfrom,
  sectionno,
}) => {
  const handle_pagevalues = (updated_data) => {
    return setPagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  const handle_errpagevalues = (updated_data) => {
    return seterr_pagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  return (
    <>
      <div className="intake_body">
        <div className="container waist_section_main">
          <div className="waist_title">What is your waist circumference?</div>
          <div className="waist_desc">
            Measure your waist where you wear your mid-rise jeans. If you’re
            unsure, tell us your jeans size.
          </div>
          <CustInputMuiClone
            {...{
              attr: {
                autofocus: true,
                error: err_pagevalues.waist_inch,
                error_msg: "",
                value: pagevalues.waist_inch,
                inputMode: "numeric",
                event: {
                  change: ({ target }) => {
                    if (Number(target.value.slice(-1)).toString() == "NaN") {
                      return;
                    }

                    handle_pagevalues({ waist_inch: target.value });
                    handle_errpagevalues({
                      waist_inch: target.value ? "" : "Error message",
                    });
                  },
                },
              },
              data: {
                title: "",
                suffix: "in",
              },
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              if (!pagevalues.waist_inch) {
                seterr_pagevalues((prev) => ({
                  ...prev,
                  waist_inch: !pagevalues.waist_inch,
                }));
                return;
              }
              MixPanelIntake({
                step_name: "What is your waist circumference?",
                section_name: "Personal Info",
                value: pagevalues.waist_inch + "in",
                step_index: 4,
                total_steps: 4,
                remaining: lastdata,
              });
              MixPanelIntakeCompleted({
                section_name: "Personal Info",
                remaining: lastdata,
              });
              setRangeCount(5);
              setBeginBtn(true);
              setPages("menstralbeginpage");
            },
            goBackClick: () => {
              setRangeCount(3);
              setPages("weight");
            },
          },
        }}
      />
    </>
  );
};

export default WaistSection;
