import React, { useEffect, useState } from "react";
import BottomBtnSection from "../components/BottomBtnSection";
import { MultiSelect } from "../../../component/CheckOut/Intake/MultiSelect";
import { MixPanelIntake } from "../../../component/API/automation_api";

export const OftenGetPeriod = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  singleBeginRange,
  setBeginPage,
  lastdata,
  goto,
  startfrom,
  sectionno,
}) => {
  const [SympData, setSympData] = useState([
    "<21 days",
    "Every 22-35 days",
    ">35 days",
    "It’s super unpredictable",
  ]);
  const [MultiSelectData, setMultiSelectData] = useState([]);
  useEffect(() => {
    setMultiSelectData(pagevalues.oftengetperois);
  }, []);

  useEffect(() => {
    setPagevalues((prev) => ({
      ...prev,
      oftengetperois: MultiSelectData,
    }));
  }, [MultiSelectData]);
  return (
    <>
      <div className="intake_body">
        <div className="container ofterngetperiod_main">
          <div className="ofterngetperiod_title">
            How often do you get your period?
          </div>

          <MultiSelect
            {...{
              list: SympData,
              onMultiClick: (el) => {
                setMultiSelectData(el);
              },
              MultiSelectData: pagevalues.oftengetperois,
              multisel: false,
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              if (pagevalues.oftengetperois.length <= 0) {
                return;
              }

              if (singleBeginRange) {
                setRangeCount(1);
              } else {
                setRangeCount(7);
              }
              MixPanelIntake({
                step_name: "How often do you get your period?",
                section_name: "Menstrual History",
                value: pagevalues.oftengetperois,
                step_index: 1,
                total_steps: 5,
                remaining: lastdata,
              });
              setPages("averageCyclelength");
            },
            goBackClick: () => {
              setRangeCount(5);
              setPages("menstralbeginpage");
            },
          },
        }}
      />
    </>
  );
};
