import React from "react";
import { useNavigate } from "react-router-dom";
import { MixPanelArticle } from "../../component/API/automation_api";

export const SmallSizeCard = ({
  obj,
  setFertility_type,
  setTrackFertility,
  FurtherDataMixPanel,
}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const nav = useNavigate();
  function asset(url) {
    if (url.match("https")) {
      return url;
    }
    let a = url?.split("-");
    return `https://cdn.sanity.io/${a[0]}s/nao51hyy/production/${a
      ?.slice(1, -1)
      ?.join("-")}.${a[a.length - 1]}`;
  }
  return (
    <div
      className="small_size_card"
      onClick={() => {
        // setFertility_type(obj);
        // window.location.replace(`${window.location.origin}/learn/${obj.slug}`);
        // setTrackFertility(true);
        // scrollToTop();
        MixPanelArticle({
          content_name: obj.title,
          article_id: obj.slug,
          screen_name: window.location.pathname?.split("/")[1],
          remaining: FurtherDataMixPanel,
        });
        nav(`/article/${obj.slug}`);
      }}
    >
      <div
        className="small_size_card_img"
        style={{
          backgroundImage: `url(${asset(obj?.imageUrl || "")})`,
          backgroundSize: "cover",

          // height:
        }}
      >
        {/* <img src={asset(obj?.imageUrl)} /> */}
      </div>
      <div className="small_size_card_detail">
        <div className="small_size_card_detail_name">{obj?.title}</div>
        <div className="small_size_card_detail_desc">{obj?.description}</div>
      </div>
    </div>
  );
};
