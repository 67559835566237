import React, { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import BottomBtnSection from "../../NewIntake/components/BottomBtnSection";
import { date_formatter } from "../../../component/CustInput/CustInput";
export const CommingPeriodV2 = ({
  setBeginBtn,
  setRangeCount,
  setPages,
  pagevalues,
  setpagevalues,
  setBeginPage,
  singleBeginRange,
  FurtherDataMixPanel,
  BookTime,
  setBookTime,
  ListOfTimewithDate,
  setBookDate,
  BookDate,
  OverAllDateTime,
  setOverAllDateTime,
  AllData,
  MaxDate,
  setMaxDate,
  MiniDate,
  setMiniDate,
  FurtherDate,
  setFurtherDate,
}) => {
  const [err_pagevalues, seterr_pagevalues] = useState({
    menstralstate: "Select",
    date_time: false,
    zipcode: "",
  });
  const token = localStorage.getItem("user_token");
  const handle_errpagevalues = (updated_data) => {
    return seterr_pagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  return (
    <>
      <div className="bookslot_body">
        <div className="container  comming_period_main">
          <div className="comming_period_inner">
            {/* We recommend taking the test on day 2 or 3 of your period. */}
          </div>
          <div className="comming_period_desc">
            Please select an approximate date when you think you will get your
            next period.
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              minDate={dayjs(
                date_formatter(new Date(MiniDate), "year_long-month-date")
              )}
              sx={{
                ".Mui-selected": {
                  backgroundColor: "var(--base) !important",
                },
              }}
              maxDate={dayjs(
                date_formatter(
                  new Date(
                    "Wed Sep 02 2093 00:00:00 GMT+0530 (India Standard Time)"
                  ),
                  "year_long-month-date"
                )
              )}
              value={
                FurtherDate
                  ? dayjs(
                      date_formatter(
                        new Date(FurtherDate),
                        "year_long-month-date"
                      )
                    )
                  : FurtherDate
              }
              onChange={(value) => {
                if (pagevalues.menstralstate === "I’m on my period now") {
                } else {
                  // setOverAllDateTime((prev) => ({
                  //   ...prev,
                  //   date: value,
                  // }));
                  setFurtherDate(value);
                  handle_errpagevalues({
                    date_time:
                      !value ||
                      value.$d == "Invalid Date" ||
                      value?.$d == "Invalid Date"
                        ? value?.$d
                        : date_formatter(
                            new Date(value?.$d),
                            "year_long-month-date"
                          ) < new Date(MiniDate).toISOString().split("T")[0],
                  });
                }
              }}
              // slotProps={{
              //   toolbar: { toolbarFormat: "ddd DD MMMM", hidden: false },
              // }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Select date",
            onBtnClick: () => {
              if (
                FurtherDate.$d == "Invalid Date" ||
                date_formatter(
                  FurtherDate.$d || FurtherDate,
                  "year_long-month-date"
                ) < date_formatter(MiniDate, "year_long-month-date")
              ) {
                return;
              }
              // alert("wokring")
              setRangeCount(3);
              setPages("reviewpage");
              // SendReminderDate(
              //   {
              //     id: AllData.last_order._id,
              //     comming_period: FurtherDate,
              //   },
              //   token,
              //   (res) => {
              //     if (res.status) {
              //       setRangeCount(2);
              //       setPages("reviewpage");
              //     }
              //   }
              // );
            },
            goBackClick: () => {
              setRangeCount(1);

              // setRangeCount(1);
              if (pagevalues.menstralstate == "I’m getting my period later") {
                setPages("mcycle");
                // setPages("mcycle");
              } else {
                // setPages("addressedit");
                setPages("labslist");
                // setPages("samplecolledate");
              }
            },
          },
        }}
      />
    </>
  );
};
