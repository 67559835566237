import React, { useEffect, useState } from "react";
import { ReportType } from "./ReportType";
import { Summary } from "./Summary";
import { NextStep } from "./NextStep";
import { HomeSection3 } from "../Home/HomeSection3";
import { Faq } from "./Faq";
import { BaseOnResultFertity } from "./BaseOnResultFertity";
import { Graphs } from "../common/Graph";
import { Graph2 } from "../common/Graph2";
import { MixPanelResultClickExpanded } from "../API/automation_api";
import { useSelector } from "react-redux";
import { LevelResultV2 } from "./LevelResultV2";
import { count } from "d3-array";

export const Fertilty = ({
  PageValue,
  data,
  DataSelect,
  FurtherDataMixPanel,
  list,
  amh,
  age,
  reports,
  allreport,
}) => {
  const [ControlList, setControlList] = useState({
    name: "Egg Count",
    relatedBiomarkers: [],
    description:
      "Think of your ovarian reserve as a basket of eggs. We’re typically born with a basket full of eggs, and every month, one mature egg is formed and released. By the time we reach the end of our reproductive window, also known as menopause, the basket is nearly empty.",
  });
  const current_store = useSelector((state) => state);
  const [FertilityType, setFertilityType] = useState("Today");
  const [TomorrayActive, setTomorrayActive] = useState(false);
  const [GraphPlot, setGraphPlot] = useState(12);

  const [ListFilteredData, setListFilteredData] = useState({});
  const [FilterDataAMH, setFilterDataAMH] = useState([]);
  const [AMHRanges, setAMHRanges] = useState([
    {
      insights: [
        {},
        {
          max: "",
          min: "",
        },
        {},
      ],
      count: "",
    },
  ]);
  const [DatesFormap, setDatesFormap] = useState(new Date());
  useEffect(() => {
    setListFilteredData(
      PageValue?.last_order?.order_details?.testChipdata?.fertility
    );
  }, [PageValue]);

  useEffect(() => {
    setControlList({ ...data?.basedOnYourResults[0] });
    setFilterDataAMH({ ...data?.basedOnYourResults[0] });
  }, []);

  // const birthdateString = PageValue?.last_intake?.data?.birth_date;

  // Parse your birthdate string into a Date object
  const birthdate = new Date(allreport[0].dob);

  // Get the current date
  // const currentDate = new Date();

  // Calculate the age in years
  // const ageInMilliseconds = currentDate - birthdate;
  // const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);
  const ageInYears = age || 0;
  let AmhCountMax = 0;
  let AmhAgeMax = 0;
  console.log(allreport);
  // let AmhList = current_store?.checktoken_data?.all_order
  let AmhList = allreport
    ?.map((el) => {
      let amhvalue = el.biomarker.filter(
        (el) => el?.biomarkerCode?.toLowerCase() == "amh"
      )[0];
      let age =
        (new Date(el?.date) - birthdate) / (365.25 * 24 * 60 * 60 * 1000);
      if (age > AmhAgeMax) {
        AmhAgeMax = age;
      }
      if (Number(amhvalue?.value) > AmhCountMax) {
        AmhCountMax = Number(amhvalue?.value);
      }
      console.log(AmhCountMax);
      return {
        // el:,
        amh: {
          ...amhvalue,
          // value: amhvalue,
        },
        count: amhvalue?.value,
        date: new Date(el.date),
        age,
      };
    })
    .sort((a, b) => a.age - b.age);

  if (AmhAgeMax >= 36) {
    AmhList = AmhList.filter((el) => el.age >= 36);
  }
  useEffect(() => {
    // const filteredArray1 =
    //   PageValue?.last_order?.order_details?.level_list?.filter((item1) => {
    //     return FilterDataAMH?.relatedBiomarkers?.some(
    //       (item2) => item2.nameShort === item1.nameShort
    //     );
    //   });
    let relatedBiomarkers = reports[0]?.cards?.fertility?.basedOnYourResults
      ?.map((el) => el.relatedBiomarkers)
      .flat(1);
    const filteredArray1 = reports[0].biomarker?.filter((item1) => {
      return relatedBiomarkers?.some(
        (item2) => item2.nameShort === item1.biomarkerCode
      );
    });

    const tresultArray = filteredArray1
      ?.map((el, i) => ({
        name: el.short_name,
        desc: el.name,
        color: "#F05E45",
        count: el.value,
        range: "",
        count_qunt: el?.range?.unit,
        desc2: el.description,
        value_desc: el.value_desc,
        // insights_list: el.insights,
        range_list: el.range,
      }))
      .map((data) => {
        // Step 1: Filter the range_list based on age criteria
        let ageFilteredRanges = [];
        // if (data?.range_list?.length >= 2) {
        //   ageFilteredRanges = data?.range_list?.filter((range) => {
        //     // const age = 50; // Replace with the age you want to filter by
        //     return (
        //       range.ageMin <= Math.floor(ageInYears) &&
        //       range.ageMax >= Math.floor(ageInYears)
        //     );
        //   });
        // } else {
        //   ageFilteredRanges = data?.range_list;
        // }
        // Step 2: Extract highMin and highMax values from the filtered range_list
        const highMin = ageFilteredRanges
          ? ageFilteredRanges[0]?.highMin
          : 0 || 0;
        const highMax = ageFilteredRanges
          ? ageFilteredRanges[0]?.highMax
          : 0 || 0;
        const lowMax = ageFilteredRanges
          ? ageFilteredRanges[0]?.lowMax
          : 0 || 0;
        const lowMin = ageFilteredRanges
          ? ageFilteredRanges[0]?.lowMin
          : 0 || 0;
        const optimalMin = ageFilteredRanges
          ? ageFilteredRanges[0]?.optimalMin
          : 0 || 0;
        const optimalMax = ageFilteredRanges
          ? ageFilteredRanges[0]?.optimalMax
          : 0 || 0;
        const unit = ageFilteredRanges ? ageFilteredRanges[0]?.unit : 0 || 0;
        // Step 3: Create new properties "high" and "low" in the insights object
        // const insights = {
        //   ...data.insights,
        //   high: highMax || 0, // Assign highMax value
        //   low: highMin || 0, // Assign highMin value
        // };

        const lowValue = {
          min: data?.range?.start || 0,
          max: data?.range?.min || 0,
          name: "Low",
          desc: data.desc,
          // desc: desc ? desc?.filter((el) => el.score === "Low")[0] : "-",
        };

        const optimalValue = {
          min: data?.range?.min || 0,
          max: data?.range?.max || 0,
          name: "Optimal",
          desc: data.desc,
          // desc: desc ? desc.filter((el) => el.score === "Optimal")[0] : "-",
        };

        const highValue = {
          min: data?.range?.max || 0,
          max: data?.range?.end || 0,
          name: "High",
          desc: data.desc,
          // desc: desc ? desc.filter((el) => el.score === "High")[0] : "-",
        };

        // Create an array containing these values
        const newArray = [lowValue, optimalValue, highValue];

        // Step 4: Create a new object with filtered range_list and updated insights
        let newData = {
          ...data,
          range_list: ageFilteredRanges,
          insights: [...newArray],
          range: data?.count ? data.score : "Pending",
          unit: data?.range?.unit || "",
          desc: data?.name || "",
        };
        newData = {
          ...newData,
          color: [
            {
              name: "Low",
              color: "#F05E45",
            },
            {
              name: "Optimal",
              color: "#64BE45",
            },
            {
              name: "High",
              color: "#B418D1",
            },
          ].filter((el) => el.name === newData.range)[0]?.color,
        };

        return newData;
      });
    setAMHRanges(tresultArray);
    // setArrayForHgighLow(tresultArray);
  }, [amh, reports]);
  // console.log(reports[0].cards.fertility.basedOnYourResults);
  // console.log(ListFilteredData);
  return (
    <>
      <div className="fertility_main">
        <ReportType
          {...{
            clientName: `For ${PageValue?.last_order?.data?.sheet_data[12]}`,
            clientName: `For ${reports[0]?.user?.name}`,
            reportType: (
              <>
                {data?.name === "Fertility (Egg Count)" ? (
                  <>
                    {data?.name?.split("(")[0]} <br></br>{" "}
                    {data?.name?.split(" ")[1]} {data?.name.split(" ")[2]}
                  </>
                ) : (
                  data?.name
                )}
              </>
            ),
            desc: data?.description,
            bgcolor: "#FFEFEF",
            fcolor: "#FF5C5C",
          }}
        />
        {/* {AMHRanges[0]?.count ? (
          <Summary
            {...{
              img: DataSelect?.image?.asset?._ref,
              type: "Fertility",
              bgcolor: "#FFEFEF",
              fcolor: "#FF5C5C",
              main_title: "Your Fertility summary",
              summarytitle: ListFilteredData?.heading,
              summarydesc: ListFilteredData?.desc,
              chipcolor: ListFilteredData,
              typereportlearn:
                ListFilteredData?.chip?.split(" ")[0] == "-"
                  ? []
                  : [ListFilteredData?.chip],
              physicalsymptoms: [],
            }}
          />
        ) : (
          ""
        )} */}
        {amh.value ? (
          <LevelResultV2
            {...{
              list: [reports[0]?.cards?.fertility].filter((el) => el),
              insidetest: true,
              title: "Your Fertility summary",
              age: Math.floor(ageInYears),
              amh: reports[0].biomarker.filter(
                (el) => el.biomarkerCode === "AMH"
              )[0],
              age: reports[0]?.age || 0,
            }}
          />
        ) : (
          ""
        )}

        <div className="fertiltiy_timeline">
          <div className="fertiltiy_timeline_title">
            Explore your fertility timeline
          </div>
          <div className="fertiltiy_timeline_total">
            {["Today", "Predicted decline"].map((el, i) => (
              <div
                className={
                  "fertiltiy_timeline_total_box " +
                  (FertilityType === el
                    ? " fertiltiy_timeline_total_box_active"
                    : "")
                }
                onClick={() => {
                  setFertilityType(el);
                  MixPanelResultClickExpanded({
                    reportname: "Fertility (Egg Count)",
                    name: el,
                    remaining: FurtherDataMixPanel,
                  });
                }}
              >
                {el}
              </div>
            ))}
          </div>

          {amh.value ? (
            <div className="graph_fertitlity">
              <Graphs
                hormone="amh"
                type={
                  // ((AMHRanges[0]?.count || 0) <= 8 ? "type1" : "type2") +
                  ((AmhCountMax || 0) <= 8 ? "type1" : "type2") +
                  (FertilityType == "Predicted decline" ? "_future" : "")
                }
                bg_type="type1"
                // prediction_sign=""
                amh_group={AmhList.map((el) => ({
                  ...el,
                  amh: { ...el.amh, count: el.amh.value },
                  count: el.amh.value,
                }))}
                graph={{
                  // dates: allreport[0].date
                  //   ? new Date(allreport[0].date)
                  //   : new Date(),
                  dates: reports[0].date
                    ? new Date(reports[0].date)
                    : new Date(),
                  min: amh?.range?.min || 0,
                  max: amh?.range?.max || 0,
                  age: Math.floor(ageInYears),
                  plot: amh.value || 0,
                  plotmax: AmhCountMax || 0,
                  prediction_plot: "#FEDEFF",
                }}
              />
            </div>
          ) : (
            ""
          )}
          <div className="fertiltiy_timeline_desc_main">
            <div className="fertiltiy_timeline_desc">
              You just found your <span>fertility baseline</span>. It is
              completely natural for your fertility to decline over time. Your
              body is doing it’s natural thing! Fertility testing helps us track
              that decline over time, so we catch major changes at the right
              time.
            </div>
            {/* <div className="fertiltiy_timeline_btn">Remind me in 1 year</div> */}
          </div>
          <div className="fertiltiy_timeline_today_tomorrow">
            <div className="prev_next">
              <div
                className={
                  "prev_btn " +
                  (FertilityType === "Predicted decline"
                    ? "active_prev_btn"
                    : "")
                }
                onClick={() => {
                  setFertilityType("Today");
                  MixPanelResultClickExpanded({
                    reportname: "Fertility (Egg Count)",
                    name: "Today",
                    remaining: FurtherDataMixPanel,
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M12.5 5L7.5 10L12.5 15"
                    stroke="#C5C5C5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div>Today</div>
              </div>
              <div
                className={
                  "next_btn " +
                  (FertilityType === "Today" ? "active_prev_btn" : "")
                }
                onClick={() => {
                  // setTomorrayActive(false);
                  setFertilityType("Predicted decline");
                  MixPanelResultClickExpanded({
                    reportname: "Fertility (Egg Count)",
                    name: "Predicted decline",
                    remaining: FurtherDataMixPanel,
                  });
                }}
              >
                <div>Predicted decline</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M7.5 15L12.5 10L7.5 5"
                    stroke="#C5C5C5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="baseonresult_fertiltiy">
          <BaseOnResultFertity
            {...{
              list: [...reports[0].cards.fertility.basedOnYourResults],
              // list: [...],
              // list: ["Egg Count", "Ovulation", "BMI"],
              ControlList: ControlList,
              setControlList: setControlList,
              age: Math.floor(ageInYears),
              FurtherDataMixPanel: FurtherDataMixPanel,
              FullList: PageValue,
              allreport,
              reports,
              accordianData: [
                {
                  name: `${ControlList.name}`,
                  desc: ControlList.description,
                },
              ],
            }}
          />
        </div>
        {reports[0]?.cards?.fertility?.summaryInsight?.relatedArticles?.length >
        0 ? (
          <div className="homesection3_detail_shower_all_test">
            <HomeSection3
              {...{
                title: "Explore Fertility",
                FurtherDataMixPanel: FurtherDataMixPanel,
                screen: "Fertility (Egg Count)",
                data:
                  reports[0]?.cards?.fertility?.summaryInsight?.relatedArticles
                    ?.length > 0
                    ? [
                        ...reports[0]?.cards?.fertility?.summaryInsight?.relatedArticles?.map(
                          (el) => ({
                            name: el.title,
                            descti: el.topic,
                            img: el.image,
                            slug: el.slug,
                            description: el.description,
                          })
                        ),
                      ]
                    : [],
                // {
                //   name: "My options with low Ovarian Reserve",
                //   descti: "Article • 3 min",
                //   img: "",
                // },
                // {
                //   name: "ICSI, IVF, IUI, and all of the above",
                //   descti: "Article • 3 min",
                //   img: "",
                // },
              }}
            />
          </div>
        ) : (
          ""
        )}

        <Faq
          {...{
            openAcc: false,
            unique: "fertitlty_faq",
            titleDis: true,
            data: [
              {
                que: "How are my insights created?",
                ans: "Your unique insights are created by considering your hormone biomarker results, age, BMI, medical history, family history, and any other information you share with us. These insights have been engineered with the help of top fertility experts in India and provide a baseline understanding of your fertility health.",
              },
              {
                que: "How long will I be fertile?",
                ans: "No test, including this hormone test, can definitively predict pregnancy or infertility. Instead, this is the best available marker for the number of eggs you have left (quantity), which can be a helpful starting point. As you age, your egg count declines until you hit menopause, which is influenced by many factors, such as genetics, lifestyle, and more.",
              },
              {
                que: "Should I get an ultrasound?",
                ans: "Yes! Even if you have previously gotten one before, you will only be able to get a comprehensive view of your egg count (AFC) with an Ultrasound paired with your hormone blood test. ",
              },
            ],
          }}
        />

        <NextStep />
      </div>
    </>
  );
};
