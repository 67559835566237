import React, { useEffect, useState } from "react";
import TestCard from "./Cards/TestCard";
import ConsultCard from "./Cards/ConsultCard";
import { useSelector } from "react-redux";
import GuideCard from "./Cards/GuideCard";

const HomeNewNext = ({
  card_to_show,
  data = [],
  ProductImages,
  guide_data,
}) => {
  const [is_guide, setis_guide] = useState(false);
  const store = useSelector((state) => state);
  useEffect(() => {
    // setis_guide(
    //   store?.checktoken_data?.orderItems?.filter(
    //     (el) => el.product_type == "guide"
    //   ).length > 0
    // );

    return () => {};
  }, [store.checktoken_data]);
  console.log({ data });
  return (
    <div className="home_new_next">
      <div className="next_header">Next</div>
      {/* {(data.length || !is_guide) &&
      store?.checktoken_data?.customer?.user?.first_name ? (
      ) : (
        ""
      )} */}
      {data?.length > 0 ? (
        <>
          {data.map(
            (el) =>
              ({
                test: (
                  <TestCard
                    ProductImage={ProductImages}
                    state={["intake", "timeslot", "sample", "results"][2]}
                    stage="2"
                    data={el}
                  />
                ),
                consult: <ConsultCard ProductImage={ProductImages} data={el} />,
                coaching: (
                  <ConsultCard
                    calendly_deault_url="https://calendly.com/anushreefertility/fertility-health-coaching"
                    type="coaching"
                    data={el}
                    ProductImage={ProductImages}
                  />
                ),
              }[el?.product_type] || "")
          )}
        </>
      ) : guide_data?.img ? (
        <>
          <GuideCard
            data={{
              ...guide_data,
              product_name: "Comprehensive Guide to Freezing your Eggs",
            }}
            guide_data={guide_data}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default HomeNewNext;

export const Cards = () => {};
