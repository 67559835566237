import React, { useEffect, useState } from "react";
import { NavbarMain } from "../component/NavbarMain";
import { BottomNav } from "../component/BottomNav";
import { Level } from "./Results/Level";
import { useNavigate } from "react-router-dom";
import { CheckTokenUser, GetSanity } from "../component/API/api";
import { useDispatch, useSelector } from "react-redux";
import {
  MixPanelUsertype,
  userProperties,
} from "../component/API/automation_api";
import { CommonFooter } from "../component/CommonFooter";
import { calculateAge, checkToken } from "../component/API/new_api";
import { createCards, labRangeMaker } from "../utils/results";

export const Result = ({ setloading }) => {
  const store = useSelector((state) => state);
  const [NavBarActive, setNavBarActive] = useState("");
  const navigate = useNavigate();
  const current_store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [MiniNavi, setMiniNavi] = useState(false);
  const [BlankScreen, setBlankScreen] = useState(true);
  const fertility_main = "fertility-test-for-women";

  const [AllData, setAllData] = useState({
    last_recorder_order: {},
    last_order: {
      published: false,
      order_details: {},
    },
    last_intake: {},
    user_data: {},
    discount: {},
  });
  const [ListDates, setListDates] = useState([]);
  const [CurrentShowingOrder, setCurrentShowingOrder] = useState({});
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // useEffect(() => {
  //   const data = localStorage.getItem("user_token");
  //   if (data) {
  //   } else {
  //     navigate("/login");
  //   }
  // }, []);

  // useEffect(() => {
  //   setloading("type1");
  //   if (current_store?.checktoken_data?.updated) {
  //     setloading(false);
  //     setBlankScreen(false);
  //     let t_arr = current_store?.checktoken_data?.all_order
  //       .filter((el) => el.published)
  //       .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  //     setListDates(t_arr);
  //     setCurrentShowingOrder(t_arr.length ? t_arr.reverse()[0] : {});
  //     setAllData((prev) => ({
  //       ...prev,
  //       ...current_store?.checktoken_data,
  //       last_order: t_arr[0] || {},
  //       // last_order: current_store?.checktoken_data?.last_order?.published
  //       //   ? current_store?.checktoken_data?.last_order
  //       //   : current_store?.checktoken_data.prev_order_data || {},
  //     }));
  //   }
  // }, [current_store?.checktoken_data?.updated]);

  // useEffect(() => {
  //   scrollToTop();
  //   const data = localStorage.getItem("user_token");
  //   if (data) {
  //     if (!current_store?.checktoken_data?.updated) {
  //       //
  //
  //       setloading("type1");
  //       CheckTokenUser(
  //         {
  //           token: data,
  //         },
  //         (res) => {
  //           // setloading(false);

  //           if (res.status) {
  //             setloading(false);
  //             setBlankScreen(false);
  //             userProperties({
  //               sign_up_date: res?.response?.user_data?.created_at,
  //               first_visit_date: res?.response?.user_data?.created_at,
  //               last_order_type: res?.response?.last_order?.data?.endPoint
  //                 ? res?.response?.last_order?.data?.endPoint == fertility_main
  //                   ? "During Menstrual Cycle"
  //                   : "Any Day"
  //                 : "",
  //               user_id: res?.response?.user_data?._id,
  //               name: res?.response?.last_order?.data?.pagevaluesinput?.f_name
  //                 ? res?.response?.last_order?.data?.pagevaluesinput?.f_name +
  //                   " " +
  //                   res?.response?.last_order?.data?.pagevaluesinput?.l_name
  //                 : "",
  //               gender: "Female",
  //               date_of_birth:
  //                 res?.response?.last_order?.data?.pagevaluesinput?.birthdate ||
  //                 "",
  //               is_test_user: false,
  //               is_paid_user: res?.response?.last_order ? true : false,

  //               user_current_goal:
  //                 res?.response?.last_intake?.data?.tracking_arva || "",
  //               is_ultrasound_done: res?.response?.last_intake?.data
  //                 ?.prev_dignosed_with_PCOS
  //                 ? res?.response?.last_intake?.data?.prev_dignosed_with_PCOS ==
  //                   "Yes"
  //                 : false || false,
  //               is_birth_control: res?.response?.last_intake?.data?.birthcontrol
  //                 ? res?.response?.last_intake?.data?.birthcontrol == "Yes"
  //                 : false || false,
  //               reported_symptoms:
  //                 res?.response?.last_intake?.data?.experience_symptoms.length >
  //                 0
  //                   ? res?.response?.last_intake?.data?.experience_symptoms.join(
  //                       ","
  //                     )
  //                   : "",

  //               reported_conditions:
  //                 res?.response?.last_intake?.data?.dr_prev_dignosed.length > 0
  //                   ? res?.response?.last_intake?.data?.dr_prev_dignosed?.join(
  //                       ","
  //                     )
  //                   : "",
  //             });
  //           }
  //           if (!res.status) {
  //             setloading(false);
  //             localStorage.clear();
  //             return navigate("/login");
  //           }
  //           const LastIndex =
  //             res.response.all_order.findIndex(
  //               (el) =>
  //                 // el?.custom_id == res?.response?.last_order?.custom_id &&
  //                 el?.published
  //             ) - 1;
  //           const PublishedOrder = res.response.all_order.filter(
  //             (el) =>
  //               // el?.custom_id == res?.response?.last_order?.custom_id &&
  //               el?.published
  //           );
  //           //

  //           setAllData((prev) => ({
  //             ...prev,
  //             prev_order_data: res.response.all_order[LastIndex + 1] || {},
  //             all_order: res.response.all_order || [],
  //             last_order: PublishedOrder[0] || {},
  //             // last_order: res?.response?.last_order?.published
  //             //   ? res?.response?.last_order
  //             //   : res.response.all_order[LastIndex + 1] || {},
  //             // last_order: res?.response?.last_order || {},
  //             last_recorder_order: res?.response?.last_recorder_order || {},
  //             last_intake: res?.response?.last_intake || {},
  //             user_data: res?.response?.user_data || {},
  //             discount: res.response.discount || {},
  //           }));
  //           let t_arr = res.response.all_order
  //             .filter((el) => el.published)
  //             .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  //           setListDates(t_arr);
  //           setCurrentShowingOrder(t_arr.length ? t_arr.reverse()[0] : {});
  //           dispatch({
  //             type: "setCheckToken",
  //             payload: {
  //               prev_order_data: res.response.all_order[LastIndex + 1] || {},
  //               // last_order: res?.response?.last_order?.published
  //               // ? res?.response?.last_order
  //               // : res.response.all_order[LastIndex + 1] || {},
  //               last_order: PublishedOrder[0] || {},
  //               last_recorder_order: res?.response?.last_recorder_order || {},
  //               last_intake: res?.response?.last_intake || {},
  //               user_data: res?.response?.user_data || {},
  //               discount: res.response.discount || {},
  //               all_order: res?.response?.all_order || [],
  //               updated: true,
  //               version: res?.response?.version || "",
  //             },
  //           });
  //           // setstore({
  //           //   token:
  //           //     "eyJhbGciOiJIUzI1NiJ9.eyJwaG9uZSI6IjExMTExMTExMTEifQ.hMFoZxcnyQGD2QONzteuJBG2vcX3nL-VjySoe8BOhq4",
  //           //   // email: res.response.user.email,
  //           //   // image: res.response.user.image,
  //           // });
  //         }
  //       );
  //     }
  //   } else {
  //     setloading(false);
  //     navigate("/login");
  //   }
  // }, []);
  //
  //
  //

  const process_me = ({ success, token }) => {
    console.log(1);
    if (success.customer == null) {
      setloading(false);
      navigate("/404");
    }
    let user_dob = success?.customer?.intake?.dob || "";
    if (!user_dob) {
      return;
    }
    MixPanelUsertype(success);

    let test_completed_ids = [];
    success?.orderItems.map((el) => {
      if (
        ["order completed", "reports published", "fc1 scheduled"].includes(
          el.product_status.toLowerCase()
        ) &&
        el.product_type.toLowerCase() == "test"
      ) {
        test_completed_ids.push(el._id);
      }
    });
    // console.log(test_completed_ids);
    let completed_report = success?.reports
      ?.filter((report) => test_completed_ids.includes(report?.order_id?._id))
      ?.map((report) => {
        let obj = { ...report };
        return {
          ...obj,
          date: obj?.order_id?.dates?.order?.split("/").reverse().join("-"),
        };
      });
    // console.log(completed_report);
    let t = completed_report.map((report) => {
      let age = calculateAge(user_dob);
      let t_ranges = labRangeMaker(report.labContent).map((el) => {
        let result_values = {};
        report.results.map(
          (el1) => (result_values[el1.shortCode] = { value: el1.value })
        );
        // console.log(result_values);
        let t = {
          ...el,
          ...el.labRange(
            age,
            {
              value: "",
              score: "pending",
              ...(result_values ? result_values[el?.biomarkerCode] || {} : {}),
            },
            el?.lab_name || ""
          ),
        };
        delete t.labRange;
        return t;
      });
      return {
        ...report,
        age,
        dob: user_dob,
        user: {
          name: success?.customer?.user?.first_name,
        },
        intake: {
          experience_symptoms:
            success?.customer?.intake?.menstrual_health?.experience_symptoms,
        },
        biomarker: t_ranges,
        cards: createCards(report.labContent.report, t_ranges, {
          ...success?.customer.intake,
        }),
      };
    });
    console.log({ t });
    t.sort((a, b) => new Date(b.date) - new Date(a.date));
    setListDates(t);
    setreports(t);
    setcurrreports(t[0]);
    dispatch({
      type: "setCheckToken",
      payload: { ...success, token: token },
    });
  };
  const [SelectedDate, setSelectedDate] = useState(new Date());
  const [reports, setreports] = useState([]);
  const [currreports, setcurrreports] = useState({});
  const [cards, setcards] = useState([]);

  const check_user_data = (token) => {
    // setBlankScreen(true);
    checkToken(
      token || "",
      (success) => {
        localStorage.setItem("user_me", JSON.stringify({ success, token }));
        process_me({ success, token });

        setloading(false);
        setBlankScreen(false);
      },
      (err) => {}
    );
  };
  console.log({ BlankScreen });

  useEffect(() => {
    const user_me = localStorage.getItem("user_me");
    if (user_me) {
      process_me(JSON.parse(user_me));
      setBlankScreen(false);
    }
    if (store.checktoken_data.token) {
      check_user_data(store.checktoken_data.token);
    } else {
      const local_token = localStorage.getItem("user_token_home");
      if (local_token) {
        check_user_data(local_token);
      } else {
        // window.open("http://localhost:3000/", "_self");
      }
    }
    return () => {};
  }, [store.checktoken_data.token]);
  const [ActiveProfile, setActiveProfile] = useState(false);
  //
  useEffect(() => {
    // setBlankScreen(false);
    return () => {};
  }, []);
  return (
    <>
      {BlankScreen ? (
        ""
      ) : (
        <>
          <div className="main_results">
            {reports.length ? (
              <Level
                {...{
                  MiniNavi: MiniNavi,
                  setMiniNavi: setMiniNavi,
                  setloading: setloading,
                  ListDates: ListDates,
                  CurrentShowingOrder,
                  setCurrentShowingOrder,
                  reports: [currreports],
                  allreport: reports,
                  setcurrreports,
                }}
              />
            ) : (
              <div className="inner_results">
                <div className="results_inner_icons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M3.72656 1.16016V5.16016M1.72656 3.16016H5.72656M4.72656 15.1602V19.1602M2.72656 17.1602H6.72656M11.7266 1.16016L14.0126 8.01716L19.7266 10.1602L14.0126 12.3032L11.7266 19.1602L9.44056 12.3032L3.72656 10.1602L9.44056 8.01716L11.7266 1.16016Z"
                      stroke="#3A7D23"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {true ? (
                  <div className="inner_results_title2">
                    Your unique fertility results will be available here 48
                    hours after your sample collection.
                  </div>
                ) : (
                  <>
                    <div className="inner_results_title">
                      Your results will be available in 48 hours
                    </div>
                    <div className="inner_results_title2">
                      We will notify you when they are ready through Whatsapp
                      and Email. While you wait...
                    </div>
                    <div className="inner_results_btn">Read our guide</div>
                  </>
                )}
              </div>
            )}
          </div>
          <BottomNav />
        </>
      )}
    </>
  );
};
