import { BookAppModal } from "../pages/Orders/BookAppModal";

const default_appdata = {
  name: "",
  cust_id: "",
  token: "",
  project_id: "",
  zipcode: "",
  reschedule: "",
  onCommingPeriodSubmit: () => {},
  onBookDate: () => {},
};

export const checktoken = {
  ...default_appdata,
};

const modal = (state = checktoken, { type, payload }) => {
  //
  switch (type) {
    case "Open":
      return { ...state, ...payload };
    case "Close":
      return { ...state, ...payload };
    default:
      return state;
  }
};
export default modal;
