import React from "react";

export const BookSlotBegin = ({
  booking_data,
  setBeginBtn,
  setRangeCount,
  setPages,
  setpagevalues,
  setBeginPage,
  singleBeginRange,
  AllData,
}) => {
  const fertility_main = "fertility-test-for-women";
  return (
    <>
      <div className="bookslot_body begin">
        <div className="container bookslotbegin_page">
          <div className="bookslotbegin_page_title">
            Let’s book your time slot
          </div>
        </div>
      </div>
      <div className="bookslotbegin_page_btn_main">
        <div
          className="bookslotbegin_page_btn"
          onClick={() => {
            if (booking_data?.product_id?.toLowerCase() == "ft01") {
              // console.log(1);
              setPages("mcycle");
            } else {
              // console.log(2);
              setpagevalues((prev) => ({
                ...prev,
                menstralstate: "Period Constraint NA",
              }));
              setPages("addressedit");
            }
            setRangeCount(0);
            setBeginBtn(true);
            // console.log(booking_data);

            // setPages("addressedit");
            // if (
            //   AllData?.last_order?.data?.endPoint == "fertility-test-for-women"
            // ) {
            // } else {
            //   setPages("addressedit");
            //   setRangeCount(0);
            // }
          }}
        >
          Begin
        </div>
      </div>
    </>
  );
};
