import React from "react";
import {
  useCalendlyEventListener,
  PopupModal,
  InlineWidget,
} from "react-calendly";
import { useSelector } from "react-redux";

const CalendlyModal = ({ url, onSubmit, isOpen, setIsOpen, is_block }) => {
  const store = useSelector((state) => state.checktoken_data);
  // console.log();
  useCalendlyEventListener({
    onEventScheduled: (e) => {
      console.info("Calendly Event Scheduled", e);
      return onSubmit
        ? onSubmit({
            eventUrl: e?.data?.payload?.event?.uri || "",
            inviteeUrl: e?.data?.payload?.invitee?.uri || "",
          })
        : "";
    },
    onEventRescheduled: (e) => {
      console.info("Calendly Event ReScheduled", e);
      return onSubmit
        ? onSubmit({
            eventUrl: e?.data?.payload?.event?.uri || "",
            inviteeUrl: e?.data?.payload?.invitee?.uri || "",
          })
        : "";
    },
  });
  return (
    <div>
      {is_block ? (
        <div style={{ margin: "92px 0 0 0" }}>
          <InlineWidget
            url={
              url +
              "?a1=" +
              store?.customer?.user.prefix +
              store?.customer?.user.phone
            }
            styles={{ height: "calc(100vh - 92px)" }}
            prefill={{
              name: [
                store?.customer?.user?.first_name,
                store?.customer?.user?.last_name,
              ]
                .join(" ")
                .trim(),
              email: store?.customer?.user?.email || "",
            }}
            onModalClose={() => setIsOpen(false)}
            open={isOpen}
            rootElement={document.getElementById("root")}
          />
        </div>
      ) : (
        <>
          <PopupModal
            url={
              url +
              "?a1=" +
              store?.customer?.user.prefix +
              store?.customer?.user.phone
            }
            prefill={{
              name: [
                store?.customer?.user?.first_name,
                store?.customer?.user?.last_name,
              ]
                .join(" ")
                .trim(),
              email: store?.customer?.user?.email || "",
              // a2: "+91 87796 54343",
            }}
            onModalClose={() => setIsOpen(false)}
            open={isOpen}
            rootElement={document.getElementById("root")}
          />
        </>
      )}
    </div>
  );
};

export default CalendlyModal;
