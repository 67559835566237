import React from "react";

export const HomeNewHeader = ({ name = "" }) => {
  return (
    <div className="home_new_header">
      <div className="name">Hey,</div>
      {name ? (
        <>
          <div className="home">{name}</div>
        </>
      ) : (
        <>
          <div className="home">There</div>
        </>
      )}
    </div>
  );
};
