import React from "react";

const CustButton = ({ custClass, btn_obj }) => {
  return (
    <>
      <div
        className={"cust_btn_main " + (custClass ? custClass : "")}
        onClick={btn_obj.onclick}
      >
        {btn_obj.txt ? btn_obj.txt : ""}
      </div>
    </>
  );
};

export default CustButton;
