import React, { useEffect, useLayoutEffect, useState } from "react";
import CustInputMuiClone from "../CustInput/CustInputMuiClone";
import { useNavigate } from "react-router-dom";
import { MixPanelOtp, MixPanelUsertype } from "../API/automation_api";
import { useDispatch, useSelector } from "react-redux";
import CustPhoneCountries from "../CustInput/CustPhoneCountries";
import { checkToken, sendOtp } from "../API/new_api";

export const LoginHomeUser = ({ setloading }) => {
  const handle_pagevalues = (updated_data) => {
    return setpagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  const handle_errpagevalues = (updated_data) => {
    return seterr_pagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  const [value, setvalue] = useState({
    flag_code: "in",
    code: "+91",
    name: "India",
  });
  const [is_open, setis_open] = useState(false);
  useLayoutEffect(() => {
    window.onclick = () => {
      setis_open(false);
    };
  }, []);
  const navigate = useNavigate();

  const [OtpVerified, setOtpVerified] = useState(false);
  const [Token, setToken] = useState("");
  const [pagevalues, setpagevalues] = useState({
    phone: "",
  });
  const [err_pagevalues, seterr_pagevalues] = useState({
    phone: false,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "setCheckToken",
      payload: {
        updated: false,
      },
    });
    if (OtpVerified) {
      localStorage.setItem("user_token", Token);
      navigate("/home");
    } else {
      let to = localStorage.getItem("user_token");
      // localStorage.setItem("user_token", Token);
      if (to) {
        navigate("/home");
      }
    }
  }, [OtpVerified]);

  const handel_login = () => {
    if (!/^(?:\d{5}\s\d{5})$/.test(pagevalues.phone)) {
      handle_errpagevalues({
        phone: !/^(?:\d{5}\s\d{5})$/.test(pagevalues.phone),
      });
      return;
    }
    localStorage.setItem("OrderPhoneNo", pagevalues.phone);
    // setloading("type1");
    navigate("/send-otp");
    dispatch({
      type: "setCheckToken",
      payload: {
        prefic: value,
        phone: pagevalues.phone,
      },
    });
    sendOtp(
      {
        prefic: value.code.slice(1),
        phone: pagevalues.phone.replaceAll(" ", "").slice(-10),
      },
      (success) => {
        console.log(success);
        if (success.status) {
          document.getElementById("otp1").focus();
        }
      },
      (error) => {
        setloading(false);
        console.log(error);
      }
    );
  };
  const check_user_data = (token) => {
    checkToken(
      token || "",
      (success) => {
        if (success.customer == null) {
          localStorage.clear();
          navigate("/404");
        }

        MixPanelUsertype(success);
        dispatch({
          type: "setCheckToken",
          payload: { ...success, token: token },
        });
        navigate("/home");
      },
      (err) => {}
    );
  };
  const store = useSelector((state) => state);

  useEffect(() => {
    if (store.checktoken_data.token) {
      check_user_data(store.checktoken_data.token);
    } else {
      const local_token = localStorage.getItem("user_token_home");
      if (local_token) {
        check_user_data(local_token);
      } else {
        navigate("/login");
      }
    }
    return () => {};
  }, [store.checktoken_data.token]);

  useEffect(() => {
    if (store?.checktoken_data?.prefic?.code && store?.checktoken_data?.phone) {
      handle_pagevalues({
        phone: store?.checktoken_data?.phone,
      });
      setvalue(store?.checktoken_data?.prefic);
    }
  }, [store?.checktoken_data?.prefic?.code, store?.checktoken_data?.phone]);

  return (
    <>
      <div className="login_v2_main">
        <div className="login_v2_top">
          {/* <img src="https://imgur.com/yfffeS3.png" /> */}
          <div className="login_v2_top_image">
            <svg
              width="101"
              height="101"
              viewBox="0 0 101 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                width="100"
                height="100.219"
                rx="50"
                fill="#5F1D1B"
              />
              <path
                d="M18.5434 58.0813H20.9786C21.1394 58.0813 21.3232 57.9894 21.3921 57.8057L22.7475 54.3367H30.4896L31.845 57.8057C31.9139 57.9894 32.0977 58.0813 32.2585 58.0813H34.6937C35.0842 58.0813 35.3599 57.7367 35.1991 57.3462L29.272 42.2757C29.2031 42.0919 29.0193 42 28.8585 42H24.3787C24.2178 42 24.0341 42.0919 23.9651 42.2757L18.038 57.3462C17.9002 57.7138 18.1529 58.0813 18.5434 58.0813ZM23.9422 51.3502L26.63 44.3663L29.3409 51.3502H23.9422Z"
                fill="white"
              />
              <path
                d="M50.1722 58.2192C50.5627 58.2192 50.8614 58.1503 50.8614 57.53V55.6921C50.8614 55.3475 50.7695 55.2326 50.4019 55.2097C49.2762 55.1637 49.2302 53.0042 47.76 51.6488C49.2073 50.7528 50.0113 49.2596 50.0113 47.3528C50.0113 44.1365 47.737 42 44.0842 42H37.0773C36.8706 42 36.7327 42.1149 36.7327 42.3446V57.7367C36.7327 57.9435 36.8706 58.0813 37.0773 58.0813H39.6044C39.8342 58.0813 39.949 57.9435 39.949 57.7367V52.7056H43.5099C47.0248 52.7056 45.4856 58.2192 50.1722 58.2192ZM39.949 49.719V44.9865H44.1991C45.8072 44.9865 46.7951 45.8136 46.7951 47.3528C46.7951 48.892 45.8072 49.719 44.1991 49.719H39.949Z"
                fill="white"
              />
              <path
                d="M57.7832 58.0813H60.8616C61.0224 58.0813 61.2062 57.9894 61.2751 57.8057L67.6617 42.7351C67.8225 42.3446 67.5468 42 67.1563 42H64.5603C64.3995 42 64.2157 42.0919 64.1468 42.2757L59.3224 54.3826L54.498 42.2757C54.429 42.0919 54.2453 42 54.0844 42H51.4885C51.0979 42 50.8222 42.3446 50.983 42.7351L57.3696 57.8057C57.4385 57.9894 57.6223 58.0813 57.7832 58.0813Z"
                fill="white"
              />
              <path
                d="M66.2976 58.0813H68.7328C68.8936 58.0813 69.0774 57.9894 69.1463 57.8057L70.5018 54.3367H78.2438L79.5992 57.8057C79.6681 57.9894 79.8519 58.0813 80.0127 58.0813H82.4479C82.8385 58.0813 83.1141 57.7367 82.9533 57.3462L77.0262 42.2757C76.9573 42.0919 76.7735 42 76.6127 42H72.1329C71.9721 42 71.7883 42.0919 71.7194 42.2757L65.7922 57.3462C65.6544 57.7138 65.9071 58.0813 66.2976 58.0813ZM71.6964 51.3502L74.3843 44.3663L77.0951 51.3502H71.6964Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="login_top_title">Welcome to Arva</div>
          <div className="login_top_desc">Login with your phone number.</div>
        </div>
        <div className="login_v2_bottom">
          <div className="login_bottom_phone">
            <CustInputMuiClone
              {...{
                attr: {
                  error: err_pagevalues.phone,
                  error_msg: "",
                  value: pagevalues.phone,
                  inputMode: "numeric",
                  event: {
                    change: ({ target }) => {
                      if (Number(target.value.slice(-1)).toString() == "NaN") {
                        return;
                      }
                      const cleanedInput = target.value.replace(/\D/g, "");
                      let formattedInput = "";
                      if (cleanedInput.length > 5) {
                        formattedInput =
                          cleanedInput.slice(0, 5) +
                          " " +
                          cleanedInput.slice(5);
                      } else {
                        formattedInput = cleanedInput;
                      }
                      if (cleanedInput.length === 11) {
                        return;
                      }
                      handle_pagevalues({
                        phone: formattedInput.slice(0, 11),
                      });
                      handle_errpagevalues({
                        phone: target.value ? "" : "Error message",
                      });
                    },
                    onkeydown: (e) => {
                      if (e.key == "Enter") {
                        handel_login();
                      }
                    },
                  },
                },
                data: {
                  title: "",
                  prefix: (
                    <CustPhoneCountries value={value} setvalue={setvalue} />
                  ),

                  // prefix: <Flag />,
                },
              }}
            />
          </div>
          <div
            className="login_bottom_btn"
            onClick={() => {
              handel_login();
              localStorage.clear();
            }}
          >
            Send OTP
          </div>
          <div className="login_bottom_desc">
            By proceeding, I accept the <span>T&C</span> and{" "}
            <span>Privacy Policy</span>
          </div>
        </div>
      </div>
    </>
  );
};
