// import React from "react";
import { initializeApp } from "firebase/app";
import {
  FacebookAuthProvider,
  getAuth,
  //   getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  //   signInWithRedirect,
} from "firebase/auth";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyBGUxw3pDH5NjrR8EVCRz1TJdSvLNM2cTw",
//   authDomain: "my-music-publisher-66143.firebaseapp.com",
//   projectId: "my-music-publisher-66143",
//   storageBucket: "my-music-publisher-66143.appspot.com",
//   messagingSenderId: "206580725955",
//   appId: "1:206580725955:web:1d5a0e3d525347e9db0a76",
// };
const firebaseConfig = {
  apiKey: "AIzaSyDzKQfDFTUD9wTw3Cmczj66MN2aLd847Ss",
  authDomain: "arva-health.firebaseapp.com",
  projectId: "arva-health",
  storageBucket: "arva-health.appspot.com",
  messagingSenderId: "751257527428",
  appId: "1:751257527428:web:1607895354690024b94ed1",
  measurementId: "GTM-5TGBH8H7",
};

export const firebase_app = initializeApp(firebaseConfig);

const auth = getAuth();
const facebook_provider = new FacebookAuthProvider();
// const linkedin_provider = new OAuthProvider("microsoft.com");
const google_provider = new GoogleAuthProvider();
const apple_provider = new OAuthProvider("apple.com");
// const messaging = getMessaging(firebase_app);

// google signin popup
export const SigninWithFirebase = (login_id, setothers) => {
  // if (login_id != 2) return;
  // let data = {
  //   token: "",
  //   others: {},
  // };
  signInWithPopup(
    // signInWithRedirect(
    auth,
    [google_provider][login_id]
  )
    .then(async (res) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      let credential;
      // [0:facebook,1:]
      switch (login_id) {
        case 0:
          credential = FacebookAuthProvider.credentialFromResult(res);
          break;
        case 1:
          facebook_provider.addScope("public_profile");
          credential = OAuthProvider.credentialFromResult(res);
          // provider.addScope("displayName");
          break;
        case 2:
          credential = GoogleAuthProvider.credentialFromResult(res);
          break;
        case 3:
          apple_provider.addScope("email");
          apple_provider.addScope("name");
          credential = OAuthProvider.credentialFromResult(res);
          break;
        default:
          return;
          break;
      }
      const token = credential.accessToken;
      // The signed-in user info.
      const user = res.user;
      // setemail(user.email);
      // setname && setname(user.displayName);
      // firebase token to send to back end
      return {
        // token: credential.accessToken,
        token: await auth.currentUser.getIdToken(true),
        others: res,
      };
    })
    .then((data) => {
      setothers(false, data);
    })
    .catch((error) => {
      // data.error = true;
      setothers(true, error);
      //   const errorCode = error.code;
      //   const errorMessage = error.message;
      //   // The email of the user's account used.
      //   const email = error.customData.email;
      //   // The AuthCredential type that was used.
      //   const credential = GoogleAuthProvider.credentialFromError(error);
    });
  // return data;
  return;
};

// export const check_user_signin = () => {
//   const user = auth.currentUser;
//   return user;
// };

// export const getToken1 = (setTokenFound) => {
//   return getToken(messaging, {
//     vapidKey:
//       "BG0V9Ys-nIeoFZodrJZuzLh91m00VPNqumsdrpEH_K3uvIdQtgW1dSe29itKRx3okyj338u--OqKfuK2MSjUJeU",
//   })
//     .then((currentToken) => {
//       if (currentToken) {
//         setTokenFound(true);
//         // Track the token -> client mapping, by sending to backend server
//         // show on the UI that permission is secured
//       } else {

//         setTokenFound(false);
//         // shows on the UI that permission is required
//       }
//     })
//     .catch((err) => {
//       // catch error while creating client token
//     });
// };
