import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
export const ExpNav = () => {
  const navigate = useNavigate();
  const NavList = [
    {
      name: "Articles",
      // bg_color_active: "#FF5E00",
      // // bg_color_active: "#FFA977",
      // txt_color: "#5F1D1B",
      // bg_color: "#FFA97740",
      // txt_color_active: "#D4DBCB",
      navi: "/article/all",
      active: false,
    },
    {
      name: "Guides",
      // bg_color_active: "#F2C0B2",
      // txt_color: "#5F1D1B",
      // bg_color: "#F2C0B280",
      // txt_color_active: "#5F1D1B",
      navi: "/guide/all",
      active: true,
    },
    {
      name: "Clinics",
      // bg_color_active: "#F2C0B2",
      // txt_color: "#5F1D1B",
      // bg_color: "#F2C0B280",
      // txt_color_active: "#5F1D1B",
      navi: "/clinic/all",
      active: true,
    },
    {
      name: "FAQ",
      // bg_color_active: "#2B3A33",
      // txt_color: "#2B3A33",
      // bg_color: "#D4DBCB80",
      // txt_color_active: "#D4DBCB",
      navi: "/faq/all",
      active: false,
    },
  ];

  return (
    <div className="main_expnav">
      <div className="inner_expnav">
        {NavList.map((el, i) => (
          <div
            key={i}
            className={
              "main_expnav_box " +
              (window.location.pathname == el.navi ? " active_expnav_box" : "")
            }
            // style={
            //   window.location.pathname == el.navi
            //     ? {
            //         backgroundColor: el.bg_color_active,
            //         color: el.txt_color_active,
            //       }
            //     : {
            //         backgroundColor: el.bg_color,
            //         color: el.txt_color,
            //       }
            // }
            onClick={() => {
              navigate(el.navi);
            }}
          >
            {el.name}
          </div>
        ))}
      </div>
    </div>
  );
};
