import { combineReducers, createStore } from "redux";
import AppData from "./appdata";
import BlogsSanity from "./BlogsSanity";
import modal from "./modal";

const rootReducer = combineReducers({
  checktoken_data: AppData,
  blogsanity_data: BlogsSanity,
  modal: modal,
});

const store = createStore(rootReducer);

export default store;
