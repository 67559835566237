import React from "react";
import { reportColor } from "../../utils/results";

export const Summary = ({
  img,
  type,
  main_title,
  summarytitle,
  summarydesc,
  typereportlearn,
  physicalsymptoms,
  bgcolor,
  fcolor,
  chipcolor,
}) => {
  console.log(img);
  function asset(url) {
    let a = url.split("-");
    return `https://cdn.sanity.io/${a[0]}s/nao51hyy/production/${a
      .slice(1, -1)
      .join("-")}.${a[a.length - 1]}`;
  }
  return (
    <div className="summary_type_main">
      <div className="summary_type_main_title">{main_title}</div>

      <div className="inner_summary_type_main">
        <div className="summary_type_img" style={{ backgroundColor: bgcolor }}>
          <img src={img} />
        </div>

        <div className="inner_summary_type">
          <div className="summary_type_title">{summarytitle}</div>
          {summarydesc && (
            <div
              className="summary_type_desc"
              style={{
                border: typereportlearn.length === 0 && "none",
                padding: typereportlearn.length === 0 && "0",
              }}
            >
              {summarydesc}
            </div>
          )}
          {!typereportlearn?.includes("Danger") && (
            <>
              {/* {type === "pcos" ? (
            typereportlearn.length === 0 ? (
              ""
            ) : (
              <div className="summarytype_list">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M3.33398 5.00016C3.33398 4.55814 3.50958 4.13421 3.82214 3.82165C4.1347 3.50909 4.55862 3.3335 5.00065 3.3335H6.66732C7.10935 3.3335 7.53327 3.50909 7.84583 3.82165C8.15839 4.13421 8.33398 4.55814 8.33398 5.00016V6.66683C8.33398 7.10886 8.15839 7.53278 7.84583 7.84534C7.53327 8.1579 7.10935 8.3335 6.66732 8.3335H5.00065C4.55862 8.3335 4.1347 8.1579 3.82214 7.84534C3.50958 7.53278 3.33398 7.10886 3.33398 6.66683V5.00016ZM11.6673 5.00016C11.6673 4.55814 11.8429 4.13421 12.1555 3.82165C12.468 3.50909 12.892 3.3335 13.334 3.3335H15.0007C15.4427 3.3335 15.8666 3.50909 16.1792 3.82165C16.4917 4.13421 16.6673 4.55814 16.6673 5.00016V6.66683C16.6673 7.10886 16.4917 7.53278 16.1792 7.84534C15.8666 8.1579 15.4427 8.3335 15.0007 8.3335H13.334C12.892 8.3335 12.468 8.1579 12.1555 7.84534C11.8429 7.53278 11.6673 7.10886 11.6673 6.66683V5.00016ZM3.33398 13.3335C3.33398 12.8915 3.50958 12.4675 3.82214 12.155C4.1347 11.8424 4.55862 11.6668 5.00065 11.6668H6.66732C7.10935 11.6668 7.53327 11.8424 7.84583 12.155C8.15839 12.4675 8.33398 12.8915 8.33398 13.3335V15.0002C8.33398 15.4422 8.15839 15.8661 7.84583 16.1787C7.53327 16.4912 7.10935 16.6668 6.66732 16.6668H5.00065C4.55862 16.6668 4.1347 16.4912 3.82214 16.1787C3.50958 15.8661 3.33398 15.4422 3.33398 15.0002V13.3335ZM11.6673 13.3335C11.6673 12.8915 11.8429 12.4675 12.1555 12.155C12.468 11.8424 12.892 11.6668 13.334 11.6668H15.0007C15.4427 11.6668 15.8666 11.8424 16.1792 12.155C16.4917 12.4675 16.6673 12.8915 16.6673 13.3335V15.0002C16.6673 15.4422 16.4917 15.8661 16.1792 16.1787C15.8666 16.4912 15.4427 16.6668 15.0007 16.6668H13.334C12.892 16.6668 12.468 16.4912 12.1555 16.1787C11.8429 15.8661 11.6673 15.4422 11.6673 15.0002V13.3335Z"
                    stroke="#777777"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div>TYPE OF PCOS - LEANING</div>
              </div>
            )
          ) : (
            ""
          )} */}
              {/* {
          } */}
              {typereportlearn.length === 0 ? (
                ""
              ) : (
                <div className="typereportlearn_list">
                  {typereportlearn?.map((el, i) => (
                    <div
                      key={i}
                      className="typereportlearn_list_box"
                      style={{
                        color: reportColor[el.color]?.f || "#B418D1",
                        backgroundColor: reportColor[el.color]?.b || "#F8E8FA",
                        // color: chipcolor?.fcolor || "#B418D1",
                        // backgroundColor: chipcolor?.bcolor || "#F8E8FA",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8.66667 4.6665H14M14 4.6665V9.99984M14 4.6665L8.66667 9.99984L6 7.33317L2 11.3332"
                          stroke={reportColor[el.color]?.f || "#B418D1"}
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Signs of{" "}
                      <div className="typereportlearn_list_box_title">
                        {el?.txt}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {physicalsymptoms.length > 0 ? (
                <div className="physicalsymptoms_list">
                  <div className="physicalsymptoms_list_top">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M16.9619 12.7952C15.5979 13.3437 14.1028 13.4793 12.6625 13.185C11.2221 12.8907 9.89994 12.1796 8.8604 11.1401C7.82086 10.1006 7.10976 8.77842 6.81549 7.33804C6.52122 5.89766 6.65676 4.40255 7.20526 3.03857C5.59002 3.68918 4.25139 4.8814 3.41886 6.41084C2.58633 7.94028 2.31175 9.71169 2.64219 11.4214C2.97262 13.1311 3.88749 14.6726 5.22997 15.7817C6.57244 16.8908 8.25891 17.4984 10.0003 17.5002C11.4977 17.5003 12.9609 17.0522 14.2015 16.2137C15.4422 15.3753 16.4036 14.1847 16.9619 12.7952Z"
                        stroke="#777777"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div>PHYSICAL SYMPTOMS</div>
                  </div>
                  <div className="physicalsymptoms_list_center">
                    {physicalsymptoms.map((el, i) => (
                      <div key={i} className="physicalsymptoms_list_center_box">
                        {el}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
