import React, { useEffect, useRef, useState } from "react";
import { date_formatter } from "../CustInput/CustInput";

export const Graphs = ({
  hormone,
  plotmax,
  type,
  amh_group,
  graph,
  bg_type,
}) => {
  console.log({
    test: {
      hormone,
      type,
      amh_group,
      graph,
      bg_type,
    },
  });
  const handelPlotterColor = (plot_value, min, max) => {
    // return " plotter_color1";
    if (plot_value < min) {
      return " plotter_color2";
    }
    if (plot_value > max) {
      return " plotter_color1";
    }
    if (plot_value > min) {
      return " plotter_color3";
    }
    // if (plot_value < 1) {
    //   return " plotter_color1";
    // } else if (plot_value < 2) {
    //   return " plotter_color2";
    // } else if (plot_value < 4) {
    //   return " plotter_color3";
    // } else if (plot_value < 5) {
    //   return " plotter_color2";
    // }
    return " plotter_color1";
  };
  function customFunctionOptimal(input, diff) {
    if (input > 4) {
      return (
        input - 2 - (diff || 0) + 0.5 * (input - (input - 2 - (diff || 0)))
      );
    }
    if (input > 2) {
      return 2 - (diff || 0) + 0.5 * (input - 2 - (diff || 0));
    }
    return input;
  }
  const GraphWid = useRef();
  const [GraphWidath, setGraphWidath] = useState(0);
  useEffect(() => {
    setGraphWidath(GraphWid?.current?.getBoundingClientRect()?.width);
  }, []);

  const NextDateShower = (currentDatew) => {
    const oneYearFromToday = new Date(currentDatew);
    oneYearFromToday.setFullYear(currentDatew.getFullYear() + 1);
    return new Date(oneYearFromToday.toDateString());
  };

  switch (hormone) {
    case "amh":
      if (type == "type1") {
        return (
          <div className="graphs amh_graph" type="1" ref={GraphWid}>
            <div
              className="custom_plotter"
              style={{
                ...(graph.min ? { "--min": graph.min } : {}),
                ...(graph.max ? { "--max": graph.max } : {}),
              }}
            >
              <div className="bg1">
                <div>High: Signs of PCOS</div>
                {/* <div>Signs of PCOS</div> */}
              </div>
              <div className="bg2">
                {/* <div>High</div> */}
                <div>Slightly High</div>
              </div>
              <div className="bg3">
                <div>Optimal</div>
              </div>
              <div className="bg4">
                <div>Slightly Low</div>
              </div>
              <div className="bg5">
                <div>Low ovarian reserve</div>
              </div>
              {/* <div className="ylabels y0">0</div> */}
              <div className="ylabels y1">1</div>
              <div className="ylabels y2">2</div>
              <div className="ylabels y3">3</div>
              <div className="ylabels y4">4</div>
              <div className="ylabels y5">5</div>
              <div className="ylabels y7">6</div>
              <div className="ylabels y9">7</div>
              <div className="ylabels y11">8</div>
              {/* <div className="ylabels yunit">ng/mL</div> */}
              <div className="ylabels yAge">Age</div>
              <div className="xlabelsgroup">
                {/* <div className="xlables x1">
                  {graph.age - 1} yrs <br></br> date{" "}
                </div> */}
                {amh_group.slice(0).map((el) => {
                  return (
                    <div
                      className={
                        "xlables x2  plotter_main" +
                        handelPlotterColor(
                          el.count,
                          graph.min || 0,
                          graph.max || 0
                        )
                      }
                      style={{
                        "--plot":
                          el.count > 5 ? `${5 + (el.count - 5) / 1}` : el.count,
                      }}
                    >
                      {Math.floor(el.age)} yrs
                      <br></br>{" "}
                      {date_formatter(
                        new Date(el?.date),
                        "date/month/year_short"
                      )}
                      {el.count ? (
                        <>
                          <div className="plotter"></div>
                          <div
                            className="plotter_ruledline"
                            style={{ width: `${GraphWidath * 0.228}px` }}
                          ></div>
                          <div className="plotter_plotnumber">
                            {el.count} ng/mL
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
                {/* <div
                  className={
                    "xlables x2  plotter_main" +
                    handelPlotterColor(
                      graph.plot,
                      graph.min || 0,
                      graph.max || 0
                    )
                  }
                  style={{
                    "--plot":
                      graph.plot > 5
                        ? `${5 + (graph.plot - 5) / 1}`
                        : graph.plot,
                  }}
                >
                  {graph.age - 0} yrs
                  <br></br>{" "}
                  {date_formatter(
                    new Date(graph?.dates),
                    "date/month/year_short"
                  )}
                  {graph?.plot ? (
                    <>
                      <div className="plotter"></div>
                      <div
                        className="plotter_ruledline"
                        style={{ width: `${GraphWidath * 0.228}px` }}
                      ></div>
                      <div className="plotter_plotnumber">
                        {graph.plot} ng/mL
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div> */}
                <div className="xlables x3">
                  {graph.age + 1} yrs <br></br>{" "}
                  {NextDateShower(new Date(graph?.dates))
                    ? date_formatter(
                        NextDateShower(new Date(graph?.dates)),
                        "date/month/year_short"
                      )
                    : ""}
                </div>
                {/* <div className="xlables x4">{graph.age + 2}</div> */}
                {/* <div className="xlables x5">{graph.age + 3}</div>
                <div className="xlables x6">{graph.age + 4}</div>
                <div className="xlables x7">{graph.age + 5}</div>
                <div className="xlables x8">{graph.age + 6}</div> */}
              </div>
            </div>
            {bg_type === "type2" ? (
              <div className="graph_txt">Tested during follicular Phase</div>
            ) : (
              ""
            )}
          </div>
        );
      } else if (type == "type2") {
        let gaps = parseInt((parseInt(graph.plot) - 5) / 3);
        console.log(gaps, "gaps");
        let plot = 6;
        if (5 + gaps * 3 != graph.plot) {
          plot += (graph.plot - (5 + gaps * 3)) / gaps;
        }
        const calc_plot = (x) =>
          5 + gaps * 3 != x ? 6 + (x - (5 + gaps * 3)) / gaps : x;
        return (
          <div className="graphs amh_graph" type="2">
            <div
              className="custom_plotter"
              style={{
                ...(graph.min ? { "--min": graph.min } : {}),
                ...(graph.max ? { "--max": graph.max } : {}),
                // ...(graph.max
                //   ? {
                //       "--in_between": customFunctionOptimal(
                //         graph.max,
                //         graph.min
                //       ),
                //     }
                //   : {}),
              }}
              // bg_type={bg_type}
            >
              <div className="bg1">
                <div>High: Signs of PCOS</div>
                {/* <div>Signs of PCOS</div> */}
              </div>
              <div className="bg2">
                {/* <div>High</div> */}
                <div>Slightly High</div>
              </div>
              <div className="bg3">
                <div>Optimal</div>
              </div>
              <div className="bg4">
                <div>Slightly Low</div>
              </div>
              <div className="bg5">
                <div>Low ovarian reserve</div>
              </div>
              <div className="ylabels y0">0</div>
              <div className="ylabels y1">1</div>
              <div className="ylabels y2">2</div>
              {/* <div className="ylabels dots dot1">...</div> */}
              <div className="ylabels y3">3</div>
              <div className="ylabels y4">4</div>
              {/* <div className="ylabels dots dot2">...</div> */}
              <div className="ylabels y5">{5 + gaps * 2}</div>
              <div className="ylabels y7">{5 + gaps * 3}</div>
              <div className="ylabels y9">{5 + gaps * 4}</div>
              <div className="ylabels y11">{5 + gaps * 5}</div>
              {/* <div className="ylabels y12">{5 + gaps * 5}</div> */}
              <div className="ylabels yunit">ng/mL</div>
              <div className="ylabels yAge">Age</div>
              <div className="xlabelsgroup">
                {amh_group.slice(0).map((el) => {
                  return (
                    <div
                      className={
                        "xlables x1 plotter_main" +
                        handelPlotterColor(
                          el.count,
                          graph.min || 0,
                          graph.max || 0
                        )
                      }
                      style={{
                        "--plot": calc_plot(el.count),
                      }}
                    >
                      {Math.floor(el.age)} yrs <br></br>{" "}
                      {date_formatter(
                        new Date(el?.date),
                        "date/month/year_short"
                      )}
                      {el.count ? (
                        <>
                          <div className="plotter"></div>
                          <div className="plotter_ruledline"></div>
                          <div className="plotter_plotnumber">
                            {el.count}ng mL
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
                {/* <div
                  className={
                    "xlables x1 plotter_main" +
                    handelPlotterColor(
                      graph.plot,
                      graph.min || 0,
                      graph.max || 0
                    )
                  }
                  style={{
                    "--plot": plot,
                  }}
                >
                  {graph.age} yrs <br></br>{" "}
                  {date_formatter(
                    new Date(graph?.dates),
                    "date/month/year_short"
                  )}
                  {graph?.plot ? (
                    <>
                      <div className="plotter"></div>
                      <div className="plotter_ruledline"></div>
                      <div className="plotter_plotnumber">
                        {graph.plot}ng mL
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div> */}

                <div className={"xlables x2 "}>
                  {graph.age + 1} yrs <br></br>{" "}
                  {NextDateShower(new Date(graph?.dates))
                    ? date_formatter(
                        NextDateShower(new Date(graph?.dates)),
                        "date/month/year_short"
                      )
                    : ""}
                </div>
                {/* <div className="xlables x3">{graph.age + 1}</div> */}
                {/* <div className="xlables x4">{graph.age + 2}</div> */}
                {/* <div className="xlables x5">{graph.age + 3}</div> */}
                {/* <div className="xlables x6">{graph.age + 4}</div> */}
                {/* <div className="xlables x7">{graph.age + 5}</div> */}
                {/* <div className="xlables x8">{graph.age + 6}</div> */}
              </div>
            </div>
            {bg_type === "type2" && (
              <div className="graph_txt">Tested during follicular Phase</div>
            )}
          </div>
        );
      } else if (type == "type3") {
        let gaps = parseInt((parseInt(graph.data.plot) - 5) / 3);
        // let plot = 7;
        // if (5 + gaps * 3 != graph.data.plot) {
        //   plot += (graph.data.plot - (5 + gaps * 3)) / gaps;
        // }
        {
          graph.list.forEach((item) => {
            let gaps = parseInt((parseInt(item.plot) - 5) / 3);
            let plot = 7;

            if (5 + gaps * 3 !== parseInt(item.plot)) {
              plot += (parseInt(item.plot) - (5 + gaps * 3)) / gaps;
            }
            // Update the 'plot' property of the current object
            item.plot = plot;
          });
        }
        <>
          {graph?.list?.map((el, i) => {
            return (
              <div
                className="graphs amh_graph"
                type="2"
                // ref={GraphWid}
                id={"graphwid_" + (i + 1)}
              >
                <div
                  className="custom_plotter"
                  style={{
                    "--plot": el.plot,
                    ...(el.min ? { "--min": el.min } : {}),
                    ...(el.max ? { "--max": el.max } : {}),
                  }}
                >
                  <div className="bg1">
                    <div>Signs of PCOS</div>
                  </div>
                  <div className="bg2">
                    <div>High</div>
                    {/* <div>Slightly High</div> */}
                  </div>
                  <div className="bg3">
                    <div>Optimal</div>
                  </div>
                  <div className="bg4">
                    <div>Slightly Low</div>
                  </div>
                  <div className="bg5">
                    <div>Low</div>
                  </div>
                  <div className="ylabels y0">0</div>
                  <div className="ylabels y1">1</div>
                  <div className="ylabels y2">2</div>
                  {/* <div className="ylabels dots dot1">...</div> */}
                  <div className="ylabels y3">3</div>
                  <div className="ylabels y4">4</div>
                  {/* <div className="ylabels dots dot2">...</div> */}
                  <div className="ylabels y5">{5 + gaps}</div>
                  <div className="ylabels y7">{5 + gaps * 2}</div>
                  <div className="ylabels y9">{5 + gaps * 3}</div>
                  <div className="ylabels y11">{5 + gaps * 4}</div>
                  {/* <div className="ylabels y12">{5 + gaps * 5}</div> */}
                  {/* <div className="ylabels yunit">ng/mL</div> */}
                  <div className="ylabels yAge">Age</div>
                  <div className="xlabelsgroup">
                    <div
                      className={
                        "xlables x2 plotter_main" +
                        handelPlotterColor(
                          graph.data.plot,
                          graph.data.min || 0,
                          graph.data.max || 0
                        )
                      }
                    >
                      {graph.data.age - 0} yrs <br></br> date
                      {graph?.data.plot ? (
                        <>
                          <div className="plotter"></div>
                          <div
                            className="plotter_ruledline"
                            style={{ width: `${GraphWidath * 0.228}px` }}
                          ></div>
                          <div className="plotter_plotnumber">
                            AMH {el.plot} ng/ml
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="xlables x3">
                      {el.age + 1} yrs <br></br> date
                      {el?.plot ? (
                        <>
                          <div className="plotter"></div>
                          <div
                            className="plotter_ruledline"
                            style={{ width: `${GraphWidath * 0.228}px` }}
                          ></div>
                          <div className="plotter_plotnumber">
                            AMH {el?.plot} ng/ml
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className="xlables x4">{graph.age + 2}</div> */}
                    {/* <div className="xlables x5">{graph.age + 3}</div> */}
                    {/* <div className="xlables x6">{graph.age + 4}</div> */}
                    {/* <div className="xlables x7">{graph.age + 5}</div> */}
                    {/* <div className="xlables x8">{graph.age + 6}</div> */}
                  </div>
                </div>
                {bg_type === "type2" && (
                  <div className="graph_txt">
                    Tested during follicular Phase
                  </div>
                )}
              </div>
            );
          })}
        </>;
      } else if (type == "type1_future") {
        let latest = amh_group.filter((el) => {
          if (
            new Date(el.date).valueOf() == new Date(graph?.dates || 0).valueOf()
          ) {
            return true;
          }
        });
        graph.age = Math.floor(latest[0]?.age || 0);
        return (
          <div className="graphs amh_graph" type="1" ref={GraphWid}>
            <div
              className="custom_plotter"
              style={{
                ...(graph.min ? { "--min": graph.min } : {}),
                ...(graph.max ? { "--max": graph.max } : {}),
              }}
            >
              <div className="bg1">
                <div>High: Signs of PCOS</div>
              </div>
              <div className="bg2">
                <div>Slightly High</div>
              </div>
              <div className="bg3">
                <div>Optimal</div>
              </div>
              <div className="bg4">
                <div>Slightly Low</div>
              </div>
              <div className="bg5">
                <div>Low ovarian reserve</div>
              </div>
              {/* <div className="ylabels y0">0</div> */}
              <div className="ylabels y1">1</div>
              <div className="ylabels y2">2</div>
              <div className="ylabels y3">3</div>
              <div className="ylabels y4">4</div>
              <div className="ylabels y5">5</div>
              <div className="ylabels y7">6</div>
              <div className="ylabels y9">7</div>
              <div className="ylabels y11">8</div>
              {/* <div className="ylabels yunit">ng/mL</div> */}
              <div className="ylabels yAge">Age</div>
              <div className="xlabelsgroup">
                {/* <div className="xlables x1">
                    {graph.age - 1} yrs <br></br> date{" "}
                  </div> */}
                <div
                  className={
                    "xlables x2  plotter_main" +
                    handelPlotterColor(
                      graph.plot,
                      graph.min || 0,
                      graph.max || 0
                    )
                  }
                  style={{
                    "--plot":
                      graph.plot > 5
                        ? `${5 + (graph.plot - 5) / 1}`
                        : graph.plot,
                  }}
                >
                  {graph.age - 0} yrs
                  <br></br>
                  {date_formatter(
                    new Date(graph?.dates),
                    "date/month/year_short"
                  )}
                  {graph?.plot ? (
                    <>
                      <div className="plotter"></div>
                      <div
                        className="plotter_ruledline"
                        style={{ width: `${GraphWidath * 0.228}px` }}
                      ></div>
                      <div className="plotter_plotnumber">
                        {Number(graph.plot).toFixed(2)} ng/mL
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    "xlables x3 plotter_main" +
                    handelPlotterColor(
                      graph.plot - (graph.age + 1 > 35 ? 0.1 : 0.2),
                      graph.min || 0,
                      graph.max || 0
                    )
                  }
                  style={{
                    "--plot":
                      graph.plot > 5
                        ? `${5 + (graph.plot - 5) / 1}`
                        : graph.plot - (graph.age + 1 > 35 ? 0.1 : 0.2),
                  }}
                >
                  {graph.age + 1} yrs <br></br>{" "}
                  {NextDateShower(new Date(graph?.dates))
                    ? date_formatter(
                        NextDateShower(new Date(graph?.dates)),
                        "date/month/year_short"
                      )
                    : ""}
                  {graph?.plot ? (
                    <>
                      <div className="plotter_pending"></div>
                      <div
                        className="plotter_ruledline"
                        style={{ width: `${GraphWidath * 0.228}px` }}
                      ></div>
                      <div className="plotter_plotnumber">
                        {(
                          graph.plot - (graph.age + 1 > 35 ? 0.1 : 0.2)
                        ).toFixed(2)}{" "}
                        ng/mL
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    "xlables x3 plotter_main" +
                    handelPlotterColor(
                      graph.plot - (graph.age + 2 > 35 ? 0.2 : 0.4),
                      graph.min || 0,
                      graph.max || 0
                    )
                  }
                  style={{
                    "--plot":
                      graph.plot > 5
                        ? `${5 + (graph.plot - 5) / 1}`
                        : graph.plot - (graph.age + 2 > 35 ? 0.2 : 0.4),
                  }}
                >
                  {graph.age + 2} yrs <br></br>{" "}
                  {NextDateShower(new Date(graph?.dates))
                    ? date_formatter(
                        NextDateShower(NextDateShower(new Date(graph?.dates))),
                        "date/month/year_short"
                      )
                    : ""}
                  {graph?.plot ? (
                    <>
                      <div className="plotter_pending"></div>
                      <div
                        className="plotter_ruledline"
                        style={{ width: `${GraphWidath * 0.228}px` }}
                      ></div>
                      <div className="plotter_plotnumber">
                        {(
                          graph.plot - (graph.age + 2 > 35 ? 0.2 : 0.4)
                        ).toFixed(2)}{" "}
                        ng/mL
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className="xlables x4">{graph.age + 2}</div> */}
                {/* <div className="xlables x5">{graph.age + 3}</div>
                  <div className="xlables x6">{graph.age + 4}</div>
                  <div className="xlables x7">{graph.age + 5}</div>
                <div className="xlables x8">{graph.age + 6}</div> */}
              </div>
            </div>
            {bg_type === "type2" ? (
              <div className="graph_txt">Tested during follicular Phase</div>
            ) : (
              ""
            )}
          </div>
        );
      } else if (type == "type2_future") {
        let latest = amh_group.filter((el) => {
          if (
            new Date(el.date).valueOf() == new Date(graph?.dates || 0).valueOf()
          ) {
            return true;
          }
        });
        graph.age = Math.floor(latest[0]?.age || 0);
        let gaps = parseInt((parseInt(graph.plot) - 5) / 3);
        let plot1 = 6;
        let plot2 = 6 - (graph.age + 1 > 35 ? 0.1 : 0.2);
        let plot3 = 6 - (graph.age + 1 > 35 ? 0.2 : 0.4);
        if (5 + gaps * 3 != graph.plot) {
          plot1 += (graph.plot - (5 + gaps * 3)) / gaps;
        }
        if (5 + gaps * 3 != graph.plot + 1) {
          plot2 += (graph.plot - (5 + gaps * 3)) / gaps;
        }
        if (5 + gaps * 3 != graph.plot + 2) {
          plot3 += (graph.plot - (5 + gaps * 3)) / gaps;
        }
        return (
          <div className="graphs amh_graph" type="2">
            <div
              className="custom_plotter"
              style={{
                ...(graph.min ? { "--min": graph.min } : {}),
                ...(graph.max ? { "--max": graph.max } : {}),
                // ...(graph.max
                //   ? {
                //       "--in_between": customFunctionOptimal(
                //         graph.max,
                //         graph.min
                //       ),
                //     }
                //   : {}),
              }}
              // bg_type={bg_type}
            >
              <div className="bg1">
                <div>High: Signs of PCOS</div>
                {/* <div>Signs of PCOS</div> */}
              </div>
              <div className="bg2">
                {/* <div>High</div> */}
                <div>Slightly High</div>
              </div>
              <div className="bg3">
                <div>Optimal</div>
              </div>
              <div className="bg4">
                <div>Slightly Low</div>
              </div>
              <div className="bg5">
                <div>Low ovarian reserve</div>
              </div>
              <div className="ylabels y0">0</div>
              <div className="ylabels y1">1</div>
              <div className="ylabels y2">2</div>
              {/* <div className="ylabels dots dot1">...</div> */}
              <div className="ylabels y3">3</div>
              <div className="ylabels y4">4</div>
              {/* <div className="ylabels dots dot2">...</div> */}
              <div className="ylabels y5">{5 + gaps * 2}</div>
              <div className="ylabels y7">{5 + gaps * 3}</div>
              <div className="ylabels y9">{5 + gaps * 4}</div>
              <div className="ylabels y11">{5 + gaps * 5}</div>
              {/* <div className="ylabels y12">{5 + gaps * 5}</div> */}
              <div className="ylabels yunit">ng/mL</div>
              <div className="ylabels yAge">Age</div>
              <div className="xlabelsgroup">
                <div
                  className={
                    "xlables x1 plotter_main" +
                    handelPlotterColor(
                      graph.plot,
                      graph.min || 0,
                      graph.max || 0
                    )
                  }
                  style={{ "--plot": plot1 }}
                >
                  {graph.age} yrs <br></br>{" "}
                  {date_formatter(
                    new Date(graph?.dates),
                    "date/month/year_short"
                  )}
                  {graph?.plot ? (
                    <>
                      <div className="plotter"></div>
                      <div className="plotter_ruledline"></div>
                      <div className="plotter_plotnumber">
                        {graph.plot}ng mL
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    "xlables x1 plotter_main" +
                    handelPlotterColor(
                      graph.plot - (graph.age + 1 > 35 ? 0.1 : 0.2),
                      graph.min || 0,
                      graph.max || 0
                    )
                  }
                  style={{ "--plot": plot2 }}
                >
                  {graph.age + 1} yrs <br></br>{" "}
                  {date_formatter(
                    NextDateShower(new Date(graph?.dates)),
                    "date/month/year_short"
                  )}
                  {graph?.plot ? (
                    <>
                      <div className="plotter_pending"></div>
                      <div className="plotter_ruledline"></div>
                      <div className="plotter_plotnumber">
                        {graph.plot - (graph.age + 1 > 35 ? 0.1 : 0.2)}ng mL
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    "xlables x1 plotter_main" +
                    handelPlotterColor(
                      graph.plot - (graph.age + 2 > 35 ? 0.2 : 0.4),
                      graph.min || 0,
                      graph.max || 0
                    )
                  }
                  style={{ "--plot": plot3 }}
                >
                  {graph.age + 2} yrs <br></br>{" "}
                  {date_formatter(
                    NextDateShower(NextDateShower(new Date(graph?.dates))),
                    "date/month/year_short"
                  )}
                  {graph?.plot ? (
                    <>
                      <div className="plotter_pending"></div>
                      <div className="plotter_ruledline"></div>
                      <div className="plotter_plotnumber">
                        {graph.plot - (graph.age + 2 > 35 ? 0.2 : 0.4)}ng mL
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className={"xlables x2 "}>
                  {graph.age + 1} yrs <br></br>{" "}
                  {NextDateShower(new Date(graph?.dates))
                    ? date_formatter(
                        NextDateShower(new Date(graph?.dates)),
                        "date/month/year_short"
                      )
                    : ""}
                </div> */}

                {/* <div className="xlables x3">{graph.age + 1}</div> */}
                {/* <div className="xlables x4">{graph.age + 2}</div> */}
                {/* <div className="xlables x5">{graph.age + 3}</div> */}
                {/* <div className="xlables x6">{graph.age + 4}</div> */}
                {/* <div className="xlables x7">{graph.age + 5}</div> */}
                {/* <div className="xlables x8">{graph.age + 6}</div> */}
              </div>
            </div>
            {bg_type === "type2" && (
              <div className="graph_txt">Tested during follicular Phase</div>
            )}
          </div>
        );
      } else {
        return "";
      }

    default:
      return <div>type not defined</div>;
  }
};
