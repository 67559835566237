import React, { useEffect, useState } from "react";

const Range = ({
  startRange,
  range,
  singleBeginRange,
  BeginPage,
  totalcount,
  type,
}) => {
  // const [RangeState, setRangeState] = useState([
  //   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
  // ]);
  // const [yourArray, setYourArray] = useState([]);
  // useEffect(() => {
  //   const updatedArray = [...yourArray, range + 1];

  //   setYourArray(updatedArray);
  // }, [range]);

  // // Access the last value of the array
  // const lastValue =
  //   yourArray.length > 0 ? yourArray[yourArray.length - 1] : null;
  const ListBegin = [
    {
      name: "presonalise",
      maxwidth: 5,
    },
    {
      name: "menstralhealth",
      maxwidth: 5,
    },
    {
      name: "familyhistory",
      maxwidth: 3,
    },
    {
      name: "reproductivehealth",
      maxwidth: 6,
    },
  ];

  switch (type) {
    case "Old":
      return (
        <div className="main_range">
          <div
            className="range_box"
            style={{
              "--widthcount": range,
              "--maxwidthcount": singleBeginRange
                ? ListBegin.filter((el) => el.name === BeginPage)[0].maxwidth
                : totalcount,
            }}
          ></div>
        </div>
      );
      break;
    case "New":
      return (
        <>
          <div className="main_range_new">
            {new Array(totalcount)?.fill("").map((el, i) => (
              <div
                key={i}
                className={
                  "inner_range_box" +
                  (range + 1 == i + 1 ? " inner_range_box_active" : "")
                }
              ></div>
            ))}
          </div>
        </>
      );

      break;

    default:
      break;
  }
};

export default Range;
