const default_appdata = {
  // last_recorder_order: {},
  // last_order: {
  //   published: false,
  // },
  // last_intake: {},
  // user_data: {},
  // discount: {},
  // updated: false,
  // all_order: [],
};
export const checktoken = {
  ...default_appdata,
};

const AppData = (state = checktoken, { type, payload }) => {
  //
  switch (type) {
    case "setCheckToken":
      return { ...state, ...payload };
    default:
      return state;
  }
};
export default AppData;
