import React from "react";

export const ProfileSection = ({ obj }) => {
  return (
    <div className="profile_box">
      <div className="profile_box_img">
        <img src={obj.prof_img} />
      </div>
      <div className="profile_box_detail">
        <div className="profile_box_detail_title">{obj.prof_name}</div>
        <div className="profile_box_detail_desc">{obj.prof_desc}</div>
      </div>
    </div>
  );
};
