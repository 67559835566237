import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavbarMain } from "../../component/NavbarMain";
import { BottomNav } from "../../component/BottomNav";
import { useNavigate } from "react-router-dom";
import {
  CheckTokenUser,
  Series,
  get_blogs_list,
} from "../../component/API/api";

import {
  MixPanelReferal,
  MixPanelUsertype,
  userProperties,
} from "../../component/API/automation_api";
import { HomeNewHeader } from "./components/HomeNewHeader";
import HomeNewNext from "./components/HomeNewNext";
import HomeNewUpcomin from "./components/HomeNewUpcomin";
import { checkToken } from "../../component/API/new_api";

export const Upcomming = ({ setloading }) => {
  const [pagevalues, setPagevalues] = useState({
    name: "",
  });
  const store = useSelector((state) => state);
  // const current_store = useSelector((state) => state);
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    document.getElementById("title").innerHTML = "Arva Health";
  }, []);
  // const [NavBarActive, setNavBarActive] = useState("");
  const navigate = useNavigate();
  const [AllData, setAllData] = useState({
    last_recorder_order: {},
    last_order: {
      published: false,
    },
    last_intake: {},
    user_data: {},
    base_color: "#5F1D1B",
    linear_grad: "linear-gradient(180deg, #FFECCB 0%, #FFB88F 100%)",
    fert_name: "",
    extradata: {
      join_community: false,
      guide: false,
      chat_council: false,
    },
    user_clicked: {},
    discount: {},
    third_obj: {
      ac_title: "",
      title: "",
      desc: "",
    },
  });
  // const [GetAppointnment, setGetAppointnment] = useState(false);
  // const [MiniNavi, setMiniNavi] = useState(false);
  // const [RecordData, setRecordData] = useState({});
  // const scrollToTop = () => {
  // window.scrollTo({
  //   top: 0,
  //   behavior: "smooth",
  // });
  // };
  // const [VideoMaterial, setVideoMaterial] = useState([
  //   {
  //     name: "Home - Videos - Free",
  //   },
  // ]);
  // const fertility_main = "fertility-test-for-women";
  // const [Article, setArticle] = useState([]);
  // const [VideoFilter, setVideoFilter] = useState({});
  // const [ArticleFilter, setArticleFilter] = useState({});
  const [FurtherDataMixPanel, setFurtherDataMixPanel] = useState({
    sign_up_date: "",
    user_id: "",
    last_order_type: "",
    name: "",
    birthdate: "",
    is_test_user: false,
    user_current_goal: "",
    is_paid_user: false,
    reported_symptoms: "",
    reported_conditions: "",
    is_ultrasound_done: false,
    is_birth_control: false,
  });
  const [orders, setorders] = useState({
    completed: [],
    inprogress: [],
  });
  const [BlankScreen, setBlankScreen] = useState(false);
  // const series_list = (data) => {
  //   Series((res1) => {
  //
  //     //
  //     const t_videm = res1.response.series.filter(
  //       (el) => el.name.split("-")[1] === " Videos "
  //     );
  //     const t_artice = res1.response.series.filter(
  //       (el) => el.name.split("-")[1] != " Videos "
  //     );
  //     setArticle(
  //       // res.response.series.filter(
  //       //   (el) => el.name.split("-")[1] != " Videos "
  //       // )
  //       t_artice
  //     );

  //     setVideoMaterial(
  //       // res.response.series.filter(
  //       //   (el) => el.name.split("-")[1] === " Videos "
  //       // )
  //       t_videm
  //     );
  //     if (data?.last_order?.custom_id) {
  //       setVideoFilter(
  //         t_videm?.filter((el) => el.name.split("-")[2] === " Paid")[0]
  //       );
  //       setArticleFilter(
  //         t_artice?.filter((el) => el.name.split("-")[2] === " Paid")[0]
  //       );
  //     } else {
  //       setVideoFilter(
  //         t_videm?.filter((el) => el.name.split("-")[2] === " Free")[0]
  //       );
  //       setArticleFilter(
  //         t_artice?.filter((el) => el.name.split("-")[2] === " Free")[0]
  //       );
  //     }
  //   });
  // };

  // useEffect(() => {
  //   scrollToTop();
  //   // localStorage.setItem("BreadCrumNav", []);
  //   localStorage.setItem("ShowBreadCrum", false);
  //   let data = localStorage.getItem("user_token");
  //   const urlData = window?.location?.search?.slice(1);
  //   //
  //   data = data ? data : urlData ? urlData : "";

  //   if (data) {
  //     if (!current_store?.checktoken_data?.updated) {
  //       setloading("type1");
  //
  //       if (urlData) {
  //         localStorage.setItem("urlLogin", true);
  //       }
  //       // else {
  //       //   localStorage.setItem("urlLogin", false);
  //       // }
  //       // CheckTokenUser(
  //       //   {
  //       //     token: data,
  //       //   },
  //       //   (res) => {
  //       //     if (res.status) {
  //       //       localStorage.setItem("user_token", data);

  //       //       setloading(false);
  //       //       setBlankScreen(false);
  //       //       setFurtherDataMixPanel((prev) => ({
  //       //         ...prev,
  //       //         sign_up_date: res?.response?.user_data?.created_at,
  //       //         user_id: res?.response?.user_data?._id,
  //       //         last_order_type: res?.response?.last_order?.data?.endPoint
  //       //           ? res?.response?.last_order?.data?.endPoint == fertility_main
  //       //             ? "During Menstrual Cycle"
  //       //             : "Any Day"
  //       //           : "",
  //       //         name: res?.response?.last_order?.data?.pagevaluesinput?.f_name
  //       //           ? res?.response?.last_order?.data?.pagevaluesinput?.f_name +
  //       //             " " +
  //       //             res?.response?.last_order?.data?.pagevaluesinput?.l_name
  //       //           : "",
  //       //         birthdate:
  //       //           res?.response?.last_order?.data?.pagevaluesinput?.birthdate ||
  //       //           "",
  //       //         user_current_goal:
  //       //           res?.response?.user_data?.tracking_arva ||
  //       //           res?.response?.last_intake?.data?.tracking_arva ||
  //       //           "",
  //       //         is_ultrasound_done: res?.response?.last_intake?.data
  //       //           ?.prev_dignosed_with_PCOS
  //       //           ? res?.response?.last_intake?.data?.prev_dignosed_with_PCOS ==
  //       //             "Yes"
  //       //           : false || false,
  //       //         is_birth_control: res?.response?.last_intake?.data?.birthcontrol
  //       //           ? res?.response?.last_intake?.data?.birthcontrol == "Yes"
  //       //           : false || false,
  //       //         reported_symptoms:
  //       //           res?.response?.last_intake?.data?.experience_symptoms.length >
  //       //           0
  //       //             ? res?.response?.last_intake?.data?.experience_symptoms.join(
  //       //                 ","
  //       //               )
  //       //             : "",
  //       //         reported_conditions:
  //       //           res?.response?.last_intake?.data?.dr_prev_dignosed.length > 0
  //       //             ? res?.response?.last_intake?.data?.dr_prev_dignosed?.join(
  //       //                 ","
  //       //               )
  //       //             : "",
  //       //       }));
  //       //       localStorage.setItem(
  //       //         "OrderPhoneNo",
  //       //         res?.response?.user_data?.phone
  //       //       );
  //       //       series_list(res?.response);
  //       //     }
  //       //     if (!res.status) {
  //       //       setloading(false);
  //       //       localStorage.clear();
  //       //       return navigate("/login");
  //       //     }
  //       //     // if (res?.response?.last_order) {
  //       //     //   MixPanelUsertype({
  //       //     //     first_order: false,
  //       //     //     remaining: {
  //       //     //       is_test_user: false,
  //       //     //       is_paid_user: true,
  //       //     //       sign_up_date: res?.response?.user_data?.created_at,
  //       //     //       user_id: res?.response?.user_data?._id,
  //       //     //       last_order_type: res?.response?.last_order?.data?.endPoint
  //       //     //         ? res?.response?.last_order?.data?.endPoint ==
  //       //     //           fertility_main
  //       //     //           ? "During Menstrual Cycle"
  //       //     //           : "Any Day"
  //       //     //         : "",
  //       //     //       name: res?.response?.last_order?.data?.pagevaluesinput?.f_name
  //       //     //         ? res?.response?.last_order?.data?.pagevaluesinput?.f_name +
  //       //     //           " " +
  //       //     //           res?.response?.last_order?.data?.pagevaluesinput?.l_name
  //       //     //         : "",
  //       //     //       birthdate:
  //       //     //         res?.response?.last_order?.data?.pagevaluesinput
  //       //     //           ?.birthdate || "",
  //       //     //       user_current_goal:
  //       //     //         res?.response?.last_intake?.data?.tracking_arva || "",
  //       //     //       is_ultrasound_done: res?.response?.last_intake?.data
  //       //     //         ?.prev_dignosed_with_PCOS
  //       //     //         ? res?.response?.last_intake?.data
  //       //     //             ?.prev_dignosed_with_PCOS == "Yes"
  //       //     //         : false || false,
  //       //     //       is_birth_control: res?.response?.last_intake?.data
  //       //     //         ?.birthcontrol
  //       //     //         ? res?.response?.last_intake?.data?.birthcontrol == "Yes"
  //       //     //         : false || false,
  //       //     //       reported_symptoms:
  //       //     //         res?.response?.last_intake?.data?.experience_symptoms
  //       //     //           .length > 0
  //       //     //           ? res?.response?.last_intake?.data?.experience_symptoms.join(
  //       //     //               ","
  //       //     //             )
  //       //     //           : "",
  //       //     //       reported_conditions:
  //       //     //         res?.response?.last_intake?.data?.dr_prev_dignosed.length >
  //       //     //         0
  //       //     //           ? res?.response?.last_intake?.data?.dr_prev_dignosed?.join(
  //       //     //               ","
  //       //     //             )
  //       //     //           : "",
  //       //     //     },
  //       //     //   });
  //       //     // } else {
  //       //     //   MixPanelUsertype({
  //       //     //     first_order: false,
  //       //     //     remaining: {
  //       //     //       is_test_user: false,
  //       //     //       is_paid_user: false,
  //       //     //       sign_up_date: res?.response?.user_data?.created_at,
  //       //     //       user_id: res?.response?.user_data?._id,
  //       //     //       last_order_type: res?.response?.last_order?.data?.endPoint
  //       //     //         ? res?.response?.last_order?.data?.endPoint ==
  //       //     //           fertility_main
  //       //     //           ? "During Menstrual Cycle"
  //       //     //           : "Any Day"
  //       //     //         : "",
  //       //     //       name: res?.response?.last_order?.data?.pagevaluesinput?.f_name
  //       //     //         ? res?.response?.last_order?.data?.pagevaluesinput?.f_name +
  //       //     //           " " +
  //       //     //           res?.response?.last_order?.data?.pagevaluesinput?.l_name
  //       //     //         : "",
  //       //     //       birthdate:
  //       //     //         res?.response?.last_order?.data?.pagevaluesinput
  //       //     //           ?.birthdate || "",
  //       //     //       user_current_goal:
  //       //     //         res?.response?.last_intake?.data?.tracking_arva || "",
  //       //     //       is_ultrasound_done: res?.response?.last_intake?.data
  //       //     //         ?.prev_dignosed_with_PCOS
  //       //     //         ? res?.response?.last_intake?.data
  //       //     //             ?.prev_dignosed_with_PCOS == "Yes"
  //       //     //         : false || false,
  //       //     //       is_birth_control: res?.response?.last_intake?.data
  //       //     //         ?.birthcontrol
  //       //     //         ? res?.response?.last_intake?.data?.birthcontrol == "Yes"
  //       //     //         : false || false,
  //       //     //       reported_symptoms:
  //       //     //         res?.response?.last_intake?.data?.experience_symptoms
  //       //     //           .length > 0
  //       //     //           ? res?.response?.last_intake?.data?.experience_symptoms.join(
  //       //     //               ","
  //       //     //             )
  //       //     //           : "",
  //       //     //       reported_conditions:
  //       //     //         res?.response?.last_intake?.data?.dr_prev_dignosed.length >
  //       //     //         0
  //       //     //           ? res?.response?.last_intake?.data?.dr_prev_dignosed?.join(
  //       //     //               ","
  //       //     //             )
  //       //     //           : "",
  //       //     //     },
  //       //     //   });
  //       //     // }
  //       //     const LastIndex = res.response.all_order.findIndex(
  //       //       (el) =>
  //       //         el.custom_id == res?.response?.last_recorder_order?.custom_id
  //       //     );
  //       //     setAllData((prev) => ({
  //       //       ...prev,
  //       //       all_order: res.response.all_order || [],
  //       //       last_order: res?.response?.last_order || {},
  //       //       last_recorder_order: res?.response?.last_recorder_order || {},
  //       //       last_intake: res?.response?.last_intake || {},
  //       //       user_data: res?.response?.user_data || {},
  //       //       discount: res.response.discount || {},
  //       //       prev_order_data: res.response.all_order[LastIndex + 1] || {},
  //       //     }));
  //       //     dispatch({
  //       //       type: "setCheckToken",
  //       //       payload: {
  //       //         last_order: res?.response?.last_order || {},
  //       //         last_recorder_order: res?.response?.last_recorder_order || {},
  //       //         last_intake: res?.response?.last_intake || {},
  //       //         user_data: res?.response?.user_data || {},
  //       //         discount: res.response.discount || {},
  //       //         all_order: res?.response?.all_order || [],
  //       //         updated: true,
  //       //         version: res?.response?.version || "",
  //       //         prev_order_data: res.response.all_order[LastIndex + 1] || {},
  //       //       },
  //       //     });
  //       //   }
  //       // );
  //     }
  //     if (!current_store?.blogsanity_data?.updated) {
  //       get_blogs_list((data) => {
  //         //
  //         dispatch({
  //           type: "setBlogSanity",
  //           payload: {
  //             blog_list: data.response.data.explore.article || {},
  //             qanda_list: data.response.data.explore.QA || {},
  //             guide_list: data.response.data.explore.guide || {},
  //             updated: true,
  //           },
  //         });
  //       });
  //     }
  //   } else {
  //     // navigate("/login");
  //     // if(res.status){
  //     setloading(false);
  //     // }
  //   }
  // }, []);
  const check_user_data = (token) => {
    checkToken(
      token || "",
      (success) => {
        if (success.customer == null) {
          navigate("/404");
        }
        MixPanelUsertype(success);
        dispatch({
          type: "setCheckToken",
          payload: { ...success, token: token },
        });
        let t_completed = [];
        let t_inprogress = [];
        success.orderItems.filter((item) =>
          item.product_status == "Order completed"
            ? t_completed.push(item)
            : t_inprogress.push(item)
        );
        setorders({
          completed: t_completed,
          inprogress: t_inprogress,
        });
      },
      (err) => {}
    );
  };
  useEffect(() => {
    if (store.checktoken_data.token) {
      check_user_data(store.checktoken_data.token);
    } else {
      const local_token = localStorage.getItem("user_token_home");
      if (local_token) {
        check_user_data(local_token);
      } else {
        // window.open("http://localhost:3000/", "_self");
      }
    }
    return () => {};
  }, [store.checktoken_data.token]);

  // useEffect(() => {
  //   setPagevalues((prev) => ({
  //     ...prev,
  //     name: AllData.user_data.f_name || "",
  //   }));
  // }, [AllData]);

  useEffect(() => {
    let obj = {};
    const checkGoal =
      AllData?.user_data?.tracking_arva ||
      AllData?.last_intake?.data?.tracking_arva;
    if (["I’m exploring options like Egg Freezing"].includes(checkGoal)) {
      obj = {
        fert_name: "Fertility preservation",
        base_color: "#5F1D1B",
        linear_grad: "linear-gradient(180deg, #FFE3DB 0%, #FFA977 100%)",
        third_obj: {
          ac_title: "Freeze your eggs",
          title: "Partner clinics in your city",
          desc: "Arva-certified, curated quality clinics, with transparent pricing, an Egg Freezing concierge service, and 24x7 support (all kinds!).",
        },
      };
    } else if (
      [
        "Just curious to learn about my fertility",
        "I don’t want biological kids. I’m here to learn more about my body.",
      ].includes(checkGoal)
    ) {
      obj = {
        fert_name: "Proactive fertility care",
        base_color: "#2B3A33",
        linear_grad: "linear-gradient(180deg, #EBF5DD 0%, #E7EDE0 100%)",
        third_obj: {
          ac_title: "Ongoing fertility care",
          title: "Optimise your fertility health",
          desc: "Evidence-based programs with holisitic health coaches designed to optimise your health and maximise your conception potential.",
        },
      };
    } else if (
      [
        "I want kids but I won’t get started for at least another year",
      ].includes(checkGoal)
    ) {
      obj = {
        fert_name: "Pre-conception",
        base_color: "#5F1D1B",
        linear_grad: "linear-gradient(180deg, #FFECCB 0%, #FFB88F 100%)",
        third_obj: {
          ac_title: "Ongoing fertility care",
          title: "Optimise your fertility health",
          desc: "Evidence-based programs with holisitic health coaches designed to optimise your health and maximise your conception potential.",
        },
      };
    } else if (
      [
        "I’m trying for kids as we speak (with fertility treatment)",
        "I’m trying for kids as we speak (without assistance)",
      ].includes(checkGoal)
    ) {
      obj = {
        fert_name: "Conception",
        base_color: "#5F1D1B",
        linear_grad:
          "linear-gradient(180deg, #FFF0D7 0%, #F9D6B9 61.5%, #F2C0B2 100%)",
        third_obj: {
          ac_title: "Ongoing fertility care",
          title: "Partner clinics in your city",
          desc: "Arva-certified, curated quality clinics, with transparent pricing, a concierge service, and 24x7 support (all kinds!).",
        },
        // linear_grad: "linear-gradient(180deg, #FFECCB 0%, #FFB88F 100%)",
      };
    } else {
      obj = {
        fert_name: "",
        base_color: "#5F1D1B",
        linear_grad: "#FFFBF2",
        third_obj: {
          ac_title: "Freeze your eggs",
          title: "Partner clinics in your city",
          desc: "Arva-certified, curated quality clinics, with transparent pricing, an Egg Freezing concierge service, and 24x7 support (all kinds!).",
        },
      };
    }
    setAllData((prev) => ({
      ...prev,
      ...obj,
    }));
  }, [FurtherDataMixPanel]);
  console.table(orders.inprogress);
  return (
    <>
      {BlankScreen ? (
        ""
      ) : (
        <>
          <div className="home_new_main">
            <div className="main_section">
              <div className="container">
                <HomeNewHeader
                  name={
                    store?.checktoken_data?.customer?.user?.first_name || ""
                  }
                />
                {/* {orders.completed.map((order) => order.orderId)}
                {orders.inprogress.map((order) => order.orderId)} */}
                <HomeNewNext
                  card_to_show={
                    ["test", "consultation", "coaching", "clinic", "guide"][0]
                  }
                />
                {orders.inprogress.length > 0 ? (
                  <HomeNewUpcomin data={[...orders.inprogress]} />
                ) : (
                  ""
                )}
                <div
                  className="care_history"
                  onClick={
                    () => ""
                    //  navigate("/care-history")
                  }
                >
                  Care History
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.29279 14.7069C7.10532 14.5194 7 14.2651 7 13.9999C7 13.7348 7.10532 13.4804 7.29279 13.2929L10.5858 9.99992L7.29279 6.70692C7.11063 6.51832 7.00983 6.26571 7.01211 6.00352C7.01439 5.74132 7.11956 5.49051 7.30497 5.3051C7.49038 5.11969 7.74119 5.01452 8.00339 5.01224C8.26558 5.00997 8.51818 5.11076 8.70679 5.29292L12.7068 9.29292C12.8943 9.48045 12.9996 9.73475 12.9996 9.99992C12.9996 10.2651 12.8943 10.5194 12.7068 10.7069L8.70679 14.7069C8.51926 14.8944 8.26495 14.9997 7.99979 14.9997C7.73462 14.9997 7.48031 14.8944 7.29279 14.7069Z"
                      fill="#5F1D1B"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="reffer_title">For You</div>
              <div className="home_referal_section">
                <img src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/ARVA/images/Frame%20750.png" />
                <div className="home_referal_title">
                  A community that gets it
                </div>
                <div className="home_referal_desc">
                  Find people on a similar journey, no matter where you are in
                  your fertility journey.
                </div>
                <div
                  className="btn type2"
                  style={{ margin: "16px 0 0 0" }}
                  onClick={() => {
                    // navigate("/referral");
                    // toast.success("Error occured")
                  }}
                >
                  Join
                </div>
              </div>
              {/* <div className="home_referal_section">
                <img src="https://arva-lab-reports.s3.ap-south-1.amazonaws.com/Products/png/productimage_1708497852389.png" />
                <div className="home_referal_title">Give a friend 20% off</div>
                <div className="home_referal_desc">
                  If you believe every person should make informed decisions
                  about their reproductive future—do share the love with family
                  & friends. You have no idea who might need it!
                </div>
                <div
                  className="btn type2"
                  style={{ margin: "16px 0 0 0" }}
                  onClick={() => {
                    navigate("/referral");
                    // toast.success("Error occured")
                  }}
                >
                  Refer
                </div>
              </div> */}
              <div className="home_referal_section">
                <img src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/ARVA/images/Frame%20723.png" />
                <div className="home_referal_title">
                  Get referred to the best clinics
                </div>
                <div className="home_referal_desc">
                  If you decide fertility treatment is right for you, you’ll be
                  referred to our partner clinics.
                </div>
                <div
                  className="btn type2"
                  style={{ margin: "16px 0 0 0" }}
                  onClick={() => {
                    // navigate("/referral");
                    // toast.success("Error occured")
                  }}
                >
                  Explore
                </div>
              </div>
            </div>
          </div>
          <BottomNav />
        </>
      )}
    </>
  );
};
