import React, { useEffect, useState } from "react";
import BottomBtnSection from "../components/BottomBtnSection";
import { MultiSelect } from "../../../component/CheckOut/Intake/MultiSelect";
import { MixPanelIntake } from "../../../component/API/automation_api";

export const Birthcontrol = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  singleBeginRange,
  setBeginPage,
  lastdata,
  setlastdata,
  goto,
}) => {
  const [BirthcontrolList, setBirthcontrolList] = useState(["Yes", "No"]);
  const [BirthcontrolStatus, setBirthcontrolStatus] = useState("");

  useEffect(() => {
    setBirthcontrolStatus(pagevalues?.birthcontrol);
  }, []);

  useEffect(() => {
    setPagevalues((prev) => ({
      ...prev,
      birthcontrol: BirthcontrolStatus,
    }));
    setlastdata((prev) => ({
      ...prev,
      is_birth_control: BirthcontrolStatus == "Yes",
    }));
  }, [BirthcontrolStatus]);
  return (
    <>
      <div className="intake_body">
        <div className="container birthcontrol_main">
          <div className="birthcontrol_title">
            Are you on any form of birth control?
          </div>
          <div className="birthcontrol_desc">
            Like any pills, an IUD, vaginal ring, or contraceptive skin
            patches...
          </div>
          <MultiSelect
            {...{
              list: BirthcontrolList,
              onMultiClick: (el) => setBirthcontrolStatus(el),
              MultiSelectData: BirthcontrolStatus,
              multisel: false,
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              if (!BirthcontrolStatus) {
                return;
              }
              MixPanelIntake({
                step_name: "Are you on any form of birth control?",
                section_name: "Reproductive Health",
                value: pagevalues.birthcontrol,
                step_index: 5,
                total_steps: 8,
                remaining: lastdata,
              });
              if (singleBeginRange) {
                setRangeCount(3);
              } else {
                setRangeCount(21);
              }
              setPages("medicalcondition");
            },
            goBackClick: () => {
              if (pagevalues.singleormingle.includes("Nope")) {
                setRangeCount(18);
                setPages("singleOrMingle");
              } else {
                setRangeCount(19);
                setPages("partnerKnow");
              }
            },
          },
        }}
      />
    </>
  );
};
