import React from "react";
import { CommonFooter } from "../CommonFooter";

export const NextStep = () => {
  return (
    <>
      <div className="nextstep_main">
        <div className="nextstep_main_title">Next steps</div>
        <div className="nextstep_box">
          <div className="nextstep_top">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
            >
              <g clipPath="url(#clip0_2060_1040)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24.4202 4.08843C21.7885 1.45328 18.2882 0.00133696 14.5588 0C6.87397 0 0.619658 6.25364 0.616984 13.9405C0.615647 16.3978 1.25806 18.7963 2.47804 20.9101L0.5 28.135L7.89072 26.1964C9.9269 27.3075 12.2198 27.8924 14.5528 27.8931H14.5588C22.2423 27.8931 28.4974 21.6387 28.5 13.9519C28.5013 10.2265 27.0527 6.72425 24.4202 4.0891V4.08843ZM14.5588 25.5386H14.5541C12.4751 25.538 10.4356 24.9791 8.65617 23.9236L8.23299 23.6723L3.84709 24.8227L5.0176 20.5464L4.74218 20.1079C3.58236 18.2629 2.96937 16.1304 2.9707 13.9412C2.97338 7.5525 8.17149 2.35439 14.5635 2.35439C17.6586 2.35573 20.5678 3.56234 22.7557 5.75295C24.9437 7.9429 26.1476 10.8548 26.1463 13.9505C26.1436 20.3399 20.9455 25.538 14.5588 25.538V25.5386ZM20.9147 16.8604C20.5664 16.686 18.8538 15.8436 18.5343 15.7274C18.2147 15.611 17.9828 15.5529 17.7508 15.9018C17.5189 16.2508 16.8511 17.0356 16.6478 17.2675C16.4446 17.5002 16.2414 17.5289 15.8931 17.3544C15.5449 17.1799 14.4225 16.8123 13.0915 15.6257C12.0561 14.7019 11.3568 13.5615 11.1536 13.2125C10.9504 12.8636 11.1322 12.6751 11.306 12.5019C11.4624 12.3455 11.6543 12.0948 11.8287 11.8916C12.0033 11.6884 12.0607 11.5427 12.177 11.3107C12.2934 11.078 12.2352 10.8749 12.1483 10.7004C12.0614 10.5259 11.3649 8.81121 11.074 8.11401C10.7912 7.43485 10.5039 7.5271 10.2906 7.51574C10.0874 7.5057 9.85542 7.5037 9.62279 7.5037C9.39016 7.5037 9.01309 7.59061 8.69358 7.93956C8.37407 8.28847 7.47426 9.13142 7.47426 10.8454C7.47426 12.5594 8.72236 14.2166 8.89681 14.4492C9.07127 14.6819 11.3534 18.2001 14.8476 19.7095C15.6786 20.0685 16.3276 20.283 16.8336 20.4435C17.668 20.7089 18.4273 20.6715 19.0276 20.5819C19.6968 20.4816 21.0886 19.7389 21.3787 18.9254C21.6688 18.1118 21.6688 17.4139 21.5819 17.2688C21.495 17.1238 21.2624 17.0362 20.9141 16.8618L20.9147 16.8604Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2060_1040">
                  <rect
                    width="28"
                    height="28.1556"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="nextstep_title">Chat with the Arva Care Team</div>
          <div className="nextstep_desc">
            Have questions about your results? Your team is available to you.
          </div>
          <div
            className="chat_withus"
            onClick={() => {
              window.open("https://wa.me/919731107947");
            }}
          >
            Chat with us
          </div>
        </div>
        <div className="nextstep_box_bottom">
          Your results were processed by Thyrocare Technologies and were
          approved by an Arva Health physician. The Arva Health tests do not
          predict fertility, or diagnose PCOS and Thyroid issues. They serve as
          an important data point in understanding fertility and overall health
          and are meant to facilitate a conversation with a healthcare provider.
          <div
            onClick={() => {
              window.open("https://www.arva.health/legal/terms-of-service");
            }}
          >
            Full disclosure
          </div>
        </div>
        <CommonFooter />
      </div>
    </>
  );
};
