import React from "react";
import { MixPanelIntakeStarted } from "../../../component/API/automation_api";
import BottomBtnSection from "../components/BottomBtnSection";

const BeginSection = ({
  setBeginBtn,
  setRangeCount,
  setPages,
  singleBeginRange,
  setBeginPage,
  lastdata,
  goto,
  startfrom,
  sectionno,
}) => {
  return (
    <>
      <div className="intake_body begin">
        <div className="container">
          <div className="main_begin_title_start">
            Let’s personalise your results!
          </div>
          <div className="main_beign_desc_start">
            To get to know you, we’ll ask you a few questions about your medical
            history.
          </div>
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Begin",
            onBtnClick: () => {
              setBeginBtn(true);
              // if (singleBeginRange) {
              //   setRangeCount(0);
              // }
              // else{
              //   setRangeCount(0);

              // }
              MixPanelIntakeStarted({
                name: "Personal Info",
                remaining: lastdata,
              });
              setBeginPage("presonalise");
              setPages("tracking");
            },
          },
        }}
      />
    </>
  );
};

export default BeginSection;
