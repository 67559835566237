import React, { useEffect } from "react";

export const Error = () => {
  useEffect(() => {
    // window.location.replace("https://arva.health/404");
  }, []);
  return (
    <div className="error">
      <h1>404</h1>
    </div>
  );
};
