import React from "react";

export const ReportType = ({
  clientName,
  reportType,
  desc,
  bgcolor,
  fcolor,
}) => {
  return (
    <>
      <div className="report_type_main" style={{ backgroundColor: bgcolor }}>
        <div className="report_type_client_name">{clientName}</div>
        <div className="report_type_title" style={{ color: fcolor }}>
          {reportType}
        </div>
        <div className="report_type_desc">{desc}</div>
      </div>
    </>
  );
};
