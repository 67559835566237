import React, { useLayoutEffect, useRef, useState } from "react";

export const SeeMoreOrLessComp = ({ list, curret, setCurrent }) => {
  const seemoreorlessid = useRef();
  const [SeeMoreOrLess, setSeeMoreOrLess] = useState(false);
  const [MaxHeight, setMaxHeight] = useState(0);
  const [SeeMoreOrLessActive, setSeeMoreOrLessActive] = useState(false);
  useLayoutEffect(() => {
    // if (list.length) {

    setTimeout(() => {
      let scrollheight = seemoreorlessid?.current?.scrollHeight;
      let innerheight =
        seemoreorlessid?.current?.getBoundingClientRect()?.height;
      setMaxHeight(scrollheight || 0);
      setSeeMoreOrLessActive(scrollheight != innerheight);
    }, 50);
    // }
  }, [list]);

  return (
    <>
      <div
        className={"seemoreless_main "}
        ref={seemoreorlessid}
        style={
          SeeMoreOrLess
            ? {
                "--height": MaxHeight,
              }
            : {}
        }
      >
        {list.map((el, i) => (
          <div
            key={i}
            className={
              "seemoreless_main_list_box" +
              (curret === el ? " seemoreless_main_list_box_active" : "")
            }
            onClick={() => {
              setCurrent(el);
            }}
          >
            {el}
          </div>
        ))}
      </div>
      {SeeMoreOrLessActive && (
        <div
          className="seemoreless_main_list_box_ctr"
          onClick={() => {
            setSeeMoreOrLess((prev) => !prev);
          }}
        >
          {!SeeMoreOrLess ? "See more" : "See less"}
        </div>
      )}
    </>
  );
};
