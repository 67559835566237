import React from "react";
import BeginSection from "./IntakePages/BeginSection";
import TrackingArva from "./IntakePages/TrackingArva";
import BmiCalculator from "./IntakePages/BmiCalculator";
import { TallSection } from "./IntakePages/TallSection";
import WeightSection from "./IntakePages/WeightSection";
import WaistSection from "./IntakePages/WaistSection";
import { MenstralHealthBasePage } from "./IntakePages/MenstralHealthBasePage";
import { OftenGetPeriod } from "./IntakePages/OftenGetPeriod";
import { AverageCycleLength } from "./IntakePages/AverageCycleLength";

import { DescPeriod } from "./IntakePages/DescPeriod";

import { ExcessBodyHair } from "./IntakePages/ExcessBodyHair";
import { FamilyHistory } from "./IntakePages/FamilyHistory";
import { MomAge } from "./IntakePages/MomAge";
import { FatherStrength } from "./IntakePages/FatherStrength";
import { MomMenoPause } from "./IntakePages/MomMenoPause";
import { ReporeductiveHealthBegin } from "./IntakePages/ReporeductiveHealthBegin";
import { DrprevDignosed } from "./IntakePages/DrprevDignosed";
import AnySymotoms from "./IntakePages/AnySymotoms";
import { Pcos } from "./IntakePages/Pcos";
import { SingleOrMingle } from "./IntakePages/SingleOrMingle";
import { TellUsAboutPartner } from "./IntakePages/TellUsAboutPartner";
import { Birthcontrol } from "./IntakePages/Birthcontrol";
import { Medicalcondition } from "./IntakePages/Medicalcondition";
import Medication from "./IntakePages/Medication";
import { KnowArva } from "./IntakePages/KnowArva";

const IntakeAllPages = ({ page_name, otherData }) => {
  let c = 1;
  let sf = 1;

  const page_type = {
    //1
    begin: (
      <BeginSection
        {...{
          setBeginBtn: otherData.begin_range,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    tracking: (
      <TrackingArva
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          setBeginBtn: otherData.begin_range,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
          profile: false,
          setlastdata: otherData.setlastdata,
          AllData: otherData.AllData,
        }}
      />
    ),
    // //2
    // birthdate: (
    //   <BirtthdateSection
    //     {...{
    //       // goto:c++,
    //       setBeginBtn: otherData.begin_range,
    //       setRangeCount: otherData.range_count,
    //       setPages: otherData.page,
    //       pagevalues: otherData.pagevalues,
    //       setPagevalues: otherData.setPagevalues,
    //       err_pagevalues: otherData.err_pagevalues,
    //       seterr_pagevalues: otherData.seterr_pagevalues,
    //       lastdata: otherData.lastdata,
    //     }}
    //   />
    // ),
    // //3
    bmi: (
      <BmiCalculator
        {...{
          goto: c++,
          startfrom: (sf = c - 2),
          sectionno: 1,
          setBeginBtn: otherData.begin_range,
          // setRangeCount: otherData.range_count,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // //4
    tall: (
      <TallSection
        {...{
          goto: c++,
          startfrom: sf,
          sectionno: 1,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // //5
    weight: (
      <WeightSection
        {...{
          goto: c++,
          startfrom: sf,
          sectionno: 1,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // //6
    waist: (
      <WaistSection
        {...{
          goto: c++,
          startfrom: sf,
          sectionno: 1,
          setRangeCount: otherData.range_count,
          setBeginBtn: otherData.begin_range,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // //7
    menstralbeginpage: (
      <MenstralHealthBasePage
        {...{
          goto: c++,
          startfrom: (sf = c - 2),
          sectionno: 2,
          setBeginBtn: otherData.begin_range,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // //8
    oftengetperiod: (
      <OftenGetPeriod
        {...{
          goto: c++,
          startfrom: sf,
          sectionno: 2,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // //9
    averageCyclelength: (
      <AverageCycleLength
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // //10
    descperois: (
      <DescPeriod
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // //11
    symptoms: (
      <AnySymotoms
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
          setlastdata: otherData.setlastdata,
        }}
      />
    ),
    // // //12
    excessbodyhair: (
      <ExcessBodyHair
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginBtn: otherData.begin_range,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // // //13
    familyhsitory: (
      <FamilyHistory
        {...{
          goto: c++,
          setBeginBtn: otherData.begin_range,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // // //14
    momage: (
      <MomAge
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // //15
    fatherstrength: (
      <FatherStrength
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // //16
    mommenopause: (
      <MomMenoPause
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          setBeginBtn: otherData.begin_range,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // //17
    reporeductivehealthbegin: (
      <ReporeductiveHealthBegin
        {...{
          goto: c++,
          setBeginBtn: otherData.begin_range,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // //18
    doctordignosed: (
      <DrprevDignosed
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
          setlastdata: otherData.setlastdata,
        }}
      />
    ),
    // //19
    pcos: (
      <Pcos
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
          setlastdata: otherData.setlastdata,
        }}
      />
    ),
    singleOrMingle: (
      <SingleOrMingle
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
          setlastdata: otherData.setlastdata,
        }}
      />
    ),
    partnerKnow: (
      <TellUsAboutPartner
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
          setlastdata: otherData.setlastdata,
        }}
      />
    ),
    // //20
    birthcontrol: (
      <Birthcontrol
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
          setlastdata: otherData.setlastdata,
        }}
      />
    ),
    // //21
    medicalcondition: (
      <Medicalcondition
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // //22
    medication: (
      <Medication
        {...{
          goto: c++,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          lastdata: otherData.lastdata,
        }}
      />
    ),
    // //23
    socialmedia: (
      <KnowArva
        {...{
          setBeginBtn: otherData.begin_range,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setPagevalues: otherData.setPagevalues,
          err_pagevalues: otherData.err_pagevalues,
          seterr_pagevalues: otherData.seterr_pagevalues,
          lastdata: otherData.lastdata,
        }}
      />
    ),
  };

  if (page_type[page_name]) {
    return page_type[page_name];
  } else {
    return <div>No Pages - {page_name}</div>;
  }
};

export default IntakeAllPages;
