// import XLSX  from "xlsx";
const XLSX = require("xlsx");
export const DownloadCsv = (arrayData, filename) => {
  let total = [0, 0, 0, 0, 0, 0, 0];

  let rows = [];
  // Iterate over each section
  rows.push(new Array(18).fill(""));
  rows.push([
    "Order Id",
    "Date",
    "Place of supply",
    "Name",
    "Address",
    "Pincode",
    "Phone",
    "Email",
    "Product name",
    "SAC",
    "Quantity",
    "Unit total",
    "Discount",
    "Net Revenue",
    "Cgst",
    "Sgst",
    "Igst",
    "Gross Revenue",
    "customid",
  ]);
  arrayData?.forEach((section) => {
    let rowData = [
      "" + section.orderid || "-",
      //   section.orderid.toString().padStart(4, "0"),
      //   (section.orderid < 10
      //     ? "000"
      //     : section.orderid < 100
      //     ? "00"
      //     : section.orderid < 1000
      //     ? "0"
      //     : "") + section.orderid || "-",
      section.date || "-",
      section.placeofsupply || "-",
      section.name || "-",
      section.address || "-",
      section.pincode || "-",
      section.phone || "-",
      section.email || "-",
      section.productname || "-",
      section.sac || "-",
      section.quantity || "-",

      section.unittotal || "-",
      section.discount || "-",
      section.netrevenue || "-",
      section.cgst || "-",
      section.sgst || "-",
      section.igst || "-",
      section.grossrevenue || "-",
      section.customid || "-",
    ];
    total = [
      total[0] + Number(section.unittotal || 0),
      total[1] + Number(section.discount || 0),
      total[2] + Number(section.netrevenue || 0),
      total[3] + Number(section.cgst || 0),
      total[4] + Number(section.sgst || 0),
      total[5] + Number(section.igst || 0),
      total[6] + Number(section.grossrevenue || 0),
    ];
    rows.push(rowData);
  });
  rows.push([...new Array(11).fill(""), ...total]);
  //   generateExcel = () => {
  const mergeCellsAndInsertText = (
    sheet,
    startRow,
    startCol,
    endRow,
    endCol,
    text
  ) => {
    const merge = {
      s: { r: startRow, c: startCol },
      e: { r: endRow, c: endCol },
    };
    sheet["!merges"] = sheet["!merges"] || [];
    sheet["!merges"].push(merge);
    sheet[XLSX.utils.encode_cell({ r: startRow, c: startCol })] = {
      v: text,
      t: "s",
      s: { font: { bold: true }, alignment: { horizontal: "center" } },
    };
  };

  const wb = XLSX.utils.book_new();
  let ws = XLSX.utils.aoa_to_sheet(rows);
  //   const addBorders = (ws, startRow, startCol, endRow, endCol) => {
  //     const range = {
  //       s: { r: startRow, c: startCol },
  //       e: { r: endRow, c: endCol },
  //     };
  //     for (let R = startRow; R <= endRow; ++R) {
  //       for (let C = startCol; C <= endCol; ++C) {
  //         const cellAddress = { c: C, r: R };
  //         const cellRef = XLSX.utils.encode_cell(cellAddress);
  //         if (!ws[cellRef]) ws[cellRef] = { v: "", s: {} };
  //         if (!ws[cellRef].s) ws[cellRef].s = {};
  //         ws[cellRef].s.border = {
  //           top: { style: "thin", color: { auto: 1 } },
  //           bottom: { style: "thin", color: { auto: 1 } },
  //           left: { style: "thin", color: { auto: 1 } },
  //           right: { style: "thin", color: { auto: 1 } },
  //         };
  //       }
  //     }
  //     if (!ws["!merges"]) ws["!merges"] = [];
  //     ws["!merges"].push(range);
  //   };
  // Merge cells and insert text
  mergeCellsAndInsertText(ws, 0, 0, 0, 2, "Invoice");
  mergeCellsAndInsertText(ws, 0, 3, 0, 7, "Customer");
  mergeCellsAndInsertText(ws, 0, 8, 0, 17, "Particulars");
  //   addBorders(ws, 0, 0, 5, 3);
  //   ws = {
  //     ...ws,
  //     A2: {
  //       ...ws.A2,
  //       s: {
  //         border: {
  //           top: { style: "thin", color: { auto: 1 } },
  //           bottom: { style: "thin", color: { auto: 1 } },
  //           left: { style: "thin", color: { auto: 1 } },
  //           right: { style: "thin", color: { auto: 1 } },
  //         },
  //       },
  //     },
  //   };
  //   return;
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `${filename}.xlsx`);
  //   };
  // Convert rows to CSV format
  return;
  let csvContent = rows
    .map((row) => row.map((cell) => `"${cell}"`).join(","))
    .join("\n");

  // Create a Blob containing the CSV rows
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a link element to trigger the download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  // Append link to the document body and trigger the click event
  document.body.appendChild(link);
  link.click();

  // Clean up: remove the link from the document body
  document.body.removeChild(link);
};
