import React, { useEffect, useLayoutEffect, useState } from "react";

export const Faq = ({ openAcc, data, titleDis, unique }) => {
  // const [current_ques, setcurrent_ques] = useState(false);
  const [current_ques, setcurrent_ques] = useState(false);
  const [ques_body_height, setques_body_height] = useState([]);
  useLayoutEffect(() => {
    let temp_height = data.map((el, i) => {
      return document
        .getElementById(`ques_accord_body_${unique}_${i + 1}`)
        .getBoundingClientRect().height;
    });
    setques_body_height(temp_height);
  }, [current_ques, openAcc, data]);

  // useLayoutEffect(() => {
  //   let temp_height = data.map((el, i) => {
  //     return document
  //       .getElementById(`ques_accord_body_${unique}_${i + 1}`)
  //       .getBoundingClientRect().height;
  //   });
  //   if(unique){
  //     if(openAcc){
  //       setques_body_height(temp_height[0]);
  //     }
  //   }
  // }, [openAcc ,unique])

  window.addEventListener("click", function () {
    if (openAcc) {
      setcurrent_ques(1);
      // setques_body_height([121])
    } else {
      setcurrent_ques(false);
    }
  });

  // useEffect(() => {
  //   const handleClick = () => {
  //     setcurrent_ques(false);
  //   };
  //   window.addEventListener("click", handleClick);
  //   return () => {
  //     window.removeEventListener("click", handleClick);
  //   };
  // }, []);
  return (
    <div className="section10">
      {titleDis ? (
        <div className="faq_title">
          <div>
            <div>FAQs</div>
          </div>
        </div>
      ) : (
        ""
      )}

      {[...data].map((el, i) => (
        <div className="accordian_main" key={i}>
          <div
            className="accordian_header"
            onClick={(e) => {
              setcurrent_ques((prev) => (prev === i + 1 ? "" : i + 1));
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className="que">{el.que}</div>
            <div
              className="accordian_arrow"
              style={{
                transform: `rotate(${current_ques === i + 1 ? 180 : 0}deg)`,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="#1D1D1D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div
            className="accordian_body"
            style={{
              height: current_ques === i + 1 ? `${ques_body_height[i]}px` : 0,
            }}
          >
            <div>
              <div id={`ques_accord_body_${unique}_${i + 1}`}>{el.ans}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
