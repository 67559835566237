import React from "react";
import BottomBtnSection from "../components/BottomBtnSection";
import { MixPanelIntakeStarted } from "../../../component/API/automation_api";

export const MenstralHealthBasePage = ({
  setBeginBtn,
  setRangeCount,
  setPages,
  setBeginPage,
  singleBeginRange,
  lastdata,
  goto,
  startfrom,
  sectionno,
}) => {
  return (
    <>
      <div className="intake_body">
        <div className="container main_begin">
          <div className="main_begin_title">Your Menstrual Health</div>
          <div className="main_beign_desc">
            To get to know you, we’ll ask you a few questions about your medical
            history.
          </div>
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Continue",
            onBtnClick: () => {
              setBeginBtn(true);
              setBeginPage("menstralhealth");
              if (singleBeginRange) {
                setRangeCount(0);
              }
              MixPanelIntakeStarted({
                name: "Menstrual History",
                remaining: lastdata,
              });
              setRangeCount(6);
              setPages("oftengetperiod");
            },
            goBackClick: () => {
              setRangeCount(4);
              setPages("waist");
            },
          },
        }}
      />
    </>
  );
};
