import React, { useEffect, useState } from "react";
import { Switch } from "../../component/Switch/Switch";
import { EditNotification } from "../../component/API/api";

export const Notification = ({ pagedetail }) => {
  const [PageValue, setPageValue] = useState({
    reminders: false,
    content_updates: false,
    feature_updates: false,
    order_updates: false,
    pause_all: false,
  });

  const token = localStorage.getItem("user_token");
  const [WorkOrNot, setWorkOrNot] = useState(true);
  useEffect(() => {
    if (WorkOrNot) {
      setPageValue({
        reminders: pagedetail.reminders,
        content_updates: pagedetail.content_updates,
        feature_updates: pagedetail.feature_updates,
        order_updates: pagedetail.order_updates,
        pause_all: pagedetail.pause_all,
      });
    }
  }, [pagedetail]);

  // const handleToggle = (index) => {
  //   const updatedSwitches = [...PageValue];
  //   updatedSwitches[index].isChecked = !updatedSwitches[index].isChecked;
  //   setPageValue(updatedSwitches);
  // };

  const handleSwitchToggle = (notificationType) => {
    //
    setWorkOrNot(false);
    let temp_noti = {
      ...PageValue,
    };
    if (!PageValue.pause_all && notificationType === "pause_all") {
      temp_noti = {
        reminders: false,
        content_updates: false,
        feature_updates: false,
        order_updates: false,
        pause_all: true,
      };
    }
    temp_noti = {
      ...temp_noti,
      pause_all: false,
      [notificationType]: !PageValue[notificationType],
    };

    setPageValue(temp_noti);

    //
    EditNotification(
      {
        ...temp_noti,
      },
      token,
      (res) => {
        if (res.status) {
          // Successfully updated the API
          // You can handle the response as needed
        } else {
          // Handle errors if the API request fails
        }
      }
    );
  };

  // const api = (PageValue, notificationType) => {
  //
  // };

  return (
    <>
      <div className="notification_main">
        {/* <div className="notification_main_title">Notifications</div> */}

        <div className="notification_main_list">
          {/* {PageValue?.map((el, i) => (
            <div key={i} className="notification_main_list_box">
              <div className="notification_main_list_box_title">
                {el.notifi_title}
              </div>
              <div>
                <Switch
                  custclass=""
                  page_values={{
                    left: "",
                    right: "",
                  }}
                  isChecked={el.isChecked}
                  onToggle={() => handleToggle(i)}
                />
              </div>
            </div>
          ))} */}
          <div className="notification_main_list_box">
            <div className="notification_main_list_box_title">Pause all</div>
            <div>
              <Switch
                custclass=""
                page_values={{
                  left: "",
                  right: "",
                }}
                isChecked={PageValue.pause_all}
                onToggle={(i) => handleSwitchToggle("pause_all")}
              />
            </div>
          </div>
          <div className="notification_main_list_box">
            <div className="notification_main_list_box_title">
              Order updates
            </div>
            <div>
              <Switch
                custclass=""
                page_values={{
                  left: "",
                  right: "",
                }}
                isChecked={PageValue.order_updates}
                onToggle={(i) => handleSwitchToggle("order_updates")}
              />
            </div>
          </div>
          <div className="notification_main_list_box">
            <div className="notification_main_list_box_title">Reminders</div>
            <div>
              <Switch
                custclass=""
                page_values={{
                  left: "",
                  right: "",
                }}
                isChecked={PageValue.reminders}
                onToggle={(i) => handleSwitchToggle("reminders")}
              />
            </div>
          </div>
          <div className="notification_main_list_box">
            <div className="notification_main_list_box_title">
              Content updates
            </div>
            <div>
              <Switch
                custclass=""
                page_values={{
                  left: "",
                  right: "",
                }}
                isChecked={PageValue.content_updates}
                onToggle={(i) => handleSwitchToggle("content_updates")}
              />
            </div>
          </div>
          <div className="notification_main_list_box">
            <div className="notification_main_list_box_title">
              Feature updates
            </div>
            <div>
              <Switch
                custclass=""
                page_values={{
                  left: "",
                  right: "",
                }}
                isChecked={PageValue.feature_updates}
                onToggle={(i) => handleSwitchToggle("feature_updates")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
