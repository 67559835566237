import { logDOM } from "@testing-library/react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useRef } from "react";

const CircleRange = ({
  range,
  total_range,
  color,
  age,
  biomarker,
  range_text,
  list,
  amh,
}) => {
  const ci = useRef();
  const [value, setvalue] = useState(0);

  useLayoutEffect(() => {
    let stroke_length = ci.current.getTotalLength();
    // let ageFilteredRanges = [];
    if (biomarker?.labRange?.length >= 2) {
      // ageFilteredRanges = biomarker?.labRange?.filter((range) => {
      //   return range.ageMin <= age && range.ageMax >= age;
      // });
    } else {
      // ageFilteredRanges = biomarker?.labRange;
    }
    // const highMin = ageFilteredRanges ? ageFilteredRanges[0]?.highMin : 0 || 0;
    // const highMax = ageFilteredRanges ? ageFilteredRanges[0]?.highMax : 0 || 0;
    // const lowMax = ageFilteredRanges ? ageFilteredRanges[0]?.lowMax : 0 || 0;
    // const lowMin = ageFilteredRanges ? ageFilteredRanges[0]?.lowMin : 0 || 0;
    // const optimalMin = ageFilteredRanges
    //   ? ageFilteredRanges[0]?.optimalMin
    //   : 0 || 0;
    // const optimalMax = ageFilteredRanges
    //   ? ageFilteredRanges[0]?.optimalMax
    //   : 0 || 0;

    // Create an array containing these values
    console.log({amh});
    const newArray = {
      Low: {
        min: amh?.range?.start || 0,
        max: amh?.range?.min || 0,
        name: "Low",
        toadd: 0,
      },
      Optimal: {
        min: amh?.range?.min || 0,
        max: amh?.range?.max || 0,
        name: "Optimal",
        toadd: stroke_length / 3,
      },
      High: {
        min: amh?.range?.max || 0,
        max: amh?.range?.end || 0,
        name: "High",
        toadd: stroke_length / 1.5,
      },
    };
    console.log({ newArray, amh });

    let t_ran = Number(total_range);
    // let plot = 2.8;
    let plot = Number(range);
    // let stroke_length = ci.current.getTotalLength();
    let divide_stroke = stroke_length / 3;
    // let plot_text = "Optimal";
    let plot_text = range_text;
    let start = newArray[plot_text]?.min;
    let end = newArray[plot_text]?.max;
    let precent = (plot - start) / (end - start);
    let main_percent = newArray[plot_text]?.toadd + precent * divide_stroke;
    if (main_percent > stroke_length) {
      main_percent = stroke_length;
    } else if (main_percent < 0) {
      main_percent = 0;
    }
    console.log({ plot_text });
    setvalue(main_percent);
  }, []);
  return (
    <div className="main_circle">
      <svg
        className="circle_svg"
        viewBox="0 0 281 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M275 138C271.194 101.804 255.482 68.3944 230.832 44.0765C206.181 19.7586 174.296 6.2152 141.195 6.00255C108.095 5.7899 76.0682 18.9228 51.1612 42.9222C26.2542 66.9217 10.1897 100.127 6 136.272"
          stroke="white"
          stroke-width="12"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M275 138C271.194 101.804 255.482 68.3944 230.832 44.0765C206.181 19.7586 174.296 6.2152 141.195 6.00255C108.095 5.7899 76.0682 18.9228 51.1612 42.9222C26.2542 66.9217 10.1897 100.127 6 136.272"
          stroke={color}
          stroke-width="12"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeDashoffset={411 - value}
          strokeDasharray="411"
          ref={ci}
        />
      </svg>
      <div className="current_range" style={{ color: color }}>
        {range_text}
      </div>
    </div>
  );
  return (
    // <div>
    //   <div>

    //   {range}
    //   </div>
    //   {total_range}
    // </div>

    <svg
      viewBox="0 0 281 131"
      width="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="125"
        fill="none"
        stroke="white"
        strokeWidth="10"
        cy="125"
        r="100"
      />
      {/* <circle
        cx="125"
        fill="none"
        stroke="red"
        strokeWidth="10"
        cy="125"
        r="100"
      />
      <circle
        cx="125"
        fill="none"
        stroke="yellow"
        strokeWidth="10"
        cy="125"
        r="100"
      /> */}
      <circle
        cx="125"
        fill="none"
        stroke="green"
        strokeWidth="10"
        cy="125"
        r="100"
        strokeDasharray="600"
        strokeDashoffset="100"
      />
      {/* <path
        d="M5.6199 140.5C2.51607 140.5 -0.0118254 137.983 0.112294 134.881C0.773959 118.348 4.35162 102.047 10.6948 86.733C17.7556 69.6867 28.1048 54.1981 41.1514 41.1515C54.1981 28.1049 69.6867 17.7557 86.7329 10.6949C103.779 3.63414 122.049 -2.41952e-06 140.5 0C158.951 2.41952e-06 177.221 3.63414 194.267 10.6949C211.313 17.7557 226.802 28.1049 239.848 41.1515C252.895 54.1981 263.244 69.6868 270.305 86.733C276.648 102.047 280.226 118.348 280.888 134.881C281.012 137.983 278.484 140.5 275.38 140.5C272.276 140.5 269.773 137.983 269.638 134.882C268.983 119.825 265.699 104.984 259.921 91.0344C253.425 75.3518 243.903 61.1023 231.901 49.0994C219.898 37.0965 205.648 27.5753 189.966 21.0793C174.283 14.5834 157.475 11.24 140.5 11.24C123.525 11.24 106.717 14.5834 91.0343 21.0793C75.3517 27.5752 61.1022 37.0965 49.0993 49.0994C37.0964 61.1023 27.5752 75.3518 21.0792 91.0343C15.3009 104.984 12.0171 119.825 11.3621 134.882C11.2271 137.983 8.72374 140.5 5.6199 140.5Z"
        fill="white"
      />
      <path
        d="M5.51201 140.362C2.40817 140.362 -0.119718 137.845 0.00438243 134.743C0.945945 111.213 7.78812 88.2941 19.9004 68.0987C21.4968 65.4369 24.9911 64.7173 27.5869 66.4188C30.1828 68.1204 30.8962 71.5985 29.309 74.2658C18.3862 92.6226 12.1825 113.403 11.2541 134.744C11.1193 137.844 8.61585 140.362 5.51201 140.362Z"
        fill="#F05E45"
      /> */}
    </svg>
  );
};

export default CircleRange;
