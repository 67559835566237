import React from "react";
import { useNavigate } from "react-router-dom";
import { MixPanelArticle } from "../../component/API/automation_api";

export const ClinicCard = ({
  type,
  obj,
  //   {
  //     "imageUrl": "https://cdn.sanity.io/images/nao51hyy/production/1b808519748a04648c0e5d87314de58b713fa05f-337x337.webp",
  //     "city": "Mumbai",
  //     "area": "Chembur",
  //     "title": "Dr. Aditi Tandon",
  //     "slug": "f1477849-bcb6-45d1-933f-8974b7a5692f"
  // }
  setFertility_type,
  setTrackFertility,
  FurtherDataMixPanel,
}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const nav = useNavigate();
  function asset(url) {
    if (url.match("https")) {
      return url;
    }
    let a = url?.split("-");
    return `https://cdn.sanity.io/${a[0]}s/nao51hyy/production/${a
      ?.slice(1, -1)
      ?.join("-")}.${a[a.length - 1]}`;
  }

  return (
    <div
      className="small_size_card"
      onClick={() => {
        MixPanelArticle({
          content_name: obj.title,
          article_id: obj.slug,
          screen_name: obj.title,
          // screen_name: `${name
          //   .replaceAll(" ", "")
          //   .toLowerCase()}/${el.name.replaceAll(" ", "").toLowerCase()}`,
          content_type: "Clinic",
        });
        nav(
          type == "clinic" ? `/clinicdetail/${obj.slug}` : `/learn/${obj.slug}`
        );
      }}
    >
      <div className="details">
        <div className="location">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M11.7726 11.1045L8.94397 13.9331C8.82027 14.057 8.67337 14.1552 8.51168 14.2222C8.34999 14.2892 8.17667 14.3237 8.00164 14.3237C7.82661 14.3237 7.65329 14.2892 7.4916 14.2222C7.32991 14.1552 7.18301 14.057 7.0593 13.9331L4.22997 11.1045C3.48412 10.3586 2.9762 9.40827 2.77043 8.37371C2.56466 7.33916 2.6703 6.26681 3.07397 5.29229C3.47765 4.31777 4.16124 3.48483 5.03829 2.89881C5.91535 2.31279 6.94648 2 8.0013 2C9.05613 2 10.0873 2.31279 10.9643 2.89881C11.8414 3.48483 12.525 4.31777 12.9286 5.29229C13.3323 6.26681 13.4379 7.33916 13.2322 8.37371C13.0264 9.40827 12.5185 10.3586 11.7726 11.1045Z"
              stroke="#5F1D1B"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 7.33301C10 7.86344 9.78929 8.37215 9.41421 8.74722C9.03914 9.12229 8.53043 9.33301 8 9.33301C7.46957 9.33301 6.96086 9.12229 6.58579 8.74722C6.21071 8.37215 6 7.86344 6 7.33301C6 6.80257 6.21071 6.29387 6.58579 5.91879C6.96086 5.54372 7.46957 5.33301 8 5.33301C8.53043 5.33301 9.03914 5.54372 9.41421 5.91879C9.78929 6.29387 10 6.80257 10 7.33301Z"
              stroke="#5F1D1B"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div className="city">{obj?.area}</div>
          <div className="divider"></div>
          <div className="area">{obj?.city}</div>
        </div>
        <div className="name">{obj?.title}</div>
        {/* <div className="desc">{obj?.description}</div> */}
      </div>
      <div
        className="small_size_card_img"
        style={{
          backgroundImage: `url(${obj?.imageUrl || ""})`,
          backgroundSize: "cover",

          // height:
        }}
      >
        {/* <img src={asset(obj?.imageUrl)} /> */}
      </div>
    </div>
  );
};
