import React from "react";
import { useNavigate } from "react-router-dom";
export const BookCompleteV2 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bookslot_body">
        <div className="container  bookcomplete_main">
          <div className="bookcomplete_main_video">
            <iframe
              width="100%"
              height="300px"
              id="theiframe"
              // onLoad={iframeclick}
              src="https://www.youtube.com/embed/eGXXNGpTIqI"
              title="YouTube video player"
              frameBorder="0"
              className="background_video"
              allowFullScreen
            ></iframe>
          </div>
          <div className="bookcomplete_main_quote">Welcome to Arva!</div>
        </div>
      </div>
      <div
        className="bookcomplete_main_btn"
        onClick={() => {
          navigate("/home");
          window.location.reload();
        }}
      >
        Let's go
      </div>
    </>
  );
};
