import React, { useLayoutEffect, useState } from "react";
import { flags } from "../../utils/utils";

const CustPhoneCountries = ({ value, setvalue }) => {
  const [is_open, setis_open] = useState(false);
  useLayoutEffect(() => {
    window.onclick = () => {
      setis_open(false);
    };
  }, []);
  return (
    <div
      className="custom_phone_input"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setis_open((prev) => !prev);
      }}
    >
      <div className="custom_phone_main cust_phone_input_main">
        <div className={"flag " + value?.flag_code}></div>

        {/* 
        <div>{value.name}</div> */}
        <div
          className="arrow"
          style={{
            transform: `rotate(${is_open ? -135 : 45}deg)`,
          }}
        ></div>
        <div>{value?.code}</div>
      </div>
      {is_open && (
        <div className="custom_phone_dropdown cust_phone_input_main">
          {flags.map(({ flag_code, code, name }) => (
            <div
              onClick={() => {
                setvalue({ flag_code, code, name });
              }}
            >
              <div className={"flag " + flag_code}></div>
              <div>{name}</div>
              <div>{code}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustPhoneCountries;
