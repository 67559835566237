import React from "react";
import { useNavigate } from "react-router-dom";
import { MixPanelArticle } from "../API/automation_api";
export const QandAlist = ({ name, list }) => {
  const nav = useNavigate();
  return (
    <div className="main_qandalsit_cmp">
      <div className="main_qandalsit_cmp_name">{name}</div>
      <div className="main_qandalsit_cmp_list">
        {list?.map((el, i) => (
          <div
            className="main_qandalsit_cmp_list_box"
            onClick={() => {
              MixPanelArticle({
                content_name: el.name,
                article_id: el._id,
                screen_name: `${name
                  .replaceAll(" ", "")
                  .toLowerCase()}/${el.name.replaceAll(" ", "").toLowerCase()}`,
                content_type: "FAQ",
              });
              nav(
                `/faq/${name.replaceAll(" ", "").toLowerCase()}/${el.name
                  .replaceAll(" ", "")
                  .toLowerCase()}`
              );
            }}
          >
            {el.name}
          </div>
        ))}
      </div>
    </div>
  );
};
