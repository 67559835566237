// import XLSX  from "xlsx";
const XLSX = require("xlsx");
export const DownloadCsvOrder = (arrayData, filename) => {
  let total = [0, 0, 0, 0, 0, 0, 0];

  let rows = [];
  // Iterate over each section
  rows.push(
    [
      "Name",
      "Phone",
      "Email",
      "Date",

      "Apt",
      "Address",
      "City",
      "Landmark",
      "Zipcode",

      "Age",
      "Height Ft",
      "Height In",
      "Weight",
      "Waist",
      "Period",
      "Period Length",
      "Flow Level",
      "Symptoms",
      "Body Hair",
      "DIAGNOSIS",
      "FERTILITY GOAL",
      "ULTRASOUND",
      "BIRTH CONTROL",
      "MEDICAL CONDITIONS",
      "MEDICATION",
      "AGE OF PREGNANCY",
      "EASE OF PREGNANCY",
      "MENOPAUSE",
      "HOW DID YOU HEAR ABOUT ARVA?",
      "DO YOU HAVE A PARTNER IN YOUR LIFE?",
      "CAN YOU TELL US ABOUT YOUR PARTNER?",

      "Order Id",
      "Test Name",
      "VL Id",
      "REPORT",

      "AMH",
      "FSH",
      "E2",
      "FREE T",
      "DHEAS",
      "T3",
      "T4",
      "PRL",
      "HOMA IR",
      "LH",
      "ABG",
      "HBA1C",
      "TSH",
    ].map((el) => el.toUpperCase())
  );

  arrayData?.forEach((section) => {
    let rowData = [
      section.name,
      section.phone,
      section.email,
      section.createdAt,

      section.apt,
      section.address,
      section.city,
      section.landmark,
      section.zipcode,

      section.age,
      section.heightft,
      section.heightin,
      section.weight,
      section.waist,
      section.period,
      section.periodlength,
      section.flowlevel,

      section?.symptoms?.length ? section?.symptoms.join(", ") : "",
      section?.bodyhair?.length ? section?.bodyhair.join(", ") : "",
      section?.diagnosis?.length ? section?.diagnosis.join(", ") : "",

      section.fertilitygoal,
      section.ultrasound,
      section.birthcontrol,
      section.medicalconditions,
      section.medication,
      section.ageofpregnancy,
      section.easeofpregnancy,
      section.menopause,
      section.howdidyouhearaboutarva,
      section.doyouhaveapartnerinyourlife,
      section.canyoutellusaboutyourpartner,
      section.orderid,
      section.testname,
      section.vlid,
      section.report,

      section.amh,
      section.fsh,
      section.e2,
      section.freet,
      section.dheas,
      section.t3,
      section.t4,
      section.prl,
      section.homair,
      section.lh,
      section.abg,
      section.hba1c,
      section.tsh,
    ];
    rows.push(rowData);
  });
  //   generateExcel = () => {
  // const mergeCellsAndInsertText = (
  //   sheet,
  //   startRow,
  //   startCol,
  //   endRow,
  //   endCol,
  //   text
  // ) => {
  //   const merge = {
  //     s: { r: startRow, c: startCol },
  //     e: { r: endRow, c: endCol },
  //   };
  //   sheet["!merges"] = sheet["!merges"] || [];
  //   sheet["!merges"].push(merge);
  //   sheet[XLSX.utils.encode_cell({ r: startRow, c: startCol })] = {
  //     v: text,
  //     t: "s",
  //     s: { font: { bold: true }, alignment: { horizontal: "center" } },
  //   };
  // };

  const wb = XLSX.utils.book_new();
  let ws = XLSX.utils.aoa_to_sheet(rows);
  //   const addBorders = (ws, startRow, startCol, endRow, endCol) => {
  //     const range = {
  //       s: { r: startRow, c: startCol },
  //       e: { r: endRow, c: endCol },
  //     };
  //     for (let R = startRow; R <= endRow; ++R) {
  //       for (let C = startCol; C <= endCol; ++C) {
  //         const cellAddress = { c: C, r: R };
  //         const cellRef = XLSX.utils.encode_cell(cellAddress);
  //         if (!ws[cellRef]) ws[cellRef] = { v: "", s: {} };
  //         if (!ws[cellRef].s) ws[cellRef].s = {};
  //         ws[cellRef].s.border = {
  //           top: { style: "thin", color: { auto: 1 } },
  //           bottom: { style: "thin", color: { auto: 1 } },
  //           left: { style: "thin", color: { auto: 1 } },
  //           right: { style: "thin", color: { auto: 1 } },
  //         };
  //       }
  //     }
  //     if (!ws["!merges"]) ws["!merges"] = [];
  //     ws["!merges"].push(range);
  //   };
  // Merge cells and insert text
  // mergeCellsAndInsertText(ws, 0, 0, 0, 2, "Invoice");
  // mergeCellsAndInsertText(ws, 0, 3, 0, 7, "Customer");
  // mergeCellsAndInsertText(ws, 0, 8, 0, 17, "Particulars");
  //   addBorders(ws, 0, 0, 5, 3);
  //   ws = {
  //     ...ws,
  //     A2: {
  //       ...ws.A2,
  //       s: {
  //         border: {
  //           top: { style: "thin", color: { auto: 1 } },
  //           bottom: { style: "thin", color: { auto: 1 } },
  //           left: { style: "thin", color: { auto: 1 } },
  //           right: { style: "thin", color: { auto: 1 } },
  //         },
  //       },
  //     },
  //   };
  //   return;
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `${filename}.xlsx`);
  //   };
  // Convert rows to CSV format
  return;
  let csvContent = rows
    .map((row) => row.map((cell) => `"${cell}"`).join(","))
    .join("\n");

  // Create a Blob containing the CSV rows
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a link element to trigger the download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  // Append link to the document body and trigger the click event
  document.body.appendChild(link);
  link.click();

  // Clean up: remove the link from the document body
  document.body.removeChild(link);
};
