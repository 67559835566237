import React from "react";

const Bottom_Btn_section = ({ control }) => {
  return (
    <>
      <div className="bottom_btn_section">
        <div>
          {control.txt === "Begin" || control.txt === "Update goal"? (
            ""
          ) : (
            <div className="intake_go_back"
            onClick={()=>{
              control.goBackClick()
            }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M8.33333 15.8334L2.5 10.0001M2.5 10.0001L8.33333 4.16675M2.5 10.0001H17.5"
                  stroke="#5F1D1B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}

          <div
            className="btn_controller_section"
            onClick={() => {
              control.onBtnClick();
            }}
          >
            {control.txt}
          </div>
        </div>
      </div>
    </>
  );
};

export default Bottom_Btn_section;
