import axios from "axios";
import mixpanel from "mixpanel-browser";
import { utilsArray } from "../../utils/utils";
// import TagManager from "react-gtm-module";

export let base_url;
export let base_url_result;
if (utilsArray("testing")) {
  //   // base_url = "https://automations-staging.arva.health/api/"
  //   // base_url_result = `https://automations-staging.arva.health/api/zoko/report-ready`;
  base_url = `https://automations.arva.health/api/testingstate`;
  base_url_result = `https://automations.arva.health/api/zoko/report-ready/testingstate`;
} else {
  base_url = `https://automations.arva.health/api`;
  base_url_result = `https://automations.arva.health/api/zoko/report-ready`;
}

// {
//   "sign_up_date": "01/14/2022",
//   "last_order_type": "Fertility Assessment",
//   "user_id": "adfa79ye",
//   "name": "Abdul",
//   "gender": "Female",
//   "date_of_birth": "01/13/2022",
//   "first_visit_date": "01/14/2022",
//   "is_test_user": true,
//   "user_current_goal": "Just curious to learn about my fertility",
//   "is_paid_user": true,
//   "reported_symptoms": "Hairfall",
//   "reported_conditions": "PCOS",
//   "is_ultrasound_done": true,
//   "is_birth_control": true
// },
// mixpanel
// const project_token = "a118513275c37393066b01c1d2a13da3"; //test
const project_token = "18bf1697859093af3fa810b39fb37680"; //prod

try {
  mixpanel.reset();
} catch (error) {
  console.log("mixpanel clear error");
}
console.log("here =>", mixpanel);

mixpanel.init(project_token);
const api = {
  orderconfirmation: "/zoko/order-confirmation",
  slotconfirmation: "/zoko/slot-confirmation",
  appointmentreview: "/zoko/appointment",

  beehivlead: "/beehiiv/lead",
  beehivpayment: "/beehiiv/payment",
  beehivpaid: "/beehiiv/paid",
  beehivintake: "/beehiiv/intake",

  mixpaneltest: "/mixpanel/test",
  mixpaneldiscount: "/mixpanel/discount",
  mixpanellogin: "/mixpanel/login",
  mixpanelotp: "/mixpanel/otp-sent",
  mixpanelotpverified: "/mixpanel/otp-entered",
  mixpanelusertype: "/mixpanel/user-type",
  mixpanelpincode: "/mixpanel/pincode",
  mixpanelcompleteorder: "/mixpanel/completed-order",
  mixpanelslot: "/mixpanel/slot",
  mixpanelintakestart: "/mixpanel/intake-started",
  mixpanelintake: "/mixpanel/intake",
  mixpanelintakecompleted: "/mixpanel/intake-completed",
  mixpanelhomevideo: "/mixpanel/home-video",
  mixpanelhomearticle: "/mixpanel/article",
  mixpanelresult: "/mixpanel/results",
  mixpanelresultsection: "/mixpanel/result-section",
  mixpanelbiomarkerexpanded: "/mixpanel/biomarker-expanded",
  mixpanelinsightresult: "/mixpanel/insight-report",
  mixpanelinsightreportexpanded: "/mixpanel/insight-report-expanded",
  mixpanelinsightreferal: "/mixpanel/referral",
  mixpanelonload: "/mixpanel/page-load",

  balance: "/balance",
};
// export const FbDetail = (obj, endpt) => {
//   // if (!utilsArray("testing")) {
//   window.fbq("trackCustom", endpt, {
//     ...obj,
//   });
//   // }
// };
export const FbDetailNew = (endpt, obj) => {
  window.fbq("trackCustom", endpt, {
    ...obj,
  });
};

export const CompleteResult = (data, callback) => {
  try {
    // Fb({
    //   phone: data.phone,
    //   email: data.email,
    //   name: data.name,
    // });
    axios
      .post(`${base_url_result}`, {
        phone: data?.phone,
        email: data?.email,
        name: data?.name,
        orderId: data?.orderId,
        mongoId: data?.mongoId,
      })
      .then((response) => response.data)
      .catch(() => {});
  } catch {}
};

export const VerifyOtp = (data1, callback) => {
  let data = JSON.stringify(data1);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${base_url}/verify-otp`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
          response: response.err,
          // response: "Network Err or Try Again",
        });
      }

      return callback({
        status: true,
        response,
      });
    })
    .catch((error) => {
      return callback({
        status: false,
        response: "Network Err or Try Again",
      });
    });
};
export const BookAppoin = (data, callback) => {
  try {
    axios
      .post(`${base_url}${api.appointmentreview}`, {
        // phone: utilsArray("phone_formater")(data.phone),
        // id: data.id,
        phone: data?.phone,
        email: data?.email,
        name: data?.name,
        orderId: data?.orderId,
        mongoId: data?.mongoId,
      })
      .then((response) => response.data)
      .catch(() => {});
  } catch {}
};
export const BeehivLead = (data, callback) => {
  try {
    // Fb(
    //   {
    //     email: data,
    //   },
    //   "Login"
    // );
    axios
      .post(`${base_url}${api.beehivlead}`, {
        phone: utilsArray("phone_formater")(data),
      })
      .then((response) => response.data)

      .catch(() => {});
  } catch {}
};
export const BeehivPayment = (data, callback) => {
  try {
    // Fb({
    //   email: data.email,
    //   first_name: data.first_name,
    //   last_name: data.last_name,
    //   phone: data.phone,
    // },api.beehivpayment);
    axios
      .post(`${base_url}${api.beehivpayment}`, {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: utilsArray("phone_formater")(data.phone),
      })
      .then((response) => response.data)

      .catch(() => {});
  } catch {}
};
export const BeehivPaid = (data, callback) => {
  try {
    // Fb({
    //   email: data.email,
    // },api.beehivpaid);
    axios
      .post(`${base_url}${api.beehivpaid}`, {
        phone: utilsArray("phone_formater")(data.phone),
      })
      .then((response) => response.data)

      .catch(() => {});
  } catch {}
};
export const BeehivIntake = (data, callback) => {
  try {
    // Fb({
    //   email: data.email,
    //   goal: data.goal,
    // },api.beehivintake);
    axios
      .post(`${base_url}${api.beehivintake}`, {
        phone: utilsArray("phone_formater")(data.phone),
        goal: data.goal,
      })
      .then((response) => response.data)

      .catch(() => {});
  } catch {}
};
export const BalanceArva = (callback) => {
  try {
    axios
      .get(`https://automations.arva.health/api${api.balance}`, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }
        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const MixPanelLogOut = () => {
  // mixpanel.unregister_all();

  // Reset the distinct ID
  mixpanel.reset();

  // Clear cached events (optional)
  // mixpanel.flush();
};
export const MixPanelTest = (data, callback) => {
  try {
    mixpanel.track("Preferred Time Period Selected", {
      ...data,
    });
    FbDetailNew("Preferred Time Period Selected", {
      ...data,
    });
  } catch {}
};
export const MixPanelDiscount = (data, callback) => {
  try {
    // Fb({
    //   is_valid: data.isvalid,
    //   discount_code: data.code,

    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // },api.mixpaneldiscount);
    // axios
    // .post(`${base_url}${api.mixpaneldiscount}`, {
    //   is_valid: data.isvalid,
    //   discount_code: data.code,

    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // })
    // .then((response) => response.data)

    // .catch(() => {});
    mixpanel.track("Discount Button tapped", {
      is_valid: data.isvalid,
      discount_code: data.discount_code,
    });
    FbDetailNew("Discount Button tapped", {
      is_valid: data.isvalid,
      discount_code: data.discount_code,
    });
  } catch (err) {}
};
export const MixPanelLogin = (data, callback) => {
  try {
    // Fb({
    //   logged_in_via: data.type,
    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // },api.mixpanellogin);
    // axios
    //   .post(`${base_url}${api.mixpanellogin}`, {
    //     logged_in_via: data.type,
    // sign_up_date: data.remaining.sign_up_date,
    // last_order_type: data.remaining.last_order_type,
    // user_id: data.remaining.user_id,
    // name: data.remaining.name,
    // gender: "Female",
    // date_of_birth: data.remaining.birthdate,
    // first_visit_date: data.remaining.sign_up_date,
    // is_test_user: false,
    // user_current_goal: data.remaining.user_current_goal,
    // is_paid_user: false,
    // reported_symptoms: data.remaining.reported_symptoms,
    // reported_conditions: data.remaining.reported_conditions,
    // is_ultrasound_done: data.remaining.is_ultrasound_done,
    // is_birth_control: data.remaining.is_birth_control,
    //   })
    //   .then((response) => response.data)
    //   .catch(() => {});
  } catch {}
};

export const userProperties = (data) => {
  mixpanel.people.set({
    // ...data
    "Sign Up Date": data.sign_up_date || "N/A",
    "User ID": data.user_id || "N/A",
    Name: data.name || "N/A",
    Gender: "Female",
    "Date Of Birth": data.date_of_birth || "N/A",
    "First Visit Date": data.sign_up_date || "N/A",
    "Is Test User": false,
    "User Current Goal": data.user_current_goal || "N/A",
    "Is Paid User": data.is_paid_user,
    "Reported Symptoms": data.reported_symptoms || "N/A",
    "Reported Conditions": data.reported_conditions || "N/A",
    "Is Ultrasound Done": data.is_ultrasound_done,

    "Is Birth Control": data.is_birth_control,
  });
  mixpanel.register({
    "Sign Up Date": data.sign_up_date,
    "Is Paid User": data.is_paid_user,
    "Last Order Type": data.last_order_type || "N/A",
    "User Current Goal": data.user_current_goal || "N/A",
  });
};

export const MixPanelOtp = (data, callback) => {
  try {
    // Fb({
    //   is_edited: data.edit,
    //   is_retriggered: data.resend,

    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // },api.mixpanelotp);
    // axios
    //   .post(`${base_url}${api.mixpanelotp}`, {
    //     is_edited: data.edit,
    //     is_retriggered: data.resend,

    //     sign_up_date: data.remaining.sign_up_date,
    //     last_order_type: data.remaining.last_order_type,
    //     user_id: data.remaining.user_id,
    //     name: data.remaining.name,
    //     gender: "Female",
    //     date_of_birth: data.remaining.birthdate,
    //     first_visit_date: data.remaining.sign_up_date,
    //     is_test_user: false,
    //     user_current_goal: data.remaining.user_current_goal,
    //     is_paid_user: false,
    //     reported_symptoms: data.remaining.reported_symptoms,
    //     reported_conditions: data.remaining.reported_conditions,
    //     is_ultrasound_done: data.remaining.is_ultrasound_done,
    //     is_birth_control: data.remaining.is_birth_control,
    //   })
    //   .then((response) => response.data)

    //   .catch(() => {});
    mixpanel.track("OTP Entered", {
      is_edited: data.edit,
      is_retriggered: data.resend,
    });
    mixpanel.track("OTP Entered", {
      is_edited: data.edit,
      is_retriggered: data.resend,
    });
  } catch {}
};
export const MixPanelOtpVerified = (data, callback) => {
  try {
    // Fb({
    //   user_email: data.email,
    //   is_valid: data.vaid,

    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // },api.mixpanelotpverified);
    // axios
    //   .post(`${base_url}${api.mixpanelotpverified}`, {
    //     user_email: data.email,
    //     is_valid: data.vaid,

    //     sign_up_date: data.remaining.sign_up_date,
    //     last_order_type: data.remaining.last_order_type,
    //     user_id: data.remaining.user_id,
    //     name: data.remaining.name,
    //     gender: "Female",
    //     date_of_birth: data.remaining.birthdate,
    //     first_visit_date: data.remaining.sign_up_date,
    //     is_test_user: false,
    //     user_current_goal: data.remaining.user_current_goal,
    //     is_paid_user: false,
    //     reported_symptoms: data.remaining.reported_symptoms,
    //     reported_conditions: data.remaining.reported_conditions,
    //     is_ultrasound_done: data.remaining.is_ultrasound_done,
    //     is_birth_control: data.remaining.is_birth_control,
    //   })
    //   .then((response) => response.data)

    //   .catch(() => {});
    mixpanel.track("OTP Triggered", {
      user_phone: utilsArray("phone_formater")(data.phone),
      is_valid: data.valid,
    });
    FbDetailNew("OTP Triggered", {
      user_phone: utilsArray("phone_formater")(data.phone),
      is_valid: data.valid,
    });
  } catch {}
};
export const MixPanelUsertype = (success, callback) => {
  try {
    // Fb({
    //   is_first_order: data.first_order,

    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // },api.mixpanelusertype);
    // axios
    //   .post(`${base_url}${api.mixpanelusertype}`, {
    //     is_first_order: data.first_order,

    //     sign_up_date: data.remaining.sign_up_date,
    //     last_order_type: data.remaining.last_order_type,
    //     user_id: data.remaining.user_id,
    //     name: data.remaining.name,
    //     gender: "Female",
    //     date_of_birth: data.remaining.birthdate,
    //     first_visit_date: data.remaining.sign_up_date,
    //     is_test_user: false,
    //     user_current_goal: data.remaining.user_current_goal,
    //     is_paid_user: false,
    //     reported_symptoms: data.remaining.reported_symptoms,
    //     reported_conditions: data.remaining.reported_conditions,
    //     is_ultrasound_done: data.remaining.is_ultrasound_done,
    //     is_birth_control: data.remaining.is_birth_control,
    //   })
    //   .then((response) => response.data);
    // userProperties({
    //   // sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,

    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,

    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: data.remaining.is_paid_user,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,

    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // });
    console.error("set user prorperties");
    userProperties({
      // sign_up_date: data.remaining.sign_up_date,
      user_id: success?.customer?._id || "",
      name: `${success?.customer?.user?.first_name || ""} ${
        success?.customer?.user?.last_name || ""
      }`.trim(),
      date_of_birth: success?.customer?.user?.dob || "",
      // first_visit_date: data.remaining.sign_up_date,
      is_test_user: false,
      user_current_goal:
        success?.customer?.intake?.reproductive_health?.goal || "",
      is_paid_user: success?.orderItems?.length > 0,
      reported_symptoms:
        success?.customer?.intake?.menstrual_health?.symptoms || [],
      reported_conditions:
        success?.customer?.intake?.menstrual_health?.experience_symptoms || [],
      is_ultrasound_done:
        success?.customer?.intake?.reproductive_health?.ultrasound_diagnosis ||
        "",
      is_birth_control:
        success?.customer?.intake?.reproductive_health?.birth_control || "",
    });
    mixpanel.identify(success?.customer?._id || "");
    // mixpanel.track("Sign In Completed", {
    //   is_first_time_user: data.first_order,
    //   logged_in_via: data.type,
    // });
  } catch {}
};
export const MixPanelZipCode = (data, callback) => {
  try {
    // Fb({
    //   pincode: data.zipcode,
    //   is_serviceable: true,

    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // },api.mixpanelpincode);
    // axios
    //   .post(`${base_url}${api.mixpanelpincode}`, {
    //     pincode: data.zipcode,
    //     is_serviceable: true,

    //     sign_up_date: data.remaining.sign_up_date,
    //     last_order_type: data.remaining.last_order_type,
    //     user_id: data.remaining.user_id,
    //     name: data.remaining.name,
    //     gender: "Female",
    //     date_of_birth: data.remaining.birthdate,
    //     first_visit_date: data.remaining.sign_up_date,
    //     is_test_user: false,
    //     user_current_goal: data.remaining.user_current_goal,
    //     is_paid_user: false,
    //     reported_symptoms: data.remaining.reported_symptoms,
    //     reported_conditions: data.remaining.reported_conditions,
    //     is_ultrasound_done: data.remaining.is_ultrasound_done,
    //     is_birth_control: data.remaining.is_birth_control,
    //   })
    //   .then((response) => response.data)

    //   .catch(() => {});
    mixpanel.track("Pincode Check Requested", {
      pincode: data.zipcode,
      is_serviceable: true,
    });
    FbDetailNew("Pincode Check Requested", {
      pincode: data.zipcode,
      is_serviceable: true,
    });
  } catch {}
};
export const MixPanelJoinCommunity = (data, callback) => {
  console.error("mix panel joi communioty hit");
  try {
    mixpanel.track("Join Community Tapped", {});
    FbDetailNew("Join Community Tapped", {});
  } catch {}
};
export const MixPanelGoalChange = (data, callback) => {
  try {
    mixpanel.track("User Goal Added", {
      previous_goal: data.prev,
      selected_goal: data.curr,
    });
    FbDetailNew("User Goal Added", {
      previous_goal: data.prev,
      selected_goal: data.curr,
    });
  } catch {}
};
export const MixPanelCompletedOrder = (data, callback) => {
  try {
    // Fb({
    //   is_discount_applied: data.discount,
    //   order_type: data.type,
    //   order_value: data.value,
    //   is_first_order: data.first_order,

    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // },api.mixpanelcompleteorder);
    // axios
    //   .post(`${base_url}${api.mixpanelcompleteorder}`, {
    //     is_discount_applied: data.discount,
    //     order_type: data.type,
    //     order_value: data.value,
    //     is_first_order: data.first_order,

    //     sign_up_date: data.remaining.sign_up_date,
    //     last_order_type: data.remaining.last_order_type,
    //     user_id: data.remaining.user_id,
    //     name: data.remaining.name,
    //     gender: "Female",
    //     date_of_birth: data.remaining.birthdate,
    //     first_visit_date: data.remaining.sign_up_date,
    //     is_test_user: false,
    //     user_current_goal: data.remaining.user_current_goal,
    //     is_paid_user: false,
    //     reported_symptoms: data.remaining.reported_symptoms,
    //     reported_conditions: data.remaining.reported_conditions,
    //     is_ultrasound_done: data.remaining.is_ultrasound_done,
    //     is_birth_control: data.remaining.is_birth_control,
    //   })
    //   .then((response) => response.data)

    //   .catch(() => {});

    // mixpanel.track("Test Booking Completed", {
    mixpanel.track("Booking Completed", {
      is_discount_applied: data.discount,
      order_type: data.order_type,
      order_value: data.order_value,
      is_first_order: data.first_order,
    });
    FbDetailNew("Booking Completed", {
      is_discount_applied: data.discount,
      order_type: data.order_type,
      order_value: data.order_value,
      is_first_order: data.first_order,
    });
  } catch {}
};
export const MixPanelSlot = (data, callback) => {
  try {
    mixpanel.track("Slot Selected", {
      ...data,
    });
    FbDetailNew("Slot Selected", {
      ...data,
    });
  } catch {}
};
export const MixPanelIntakeStarted = (data, callback) => {
  try {
    mixpanel.track("Intake Form Section Started", {
      section_name: data.name,
    });
    FbDetailNew("Intake Form Section Started", {
      section_name: data.name,
    });
  } catch {}
};
export const MixPanelIntake = (data, callback) => {
  try {
    mixpanel.track("Intake Form Step Completed", {
      step_name: data.step_name,
      // value: data.value,
      section_name: data.section_name,
      step_index: data.step_index,
      total_steps: data.total_steps,
    });
    FbDetailNew("Intake Form Step Completed", {
      step_name: data.step_name,
      // value: data.value,
      section_name: data.section_name,
      step_index: data.step_index,
      total_steps: data.total_steps,
    });
  } catch {}
};
export const MixPanelIntakeCompleted = (data, callback) => {
  try {
    mixpanel.track("Intake Form Section Completed", {
      section_name: data.section_name,
    });
    FbDetailNew("Intake Form Section Completed", {
      section_name: data.section_name,
    });
  } catch {}
};
export const MixPanelVideo = (data, callback) => {
  try {
    // Fb({
    //   video_url: data.url,

    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // },api.mixpanelhomevideo);
    // axios
    //   .post(`${base_url}${api.mixpanelhomevideo}`, {
    //     video_url: data.url,

    //     sign_up_date: data.remaining.sign_up_date,
    //     last_order_type: data.remaining.last_order_type,
    //     user_id: data.remaining.user_id,
    //     name: data.remaining.name,
    //     gender: "Female",
    //     date_of_birth: data.remaining.birthdate,
    //     first_visit_date: data.remaining.sign_up_date,
    //     is_test_user: false,
    //     user_current_goal: data.remaining.user_current_goal,
    //     is_paid_user: false,
    //     reported_symptoms: data.remaining.reported_symptoms,
    //     reported_conditions: data.remaining.reported_conditions,
    //     is_ultrasound_done: data.remaining.is_ultrasound_done,
    //     is_birth_control: data.remaining.is_birth_control,
    //   })
    //   .then((response) => response.data)

    //   .catch(() => {});
    mixpanel.track("Video " + data.videoState, {
      video_url: data.url,
      currentTime: data.currentTime,
    });
    FbDetailNew("Video " + data.videoState, {
      video_url: data.url,
      currentTime: data.currentTime,
    });
  } catch {}
};
export const MixPanelArticle = (data, callback) => {
  try {
    // mixpanel.track("Article Viewed", {
    mixpanel.track("Content Viewed", {
      // content_name: data.ar_name,
      // article_id: data.slug,
      // screen_name: data.screen_name,
      is_paid_content: false,
      ...data,
    });
    FbDetailNew("Content Viewed", {
      // content_name: data.ar_name,
      // article_id: data.slug,
      // screen_name: data.screen_name,
      is_paid_content: false,
      ...data,
    });
  } catch {}
};
export const MixPanelPaidArticleTapped = (data, callback) => {
  try {
    mixpanel.track("Paid Article Tapped", {
      // article_name
      // section_name
      ...data,
    });
    FbDetailNew("Paid Article Tapped", {
      // article_name
      // section_name
      ...data,
    });
  } catch {}
};
export const MixPanelResult = (data, callback) => {
  try {
    mixpanel.track("Results Viewed", {});
    FbDetailNew("Results Viewed", {});
  } catch {}
};
export const MixPanelAddress = (data, callback) => {
  try {
    // Fb({
    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // },api.mixpanelresult);
    // axios
    //   .post(`${base_url}${api.mixpanelresult}`, {
    //     sign_up_date: data.remaining.sign_up_date,
    //     last_order_type: data.remaining.last_order_type,
    //     user_id: data.remaining.user_id,
    //     name: data.remaining.name,
    //     gender: "Female",
    //     date_of_birth: data.remaining.birthdate,
    //     first_visit_date: data.remaining.sign_up_date,
    //     is_test_user: false,
    //     user_current_goal: data.remaining.user_current_goal,
    //     is_paid_user: false,
    //     reported_symptoms: data.remaining.reported_symptoms,
    //     reported_conditions: data.remaining.reported_conditions,
    //     is_ultrasound_done: data.remaining.is_ultrasound_done,
    //     is_birth_control: data.remaining.is_birth_control,
    //   })
    //   .then((response) => response.data)

    //   .catch(() => {});
    mixpanel.track("Address Completed", {});
    FbDetailNew("Address Completed", {});
  } catch {}
};
export const MixPanelResultSection = (data, callback) => {
  try {
    // Fb({
    //   result_section_name: data.s_name,

    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // },api.mixpanelresultsection);
    // axios
    //   .post(`${base_url}${api.mixpanelresultsection}`, {
    //     result_section_name: data.s_name,

    //     sign_up_date: data.remaining.sign_up_date,
    //     last_order_type: data.remaining.last_order_type,
    //     user_id: data.remaining.user_id,
    //     name: data.remaining.name,
    //     gender: "Female",
    //     date_of_birth: data.remaining.birthdate,
    //     first_visit_date: data.remaining.sign_up_date,
    //     is_test_user: false,
    //     user_current_goal: data.remaining.user_current_goal,
    //     is_paid_user: false,
    //     reported_symptoms: data.remaining.reported_symptoms,
    //     reported_conditions: data.remaining.reported_conditions,
    //     is_ultrasound_done: data.remaining.is_ultrasound_done,
    //     is_birth_control: data.remaining.is_birth_control,
    //   })
    //   .then((response) => response.data)

    //   .catch(() => {});
    mixpanel.track("Result Section Tapped", {
      result_section_name: data.s_name,
    });
    FbDetailNew("Result Section Tapped", {
      result_section_name: data.s_name,
    });
  } catch {}
};
export const MixPanelBiomarker = (data, callback) => {
  try {
    // Fb({
    //   marker_name: data.name,
    //   is_in_range: data.range,
    //   screen_name: data.screen,

    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // },api.mixpanelbiomarkerexpanded);
    // axios
    //   .post(`${base_url}${api.mixpanelbiomarkerexpanded}`, {
    //     marker_name: data.name,
    //     is_in_range: data.range,
    //     screen_name: data.screen,

    //     sign_up_date: data.remaining.sign_up_date,
    //     last_order_type: data.remaining.last_order_type,
    //     user_id: data.remaining.user_id,
    //     name: data.remaining.name,
    //     gender: "Female",
    //     date_of_birth: data.remaining.birthdate,
    //     first_visit_date: data.remaining.sign_up_date,
    //     is_test_user: false,
    //     user_current_goal: data.remaining.user_current_goal,
    //     is_paid_user: false,
    //     reported_symptoms: data.remaining.reported_symptoms,
    //     reported_conditions: data.remaining.reported_conditions,
    //     is_ultrasound_done: data.remaining.is_ultrasound_done,
    //     is_birth_control: data.remaining.is_birth_control,
    //   })
    //   .then((response) => response.data)

    //   .catch(() => {});
    mixpanel.track("Biomarker Expanded", {
      marker_name: data.name,
      is_in_range: data.range,
      screen_name: data.screen,
    });
    FbDetailNew("Biomarker Expanded", {
      marker_name: data.name,
      is_in_range: data.range,
      screen_name: data.screen,
    });
  } catch {}
};
export const MixPanelResultClick = (data, callback) => {
  try {
    // Fb({
    //   insight_report_name: data.name,

    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // },api.mixpanelinsightresult);
    // axios
    //   .post(`${base_url}${api.mixpanelinsightresult}`, {
    //     insight_report_name: data.name,

    //     sign_up_date: data.remaining.sign_up_date,
    //     last_order_type: data.remaining.last_order_type,
    //     user_id: data.remaining.user_id,
    //     name: data.remaining.name,
    //     gender: "Female",
    //     date_of_birth: data.remaining.birthdate,
    //     first_visit_date: data.remaining.sign_up_date,
    //     is_test_user: false,
    //     user_current_goal: data.remaining.user_current_goal,
    //     is_paid_user: false,
    //     reported_symptoms: data.remaining.reported_symptoms,
    //     reported_conditions: data.remaining.reported_conditions,
    //     is_ultrasound_done: data.remaining.is_ultrasound_done,
    //     is_birth_control: data.remaining.is_birth_control,
    //   })
    //   .then((response) => response.data)

    //   .catch(() => {});

    mixpanel.track("Insight Report Viewed", {
      insight_report_name: data.name,
    });
    FbDetailNew("Insight Report Viewed", {
      insight_report_name: data.name,
    });
  } catch {}
};
export const MixPanelResultClickExpanded = (data, callback) => {
  try {
    // Fb({
    //   insight_report_name: data.reportname,
    //   insight_section_name: data.name,

    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // },api.mixpanelinsightreportexpanded);
    // axios
    //   .post(`${base_url}${api.mixpanelinsightreportexpanded}`, {
    //     insight_report_name: data.reportname,
    //     insight_section_name: data.name,

    //     sign_up_date: data.remaining.sign_up_date,
    //     last_order_type: data.remaining.last_order_type,
    //     user_id: data.remaining.user_id,
    //     name: data.remaining.name,
    //     gender: "Female",
    //     date_of_birth: data.remaining.birthdate,
    //     first_visit_date: data.remaining.sign_up_date,
    //     is_test_user: false,
    //     user_current_goal: data.remaining.user_current_goal,
    //     is_paid_user: false,
    //     reported_symptoms: data.remaining.reported_symptoms,
    //     reported_conditions: data.remaining.reported_conditions,
    //     is_ultrasound_done: data.remaining.is_ultrasound_done,
    //     is_birth_control: data.remaining.is_birth_control,
    //   })
    //   .then((response) => response.data)

    //   .catch(() => {});

    mixpanel.track("Insight Section Tapped", {
      insight_section_name: data.reportname,
      insight_type: data.name,
    });
    FbDetailNew("Insight Section Tapped", {
      insight_section_name: data.reportname,
      insight_type: data.name,
    });
  } catch {}
};
export const MixPanelReferal = (data, callback) => {
  try {
    // Fb({
    //   cta_text: data.cta,
    //   screen_name: data.screen,

    //   sign_up_date: data.remaining.sign_up_date,
    //   last_order_type: data.remaining.last_order_type,
    //   user_id: data.remaining.user_id,
    //   name: data.remaining.name,
    //   gender: "Female",
    //   date_of_birth: data.remaining.birthdate,
    //   first_visit_date: data.remaining.sign_up_date,
    //   is_test_user: false,
    //   user_current_goal: data.remaining.user_current_goal,
    //   is_paid_user: false,
    //   reported_symptoms: data.remaining.reported_symptoms,
    //   reported_conditions: data.remaining.reported_conditions,
    //   is_ultrasound_done: data.remaining.is_ultrasound_done,
    //   is_birth_control: data.remaining.is_birth_control,
    // },api.mixpanelinsightreferal);
    // axios
    //   .post(`${base_url}${api.mixpanelinsightreferal}`, {
    //     cta_text: data.cta,
    //     screen_name: data.screen,

    //     sign_up_date: data.remaining.sign_up_date,
    //     last_order_type: data.remaining.last_order_type,
    //     user_id: data.remaining.user_id,
    //     name: data.remaining.name,
    //     gender: "Female",
    //     date_of_birth: data.remaining.birthdate,
    //     first_visit_date: data.remaining.sign_up_date,
    //     is_test_user: false,
    //     user_current_goal: data.remaining.user_current_goal,
    //     is_paid_user: false,
    //     reported_symptoms: data.remaining.reported_symptoms,
    //     reported_conditions: data.remaining.reported_conditions,
    //     is_ultrasound_done: data.remaining.is_ultrasound_done,
    //     is_birth_control: data.remaining.is_birth_control,
    //   })
    //   .then((response) => response.data)

    //   .catch(() => {});
    mixpanel.track("Referral Button Tapped", {
      cta_text: data.cta,
      screen_name: data.screen,
    });
    FbDetailNew("Referral Button Tapped", {
      cta_text: data.cta,
      screen_name: data.screen,
    });
  } catch {}
};
export const MixPanelOnload = (data, callback) => {
  try {
    // Fb({
    //   cta_text: data.cta_text,
    //   cta_position: data.cta_position,
    //   selected_test_package: data.cta_selected,
    // },api.mixpanelonload);
    // axios
    //   .post(`${base_url}${api.mixpanelonload}`, {
    //     cta_text: data.cta_text,
    //     cta_position: data.cta_position,
    //     selected_test_package: data.cta_selected,
    //   })
    //   .then((response) => response.data)

    //   .catch(() => {});

    mixpanel.track("Order Button Tapped", {
      cta_text: data.cta_text,
      cta_position: data.cta_position,
      selected_test_package: data.cta_selected,
    });
    FbDetailNew("Order Button Tapped", {
      cta_text: data.cta_text,
      cta_position: data.cta_position,
      selected_test_package: data.cta_selected,
    });
  } catch {}
};
export const MixPanelOtpTriggered = (data, callback) => {
  try {
    mixpanel.track("OTP Triggered", {
      ...data,
    });
    FbDetailNew("OTP Triggered", {
      ...data,
    });
  } catch {}
};
export const MixPanelOtpEntered = (data, callback) => {
  try {
    mixpanel.track("OTP Entered", {
      // ...data,
    });
    FbDetailNew("OTP Entered", {
      // ...data,
    });
  } catch {}
};
export const MixPanelSignInComplete = (data, callback) => {
  try {
    mixpanel.track("Sign In Completed", {
      // ...data,
      // is_first_time_user:false,
      // sign_in_method:""
      ...data,
    });
    FbDetailNew("Sign In Completed", {
      // ...data,
      // is_first_time_user:false,
      // sign_in_method:""
      ...data,
    });
  } catch {}
};
