import React from "react";
import BottomBtnSection from "../../NewIntake/components/BottomBtnSection";

export const BookSlotLabList = ({
  pagevalues,
  setpagevalues,
  setBeginBtn,
  setRangeCount,
  setPages,
  setBeginPage,
  singleBeginRange,
  AllData,
  FurtherDataMixPanel,
  lab_validation,
  booking_data,
  lab_selcted,
  setlab_selcted,
}) => {
  return (
    <>
      <div className="bookslot_body">
        <div className="container labs_container">
          <div className="labs_container_title">
            Choose Diagnostic <br></br> Partner
          </div>
          <div className="labs_container_desc">
            Product - {booking_data.product_name}
          </div>
          <div className="labs_container_desc">
            Choose a diagnostic partner that works for you. Subject to
            availability.
          </div>
          <div className="labs_inner_title">Diagnostic Partners</div>
          <div
            className="labs_inner_box"
            onClick={() =>
              lab_validation.thyrocare ? setlab_selcted("thyrocare") : ""
            }
          >
            <div className="labs_inner_box_inner">
              <div className="labs_inner_box_inner_title">
                Thyrocare Technologies
              </div>
              {!lab_validation.thyrocare ? (
                <div className="labs_inner_box_inner_rec">
                  Unavailable in your area
                </div>
              ) : (
                <div className="labs_inner_box_inner_rec">Recommended</div>
              )}
            </div>
            {
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.9984 21.6004C14.5445 21.6004 16.9863 20.589 18.7867 18.7886C20.587 16.9883 21.5984 14.5465 21.5984 12.0004C21.5984 9.45431 20.587 7.01251 18.7867 5.21217C16.9863 3.41182 14.5445 2.40039 11.9984 2.40039C9.45236 2.40039 7.01056 3.41182 5.21021 5.21217C3.40986 7.01251 2.39844 9.45431 2.39844 12.0004C2.39844 14.5465 3.40986 16.9883 5.21021 18.7886C7.01056 20.589 9.45236 21.6004 11.9984 21.6004ZM16.4468 10.4488C16.6654 10.2225 16.7864 9.91935 16.7836 9.60471C16.7809 9.29007 16.6547 8.9891 16.4322 8.76661C16.2097 8.54412 15.9088 8.41792 15.5941 8.41518C15.2795 8.41245 14.9764 8.5334 14.75 8.75199L10.7984 12.7036L9.24684 11.152C9.02052 10.9334 8.71739 10.8124 8.40276 10.8152C8.08812 10.8179 7.78715 10.9441 7.56466 11.1666C7.34217 11.3891 7.21596 11.6901 7.21323 12.0047C7.21049 12.3193 7.33145 12.6225 7.55004 12.8488L9.95004 15.2488C10.1751 15.4738 10.4802 15.6001 10.7984 15.6001C11.1166 15.6001 11.4218 15.4738 11.6468 15.2488L16.4468 10.4488Z"
                    fill={lab_selcted == "thyrocare" ? "#5F1D1B" : "#aeaeae"}
                  />
                </svg>
              </div>
            }
          </div>
          <div
            className="labs_inner_box inn_active"
            onClick={() =>
              lab_validation.redcliff ? setlab_selcted("redcliffe") : ""
            }
          >
            <div className="labs_inner_box_inner">
              <div className="labs_inner_box_inner_title">Redcliffe Labs</div>
              {!lab_validation.redcliff ? (
                <div className="labs_inner_box_inner_rec">
                  Unavailable in your area
                </div>
              ) : (
                ""
              )}
            </div>
            {
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.9984 21.6004C14.5445 21.6004 16.9863 20.589 18.7867 18.7886C20.587 16.9883 21.5984 14.5465 21.5984 12.0004C21.5984 9.45431 20.587 7.01251 18.7867 5.21217C16.9863 3.41182 14.5445 2.40039 11.9984 2.40039C9.45236 2.40039 7.01056 3.41182 5.21021 5.21217C3.40986 7.01251 2.39844 9.45431 2.39844 12.0004C2.39844 14.5465 3.40986 16.9883 5.21021 18.7886C7.01056 20.589 9.45236 21.6004 11.9984 21.6004ZM16.4468 10.4488C16.6654 10.2225 16.7864 9.91935 16.7836 9.60471C16.7809 9.29007 16.6547 8.9891 16.4322 8.76661C16.2097 8.54412 15.9088 8.41792 15.5941 8.41518C15.2795 8.41245 14.9764 8.5334 14.75 8.75199L10.7984 12.7036L9.24684 11.152C9.02052 10.9334 8.71739 10.8124 8.40276 10.8152C8.08812 10.8179 7.78715 10.9441 7.56466 11.1666C7.34217 11.3891 7.21596 11.6901 7.21323 12.0047C7.21049 12.3193 7.33145 12.6225 7.55004 12.8488L9.95004 15.2488C10.1751 15.4738 10.4802 15.6001 10.7984 15.6001C11.1166 15.6001 11.4218 15.4738 11.6468 15.2488L16.4468 10.4488Z"
                    // fill="#5F1D1B"
                    fill={lab_selcted == "redcliffe" ? "#5F1D1B" : "#aeaeae"}
                  />
                </svg>
              </div>
            }
          </div>
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Continue",
            onBtnClick: () => {
              setRangeCount(3);
              // setPages("mcycle");
              if (pagevalues.menstralstate == "I’m getting my period later") {
                setPages("commingperiod");
              } else {
                setPages("samplecolledate");
              }
              //   setPages("samplecolledate");
              // if (
              //   AllData?.last_order?.data?.endPoint ==
              //   "fertility-test-for-women"
              // ) {
              // } else {
              // }
            },
            goBackClick: () => {
              setBeginBtn(false);
              // setRangeCount(0);
              // setPages("begin");
              if (pagevalues.menstralstate == "I’m getting my period later") {
                setPages("mcycle");
              } else {
                setPages("addressedit");
              }
            },
          },
        }}
      />
    </>
  );
};
