import React, { useEffect, useRef, useState } from "react";
import { Faq } from "./Faq";
import { LevelList } from "./LevelList";
import Slider from "react-slick";
import { LevelListForBmi } from "./LevelListForBmi";
import { MixPanelResultClickExpanded } from "../API/automation_api";

export const BaseOnResult = ({
  list,
  accordianData,
  ControlList,
  setControlList,
  age,
  name,
  // PageValue,
  FullList,
  physicalsymptoms,
  FurtherDataMixPanel,
  reports,
}) => {
  const [TomorrayActive, setTomorrayActive] = useState(false);
  const [ArrayForHgighLow, setArrayForHgighLow] = useState([]);

  var Section = {
    dots: false,
    infinite: false,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    arrows: false,
    focusOnSelect: false,
    variableWidth: true,
  };
  const SliderMenu = useRef();
  const [FilterPcos, setFilterPcos] = useState([]);
  const [CountBoxes, setCountBoxes] = useState(1);
  useEffect(() => {
    const filteredArray1 = reports[0].biomarker?.filter((item1) => {
      return ControlList?.relatedBiomarkers?.some(
        (item2) => item2.nameShort === item1.short_name
      );
    });
    setFilterPcos(filteredArray1);
  }, [ControlList, reports]);

  console.log({ FilterPcos });
  console.log(ControlList?.relatedBiomarkers);
  console.log(reports[0].biomarker);
  useEffect(() => {
    setControlList(list[CountBoxes - 1]);
    MixPanelResultClickExpanded({
      reportname: name,
      name: list[CountBoxes - 1]?.name,
      remaining: FurtherDataMixPanel,
    });
  }, [CountBoxes]);
  return (
    <>
      <div className="baseonresult_main">
        <div className="baseonresult_title">Based on your results</div>
        <div className="baseonresult_list">
          <Slider
            ref={SliderMenu}
            {...{
              ...Section,
              // afterChange: (i) => setControlList(list[i] || {}),
            }}
          >
            {list?.map((el, i) => (
              <div>
                <div
                  key={i}
                  className={
                    "baseonresult_list_box " +
                    (ControlList.name === el.name
                      ? " baseonresult_list_box_active"
                      : "")
                  }
                  onClick={() => {
                    setControlList(el);
                    setCountBoxes(i + 1);
                    MixPanelResultClickExpanded({
                      reportname: name,
                      name: el.name,
                      remaining: FurtherDataMixPanel,
                    });
                  }}
                >
                  <div className="svg_holder">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10.8333 5.8335H17.5M17.5 5.8335V12.5002M17.5 5.8335L10.8333 12.5002L7.5 9.16683L2.5 14.1668"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  {el.name}
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="baseonresult_accordain">
          <Faq
            {...{
              openAcc: true,
              unique: "test_base_result",
              titleDis: false,
              data: [
                ...accordianData.map((el, i) => ({
                  que: el.name,
                  ans: el.desc,
                })),
              ],
            }}
          />
        </div>
        <div className="baseonresult_related_physics">
          <div className="baseonresult_related_physics_title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M16.9619 12.7952C15.5979 13.3437 14.1028 13.4793 12.6625 13.185C11.2221 12.8907 9.89994 12.1796 8.8604 11.1401C7.82086 10.1006 7.10976 8.77842 6.81549 7.33804C6.52122 5.89766 6.65676 4.40255 7.20526 3.03857C5.59002 3.68918 4.25139 4.8814 3.41886 6.41084C2.58633 7.94028 2.31175 9.71169 2.64219 11.4214C2.97262 13.1311 3.88749 14.6726 5.22997 15.7817C6.57244 16.8908 8.25891 17.4984 10.0003 17.5002C11.4977 17.5003 12.9609 17.0522 14.2015 16.2137C15.4422 15.3753 16.4036 14.1847 16.9619 12.7952Z"
                stroke="#777777"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div>RELATED PHYSICAL SYMPTOMS</div>
          </div>
          <div className="baseonresult_related_physics_list">
            {physicalsymptoms?.map((el, i) => (
              <div key={i} className="baseonresult_related_physics_list_box">
                {el}
              </div>
            ))}
          </div>
        </div>
        {/* <LevelListForBmi
          {...{
            title: "",
            titleDate: "",
            unique_id: "forbmi3",
            pagevalues: FullList?.last_intake?.data,
            list: [
              ...FullList?.last_order?.order_details?.level_list
                ?.filter((el1) => el1.nameShort === "BMI")
                .map((el) => ({
                  name: el.nameShort,
                  desc: el.name,
                  color: "#F05E45",
                  count: el.count,
                  count_qunt: el?.count_qunt,
                  range: "",
                  range_list: [{ unit: "kg/m2" }],
                  desc2: el.description,
                })),
            ],
            AccordainWork: false,
            setArrayForHgighLow: setArrayForHgighLow,
            ArrayForHgighLow: ArrayForHgighLow,
          }}
          /> */}
        <LevelList
          {...{
            title: "",
            titleDate: "",
            age: age,
            unique_id: "forbmi3",
            screen: "Signs of PCOS",
            list: ControlList.relatedBiomarkers
              ? [
                  ...reports[0].biomarker
                    ?.filter((el1) => el1.biomarkerCode === "BMI")
                    ?.map((el, i) => ({
                      ...el,
                      // name: el.short_name,
                      desc: el.value_desc,
                      desc2: el.desc,
                      color: "",
                      count: el.value,

                      // ??
                      score: el?.score
                        ? `${el?.score[0].toUpperCase()}${el?.score.slice(1)}`
                        : "Pending",
                      insights_list: el.insights,
                      range_list: el.labRange,
                      // ??
                    })),
                ]
              : [],
            AccordainWork: false,
            setArrayForHgighLow: setArrayForHgighLow,
            ArrayForHgighLow: ArrayForHgighLow,
          }}
        />

        <div className="related_level">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10.8333 5.8335H17.5M17.5 5.8335V12.5002M17.5 5.8335L10.8333 12.5002L7.5 9.16683L2.5 14.1668"
              stroke="#777777"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div>RELATED LEVELS</div>
        </div>
        <LevelList
          {...{
            title: "",
            titleDate: "",
            age: age,
            screen: "Signs of PCOS",
            list: ControlList.relatedBiomarkers
              ? [
                  ...FilterPcos?.map((el, i) => ({
                    ...el,
                    // name: el.short_name,
                    desc: el.value_desc,
                    desc2: el.desc,
                    color: "",
                    count: el.value,

                    // ??
                    score: el?.score
                      ? `${el?.score[0].toUpperCase()}${el?.score.slice(1)}`
                      : "Pending",
                    insights_list: el.insights,
                    range_list: el.labRange,
                    // ??
                  })),
                ]
              : [],
            AccordainWork: false,
            setArrayForHgighLow: setArrayForHgighLow,
            ArrayForHgighLow: ArrayForHgighLow,
          }}
        />
        {ControlList.tip ? (
          <div className="desc_baseresult">{ControlList?.tip}</div>
        ) : (
          "   "
        )}
        <div className="prev_next">
          <div
            className={
              "prev_btn " + (CountBoxes === 1 ? "" : "active_prev_btn")
            }
            onClick={() => {
              // setTomorrayActive(true);
              // if (CountBoxes != 1) {
              //   SliderMenu.current.slickPrev();
              // }
              setCountBoxes((prev) => (1 === prev ? prev : prev - 1));
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M12.5 5L7.5 10L12.5 15"
                stroke="#C5C5C5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div>Previous</div>
          </div>
          <div
            className={
              "next_btn " +
              (CountBoxes === list.length ? "" : "active_prev_btn")
            }
            onClick={() => {
              // setTomorrayActive(false);
              // if (CountBoxes != list.length) {
              //   SliderMenu.current.slickNext();
              // }
              setCountBoxes((prev) => (list.length === prev ? prev : prev + 1));
            }}
          >
            <div>Next </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M7.5 15L12.5 10L7.5 5"
                stroke="#C5C5C5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};
