import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { Icons } from "../../component/Icons/Icons";
import { Checkbox } from "../../component/Checkbox/Checkbox";
import {
  AddDiscount,
  DeleteDicount,
  EditDiscount,
} from "../../component/API/api";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { Input } from "../../component/Input";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const TCreateDiscount = ({
  setCreateProduct,
  setloading,
  setstore,
  store,
  edit,
  close,
  current_selected_item,
  Products,
  active,
  discounts,
}) => {
  const [GETChange, setGETChange] = useState("");
  const [page_value, setPage_value] = useState({
    active: true,
    name: "",
    discountvalue: "",
    quantity: "",
    // products: "All",
    products: ["All"],
    percentage: true,
    fixedamount: false,
    limitednumbercode: false,
    limitedoneuser: false,
  });
  const [InputError, setInputError] = useState({
    name: "",
    discountvalue: "",
    quantity: "",
    products: "",
  });
  // const currencies = [
  //   "All",
  //   "All",
  //   "All",
  //   "All",
  //   "All",
  //   "All",
  //   "All",
  //   "All",
  //   "All",
  //   // {
  //   //   value: "Data",
  //   //   label: "Data",
  //   // },
  //   // {
  //   //   value: "Data2",
  //   //   label: "Data2",
  //   // },
  // ];
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  useEffect(() => {
    if (edit) {
      //
      const data = {
        active: current_selected_item.active,
        name: current_selected_item.name,
        discountvalue: current_selected_item.discountvalue,
        quantity: current_selected_item.quantity,
        products: current_selected_item.products,
        percentage: current_selected_item.percentage,
        fixedamount: current_selected_item.lfixedamount,
        limitednumbercode: current_selected_item.limitednumbercode,
        limitedoneuser: current_selected_item.limitedoneuser,
      };
      setPage_value({ ...data });
      setGETChange(JSON.stringify({ ...data }));
    } else {
      setGETChange(JSON.stringify({ ...page_value }));
    }
  }, []);
  //
  const handle_pagevalues = (updated_data) => {
    return setPage_value((prev) => ({ ...prev, ...updated_data }));
  };
  const handle_errpagevalues = (updated_data) => {
    return setInputError((prev) => ({ ...prev, ...updated_data }));
  };
  return (
    <>
      <div className={"create_discount " + (active && "active")}>
        <div className="header">
          <div
            className="btn-back"
            onClick={() => {
              setCreateProduct(false);
              close();
            }}
          >
            <Icons img_var={"arrowleft"} />
          </div>
          <div className="title">
            {edit ? "Edit Discount Code" : "Create discount code"}
          </div>
        </div>
        <div className="creatediscount">
          <div className="innercreate_discount">
            <div className="active_or_dactive">
              <div>This discount is</div>
              <div className="activeaa">
                {page_value.active ? "active" : "deactivated"}.
              </div>
              <div
                className={
                  "active_deactivebtn" + (page_value.active ? "" : " deactive")
                }
                onClick={() =>
                  handle_pagevalues({ active: !page_value.active })
                }
              >
                {!page_value.active ? "Active" : "Deactivate"}
              </div>
            </div>
            <div className="line"></div>
            <div className="ReturnURL">
              <div className="ReturnURL_title">Discount code</div>
              {/* <TextField
                className={"cust_input" + (InputError.name ? " inp_error" : "")}
                placeholder="DISCOVER10"
                variant="outlined"
                error={InputError.name}
                value={page_value.name}
                onChange={({ target }) => {
                  handle_pagevalues({ name: target.value.toUpperCase() });
                  handle_errpagevalues({
                    name: edit
                      ? !target.value ||
                        discounts.filter(
                          (el) =>
                            el.name == target.value.toUpperCase() &&
                            current_selected_item.id != el.id
                        ).length
                      : !target.value ||
                        discounts.filter(
                          (el) => el.name == target.value.toUpperCase()
                        ).length,
                  });
                }}
                style={{
                  color: "blue",
                }}
              /> */}
              <Input
                {...{
                  attr: {
                    error: InputError.name,
                    error_msg: InputError.name,
                    value: page_value.name,
                    event: {
                      change: ({ target }) => {
                        // if (page_value.urlSlug.length > target.value.length) {
                        //   handle_pagevalues({
                        //     urlSlug: target.value.trim().toLowerCase(),
                        //   });

                        // }
                        handle_pagevalues({ name: target.value.toUpperCase() });
                        handle_errpagevalues({
                          name: target.value
                            ? edit
                              ? !target.value ||
                                discounts.filter(
                                  (el) =>
                                    el.name == target.value.toUpperCase() &&
                                    current_selected_item.id != el.id
                                ).length
                              : !target.value ||
                                discounts.filter(
                                  (el) => el.name == target.value.toUpperCase()
                                ).length
                              ? "Already Exist"
                              : ""
                            : "Required",
                        });
                      },
                    },
                  },
                  data: {
                    title: "DISCOVER10",
                  },
                }}
              />
              <div className="asd">
                Customers will enter this code at checkout.
              </div>
            </div>
            <div className="type">
              <div className="type_title">Type</div>
              <div
                className="per checkbox_group"
                onClick={() =>
                  setPage_value((prev) => ({
                    ...prev,
                    percentage: true,
                    discountvalue:
                      prev.discountvalue >= 100 ? 100 : prev.discountvalue,
                  }))
                }
              >
                <Checkbox type={"type2"} active={page_value.percentage} />
                <div>Percentage</div>
              </div>
              <div
                className="per checkbox_group"
                onClick={() =>
                  setPage_value((prev) => ({
                    ...prev,
                    percentage: false,
                  }))
                }
              >
                <Checkbox type={"type2"} active={!page_value.percentage} />
                <div>Fixed amount</div>
              </div>
              <div className="appled">
                <div className="in">Applies to:</div>
                <div
                  className="gin"
                  onClick={() => {
                    if (page_value.products != ["All"]) {
                      handle_pagevalues({ products: ["All"] });
                    }
                  }}
                >
                  {page_value.products[0] == "All" ? "" : "Select all"}
                </div>
              </div>
              {/* <TextField
                className={
                  "cust_input" + (InputError.products ? " inp_error" : "")
                }
                select
                label="Products"
                variant="filled"
                error={InputError.products}
                value={page_value.products}
                onChange={({ target }) => {
                  
                  handle_pagevalues({ products: target.value });
                  handle_errpagevalues({ products: !target.value });
                }}
                // inputProps
                style={{
                  color: "blue",
                }}
              >
                {Products?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField> */}
              {/* <FormControl style={{ margin:"" , width: "100%" }}> */}
              {/* <InputLabel id="demo-multiple-chip-label">Chip</InputLabel> */}
              <div
                className={
                  "cust_input" + (InputError.products ? " inp_error" : "")
                }
                style={{ width: "100%" }}
              >
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  style={{ width: "100%" }}
                  value={page_value.products}
                  onChange={({ target }) => {
                    //
                    let temperaryarray = target.value.filter(
                      (el) => el != "All"
                    );
                    temperaryarray = temperaryarray.length
                      ? temperaryarray
                      : ["All"];
                    handle_pagevalues({ products: temperaryarray });
                    handle_errpagevalues({ products: !temperaryarray });
                  }}
                  // input={
                  //   <OutlinedInput id="select-multiple-chip" label="Chip" />
                  // }
                  renderValue={(selected) => {
                    //
                    return (
                      <div
                        style={{ display: "flex", flexWrap: "wrap", gap: 10 }}
                      >
                        {selected.length == 1 && selected[0] == "All" ? (
                          <div style={{ padding: "5px" }}>All</div>
                        ) : (
                          selected.map((value) => (
                            <>
                              <div className="chips">
                                {
                                  Products.filter((el) => el.value == value)[0]
                                    ?.label
                                }
                              </div>
                              {/* <Chip
                              key={value}
                              label={
                                Products.filter((el) => el.value == value)[0]
                                ?.label
                              }
                              /> */}
                            </>
                          ))
                        )}
                      </div>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  {Products.map((el) => (
                    <MenuItem
                      key={el.value}
                      value={el.value}
                      // style={getStyles(name, personName, theme)}
                    >
                      {el.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {/* </FormControl> */}
            </div>
            <div className="ReturnURL">
              <div className="ReturnURL_title">Discount value</div>
              <TextField
                className={
                  "cust_input" + (InputError.discountvalue ? " inp_error" : "")
                }
                placeholder="10"
                variant="outlined"
                error={InputError.discountvalue}
                value={page_value.discountvalue}
                onChange={({ target }) => {
                  if (isNaN(Number(target.value))) {
                    return;
                  }

                  handle_pagevalues({
                    discountvalue: page_value.percentage
                      ? target.value >= 100
                        ? 100
                        : target.value
                      : target.value,
                  });
                  handle_errpagevalues({ discountvalue: !target.value });
                }}
                style={{
                  color: "blue",
                }}
              />
              {/* <Input
                {...{
                  attr: {
                    error: InputError.discountvalue,
                    error_msg: InputError.discountvalue,
                    value: page_value.discountvalue,
                    event: {
                      change: ({ target }) => {
                        // if (page_value.urlSlug.length > target.value.length) {
                        //   handle_pagevalues({
                        //     urlSlug: target.value.trim().toLowerCase(),
                        //   });
                        if (isNaN(Number(target.value))) {
                          return;
                        }
                        // }
                        handle_pagevalues({
                          discountvalue: page_value.percentage
                            ? target.value >= 100
                              ? 100
                              : target.value
                            : target.value,
                        });
                        handle_errpagevalues({
                          discountvalue: !target.value ? "Required" : "",
                        });
                      },
                    },
                  },
                  data: {
                    title: "",
                  },
                }}
              /> */}
              <div className="asd">
                Applies to all products and entire order.
              </div>
            </div>
            <div className="usage_limit">
              <div className="usage_title">Usage limits</div>
              <div
                className="listofnum"
                onClick={() => {
                  handle_pagevalues({ quantity: 0 });
                  setPage_value((prev) => ({
                    ...prev,
                    // section: {
                    //   ...prev.section,
                    limitednumbercode: !prev.limitednumbercode,
                    // },
                  }));
                }}
              >
                <Checkbox
                  active={page_value.limitednumbercode}
                  type={"type1"}
                />
                <div>Limit number of times discount code can be used</div>
              </div>

              <TextField
                className={
                  "cust_input"
                  //  + (InputError.quantity ? " inp_error" : "")
                }
                placeholder="Quantity / 100"
                variant="outlined"
                // error={InputError.quantity}
                value={page_value.quantity}
                onChange={({ target }) => {
                  if (isNaN(Number(target.value))) {
                    return;
                  }
                  if (!page_value.limitednumbercode) {
                    return;
                  }
                  handle_pagevalues({ quantity: parseInt(target.value || 0) });
                  // handle_errpagevalues({ quantity: !target.value });
                }}
                // inputProps
                style={{
                  color: "blue",
                }}
              />
              <div
                className="listofnum"
                onClick={() =>
                  setPage_value((prev) => ({
                    ...prev,
                    // section: {
                    //   ...prev.section,
                    limitedoneuser: !prev.limitedoneuser,
                    // },
                  }))
                }
              >
                <Checkbox active={page_value.limitedoneuser} type={"type1"} />
                <div>Limit to one use per customer</div>
              </div>
            </div>
            <div className="lastsextion">
              {edit ? (
                <div
                  className="delebtn"
                  onClick={() => {
                    DeleteDicount(
                      {
                        id: current_selected_item.id,
                      },
                      store.token,
                      (res) => {
                        setloading(false);
                        //
                        if (!res.status) {
                          return toast(res.response);
                        }
                        close();
                        // setCreateProduct(false);
                      }
                    );
                  }}
                >
                  Delete
                </div>
              ) : (
                ""
              )}

              <div
                className={
                  JSON.stringify({ ...page_value }) != GETChange
                    ? "savebtn opas"
                    : "savebtn"
                }
                onClick={() => {
                  if (JSON.stringify({ ...page_value }) == GETChange) {
                    return;
                  }
                  if (
                    page_value.name == "" ||
                    discounts.filter(
                      (el) =>
                        el.name == page_value.name &&
                        current_selected_item.id != el.id
                    ).length == 1 ||
                    page_value.discountvalue == ""
                  ) {
                    setInputError((prev) => ({
                      ...prev,
                      name:
                        page_value.name == "" ||
                        discounts.filter((el) => el.name == page_value.name)
                          .length == 1
                          ? "Required name"
                          : "",
                      discountvalue: page_value.discountvalue == "",
                    }));

                    return;
                  }

                  if (edit) {
                    EditDiscount(
                      {
                        id: current_selected_item.id,
                        active: page_value.active,
                        name: page_value.name,
                        percentage: page_value.percentage,
                        products: page_value.products,
                        discountvalue: page_value.discountvalue,
                        limitednumbercode: page_value.limitednumbercode,
                        limitedoneuser: page_value.limitedoneuser,
                        quantity: page_value.quantity,
                      },
                      store.token,
                      (res) => {
                        setloading(false);
                        //
                        if (!res.status) {
                          return toast(res.response);
                        }
                        close();
                        // setCreateProduct(false);
                      }
                    );
                  } else {
                    setloading(true);
                    AddDiscount(
                      {
                        active: page_value.active,
                        name: page_value.name,
                        percentage: page_value.percentage,
                        products: page_value.products,
                        discountvalue: page_value.discountvalue,
                        limitednumbercode: page_value.limitednumbercode,
                        limitedoneuser: page_value.limitedoneuser,
                        quantity: page_value.quantity,
                      },
                      store.token,
                      (res) => {
                        setloading(false);
                        //
                        if (!res.status) {
                          return toast(res.response);
                        }
                        close();
                        // setCreateProduct(false);
                      }
                    );
                  }
                }}
              >
                Save
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
