import React from "react";

export const ImagesCol = () => {
  return (
    <>
      <div className="images_collection">
        <img src="https://imgur.com/dPgEASX.jpeg"></img>
      </div>
    </>
  );
};
