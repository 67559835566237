import React, { useEffect, useState } from "react";
import { ProfileSection } from "./ProfileSection";
import { SmallSizeCard } from "./SmallSizeCard";
import { useNavigate } from "react-router-dom";
import { ClinicCard } from "./ClinicCard";
import { filter } from "lodash";
// import { fertility_main } from "../Checkout";

export const AllBlogsMaterial = ({
  type,
  obj,
  setFertility_type,
  setTrackFertility,
  FurtherDataMixPanel,
  UniqueList,
}) => {
  const [articles, setarticles] = useState({
    main: [],
    data: {},
  });
  // useEffect(() => {
  //   if (UniqueList) {
  //     let filtered = {};
  //     UniqueList.map((item) => (filtered[item] = []));
  //     obj.map(
  //       (el) => (filtered[el.description] = [...filtered[el.description], el])
  //     );
  //     setarticles({
  //       main: UniqueList,
  //       data: filtered,
  //     });
  //     console.log(filtered);
  //   }
  //   return () => {};
  // }, [UniqueList]);
  //
  const fertility_main = "fertility-test-for-women";
  const fertility_amh = "fertility-test-for-women-starter";
  //
  const navigate = useNavigate();
  function asset(url) {
    if (url.match("https")) {
      return url;
    }
    if (url) {
      let a = url.split("-");
      return `https://cdn.sanity.io/${a[0]}s/nao51hyy/production/${a
        .slice(1, -1)
        .join("-")}.${a[a.length - 1]}`;
    }
    return "";
  }
  switch (type) {
    case "header":
      return (
        <div
          style={{
            color: obj?.color,
          }}
          className="header"
        >
          {obj?.data}
        </div>
      );
    case "desc":
      return (
        <div
          style={{
            color: obj?.color,
          }}
          className="desc"
        >
          {obj.data}
        </div>
      );
    case "span":
      return (
        <span
          style={{
            color: obj?.color,
          }}
          className="desc"
        >
          {obj.text}
        </span>
      );
    case "chips":
      return (
        <div
          style={{
            color: obj?.color,
          }}
          className="chips"
        >
          {obj.data}
        </div>
      );
    case "sidelinedesc":
      return (
        <div
          style={{
            color: obj?.color,
          }}
          className="sidelinedesc"
        >
          {obj.data}
        </div>
      );
    case "headerbig":
      return (
        <div
          style={{
            color: obj?.color,
          }}
          className="header"
        >
          {obj.data}
        </div>
      );
    case "headerSmall":
      return (
        <div
          style={{
            color: obj?.color,
          }}
          className="headerSmall"
        >
          {obj.data}
        </div>
      );

    case "card_type1":
      return (
        <div className="card_type1_box">
          <div className="card_type1_box_btn">{obj?.inner_box}</div>
          <img src={obj?.img} />
          <div className="card_type1_box_inner">
            <div className="card_type1_box_title">{obj?.title}</div>
            <div className="card_type1_box_desc">{obj?.desc}</div>
            <div className="profile_section">
              <ProfileSection
                {...{
                  obj: obj,
                }}
              />
            </div>
          </div>
        </div>
      );
    case "card_type2_list": {
      return (
        <div
          className={
            "card_type2_box_list " +
            (obj?.length === 1 ? " card_type2_box_list_active" : "")
          }
        >
          {obj?.map((el, i) => (
            <SmallSizeCard
              {...{
                obj: el,
                setFertility_type: setFertility_type,
                setTrackFertility: setTrackFertility,
                FurtherDataMixPanel: FurtherDataMixPanel,
              }}
              key={i}
            />
          ))}
        </div>
      );
    }
    case "clinic_list":
      return (
        <div
          className={
            "card_type2_box_list clinic_card_list " +
            (obj?.length === 1 ? " card_type2_box_list_active" : "")
          }
        >
          {obj?.map((el, i) => (
            <ClinicCard
              {...{
                type: "clinic",
                obj: el,
                setFertility_type: setFertility_type,
                setTrackFertility: setTrackFertility,
                FurtherDataMixPanel: FurtherDataMixPanel,
              }}
              key={i}
            />
          ))}
        </div>
      );
    case "profile":
      return (
        <ProfileSection
          {...{
            obj: obj.data,
          }}
        />
      );
    case "imgstand":
      return (
        <div className="img_stand_box">
          <img src={asset(obj?.data?.img)} />
          {obj?.data?.desc && (
            <div className="img_stand_box_desc">{obj?.data?.desc}</div>
          )}
        </div>
      );
    case "horizontal_card":
      return (
        <div className="horizontal_card">
          <div
            className="horizontal_card_img"
            style={{ backgroundImage: `url(${obj?.data?.img})` }}
          >
            {/* <img src={obj?.data?.img} /> */}
          </div>
          <div className="horizontal_card_desc">
            <span className="horizontal_card_desc_quest">
              {obj?.data?.quet}
            </span>{" "}
            <span className="horizontal_card_desc_ans">{obj?.data?.ans}</span>
            <div
              className="horizontal_card_btn"
              onClick={() => {
                // navigate(`/${fertility_main}`,"_blank");
                window.open("https://www.arva.health/");
              }}
            >
              Learn more
            </div>
          </div>
        </div>
      );
    default:
  }
};
