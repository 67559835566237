import React from "react";
import { date_formatter } from "../../component/CustInput/CustInput";
import { useSelector } from "react-redux";

export const LavelReport = ({ AllData }) => {
  const store = useSelector((state) => state);
  console.log(store);
  return (
    <>
      <div className="lavelreport_main">
        {/* <div className="lavelreport_main_title">Lab reports</div> */}
        {[...store?.checktoken_data?.reports]?.filter(
          (el1) => el1.report_url != ""
        ).length == 0 ? (
          <div className="lavelreport_main_inner_no_data">
            Nothing to show! Once your lab reports become available, they will
            be displayed here.
          </div>
        ) : (
          [...store?.checktoken_data?.reports]
            ?.filter((el1) => el1.report_url != "")
            ?.map((el) =>
              el.report_url ? (
                <div className="lavelreport_main_inner">
                  <div>
                    <div className="lavelreport_main_date">
                      {date_formatter(
                        new Date(el?.date),
                        "date/month/year_long"
                      )}
                    </div>
                    <div className="lavelreport_main_desc">
                      {el?.order_id?.product_name}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      window.open(el.report_url);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      style={{ cursor: "pointer" }}
                    >
                      <path
                        d="M12 10V16M12 16L9 13M12 16L15 13M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H12.586C12.8512 3.00006 13.1055 3.10545 13.293 3.293L18.707 8.707C18.8946 8.89449 18.9999 9.1488 19 9.414V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
                        stroke="#1D1D1D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                ""
              )
            )
        )}
      </div>
    </>
  );
};
