import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { checkToken } from "../../component/API/new_api";
import { MixPanelUsertype } from "../../component/API/automation_api";

const AutoLogin = () => {
  const [data, setdata] = useState({
    token: "",
    redirect: "",
  });
  const store = useSelector((state) => state);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let search = window.location.search
      .slice(1)
      .split("&")
      .reduce((acc, curr) => {
        let t = curr.split("=");
        acc[t[0]] = t[1];
        return acc;
      }, {});
    setdata({
      token: search?.token || "",
      redirect: search?.redirect || "",
    });
    checkToken(
      search?.token || "",
      (success) => {
        MixPanelUsertype(success);
        dispatch({
          type: "setCheckToken",
          payload: { ...success, token: search.token },
        });
        localStorage.setItem("user_token_home", search.token);
        delete search.token;
        localStorage.setItem("user_data_to_show", JSON.stringify(search));
        // if (search.redirect == "intake") {
        // }
        if (success.customer.intake) {
          return navigate("/" + search?.goTo || "home");
        }
        navigate("/intake");
      },
      (err) => {}
    );
  }, []);

  return (
    <div>
      <div className="loader">
        <div className="loader_main">
          <div className="svg_floater_main">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="65"
              viewBox="0 0 64 65"
              fill="none"
            >
              <path
                d="M64 32.9972C64 50.6712 49.674 64.9972 32 64.9972C14.326 64.9972 0 50.6712 0 32.9972C0 15.3232 14.326 0.997192 32 0.997192V4.99719C16.536 4.99719 4 17.5332 4 32.9972C4 48.4612 16.536 60.9972 32 60.9972C47.464 60.9972 60 48.4612 60 32.9972H64Z"
                fill="#5F1D1B"
                // fill="#1D1D1D"
              />
            </svg>
          </div>
          {window.location.pathname.split("/")[1] !== "admin" && true && (
            <>
              {/* <div className="txt1">Your order’s being processed.</div> */}
              <div className="txt1">Your order is being processed.</div>
              {/* <div className="txt2">You will be automatically redirected.</div> */}
              <div className="txt2">Please do not close the page.</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AutoLogin;
