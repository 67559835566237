import React, { useEffect, useState } from "react";
import { BottomNav } from "../../../component/BottomNav";
import { checkToken, dateFormatter } from "../../../component/API/new_api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  MixPanelArticle,
  MixPanelUsertype,
} from "../../../component/API/automation_api";
import { Loader } from "../../../component/Loader";
import { ReactComponent as Logo } from "../../../img/ARVA.svg";

const CareHistory = ({ ProductImages }) => {
  const store = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orders, setorders] = useState([]);
  const [loading, setloading] = useState(false);

  const check_user_data = (token) => {
    setloading(true);
    checkToken(
      token || "",
      (success) => {
        if (success.customer == null) {
          setloading(false);
          navigate("/404");
        }
        MixPanelUsertype(success);
        dispatch({
          type: "setCheckToken",
          payload: { ...success, token: token },
        });
        let ids = [];
        let order_ids = {};
        success.orderItems.filter((item) => {
          // console.log("item =>", item);
          if (
            item.product_status.toLowerCase() == "order completed"
            // ||
            // item.product_status.toLowerCase() == "reports published"
            //  ||
            // item?.appointment[0]?.isCompleted
          ) {
            ids.push(item.orderId);
            order_ids[item.orderId] = [
              ...(order_ids[item.orderId] || []),
              item,
            ];
          } else if (["consult", "coaching"].includes(item.product_type)) {
            //   console.log("passed",item);
            ids.push(item.orderId);
            item.appointment.map((el) => {
              if (el?.isCompleted) {
                let c_item = {
                  ...item,
                  appointment: el,
                };
                order_ids[item.orderId] = order_ids[item.orderId]
                  ? [...order_ids[item.orderId], c_item]
                  : [c_item];
              }
            });
            // order_ids[item.orderId] = [
            //   ...(order_ids[item.orderId] || []),
            //   // item.appointment
            //   //   .filter((el1) => el1.isCompleted)
            //   //   .map((el) => ({
            //   ...item,
            //   //     ...el,
            //   //   })),
            // ].filter((el) => el);
          }
        });
        console.log(ids);
        ids = [...new Set(ids)];
        ids.sort((a, b) => b - a);
        setorders(
          ids.map((el) => ({
            orderId: el,
            items: order_ids[el] || [],
          }))
        );

        setloading(false);
        // setorders(t_completed);
      },
      (err) => {}
    );
  };
  useEffect(() => {
    setloading(true);
    if (store.checktoken_data.token) {
      check_user_data(store.checktoken_data.token);
    } else {
      const local_token = localStorage.getItem("user_token_home");
      if (local_token) {
        check_user_data(local_token);
      } else {
        setloading(false);
        // window.open("http://localhost:3000/", "_self");
      }
    }
    return () => {};
  }, [store.checktoken_data.token]);
  // console.table(orders[1]?.items);
  console.log({ ProductImages });
  if (loading) {
    return "";
  }
  return (
    <div className="care_history">
      <div className="container">
        {/* <div className="title">
          <div className="go_back" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M8.33333 15.8346L2.5 10.0013M2.5 10.0013L8.33333 4.16797M2.5 10.0013H17.5"
                stroke="#5F1D1B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          Care History
        </div> */}
        <div className="carehistory_header">
          <div className="container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              onClick={() => {
                navigate(-1);
              }}
            >
              <path
                d="M13.1625 15.5876C13.3386 15.7637 13.4375 16.0026 13.4375 16.2516C13.4375 16.5007 13.3386 16.7396 13.1625 16.9157C12.9863 17.0918 12.7475 17.1908 12.4984 17.1908C12.2493 17.1908 12.0105 17.0918 11.8343 16.9157L5.58433 10.6657C5.49693 10.5786 5.42759 10.4751 5.38027 10.3611C5.33295 10.2472 5.30859 10.125 5.30859 10.0016C5.30859 9.87824 5.33295 9.75606 5.38027 9.64211C5.42759 9.52815 5.49693 9.42466 5.58433 9.33756L11.8343 3.08756C12.0105 2.91144 12.2493 2.8125 12.4984 2.8125C12.7475 2.8125 12.9863 2.91144 13.1625 3.08756C13.3386 3.26368 13.4375 3.50255 13.4375 3.75163C13.4375 4.0007 13.3386 4.23957 13.1625 4.41569L7.5773 10.0008L13.1625 15.5876Z"
                fill="#5F1D1B"
              />
            </svg>
            <Logo
              onClick={() => {
                navigate("/home");
              }}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M13.1625 15.5876C13.3386 15.7637 13.4375 16.0026 13.4375 16.2516C13.4375 16.5007 13.3386 16.7396 13.1625 16.9157C12.9863 17.0918 12.7475 17.1908 12.4984 17.1908C12.2493 17.1908 12.0105 17.0918 11.8343 16.9157L5.58433 10.6657C5.49693 10.5786 5.42759 10.4751 5.38027 10.3611C5.33295 10.2472 5.30859 10.125 5.30859 10.0016C5.30859 9.87824 5.33295 9.75606 5.38027 9.64211C5.42759 9.52815 5.49693 9.42466 5.58433 9.33756L11.8343 3.08756C12.0105 2.91144 12.2493 2.8125 12.4984 2.8125C12.7475 2.8125 12.9863 2.91144 13.1625 3.08756C13.3386 3.26368 13.4375 3.50255 13.4375 3.75163C13.4375 4.0007 13.3386 4.23957 13.1625 4.41569L7.5773 10.0008L13.1625 15.5876Z"
                fill="#5F1D1B"
              />
            </svg>
          </div>
        </div>
        {!loading ? (
          <>
            {orders.map((el) =>
              el?.items?.length ? (
                <>
                  <div className="datetime">
                    <div className="time">
                      {el?.items[0]?.orderDate
                        ? dateFormatter(
                            el?.items[0]?.orderDate
                              .split("/")
                              .reverse()
                              .join("-"),
                            "MMS DD,YYYY"
                          )
                        : "asd"}
                    </div>
                    <div className="orderid">AH{el.orderId}</div>
                  </div>
                  {el.items.map((el) => (
                    <div
                      className="item"
                      onClick={() => {
                        if (el.product_type == "guide") {
                          console.log("guide");
                          MixPanelArticle({
                            content_name: "comprehensive-egg-freezing-guide",
                            screen_name: "Care History",
                            section_name: "Care History",
                            is_paid_content: true,
                            content_type: "Guide",
                          });
                          navigate(`/guide/comprehensive-egg-freezing-guide`);
                        }
                      }}
                    >
                      <div
                        className="image"
                        style={{
                          backgroundImage: `url(${
                            ProductImages[el.product_id]?.img
                              ? ProductImages[el.product_id].img
                              : ""
                          })`,
                        }}
                      >
                        {ProductImages[el.product_id]?.img ? (
                          ""
                        ) : (
                          <div className="svg_floater_main">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0 0 64 65"
                              fill="none"
                            >
                              <path
                                d="M64 32.9972C64 50.6712 49.674 64.9972 32 64.9972C14.326 64.9972 0 50.6712 0 32.9972C0 15.3232 14.326 0.997192 32 0.997192V4.99719C16.536 4.99719 4 17.5332 4 32.9972C4 48.4612 16.536 60.9972 32 60.9972C47.464 60.9972 60 48.4612 60 32.9972H64Z"
                                fill="#5F1D1B"
                                // fill="#1D1D1D"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                      <div className="txt">
                        <div className="name">{el.product_name}</div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                ""
              )
            )}
          </>
        ) : (
          <Loader loading="type1" />
        )}
      </div>
      <BottomNav current="home" />
    </div>
  );
};

export default CareHistory;
