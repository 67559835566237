import React, { useState } from "react";

export const Switch = ({ custclass, isChecked, onToggle, page_values }) => {
  //   const [isChecked, setController] = useState(true);
  //   function btnSwitch() {
  //     setController(!isChecked);
  //     onevent(isChecked);
  //   }
  return (
    <>
      <div className={"switch_main " + (custclass ? ` ${custclass}` : "")}>
        <div
          className={"switch_title " + (isChecked ? "active" : "")}
          onClick={() => {
            onToggle();
          }}
        >
          {page_values.right}
        </div>
        <div
          className={`switch-btn  ${
            !isChecked ? " leftBorder" : " rightBorder"
          }`}
          onClick={() => {
            // setController((prev) => !prev);
            onToggle();
          }}
        >
          <div
            className={
              !isChecked ? `switch-btn-circle left ` : `switch-btn-circle right`
            }
          ></div>
        </div>
        <div
          className={"switch_title " + (!isChecked ? "" : "active")}
          onClick={() => {
            // setController(false);
            onToggle();
          }}
        >
          {page_values.left}
        </div>
      </div>
    </>
  );
};
