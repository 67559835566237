import React from "react";
import BottomBtnSection from "../components/BottomBtnSection";
import CustInputMuiClone from "../../../component/CustInput/CustInputMuiClone";
import { MixPanelIntake } from "../../../component/API/automation_api";

export const AverageCycleLength = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  singleBeginRange,
  setBeginPage,
  lastdata,
  goto,
}) => {
  const handle_pagevalues = (updated_data) => {
    return setPagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  const handle_errpagevalues = (updated_data) => {
    return seterr_pagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  return (
    <>
      <div className="intake_body">
        <div className="container weight_section_main">
          <div className="weight_title">What is your average cycle length?</div>
          <div className="weight_desc">Take your best guess.</div>
          <CustInputMuiClone
            {...{
              attr: {
                autofocus: true,
                error: err_pagevalues.averagecyclelength,
                error_msg: "",
                inputMode: "numeric",
                value: pagevalues.averagecyclelength,
                event: {
                  change: ({ target }) => {
                    if (Number(target.value.slice(-1)).toString() == "NaN") {
                      return;
                    }

                    handle_pagevalues({ averagecyclelength: target.value });
                    handle_errpagevalues({
                      averagecyclelength: target.value ? "" : "Error message",
                    });
                  },
                },
              },
              data: {
                title: "",
                suffix: "days",
              },
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              if (!pagevalues.averagecyclelength) {
                seterr_pagevalues((prev) => ({
                  ...prev,
                  averagecyclelength: !pagevalues.averagecyclelength,
                }));
                return;
              }
              MixPanelIntake({
                step_name: "What is your average cycle length?",
                section_name: "Menstrual History",
                value: pagevalues.averagecyclelength + "days",
                step_index: 2,
                total_steps: 5,
                remaining: lastdata,
              });
              if (singleBeginRange) {
                setRangeCount(2);
              } else {
                setRangeCount(8);
              }
              setPages("descperois");
            },
            goBackClick: () => {
              setRangeCount(6);
              setPages("oftengetperiod");
            },
          },
        }}
      />
    </>
  );
};
