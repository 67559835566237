import React, { useEffect, useState } from "react";
import CustInputMuiClone from "../../../component/CustInput/CustInputMuiClone";
import { CheckTokenUser, get_guide_data } from "../../../component/API/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useRazorpay from "react-razorpay";
import axios from "axios";

const base_url = `https://api.arva.health/api/v1/arva`;

export const GuidePayment = ({ AllData, setAllData, setPages, GuideData }) => {
  let token = localStorage.getItem("user_token");
  const Razorpay = useRazorpay();
  const [pagevalues, setpagevalues] = useState({
    discount: "",
    dis_value: 0,
  });
  const [err_pagevalues, seterr_pagevalues] = useState({
    discount: false,
  });
  const params = useParams();
  const [discount, setdiscount] = useState([]);
  const [total_discount, settotal_discount] = useState(0);
  const handle_pagevalues = (updated_data) => {
    return setpagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  const handle_errpagevalues = (updated_data) => {
    return seterr_pagevalues((prev) => ({ ...prev, ...updated_data }));
  };

  useEffect(() => {
    if (discount?.length > 0) {
      setpagevalues((prev) => ({
        ...prev,
        discount: discount[0]?.code,
      }));
      // if (discount?.length > 0) {
      settotal_discount(
        Math.floor(
          discount[0]?.percent
            ? GuideData?.price * discount[0]?.cost * 0.01
            : discount[0]?.cost
        )
      );
    }
  }, [discount?.length, GuideData?.price]);
  useEffect(() => {
    if (token) {
      CheckTokenUser(
        {
          token: token,
        },
        (res) => {
          setAllData((prev) => ({
            ...prev,
            last_order: res?.response?.last_order || {},
            last_recorder_order: res?.response?.last_recorder_order || {},
            last_intake: res?.response?.last_intake || {},
            user_data: res?.response?.user_data || {},
            discount: res?.response?.discount || {},
            all_order: res?.response?.all_order || [],
          }));
        }
      );
    }
  }, []);

  //
  const handelPayment = () => {
    let amt =
      GuideData?.price - total_discount <= 0
        ? 0
        : GuideData?.price - total_discount;
    if (amt == 0) {
      axios
        .post(
          `${base_url}/guide-payment`,
          {
            razor_pay: {
              status: "Bought Free with discount code",
            },
            status: "After Payment",
            slug: GuideData.slug,
            discount_used: discount,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(function (response) {
          if (response.data.status) {
            CardClick(params.slug);
            setPages("planDetail");
          } else {
          }
        })
        .catch(function (error) {});
    } else {
      axios
        .post(
          `${base_url}/create-consultation-order`,
          {
            amount: amt * 100,
            name: AllData.user_data.name,
            phone: AllData.user_data.phone,
          },
          {}
        )
        .then((res) => {
          if (res.data.error_exist) {
            return;
          }
          const rzoptions = {
            key: "rzp_live_hC63XDouEG5lpp",
            amount: amt * 100,
            currency: "INR",
            name: GuideData?.title,
            image: GuideData?.product_images[0],
            order_id: res.data.id,
            modal: {
              onDismiss: () => {},
            },
            handler: (res1) => {
              axios
                .post(
                  `${base_url}/guide-payment`,
                  {
                    razor_pay: res1,
                    status: "After Payment",
                    slug: GuideData.slug,
                    discount_used: discount,
                  },
                  {
                    headers: {
                      Authorization: token,
                    },
                  }
                )
                .then(function (response) {
                  if (response.data.status) {
                    CardClick(params.slug);
                    setPages("planDetail");
                  } else {
                  }
                })
                .catch(function (error) {});
            },
            prefill: {
              contact: "+91" + AllData.user_data.phone,
            },
            theme: {
              color: "#852f00",
            },
          };

          const rzpay = new Razorpay(rzoptions);

          rzpay.on("payment.failed", function (response) {
            //
          });
          rzpay.on("payment.captured", function (response) {
            //
          });

          rzpay.open();
        })
        .catch((err) => {
          return;
        });
    }
  };

  const CardClick = (obj) => {
    get_guide_data(obj, (res) => {});
  };
  return (
    <div className="inner_guide_page_2">
      <div
        className="inner_giude_backbtn"
        onClick={() => {
          setPages("buyingPage");
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3996_376)">
            <path
              d="M8.33333 15.8334L2.5 10.0001M2.5 10.0001L8.33333 4.16675M2.5 10.0001H17.5"
              stroke="#5F1D1B"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3996_376">
              <rect
                width="20"
                height="20"
                rx="10"
                transform="matrix(-1 0 0 1 20 0)"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="inner_guide_page_detail">
        <div className="order_guide_box">
          <div className="order_guide_box_title">Order summary</div>
          <div className="order_guide_box_dtl">
            <div
              className="order_guide_box_dtl_img"
              // style={{
              //   backgroundImage: `url(${GuideData?.product_images[0]})`,
              // }}
            >
              <img src={GuideData?.product_images[0]} />
            </div>
            <div className="order_guide_box_dtl_name">
              <div className="order_guide_box_dtl_name_1">
                {GuideData?.title}
              </div>
              <div className="order_guide_box_dtl_price">
                ₹{GuideData?.price}
              </div>
            </div>
          </div>
          <div className="order_guide_box_amounts">
            {discount.length ? (
              <div className="order_guide_box_dis">
                <div className="v2_test_sec_dic_title">
                  Discount
                  <div>{discount[0]?.code}</div>
                </div>
                <div className="v2_test_sec_dic_title2">
                  {`-₹${total_discount}`}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="order_guide_box_total">
              <div>Total</div>
              <div>
                ₹
                {GuideData?.price - total_discount <= 0
                  ? 0
                  : GuideData?.price - total_discount}
              </div>
            </div>
          </div>
          <div className="v2_discount_code_guide">
            <div className="input_group">
              <CustInputMuiClone
                {...{
                  attr: {
                    error: err_pagevalues.discount,
                    error_msg: "",
                    value: pagevalues.discount,
                    event: {
                      change: ({ target }) => {
                        handle_pagevalues({
                          discount: target.value.toUpperCase(),
                        });
                        handle_errpagevalues({ discount: false });
                      },
                    },
                  },
                  data: {
                    title: "Discount code",
                    suffix: "",
                  },
                }}
              />

              <div
                style={{
                  opacity: pagevalues.discount === "" ? 0.5 : 1,
                  cursor: "pointer",
                }}
                className="btn_guide"
                onClick={() => {
                  if (discount.length <= 0) {
                    if (pagevalues.discount != "") {
                      if (
                        pagevalues.discount.toUpperCase() ==
                          "GUIDEDISCOUNT30" ||
                        pagevalues.discount.toUpperCase() == "OVERICE"
                      ) {
                        setdiscount(
                          [
                            {
                              code: "GUIDEDISCOUNT30",
                              percent: true,
                              cost: 30,
                            },
                            {
                              code: "OVERICE",
                              percent: true,
                              cost: 100,
                            },
                          ].filter(
                            (el) => el.code == pagevalues.discount.toUpperCase()
                          )
                        );
                      } else {
                        handle_errpagevalues({
                          discount: true,
                        });
                      }
                      // CheckCode(
                      //   {
                      //     type: pagedata?.test?.id,
                      //     discount: pagevalues.discount,
                      //   },
                      //   (res) => {
                      //   }
                      // );
                    }
                  } else {
                    handle_pagevalues({ discount: "" });
                    setdiscount([]);
                    settotal_discount(0);
                  }
                }}
              >
                {discount.length > 0 ? "Remove" : "Apply"}
              </div>
            </div>
            {total_discount === 0 ? (
              ""
            ) : (
              <div className="input_group_1">
                Yay! ₹
                {total_discount > GuideData?.price
                  ? GuideData?.price
                  : total_discount}{" "}
                total saved.
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="buy_btn_box">
        <div
          className="payment_btn"
          onClick={() => {
            handelPayment();
          }}
        >
          Pay
        </div>
      </div>
    </div>
  );
};
