import React from "react";
import { BookSlotBegin } from "./BookSlotPages/BookSlotBegin";
import { BookSlotMCycle } from "./BookSlotPages/BookSlotMCycle";
import { AddressEdit } from "./BookSlotPages/AddressEdit";
import { BookSlotLabList } from "./BookSlotPages/BookSlotLabList";
import { SampleColleDate } from "./BookSlotPages/SampleColleDate";
import { CommingPeriodV2 } from "./BookSlotPages/CommingPeriodV2";
import { ReviewPage } from "./BookSlotPages/ReviewPage";
import { ShowTimeCommingPeriodV2 } from "./BookSlotPages/ShowTimeCommingPeriodV2";
import { BookCompleteV2 } from "./BookSlotPages/BookCompleteV2";
// import { BookSlotBegin } from "./BookSlotBegin";
// import { BookSlotMCycle } from "./BookSlotMCycle";
// import { SampleColleDate } from "./SampleColleDate";
// import { BookCompleteV2 } from "./BookCompleteV2";
// import { CommingPeriodV2 } from "./CommingPeriodV2";
// import { ShowTimeCommingPeriodV2 } from "./ShowTimeCommingPeriodV2";
// import { ReviewPage } from "./ReviewPage";
// import { BookSlotLabList } from "./BookSlotLabList";
// import { AddressEdit } from "./AddressEdit";

export const BookSlotsV2AllPages = ({ page_name, otherData }) => {
  console.log(otherData.pagevalues);

  const page_type = {
    begin: (
      <BookSlotBegin
        {...{
          setBeginBtn: otherData.begin_range,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          AllData: otherData.AllData,
          booking_data: otherData.booking_data,
          lab_validation: otherData.lab_validation,
          booking_data: otherData.booking_data,
          setpagevalues: otherData.setpagevalues,
        }}
      />
    ),
    mcycle: (
      <BookSlotMCycle
        {...{
          setBeginBtn: otherData.begin_range,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setpagevalues: otherData.setpagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          AllData: otherData.AllData,
          FurtherDataMixPanel: otherData.FurtherDataMixPanel,
        }}
      />
    ),
    addressedit: (
      <AddressEdit
        {...{
          booking_data: otherData.booking_data,
          final_booking_data: otherData.final_booking_data,
          setBeginBtn: otherData.begin_range,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setpagevalues: otherData.setpagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          AllData: otherData.AllData,
          setAllData: otherData.setAllData,
          FurtherDataMixPanel: otherData.FurtherDataMixPanel,
          BookTime: otherData.BookTime,
          setBookTime: otherData.setBookTime,
          ListOfTimewithDate: otherData.ListOfTimewithDate,
          setBookDate: otherData.setBookDate,
          BookDate: otherData.BookDate,
          OverAllDateTime: otherData.OverAllDateTime,
          setOverAllDateTime: otherData.setOverAllDateTime,
          FurtherDate: otherData.FurtherDate,
          setFurtherDate: otherData.setFurtherDate,
        }}
      />
    ),
    labslist: (
      <BookSlotLabList
        {...{
          lab_selcted: otherData.lab_selcted,
          setlab_selcted: otherData.setlab_selcted,
          booking_data: otherData.booking_data,
          lab_validation: otherData.lab_validation,
          setBeginBtn: otherData.begin_range,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setpagevalues: otherData.setpagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          AllData: otherData.AllData,
          FurtherDataMixPanel: otherData.FurtherDataMixPanel,
        }}
      />
    ),
    samplecolledate: (
      <SampleColleDate
        {...{
          setBeginBtn: otherData.begin_range,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setpagevalues: otherData.setpagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          AllData: otherData.AllData,
          FurtherDataMixPanel: otherData.FurtherDataMixPanel,
          BookTime: otherData.BookTime,
          setBookTime: otherData.setBookTime,
          ListOfTimewithDate: otherData.ListOfTimewithDate,
          setBookDate: otherData.setBookDate,
          BookDate: otherData.BookDate,
          OverAllDateTime: otherData.OverAllDateTime,
          setOverAllDateTime: otherData.setOverAllDateTime,
        }}
      />
    ),
    commingperiod: (
      <CommingPeriodV2
        {...{
          setBeginBtn: otherData.begin_range,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setpagevalues: otherData.setpagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          AllData: otherData.AllData,
          FurtherDataMixPanel: otherData.FurtherDataMixPanel,
          BookTime: otherData.BookTime,
          setBookTime: otherData.setBookTime,
          ListOfTimewithDate: otherData.ListOfTimewithDate,
          setBookDate: otherData.setBookDate,
          BookDate: otherData.BookDate,
          OverAllDateTime: otherData.OverAllDateTime,
          setOverAllDateTime: otherData.setOverAllDateTime,
          MaxDate: otherData.MaxDate,
          setMaxDate: otherData.setMaxDate,
          MiniDate: otherData.MiniDate,
          setMiniDate: otherData.setMiniDate,
          FurtherDate: otherData.FurtherDate,
          setFurtherDate: otherData.setFurtherDate,
        }}
      />
    ),
    reviewpage: (
      <ReviewPage
        {...{
          final_booking_data: otherData.final_booking_data,
          setBeginBtn: otherData.begin_range,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setpagevalues: otherData.setpagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          AllData: otherData.AllData,
          setAllData: otherData.setAllData,
          FurtherDataMixPanel: otherData.FurtherDataMixPanel,
          BookTime: otherData.BookTime,
          setBookTime: otherData.setBookTime,
          ListOfTimewithDate: otherData.ListOfTimewithDate,
          setBookDate: otherData.setBookDate,
          BookDate: otherData.BookDate,
          OverAllDateTime: otherData.OverAllDateTime,
          setOverAllDateTime: otherData.setOverAllDateTime,
          FurtherDate: otherData.FurtherDate,
          setFurtherDate: otherData.setFurtherDate,
        }}
      />
    ),
    bookcomp: <BookCompleteV2 />,
    showcomming: (
      <ShowTimeCommingPeriodV2
        {...{
          setBeginBtn: otherData.begin_range,
          setRangeCount: otherData.range_count,
          setPages: otherData.page,
          pagevalues: otherData.pagevalues,
          setpagevalues: otherData.setpagevalues,
          setBeginPage: otherData.setBeginPage,
          singleBeginRange: otherData.singleBeginRange,
          AllData: otherData.AllData,
          FurtherDataMixPanel: otherData.FurtherDataMixPanel,
          BookTime: otherData.BookTime,
          setBookTime: otherData.setBookTime,
          ListOfTimewithDate: otherData.ListOfTimewithDate,
          setBookDate: otherData.setBookDate,
          BookDate: otherData.BookDate,
          OverAllDateTime: otherData.OverAllDateTime,
          setOverAllDateTime: otherData.setOverAllDateTime,
          MaxDate: otherData.MaxDate,
          setMaxDate: otherData.setMaxDate,
          MiniDate: otherData.MiniDate,
          setMiniDate: otherData.setMiniDate,
          FurtherDate: otherData.FurtherDate,
          setFurtherDate: otherData.setFurtherDate,
        }}
      />
    ),
  };
  if (page_type[page_name]) {
    return page_type[page_name];
  } else {
    return <div>No Pages - {page_name}</div>;
  }
};
