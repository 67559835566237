import React, { useEffect, useState } from "react";
import { addfc1, dateFormatter } from "../../../../component/API/new_api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CalendlyModal from "../../../../component/Calendly/CalendlyModal";

const TestCard = ({ state, stage, data, ProductImage }) => {
  const [card_state, setcard_state] = useState(
    ["IntakeNotFilled", "OrderConfirmed"][1]
  );
  const [chip_state, setchip_state] = useState(1);
  const [curr_state, setcurr_state] = useState(1);
  const [meet_state, setmeet_state] = useState(0);

  const [calendlyModalState, setcalendlyModalState] = useState(false);
  const [calendlyData, setcalendlyData] = useState({
    url: "",
    onSubmit: () => "",
  });
  const navigate = useNavigate();
  const store = useSelector((state) => state);
  console.log("next", data);
  useEffect(() => {
    let t_card_state = "";
    if (store?.checktoken_data?.customer?.intake?.dob) {
      if (data?.dates?.period) {
        t_card_state = "TestBooked_Period";
      }
      if (data?.dates?.test) {
        t_card_state = "TestBooked_Date";
      }
      const statusMap = {
        "Sample Collected": "SampleProcess",
        "Reports Available": "SampleProcess",
        "Reports Published": "ReportReady",
        "FC1 Scheduled": "ReportReady",
      };

      if (statusMap[data?.product_status]) {
        t_card_state = statusMap[data.product_status];
        if (data.product_status === "FC1 Scheduled") {
          setmeet_state(1);
        }
      }
    } else {
      console.log(3);
      t_card_state = "IntakeNotFilled";
    }
    setcard_state((prev) => t_card_state || prev);
  }, [store, data]);
  console.log(data);
  return (
    <>
      <div className="home_card" type="test">
        <div className="title">
          {ProductImage[data.product_id]?.name || "Fertility Test"}
          {/* {data._id} */}
        </div>
        <div
          className="img_holder"
          style={{
            backgroundImage: "url(" + ProductImage[data.product_id]?.img + ")",
          }}
        >
          {/* {card_state} */}
          {ProductImage[data.product_id]?.img ? (
            ""
          ) : (
            <div className="svg_floater_main">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 64 65"
                fill="none"
              >
                <path
                  d="M64 32.9972C64 50.6712 49.674 64.9972 32 64.9972C14.326 64.9972 0 50.6712 0 32.9972C0 15.3232 14.326 0.997192 32 0.997192V4.99719C16.536 4.99719 4 17.5332 4 32.9972C4 48.4612 16.536 60.9972 32 60.9972C47.464 60.9972 60 48.4612 60 32.9972H64Z"
                  fill="#5F1D1B"
                  // fill="#1D1D1D"
                />
              </svg>
            </div>
          )}
        </div>
        <div className="details">
          <div className="stage">
            {[
              { label: "intake", state: "IntakeNotFilled" },
              {
                label: "timeslot",
                state: "OrderConfirmed,TestBooked_Period,TestBooked_Date",
              },
              { label: "sample", state: "SampleProcess" },
              { label: "results", state: "ReportReady" },
            ].map((el, i) => (
              <>
                {el.state.includes(card_state) ? (
                  <div className="active">{el.label}</div>
                ) : (
                  <div className="unactive">{el.label}</div>
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5"
                  height="8"
                  viewBox="0 0 5 8"
                  fill="none"
                >
                  <path
                    d="M1 7L4 4L1 1"
                    stroke="#5F1D1B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </>
            ))}
          </div>

          {
            {
              IntakeNotFilled: (
                <>
                  <div className="txt">Complete Intake</div>
                  <div className="time">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g opacity="0.6">
                        <path
                          d="M8 5.33333V8L10 10M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                          stroke="#5F1D1B"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </svg>
                    Less than a minute
                  </div>
                  <div
                    className="btn type1"
                    onClick={() => {
                      navigate("/intake");
                    }}
                  >
                    Go
                  </div>
                </>
              ),
              OrderConfirmed: (
                <>
                  <div className="txt">Pick a time slot when you’re ready</div>
                  <div className="time">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g opacity="0.6">
                        <path
                          d="M8 5.33333V8L10 10M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                          stroke="#5F1D1B"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </svg>
                    Less than a minute
                  </div>
                  <div
                    className="btn type1"
                    onClick={() => {
                      localStorage.setItem(
                        "user_data_to_show",
                        JSON.stringify({
                          goTo: "bookingslot",
                          id: data._id,
                        })
                      );
                      navigate("/bookingslot");
                    }}
                  >
                    Schedule
                  </div>
                </>
              ),
              TestBooked_Period: (
                <>
                  <div className="txt">
                    Reminder set for{" "}
                    {data?.dates?.period &&
                      dateFormatter(
                        [data.dates.period.split(" ")[0]].reduce(
                          (acc, curr) => {
                            let [dd, mm, yy] = curr.split("/");
                            return new Date(yy, mm - 1, dd);
                          },
                          0
                        ),
                        "DD/MM/YYYY"
                      )}
                  </div>
                  <div className="time">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g opacity="0.6">
                        <path
                          d="M8 5.33333V8L10 10M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                          stroke="#5F1D1B"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </svg>
                    Less than a minute
                  </div>
                  <div
                    className="btn type1"
                    onClick={() => {
                      localStorage.setItem(
                        "user_data_to_show",
                        JSON.stringify({
                          goTo: "bookingslot",
                          id: data._id,
                        })
                      );
                      navigate("/bookingslot");
                    }}
                  >
                    Schedule
                  </div>
                </>
              ),
              TestBooked_Date: (
                <>
                  <div className="txt">
                    Collection is scheduled for{" "}
                    {data?.dates?.test &&
                      dateFormatter(
                        [data.dates.test.split(" ")[0]].reduce((acc, curr) => {
                          let [dd, mm, yy] = curr.split("/");
                          return new Date(yy, mm - 1, dd);
                        }, 0),
                        "DD/MM/YYYY"
                      )}
                  </div>
                  <div className="time">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g opacity="0.6">
                        <path
                          d="M8 5.33333V8L10 10M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                          stroke="#5F1D1B"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </svg>
                    {data?.dates?.test &&
                      dateFormatter(
                        [data.dates.test.split(" ")[0]].reduce((acc, curr) => {
                          let [dd, mm, yy] = curr.split("/");
                          return new Date(yy, mm - 1, dd);
                        }, 0),
                        "dddd, MMF DD"
                      )}
                    , {data?.dates?.test?.split(" ")[1]}
                  </div>
                  {/* <div
                    className="btn type1"
                    onClick={() => {
                      localStorage.setItem(
                        "user_data_to_show",
                        JSON.stringify({
                          goTo: "bookingslot",
                          id: data._id,
                        })
                      );
                      navigate("/bookingslot");
                    }}
                  >
                    Reschedule
                  </div> */}
                </>
              ),
              SampleProcess: (
                <>
                  <div className="txt">Your sample is being processed</div>
                  <div className="time">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g opacity="0.6">
                        <path
                          d="M8 5.33333V8L10 10M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                          stroke="#5F1D1B"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </svg>
                    Less than 48 hours
                  </div>
                  <div className="btn type1" onClick={() => ""}>
                    Contact us
                  </div>
                </>
              ),
              ReportReady: (
                <>
                  <div className="txt">Your report is ready</div>
                  <div
                    className="btn type1"
                    onClick={() => navigate("/result")}
                  >
                    View report
                  </div>
                </>
              ),
            }[card_state]
          }
          {}
        </div>
      </div>
      {card_state == "ReportReady" ? (
        <div className="home_card" type="test">
          <div className="title">{"Fertility Care Team"} </div>
          <div
            className="img_holder"
            style={
              ProductImage
                ? {
                    // backgroundImage: "url(" + data?.img + ")",
                    backgroundImage:
                      "url(" + ProductImage[data.product_id]?.img + ")",
                  }
                : {}
            }
          ></div>
          <div className="details">
            <div className="stage">
              {["Schedule", "Meet"].map((el, i) => (
                <>
                  {i == meet_state ? (
                    <div className="active">{el}</div>
                  ) : i < meet_state ? (
                    <div className="unactive">{el}</div>
                  ) : (
                    <div className="unactive">{el}</div>
                  )}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="5"
                    height="8"
                    viewBox="0 0 5 8"
                    fill="none"
                  >
                    <path
                      d="M1 7L4 4L1 1"
                      stroke="#5F1D1B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </>
              ))}
            </div>
            {
              [
                <>
                  <div className="txt">
                    {/* Schedule your call with our Fertility Care Team. */}
                    Schedule your call with Fertility Counsellor.
                  </div>

                  <div
                    className="btn type1"
                    onClick={() => {
                      setcalendlyModalState(true);
                      setcalendlyData({
                        // url: "https://calendly.com/arvahealth/testing-events",
                        onSubmit: (evenetData) => {
                          addfc1(
                            store?.checktoken_data.token,
                            data._id,
                            evenetData,
                            (success) => {
                              window.location.reload();
                            },
                            (err) => {}
                          );
                        },
                      });
                    }}
                  >
                    Schedule
                  </div>
                </>,
                <>
                  {data?.fc1?.startTime ? (
                    <div className="txt">Fertility Care Team scheduled for</div>
                  ) : (
                    <div className="txt">Fertility Care Team scheduled</div>
                  )}
                  {data?.fc1?.startTime && (
                    <div className="time">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <g opacity="0.6">
                          <path
                            d="M8 5.33333V8L10 10M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8Z"
                            stroke="#5F1D1B"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </svg>
                      {dateFormatter(data?.fc1?.startTime, "dddd, MMF DD")}
                    </div>
                  )}
                  {data?.fc1?.location ? (
                    <div
                      className="btn type1"
                      onClick={() => {
                        window.open(data?.fc1?.location);
                      }}
                    >
                      Join
                    </div>
                  ) : (
                    ""
                  )}
                  {data?.fc1?.rescheduleUrl ? (
                    <div
                      className="btn type2"
                      onClick={() => {
                        setcalendlyModalState(true);
                        setcalendlyData({
                          url: data?.fc1?.rescheduleUrl,

                          onSubmit: (evenetData) => {
                            addfc1(
                              store?.checktoken_data.token,
                              data._id,
                              evenetData,
                              (success) => {
                                window.location.reload();
                              },
                              (err) => {}
                            );
                          },
                        });
                      }}
                    >
                      Reschedule
                    </div>
                  ) : (
                    ""
                  )}
                </>,
              ][meet_state]
            }
          </div>
        </div>
      ) : (
        ""
      )}
      <CalendlyModal
        {...{
          url: "https://calendly.com/d/ckfy-5hm-xy4/fertility-counsellor",
          ...calendlyData,
          isOpen: calendlyModalState,
          setIsOpen: (state) => setcalendlyModalState(state),
        }}
      />
    </>
  );
};

export default TestCard;
