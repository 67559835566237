import React, { useEffect, useState } from "react";

import BottomBtnSection from "../components/BottomBtnSection";
import { MultiSelectV2 } from "../../../component/CheckOut/Intake/MultiSelectV2";
import { MixPanelIntake } from "../../../component/API/automation_api";

export const SingleOrMingle = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  singleBeginRange,
  setBeginPage,
  lastdata,
  setlastdata,
  goto,
}) => {
  const [DrprevDignosedData, setDrprevDignosedData] = useState([
    "Married",
    "Engaged",
    "Long term relationship",
    "Dating right now",
    "Nope",
  ]);
  const [MultiSelectData, setMultiSelectData] = useState([]);
  const [MultiSelectDataExtra, setMultiSelectDataExtra] = useState([]);
  useEffect(() => {
    setMultiSelectDataExtra([...pagevalues?.singleormingle]);
  }, []);

  //   useEffect(() => {
  //     if (MultiSelectData.length == 1 && MultiSelectData.includes("Nope")) {
  //       setPagevalues((prev) => ({
  //         ...prev,
  //         singleormingle: ["Nope"],
  //       }));
  //       setPagevalues((prev) => ({
  //         ...prev,
  //         singleormingleextra: [...MultiSelectDataExtra],
  //       }));
  //     } else {
  //       setPagevalues((prev) => ({
  //         ...prev,
  //         singleormingle: [...MultiSelectData],
  //       }));
  //       setPagevalues((prev) => ({
  //         ...prev,
  //         singleormingleextra: [...MultiSelectDataExtra],
  //       }));
  //     }
  //   }, [MultiSelectData, MultiSelectDataExtra]);

  return (
    <>
      <div className="intake_body">
        <div className="container birthcontrol_main">
          <div className="single_or_mingle_title">
            Do you have a partner in your life?
          </div>
          <MultiSelectV2
            {...{
              body: (
                <>
                  {DrprevDignosedData?.map((el, i) => {
                    return (
                      <div
                        className={
                          "multi_select_box " +
                          ((MultiSelectDataExtra?.includes(el) &&
                            !MultiSelectDataExtra?.includes("Nope")) ||
                          (MultiSelectDataExtra?.includes(el) && el == "Nope")
                            ? "multi_select_box_active "
                            : "")
                        }
                        onClick={() => {
                          setMultiSelectDataExtra((prev) => {
                            let tprev =
                              prev.includes("Nope") && el != "Nope"
                                ? [el]
                                : prev.includes(el)
                                ? prev.filter((el1) => el1 != el)
                                : [...prev, el];
                            setPagevalues((prev) => ({
                              ...prev,
                              singleormingle: [...tprev],
                            }));
                            return tprev;
                          });
                        }}
                        key={i}
                      >
                        {el}
                        {(MultiSelectDataExtra?.includes(el) &&
                          !MultiSelectDataExtra?.includes("Nope")) ||
                        (MultiSelectDataExtra?.includes(el) && el == "Nope") ? (
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM13.707 8.707C13.8892 8.5184 13.99 8.2658 13.9877 8.0036C13.9854 7.7414 13.8802 7.49059 13.6948 7.30518C13.5094 7.11977 13.2586 7.0146 12.9964 7.01233C12.7342 7.01005 12.4816 7.11084 12.293 7.293L9 10.586L7.707 9.293C7.5184 9.11084 7.2658 9.01005 7.0036 9.01233C6.7414 9.0146 6.49059 9.11977 6.30518 9.30518C6.11977 9.49059 6.0146 9.7414 6.01233 10.0036C6.01005 10.2658 6.11084 10.5184 6.293 10.707L8.293 12.707C8.48053 12.8945 8.73484 12.9998 9 12.9998C9.26516 12.9998 9.51947 12.8945 9.707 12.707L13.707 8.707Z"
                                fill="#5F1D1B"
                              />
                            </svg>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </>
              ),
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              if (pagevalues.singleormingle.length <= 0) {
                return;
              }
              MixPanelIntake({
                step_name: "Do you have a partner in your life?",
                section_name: "Reproductive Health",
                value: pagevalues.singleormingle,
                step_index: 3,
                total_steps: 8,
                remaining: lastdata,
              });
              if (singleBeginRange) {
                setRangeCount(3);
              } else {
                setRangeCount(19);
              }
              if (pagevalues.singleormingle.includes("Nope")) {
                setPages("birthcontrol");
              } else {
                setPages("partnerKnow");
              }
            },
            goBackClick: () => {
              if (pagevalues?.dr_prev_dignosed?.includes("PCOS")) {
                setPages("pcos");
                setRangeCount(17);
              } else {
                setRangeCount(16);
                setPages("doctordignosed");
              }
            },
          },
        }}
      />
    </>
  );
};
