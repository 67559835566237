import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../img/ARVA.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MixPanelUsertype } from "../../component/API/automation_api";
import { checkToken } from "../../component/API/new_api";
import Range from "../../component/CheckOut/Intake/Range";
import { Navbar } from "../../component/Navbar";
import IntakeAllPages from "./IntakeAllPages";

const NewIntake = ({ setloading }) => {
  const store = useSelector((state) => state);
  const navigate = useNavigate();

  const [pagevalues, setPagevalues] = useState({
    birth_date: "",
    tall_feet: "",
    tall_inch: "",
    weight: "",
    waist_inch: "",
    socialmedia: "",
    experience_symptoms: [],
    oftengetperois: [],
    averagecyclelength: "",
    descperiod: "",
    exphair: [],
    momage: "",
    getpregnentstatus: "",
    mommenopause: "",
    prev_dignosed_with_PCOS: "",
    birthcontrol: "",
    partnerknowledge: "",
    singleormingle: [],
    medicalcondition: "",
    medication: "",
    tracking_arva: [],
    dr_prev_dignosed: "",
  });
  const [err_pagevalues, seterr_pagevalues] = useState({
    birth_date: false,
    tall_feet: false,
    tall_inch: false,
    weight: false,
    waist_inch: false,
    socialmedia: false,
    experience_symptoms: false,
    oftengetperois: false,
    averagecyclelength: false,
    descperiod: false,
    exphair: false,
    mommenopause: false,
    getpregnentstatus: false,
    prev_dignosed_with_PCOS: false,
    diagnosed_ultrasound: false,
    birthcontrol: false,
    medicalcondition: false,
    medication: false,
    tracking_arva: false,
    peroidType: false,
    dr_prev_dignosed: false,
    momage: false,
  });
  const [AllData, setAllData] = useState({
    last_recorder_order: {},
    last_order: {},
    last_intake: {},
    user_data: {},
    discount: {},
    all_order: [],
    version: "",
  });
  const [BeginBtn, setBeginBtn] = useState(false);
  const dispatch = useDispatch();
  const [RangeCount, setRangeCount] = useState(0);
  console.error(RangeCount);
  //   const [WhichPage, setWhichPage] = useState("Begin")
  const [Pages, setPages] = useState("begin");
  const singleBeginRange = false;
  const [BeginPage, setBeginPage] = useState("");
  const [FurtherDataMixPanel, setFurtherDataMixPanel] = useState({
    sign_up_date: "",
    user_id: "",
    last_order_type: "",
    name: "",
    birthdate: "",
    is_test_user: false,
    user_current_goal: "",
    is_paid_user: false,
    reported_symptoms: "",
    reported_conditions: "",
    is_ultrasound_done: "",
    is_birth_control: "",
  });
  const fertility_main = "fertility-test-for-women";
  useEffect(() => {
    const data = localStorage.getItem("user_token");
    let OrderDetail = localStorage.getItem("OrderDetailPurchase");
    if (data && OrderDetail) {
    } else {
    }
  }, []);

  const check_user_data = (token) => {
    checkToken(
      token || "",
      (success) => {
        if (success.customer == null) {
          navigate("/404");
        }
        MixPanelUsertype(success);
        setPages("begin");
        dispatch({
          type: "setCheckToken",
          payload: { ...success, token: token },
        });
        if (success?.customer?.intake) {
          let object = success.customer.intake;
          setPagevalues({
            // tall_feet: object.personalize.height_feet.toString(),
            // tall_inch: object.personalize.height_inch.toString(),
            // weight: object.personalize.weight.toString(),
            // waist_inch: object.personalize.waist_inch.toString(),
            // socialmedia: object.personalize.where_did_you_hear_about_us,
            // oftengetperois: object.menstrual_health.period_frequency,
            // averagecyclelength:
            //   object.menstrual_health.cycle_length_avg.toString(),
            // descperiod: object.menstrual_health.period_description,
            // experience_symptoms: object.menstrual_health.experience_symptoms,
            // exphair: object.menstrual_health.symptoms,
            // momage:
            //   object.family_history.mothers_age_during_your_birth.toString(),
            // getpregnentstatus: object.family_history.pregnancy_difficulty_level,
            // mommenopause: object.family_history.menopause_mom.toString(),
            // prev_dignosed:
            //   object?.reproductive_health?.previous_diagnosis || [],
            // prev_dignosed_with_PCOS:
            //   object.reproductive_health.ultrasound_diagnosis,
            // singleormingle: object.reproductive_health.partner,
            // partnerknowledge: object.reproductive_health.partner_details,
            // birthcontrol: object.reproductive_health.birth_control,
            // medicalcondition: object.reproductive_health.medical_condition,
            // medication: object.reproductive_health.current_medication,
            // tracking_arva: object.reproductive_health.goal,
            tall_feet: object?.personalize?.height_feet?.toString(),
            tall_inch: object?.personalize?.height_inch?.toString(),
            weight: object?.personalize?.weight?.toString(),
            waist_inch: object?.personalize?.waist_inch?.toString(),
            socialmedia: object?.personalize?.where_did_you_hear_about_us,
            oftengelperois: object?.menstrual_health?.period_frequency,
            averagecyclelength:
              object?.menstrual_health?.cycle_length_avg?.toString(),
            descperiod: object?.menstrual_health?.period_description,
            experience_symptoms:
              object?.menstrual_health?.experience_symptoms || [],
            exphair: object?.menstrual_health?.symptoms,
            momage:
              object?.family_history?.mothers_age_during_your_birth?.toString(),
            getpregnentstatus:
              object?.family_history?.pregnancy_difficulty_level,
            mommenopause: object?.family_history?.menopause_mom?.toString(),
            prev_dignosed:
              object?.reproductive_health?.previous_diagnosis || [],
            prev_dignosed_with_PCOS:
              object?.reproductive_health?.ultrasound_diagnosis,
            singleormingle: object?.reproductive_health?.partner || [],
            partnerknowledge: object?.reproductive_health?.partner_details,
            birthcontrol: object?.reproductive_health?.birth_control,
            medicalcondition:
              object?.reproductive_health?.medical_condition || [],
            medication: object?.reproductive_health?.current_medication,
            tracking_arva: object?.reproductive_health?.goal,
          });
          setloading(false);
        } else {
          setloading(false);
        }
      },
      (err) => {
        console.clear();
        // console.log(1);
        // console.log(err);
      }
    );
  };
  useEffect(() => {
    setloading("type1");

    if (store.checktoken_data.token) {
      check_user_data(store.checktoken_data.token);
    } else {
      const local_token = localStorage.getItem("user_token_home");
      if (local_token) {
        check_user_data(local_token);
      } else {
        setloading(false);
        // window.open("http://localhost:3000/", "_self");
      }
    }
    return () => {};
  }, [store.checktoken_data.token]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
  }, [Pages]);

  console.table(pagevalues);
  return (
    <div className="new_intake">
      <div className="intake_header">
        <div className="container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            onClick={() => {
              navigate(-1);
            }}
          >
            <path
              d="M13.1625 15.5876C13.3386 15.7637 13.4375 16.0026 13.4375 16.2516C13.4375 16.5007 13.3386 16.7396 13.1625 16.9157C12.9863 17.0918 12.7475 17.1908 12.4984 17.1908C12.2493 17.1908 12.0105 17.0918 11.8343 16.9157L5.58433 10.6657C5.49693 10.5786 5.42759 10.4751 5.38027 10.3611C5.33295 10.2472 5.30859 10.125 5.30859 10.0016C5.30859 9.87824 5.33295 9.75606 5.38027 9.64211C5.42759 9.52815 5.49693 9.42466 5.58433 9.33756L11.8343 3.08756C12.0105 2.91144 12.2493 2.8125 12.4984 2.8125C12.7475 2.8125 12.9863 2.91144 13.1625 3.08756C13.3386 3.26368 13.4375 3.50255 13.4375 3.75163C13.4375 4.0007 13.3386 4.23957 13.1625 4.41569L7.5773 10.0008L13.1625 15.5876Z"
              fill="#5F1D1B"
            />
          </svg>
          <Logo
            onClick={() => {
              navigate("/home");
            }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M13.1625 15.5876C13.3386 15.7637 13.4375 16.0026 13.4375 16.2516C13.4375 16.5007 13.3386 16.7396 13.1625 16.9157C12.9863 17.0918 12.7475 17.1908 12.4984 17.1908C12.2493 17.1908 12.0105 17.0918 11.8343 16.9157L5.58433 10.6657C5.49693 10.5786 5.42759 10.4751 5.38027 10.3611C5.33295 10.2472 5.30859 10.125 5.30859 10.0016C5.30859 9.87824 5.33295 9.75606 5.38027 9.64211C5.42759 9.52815 5.49693 9.42466 5.58433 9.33756L11.8343 3.08756C12.0105 2.91144 12.2493 2.8125 12.4984 2.8125C12.7475 2.8125 12.9863 2.91144 13.1625 3.08756C13.3386 3.26368 13.4375 3.50255 13.4375 3.75163C13.4375 4.0007 13.3386 4.23957 13.1625 4.41569L7.5773 10.0008L13.1625 15.5876Z"
              fill="#5F1D1B"
            />
          </svg>
        </div>
        {BeginBtn ? (
          <Range
            {...{
              startRange: BeginBtn,
              range: RangeCount,
              singleBeginRange: singleBeginRange,
              BeginPage: BeginPage,
              totalcount: 24,
              type: "Old",
            }}
          />
        ) : (
          ""
        )}
      </div>
      <IntakeAllPages
        {...{
          page_name: Pages,
          otherData: {
            singleBeginRange: singleBeginRange,
            setBeginPage: setBeginPage,
            pagevalues: pagevalues,
            setPagevalues: setPagevalues,
            err_pagevalues: err_pagevalues,
            seterr_pagevalues: seterr_pagevalues,
            begin_range: setBeginBtn,
            range_count: setRangeCount,
            page: setPages,
            lastdata: FurtherDataMixPanel,
            setlastdata: setFurtherDataMixPanel,
            AllData: AllData,
          },
        }}
      />
    </div>
  );
};

export default NewIntake;
