import React from "react";
import BottomBtnSection from "../components/BottomBtnSection";
import { MixPanelIntakeStarted } from "../../../component/API/automation_api";

export const FamilyHistory = ({
  setBeginBtn,
  setRangeCount,
  setPages,
  singleBeginRange,
  setBeginPage,
  pagevalues,
  lastdata,
  goto,
}) => {
  return (
    <>
      <div className="intake_body">
        <div className="container main_begin">
          <div className="main_begin_title">Your Family History</div>
          <div className="main_beign_desc">
            To get to know you, we’ll ask you a few questions about your medical
            history.
          </div>
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Continue",
            onBtnClick: () => {
              setBeginBtn(true);
              MixPanelIntakeStarted({
                name: "Family History",
                remaining: lastdata,
              });
              if (singleBeginRange) {
                setRangeCount(0);
              } else {
                setRangeCount(12);
              }
              setBeginPage("familyhistory");
              setPages("momage");
            },
            goBackClick: () => {
              if (
                pagevalues?.experience_symptoms.includes(
                  "Excess body hair (including your face)"
                )
              ) {
                setRangeCount(10);
                setPages("excessbodyhair");
              } else {
                setRangeCount(9);
                setPages("symptoms");
              }
            },
          },
        }}
      />
    </>
  );
};
