import React from "react";
import BottomBtnSection from "../../NewIntake/components/BottomBtnSection";
import { date_formatter } from "../../../component/CustInput/CustInput";

export const ShowTimeCommingPeriodV2 = ({
  setBeginBtn,
  setRangeCount,
  setPages,
  pagevalues,
  setpagevalues,
  setBeginPage,
  singleBeginRange,
  FurtherDataMixPanel,
  BookTime,
  setBookTime,
  ListOfTimewithDate,
  setBookDate,
  BookDate,
  OverAllDateTime,
  setOverAllDateTime,
  AllData,
  MaxDate,
  setMaxDate,
  MiniDate,
  setMiniDate,
  FurtherDate,
  setFurtherDate,
}) => {
  return (
    <>
      <div className="bookslot_body">
        <div className="container showcomming_p">
          <div className="showcomming_detail">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.03403 2.90972C10.3205 2.807 11.5419 2.30098 12.524 1.46372C13.6334 0.518902 15.0429 0 16.5 0C17.9572 0 19.3667 0.518902 20.476 1.46372C21.4582 2.30098 22.6795 2.807 23.966 2.90972C25.4189 3.02583 26.7829 3.65565 27.8135 4.68625C28.8441 5.71686 29.4739 7.08086 29.59 8.53372C29.692 9.81972 30.198 11.0417 31.036 12.0237C31.9808 13.133 32.4997 14.5426 32.4997 15.9997C32.4997 17.4569 31.9808 18.8664 31.036 19.9757C30.1988 20.9579 29.6927 22.1792 29.59 23.4657C29.4739 24.9186 28.8441 26.2826 27.8135 27.3132C26.7829 28.3438 25.4189 28.9736 23.966 29.0897C22.6795 29.1924 21.4582 29.6985 20.476 30.5357C19.3667 31.4805 17.9572 31.9994 16.5 31.9994C15.0429 31.9994 13.6334 31.4805 12.524 30.5357C11.5419 29.6985 10.3205 29.1924 9.03403 29.0897C7.58116 28.9736 6.21716 28.3438 5.18656 27.3132C4.15595 26.2826 3.52614 24.9186 3.41002 23.4657C3.30731 22.1792 2.80129 20.9579 1.96402 19.9757C1.01921 18.8664 0.500305 17.4569 0.500305 15.9997C0.500305 14.5426 1.01921 13.133 1.96402 12.0237C2.80129 11.0415 3.30731 9.82024 3.41002 8.53372C3.52614 7.08086 4.15595 5.71686 5.18656 4.68625C6.21716 3.65565 7.58116 3.02583 9.03403 2.90972ZM23.914 13.4137C24.2783 13.0365 24.4799 12.5313 24.4754 12.0069C24.4708 11.4825 24.2605 10.9809 23.8897 10.6101C23.5188 10.2393 23.0172 10.0289 22.4928 10.0244C21.9684 10.0198 21.4632 10.2214 21.086 10.5857L14.5 17.1717L11.914 14.5857C11.5368 14.2214 11.0316 14.0198 10.5072 14.0244C9.98283 14.0289 9.4812 14.2393 9.11039 14.6101C8.73957 14.9809 8.52923 15.4825 8.52468 16.0069C8.52012 16.5313 8.72171 17.0365 9.08603 17.4137L13.086 21.4137C13.4611 21.7887 13.9697 21.9993 14.5 21.9993C15.0304 21.9993 15.539 21.7887 15.914 21.4137L23.914 13.4137Z"
                fill="url(#paint0_linear_2468_2503)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2468_2503"
                  x1="16.5"
                  y1="0"
                  x2="16.5"
                  y2="31.9994"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FFA977" />
                  <stop offset="1" stop-color="#FF8F4E" />
                </linearGradient>
              </defs>
            </svg>
            <div className="showcomming_til">
              We’ll send you a reminder to book your time slot a few days
              before:
            </div>
            <div className="showcomming_date">
              {date_formatter(
                new Date(FurtherDate?.$d ? FurtherDate?.$d : FurtherDate),
                "m_long date, year_long"
              )}
            </div>
          </div>
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              setRangeCount(3);
              setBeginBtn(false);
              setPages("bookcomp");
            },
            goBackClick: () => {
              // setBeginBtn(false);
              setRangeCount(1);
              setPages("commingperiod");
            },
          },
        }}
      />
    </>
  );
};
