import React from "react";

import BottomBtnSection from "../components/BottomBtnSection";
import CustInputMuiClone from "../../../component/CustInput/CustInputMuiClone";
import {
  MixPanelIntake,
  MixPanelIntakeCompleted,
} from "../../../component/API/automation_api";

export const MomMenoPause = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  setBeginBtn,
  singleBeginRange,
  setBeginPage,
  lastdata,
  goto,
}) => {
  const handle_pagevalues = (updated_data) => {
    return setPagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  const handle_errpagevalues = (updated_data) => {
    return seterr_pagevalues((prev) => ({ ...prev, ...updated_data }));
  };
  return (
    <>
      <div className="intake_body">
        <div className="container weight_section_main">
          <div className="weight_title">
            At what age did your mom go through menopause?
          </div>
          <div className="weight_desc">
            If you’re unsure, you can leave this blank. However, it’s a great
            conversation starter to learn about your family history.
          </div>
          <CustInputMuiClone
            {...{
              attr: {
                autofocus: true,
                error: err_pagevalues.mommenopause,
                error_msg: "",
                value: pagevalues.mommenopause,
                inputMode: "numeric",
                event: {
                  change: ({ target }) => {
                    if (Number(target.value.slice(-1)).toString() == "NaN") {
                      return;
                    }

                    handle_pagevalues({ mommenopause: target.value });
                    handle_errpagevalues({
                      mommenopause: target.value ? "" : "Error message",
                    });
                  },
                },
              },
              data: {
                title: "",
                suffix: "yrs",
              },
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              // if (!pagevalues.mommenopause) {
              //   seterr_pagevalues((prev) => ({
              //     ...prev,
              //     mommenopause: !pagevalues.mommenopause,
              //   }));
              //   return;
              // }
              MixPanelIntake({
                step_name: "At what age did your mom go through menopause?",
                section_name: "Family History",
                value: pagevalues.mommenopause
                  ? pagevalues.mommenopause + "yrs"
                  : "N/A",
                step_index: 3,
                total_steps: 3,
                remaining: lastdata,
              });
              MixPanelIntakeCompleted({
                section_name: "Family History",
                remaining: lastdata,
              });
              if (singleBeginRange) {
                setRangeCount(3);
              } else {
                setRangeCount(15);
              }
              setBeginBtn(true);
              setPages("reporeductivehealthbegin");
            },
            goBackClick: () => {
              setRangeCount(13);
              setPages("fatherstrength");
            },
          },
        }}
      />
    </>
  );
};
