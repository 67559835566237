import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../img/ARVA.svg";

import { BookSlotsV2AllPages } from "./BookSlotsV2AllPages";
import Range from "../../component/CheckOut/Intake/Range";

import { NavbarMain } from "../../component/NavbarMain";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  checkPincode,
  checkToken,
  dateFormatter,
  getSlots,
} from "../../component/API/new_api";
import { MixPanelUsertype } from "../../component/API/automation_api";

const NewBookingSlot = ({ setloading }) => {
  const store = useSelector((state) => state);
  const [lab_validation, setlab_validation] = useState({
    thyrocare: false,
    redcliff: false,
  });
  const [lab_selcted, setlab_selcted] = useState("thyrocare");
  const [pincode_verified_data, setpincode_verified_data] = useState({
    pincode: "",
    latitude: 0,
    longitude: 0,
  });

  const [NavBarActive, setNavBarActive] = useState("");
  const [GetAppointnment, setGetAppointnment] = useState(false);
  const [ActiveProfile, setActiveProfile] = useState(false);
  const [BeginBtn, setBeginBtn] = useState(false);
  const [RangeCount, setRangeCount] = useState(0);
  const [BeginPage, setBeginPage] = useState("");
  const [Pages, setPages] = useState("begin");
  const singleBeginRange = false;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [booking_data, setbooking_data] = useState("");
  useEffect(() => {
    let t_id = window.location.search.slice(1);
    if (t_id && store.checktoken_data.token) {
    }
  }, [store.checktoken_data.token]);
  const check_user_data = (token) => {
    // let t_order_id = window.location.search.slice(1);
    let t_order_id = localStorage.getItem("user_data_to_show");
    if (!t_order_id) {
      console.log(1);
      navigate("/home");
      return;
    }
    t_order_id = JSON.parse(t_order_id).id;
    // console.log(t_order_id);
    checkToken(
      token || "",
      (success) => {
        if (success.customer == null) {
          navigate("/404");
        }
        MixPanelUsertype(success);
        dispatch({
          type: "setCheckToken",
          payload: { ...success, token: token },
        });
        if (success?.customer?.intake) {
          let test_booking = success.orderItems.filter(
            (el) => el._id === t_order_id
            // el.product_type == "test" &&
            // el.product_status == "Order Confirmed"
          )[0];
          console.log({ test_booking });
          if (test_booking?._id) {
            if (
              ["order confirmed", "test booked", "period reminder"].includes(
                test_booking.product_status.toLowerCase()
              )
            ) {
              setbooking_data(test_booking);
              // navigate("/bookingslot?" + test_booking);
              let pincode = success.customer.address.pin_code;
              let address = [
                // // success.customer.address.apt,
                // success.customer.address.address,
                // // success.customer.address.landmark,
                // success.customer.address.city,
                // // success.customer.address.pin_code,

                success.customer.address.apt,
                success.customer.address.address,
                success.customer.address.landmark,
                success.customer.address.city,
                success.customer.address.state,
              ].join(", ");
              checkPincode(
                token,
                {
                  pincode: pincode,
                  // pincode: "111111",
                  address: address,
                },
                (success) => {
                  setlab_validation({
                    thyrocare: !!success?.thyrocare?.serviceable,
                    redcliff: !!success?.redcliffe?.serviceable,
                    // thyrocare: false,
                    // redcliff: true,
                  });
                  setlab_selcted(
                    success?.thyrocare?.serviceable
                      ? "thyrocare"
                      : success?.redcliffe?.serviceable
                      ? "redcliffe"
                      : ""
                  );
                  setpincode_verified_data({
                    pincode: pincode,
                    latitude: success?.redcliffe?.latitude || 0,

                    longitude: success?.redcliffe?.longitude || 0,
                  });
                },
                (err) => {}
              );
            } else {
              console.log(2);
              navigate("/home");
            }
          } else {
            console.log(3);
            navigate("/home");
          }
        }
      },
      (err) => {}
    );
  };
  useEffect(() => {
    if (store?.checktoken_data?.token) {
      check_user_data(store.checktoken_data.token);
    } else {
      const local_token = localStorage.getItem("user_token_home");
      if (local_token) {
        check_user_data(local_token);
      } else {
        window.open("http://localhost:3000/", "_self");
      }
    }
    return () => {};
  }, [store.checktoken_data.token]);
  useEffect(() => {
    if (store?.checktoken_data?.token) {
      let pincode = store.checktoken_data.customer.address.pin_code;
      let address = [
        // // success.customer.address.apt,
        // store.checktoken_data.customer.address.address,
        // // success.customer.address.landmark,
        // store.checktoken_data.customer.address.city,
        // // success.customer.address.pin_code,
        store.checktoken_data.customer.address.apt,
        store.checktoken_data.customer.address.address,
        store.checktoken_data.customer.address.landmark,
        store.checktoken_data.customer.address.city,
        store.checktoken_data.customer.address.state,
      ].join(", ");
      checkPincode(
        store.checktoken_data.token,
        {
          pincode: pincode,
          // pincode: "111111",
          address: address,
        },
        (success) => {
          setlab_validation({
            thyrocare: !!success?.thyrocare?.serviceable,
            redcliff: !!success?.redcliffe?.serviceable,
            // thyrocare: false,
            // redcliff: true,
          });
          setlab_selcted(
            success?.thyrocare?.serviceable
              ? "thyrocare"
              : success?.redcliffe?.serviceable
              ? "redcliffe"
              : ""
          );
          setpincode_verified_data({
            pincode: pincode,
            latitude: success?.redcliffe?.latitude || 0,
            longitude: success?.redcliffe?.longitude || 0,
          });
        },
        (err) => {}
      );
    } else {
    }
    return () => {};
  }, [store.checktoken_data]);

  const [pagevalues, setpagevalues] = useState({
    menstralstate: "",
    date_time: new Date(),
    zipcode: "",
  });
  const fertility_main = "fertility-test-for-women";
  const [AllData, setAllData] = useState({
    last_recorder_order: {},
    last_order: {
      published: false,
    },
    last_intake: {},
    user_data: {},
    discount: {},
  });
  const [BookTime, setBookTime] = useState("");
  const [BookDate, setBookDate] = useState(new Date());
  const [FurtherDate, setFurtherDate] = useState(new Date());
  const [ListOfTimewithDate, setListOfTimewithDate] = useState([]);

  const [OverAllDateTime, setOverAllDateTime] = useState({
    date: new Date(),
    time: "",
    index: 0,
  });
  const [FurtherDataMixPanel, setFurtherDataMixPanel] = useState({
    sign_up_date: "",
    user_id: "",
    last_order_type: "",
    name: "",
    birthdate: "",
    is_test_user: false,
    user_current_goal: "",
    is_paid_user: false,
    reported_symptoms: "",
    reported_conditions: "",
    is_ultrasound_done: false,
    is_birth_control: false,
  });
  const [final_booking_data, setfinal_booking_data] = useState({
    type: lab_selcted,
    date: pagevalues.date || "",
    time: OverAllDateTime?.time?.slot?.split(" - ")[0],
    slot_id: OverAllDateTime.index,
    address: [
      store?.checktoken_data?.customer?.address?.apt,
      store?.checktoken_data?.customer?.address?.address,
      // ,
      // store?.checktoken_data?.customer?.address?.city,
      //  ,
    ].join(", "),
    landmark: store?.checktoken_data?.customer?.address?.landmark,
    pincode: store?.checktoken_data?.customer?.address?.pin_code,
    birthday: dateFormatter(
      store?.checktoken_data?.customer?.user?.dob,
      "YYYY-MM-DD"
    ),
    latitude: pincode_verified_data.latitude,
    longitude: pincode_verified_data.longitude,
    name: [
      store?.checktoken_data?.customer?.user?.first_name,
      store?.checktoken_data?.customer?.user?.last_name,
    ]
      .join(" ")
      .trim(),
    mobile: store.checktoken_data?.customer?.user?.phone?.replace(" ", ""),
    product: "",
    user_id: "",
    order_item_id: booking_data._id,
  });
  useEffect(() => {
    setfinal_booking_data((prev) => ({
      ...prev,
      type: lab_selcted,
      date: dateFormatter(OverAllDateTime?.date, "YYYY-MM-DD") || "",
      time: OverAllDateTime?.time?.slot?.split(" - ")[0],
      slot_id: OverAllDateTime?.time?.id,
      address: [
        store?.checktoken_data?.customer?.address?.apt,
        store?.checktoken_data?.customer?.address?.address,
        // store?.checktoken_data?.customer?.address?.landmark,
        // store?.checktoken_data?.customer?.address?.city,
        // store?.checktoken_data?.customer?.address?.state,
        // store?.checktoken_data?.customer?.address?.pin_code,
        // ,
        //  ,
      ].join(", "),
      landmark: store?.checktoken_data?.customer?.address?.landmark,
      pincode: store?.checktoken_data?.customer?.address?.pin_code,
      birthday: dateFormatter(
        store?.checktoken_data?.customer?.user?.dob,
        "YYYY-MM-DD"
      ),
      latitude: pincode_verified_data.latitude,
      longitude: pincode_verified_data.longitude,
      name: [
        store?.checktoken_data?.customer?.user?.first_name,
        store?.checktoken_data?.customer?.user?.last_name,
      ]
        .join(" ")
        .trim(),
      mobile: store.checktoken_data?.customer?.user?.phone?.replace(" ", ""),
      product: booking_data?.product_id,
      user_id: "",
      order_item_id: booking_data._id,
      comming_period: dateFormatter(FurtherDate, "YYYY-MM-DD"),
      // day: DayType[OverAllDateTime.index],
    }));
  }, [
    FurtherDate,
    lab_selcted,
    pagevalues,
    OverAllDateTime,
    store,
    pincode_verified_data,
    booking_data,
  ]);

  useEffect(() => {
    if (new Date().getHours() >= 12) {
      const presentDate = new Date();
      const nextDate = new Date(presentDate.getTime() + 24 * 60 * 60 * 1000);
      // const nextDate = new Date(presentDate.getTime());
      setBookDate(nextDate);
      setpagevalues((prev) => ({
        ...prev,
        date_time: nextDate,
      }));
      setFurtherDate(nextDate);
    } else {
      setpagevalues((prev) => ({
        ...prev,
        date_time: new Date(),
      }));
    }
  }, [pagevalues.zipcode]);
  const [MiniDate, setMiniDate] = useState(new Date());
  const [MaxDate, setMaxDate] = useState(new Date());
  useEffect(() => {
    if (new Date().getHours() >= 12) {
      const presentDate = new Date();
      const nextDate = new Date(presentDate.getTime() + 24 * 60 * 60 * 1000);
      setMiniDate(nextDate);
    }
    const presentDate = new Date();
    const nextDate = new Date(presentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    setMaxDate(nextDate);
  }, []);
  useEffect(() => {
    let presentDate = new Date();
    if (new Date().getHours() >= 12) {
      presentDate = presentDate.setDate(presentDate.getDate() + 1);
      getSlots(
        store.checktoken_data.token,
        {
          type: lab_selcted,
          date: presentDate,
          ...pincode_verified_data,
        },

        (success) => {
          setListOfTimewithDate(success);
        },
        (err) => {}
      );
    } else {
      getSlots(
        store.checktoken_data.token,
        {
          type: lab_selcted,
          date: presentDate,
          ...pincode_verified_data,
        },

        (success) => {
          setListOfTimewithDate(success);
        },
        (err) => {}
      );
    }
  }, [lab_selcted, lab_validation, store.checktoken_data]);

  return (
    <div className="new_bookslot">
      <div className="bookslot_header">
        <div className="container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            onClick={() => {
              navigate(-1);
            }}
          >
            <path
              d="M13.1625 15.5876C13.3386 15.7637 13.4375 16.0026 13.4375 16.2516C13.4375 16.5007 13.3386 16.7396 13.1625 16.9157C12.9863 17.0918 12.7475 17.1908 12.4984 17.1908C12.2493 17.1908 12.0105 17.0918 11.8343 16.9157L5.58433 10.6657C5.49693 10.5786 5.42759 10.4751 5.38027 10.3611C5.33295 10.2472 5.30859 10.125 5.30859 10.0016C5.30859 9.87824 5.33295 9.75606 5.38027 9.64211C5.42759 9.52815 5.49693 9.42466 5.58433 9.33756L11.8343 3.08756C12.0105 2.91144 12.2493 2.8125 12.4984 2.8125C12.7475 2.8125 12.9863 2.91144 13.1625 3.08756C13.3386 3.26368 13.4375 3.50255 13.4375 3.75163C13.4375 4.0007 13.3386 4.23957 13.1625 4.41569L7.5773 10.0008L13.1625 15.5876Z"
              fill="#5F1D1B"
            />
          </svg>
          <Logo
            onClick={() => {
              navigate("/home");
            }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M13.1625 15.5876C13.3386 15.7637 13.4375 16.0026 13.4375 16.2516C13.4375 16.5007 13.3386 16.7396 13.1625 16.9157C12.9863 17.0918 12.7475 17.1908 12.4984 17.1908C12.2493 17.1908 12.0105 17.0918 11.8343 16.9157L5.58433 10.6657C5.49693 10.5786 5.42759 10.4751 5.38027 10.3611C5.33295 10.2472 5.30859 10.125 5.30859 10.0016C5.30859 9.87824 5.33295 9.75606 5.38027 9.64211C5.42759 9.52815 5.49693 9.42466 5.58433 9.33756L11.8343 3.08756C12.0105 2.91144 12.2493 2.8125 12.4984 2.8125C12.7475 2.8125 12.9863 2.91144 13.1625 3.08756C13.3386 3.26368 13.4375 3.50255 13.4375 3.75163C13.4375 4.0007 13.3386 4.23957 13.1625 4.41569L7.5773 10.0008L13.1625 15.5876Z"
              fill="#5F1D1B"
            />
          </svg>
        </div>
        {BeginBtn && false ? (
          <Range
            {...{
              startRange: BeginBtn,
              range: RangeCount,
              singleBeginRange: singleBeginRange,
              BeginPage: BeginPage,
              totalcount:
                AllData?.last_order?.data?.endPoint ==
                "fertility-test-for-women"
                  ? 4
                  : 3,
              type: "New",
            }}
          />
        ) : (
          ""
        )}
      </div>
      {/* <NavbarMain
          {...{
            setNavBarActive: setNavBarActive,
            NavBarActive: NavBarActive,
            NavDropActive: true,
            MiniNavi: GetAppointnment,
            setMiniNavi: setGetAppointnment,
            ActiveProfile: ActiveProfile,
            setActiveProfile: setActiveProfile,
          }}
        /> */}

      {/* <div className="book_slot_v2_sections"> */}
      <BookSlotsV2AllPages
        {...{
          page_name: Pages,
          otherData: {
            booking_data,
            final_booking_data,
            pincode_verified_data,
            lab_selcted,
            setlab_selcted,
            lab_validation,
            booking_data,
            singleBeginRange: singleBeginRange,
            setBeginPage: setBeginPage,
            pagevalues: pagevalues,
            setpagevalues: setpagevalues,
            begin_range: setBeginBtn,
            range_count: setRangeCount,
            page: setPages,
            FurtherDataMixPanel: FurtherDataMixPanel,
            AllData: AllData,
            ListOfTimewithDate: ListOfTimewithDate,
            FurtherDate: FurtherDate,
            BookTime,
            setBookTime,
            OverAllDateTime,
            setOverAllDateTime,
            BookDate,
            setBookDate,
            MiniDate,
            setMiniDate,
            MaxDate,
            setMaxDate,
            FurtherDate,
            setFurtherDate,
            setAllData,
          },
        }}
      />
      {/* </div> */}
    </div>
  );
};

export default NewBookingSlot;
