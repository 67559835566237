import React, { useEffect, useState } from "react";
import { get_clinics_list } from "../../../../component/API/api";
import { useParams } from "react-router-dom";
import { ArticleContent } from "../../../Blogs/ArticleContent";
import { StoryContainer } from "./StoryContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { AddClinic } from "../../../../component/API/new_api";
import { useSelector } from "react-redux";

export const ClinicDetail = () => {
  const navigate = useNavigate();
  const store = useSelector((state) => state);
  const [ClinicsDetail, setClinicsDetail] = useState({
    media: [
      {
        imageUrl: "",
      },
    ],
  });
  const [stories, setstories] = useState([]);
  const params = useParams();
  useEffect(() => {
    get_clinics_list((res) => {
      setClinicsDetail({
        ...res?.response?.data?.clinics?.filter(
          (item) => item._id == params.slug
        )[0],
      });
    });
  }, []);
  const [AboutShow, setAboutShow] = useState(false);
  const [OurShow, setOurShow] = useState(false);
  const [OpenPages, setOpenPages] = useState("");

  useEffect(() => {
    setstories(
      ClinicsDetail?.stories?.map((el) => ({
        type: el?.image?._type == "image" ? "img" : "video",
        img: el?.imageUrl || el.url,
      })) || []
    );
  }, [ClinicsDetail]);
  // console.log(store.checktoken_data.customer.clinic.clinicId);

  return (
    <>
      <div style={{ display: "none" }}>
        {stories.map(({ type, img }) =>
          type == "img" ? <img src={img} /> : <video src={img} />
        )}
      </div>
      {OpenPages == "callback" ? (
        <div className="callback_confirmation">
          <div className="callback_confirmation_sec">
            <div
              className="clinic_detail_img"
              style={{
                backgroundImage: `url(${ClinicsDetail?.media[0]?.imageUrl})`,
              }}
            ></div>
            <div className="callback_title">{ClinicsDetail?.name}</div>
            <div className="callback_desc">
              We'll be in touch soon. Anticipate a call from our clinic shortly.
            </div>
          </div>
          <div className="callback_btn">
            <div onClick={() => navigate("/home")}>OK</div>
          </div>
        </div>
      ) : (
        ""
      )}
      {OpenPages == "stories" ? (
        <div className="stories_main">
          <StoryContainer
            data={{
              storyArray: [
                ...stories,
                // {
                //   type: "img",
                //   img: "https://imgur.com/ekV0oqC.jpeg",
                // },
                // {
                //   type: "img",
                //   img: "https://imgur.com/wU0Zx2q.jpeg",
                // },
                // {
                //   type: "video",
                //   img: "https://chat-brianna.s3.us-east-2.amazonaws.com/chat/testimg_chat/VID-20230921-WA0031%20%281%29_1706612878588.mp4",
                // },
                // {
                //   type: "video",
                //   img: "https://chat-brianna.s3.us-east-2.amazonaws.com/chat/testimg_chat/72607868055__61FCB6AB-50DD-4156-A3C2-E8F0CDAC46E9_1704385894089.quicktime",
                // },
                // {
                //   type: "img",
                //   img: "https://imgur.com/lMu64gX.jpeg",
                // },
              ],
              ClinicsDetail,
              setOpenPages,
            }}
          />
        </div>
      ) : (
        ""
      )}
      <div className="main_clinic_detail">
        <div
          className="clinic_go_back_btn"
          onClick={() => {
            navigate("/clinic/all");
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3366_547)">
              <path
                d="M8.33333 15.8346L2.5 10.0013M2.5 10.0013L8.33333 4.16797M2.5 10.0013H17.5"
                stroke="#5F1D1B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_3366_547">
                <rect
                  width="20"
                  height="20"
                  rx="10"
                  transform="matrix(-1 0 0 1 20 0)"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
          <svg
            class="logo"
            xmlns="http://www.w3.org/2000/svg"
            width="81"
            height="20"
            viewBox="0 0 81 20"
            fill="none"
          >
            <path
              d="M1.09588 19.83H4.09871C4.29701 19.83 4.52364 19.7167 4.60863 19.4901L6.28002 15.2125H15.8268L17.4981 19.4901C17.5831 19.7167 17.8098 19.83 18.0081 19.83H21.0109C21.4925 19.83 21.8324 19.4051 21.6341 18.9235L14.3253 0.339944C14.2404 0.113315 14.0137 0 13.8154 0H8.29135C8.09305 0 7.86642 0.113315 7.78143 0.339944L0.472652 18.9235C0.302681 19.3768 0.614295 19.83 1.09588 19.83ZM7.7531 11.5297L11.0676 2.91785L14.4103 11.5297H7.7531Z"
              fill="#5F1D1B"
            ></path>
            <path
              d="M40.0975 20C40.5791 20 40.9474 19.915 40.9474 19.1501V16.8839C40.9474 16.4589 40.8341 16.3173 40.3808 16.289C38.9927 16.2323 38.936 13.5694 37.123 11.898C38.9077 10.7932 39.8992 8.95184 39.8992 6.60057C39.8992 2.63456 37.0947 0 32.5904 0H23.9502C23.6952 0 23.5253 0.141643 23.5253 0.424929V19.4051C23.5253 19.6601 23.6952 19.83 23.9502 19.83H27.0664C27.3496 19.83 27.4913 19.6601 27.4913 19.4051V13.2011H31.8822C36.2165 13.2011 34.3185 20 40.0975 20ZM27.4913 9.51841V3.68272H32.7321C34.7151 3.68272 35.9332 4.70255 35.9332 6.60057C35.9332 8.49858 34.7151 9.51841 32.7321 9.51841H27.4913Z"
              fill="#5F1D1B"
            ></path>
            <path
              d="M49.4827 19.83H53.2787C53.477 19.83 53.7037 19.7167 53.7886 19.4901L61.664 0.906516C61.8623 0.424929 61.5224 0 61.0408 0H57.8396C57.6413 0 57.4147 0.113315 57.3297 0.339944L51.3807 15.2691L45.4317 0.339944C45.3467 0.113315 45.1201 0 44.9218 0H41.7207C41.2391 0 40.8991 0.424929 41.0974 0.906516L48.9728 19.4901C49.0578 19.7167 49.2844 19.83 49.4827 19.83Z"
              fill="#5F1D1B"
            ></path>
            <path
              d="M59.982 19.83H62.9848C63.1831 19.83 63.4097 19.7167 63.4947 19.4901L65.1661 15.2125H74.7129L76.3842 19.4901C76.4692 19.7167 76.6959 19.83 76.8942 19.83H79.897C80.3786 19.83 80.7185 19.4051 80.5202 18.9235L73.2114 0.339944C73.1265 0.113315 72.8998 0 72.7015 0H67.1775C66.9791 0 66.7525 0.113315 66.6675 0.339944L59.3588 18.9235C59.1888 19.3768 59.5004 19.83 59.982 19.83ZM66.6392 11.5297L69.9537 2.91785L73.2964 11.5297H66.6392Z"
              fill="#5F1D1B"
            ></path>
          </svg>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3366_547)">
              <path
                d="M8.33333 15.8346L2.5 10.0013M2.5 10.0013L8.33333 4.16797M2.5 10.0013H17.5"
                stroke="#5F1D1B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_3366_547">
                <rect
                  width="20"
                  height="20"
                  rx="10"
                  transform="matrix(-1 0 0 1 20 0)"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          className="clinic_detail_img"
          style={{
            backgroundImage: `url(${ClinicsDetail?.media[0]?.imageUrl})`,
          }}
        ></div>
        <div className="clinic_detail_profile_par">
          <div
            className="clinic_detail_profile"
            onClick={() => {
              setOpenPages("stories");
            }}
          >
            {/* <img src="https://cdn.sanity.io/images/nao51hyy/production/53cdba3ef7debf99dcca066d54c21a8b6ea28f59-120x121.png" /> */}
            <img src={ClinicsDetail?.docPhoto?.imageUrl} />
          </div>
        </div>
        <div className="clinic_detail_title">{ClinicsDetail?.name}</div>
        <div
          className="clinic_detail_addres"
          onClick={() => {
            window.open(
              `https://www.google.com/maps?saddr=My+Location&daddr=${ClinicsDetail?.address}`
            );
          }}
        >
          {ClinicsDetail?.address}
        </div>
        <div className="clinic_detail_phone">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clip-path="url(#clip0_2062_637)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.86343 2.36195C2.74343 1.43495 3.05443 1.17495 3.42943 1.05395C3.68895 0.985951 3.96112 0.982172 4.22243 1.04295C4.56643 1.14295 4.65743 1.21895 5.7854 2.34295C6.7764 3.32995 6.8754 3.43695 6.9704 3.62995C7.1521 3.96873 7.1805 4.36894 7.0484 4.72995C6.9484 5.00495 6.8064 5.18695 6.2054 5.78995L5.8134 6.183C5.7105 6.2876 5.6863 6.4464 5.7534 6.577C6.6244 8.0628 7.86 9.3019 9.3434 10.177C9.5142 10.2684 9.7245 10.2389 9.8634 10.104L10.2404 9.733C10.4734 9.4941 10.7202 9.2691 10.9794 9.059C11.3866 8.809 11.8939 8.7867 12.3214 9C12.5304 9.1 12.5994 9.162 13.6214 10.182C14.6754 11.233 14.7054 11.266 14.8214 11.507C15.0397 11.9059 15.0374 12.3891 14.8154 12.786C14.7024 13.01 14.6334 13.091 14.0404 13.697C13.6824 14.063 13.3454 14.397 13.2914 14.446C12.8022 14.851 12.1746 15.0497 11.5414 15C10.383 14.8944 9.2617 14.5363 8.2564 13.951C6.0296 12.7711 4.13383 11.0521 2.74243 8.951C2.43937 8.5112 2.16994 8.0492 1.93643 7.569C1.31001 6.4953 0.98653 5.27189 1.00043 4.02895C1.04825 3.37871 1.36008 2.77637 1.86343 2.36195Z"
                stroke="#777777"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_2062_637">
                <rect width="15.9835" height="16.0075" fill="white" />
              </clipPath>
            </defs>
          </svg>

          {ClinicsDetail?.phone_number
            ? "+91" +
              " " +
              ClinicsDetail?.phone_number?.slice(0, 5) +
              " " +
              ClinicsDetail?.phone_number?.slice(5)
            : ""}
        </div>
        <div className="clinic_detail_location">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7687 11.1045L8.94006 13.9331C8.81636 14.057 8.66946 14.1552 8.50777 14.2222C8.34608 14.2892 8.17276 14.3237 7.99773 14.3237C7.8227 14.3237 7.64938 14.2892 7.48769 14.2222C7.326 14.1552 7.1791 14.057 7.0554 13.9331L4.22607 11.1045C3.48021 10.3586 2.97229 9.40827 2.76652 8.37371C2.56076 7.33916 2.66639 6.26681 3.07007 5.29229C3.47374 4.31777 4.15733 3.48483 5.03439 2.89881C5.91144 2.31279 6.94258 2 7.9974 2C9.05222 2 10.0834 2.31279 10.9604 2.89881C11.8375 3.48483 12.5211 4.31777 12.9247 5.29229C13.3284 6.26681 13.434 7.33916 13.2283 8.37371C13.0225 9.40827 12.5146 10.3586 11.7687 11.1045Z"
              stroke="#777777"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 7.33203C10 7.86246 9.78929 8.37117 9.41421 8.74625C9.03914 9.12132 8.53043 9.33203 8 9.33203C7.46957 9.33203 6.96086 9.12132 6.58579 8.74625C6.21071 8.37117 6 7.86246 6 7.33203C6 6.8016 6.21071 6.29289 6.58579 5.91782C6.96086 5.54274 7.46957 5.33203 8 5.33203C8.53043 5.33203 9.03914 5.54274 9.41421 5.91782C9.78929 6.29289 10 6.8016 10 7.33203Z"
              stroke="#777777"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          {ClinicsDetail?.area}
        </div>
        <div className="clinic_detail_line"></div>
        <div className="clinic_detail_bestknowfor">
          {ClinicsDetail?.bestKnownFor?.map((el, i) => (
            <div className="clinic_detail_bestknowfor_box">
              <div className="clinic_detail_bestknowfor_title">{el.title}</div>
              <div className="clinic_detail_bestknowfor_subtitle">
                {el.subtitle}
              </div>
            </div>
          ))}
        </div>
        <div className="clinic_detail_line"></div>
        <div className="clinic_detail_about">
          <div className="clinic_detail_about_title">About this clinic</div>
          <div
            className={
              "clinic_detail_about_desc" +
              (!AboutShow ? "" : " clinic_detail_about_desc_expand")
            }
          >
            {ClinicsDetail?.about}
          </div>
          <div
            className="clinic_detail_about_show_collect"
            onClick={() => {
              setAboutShow((prev) => !prev);
            }}
          >
            {!AboutShow ? "Show more" : "Show less"}
          </div>
        </div>
        <div className="clinic_detail_line"></div>
        <div className="clinic_detail_dr">
          <div className="clinic_detail_dr_title">Fertility specialist</div>
          <div className="clinic_detail_dr_box">
            <div className="clinic_detail_dr_box_img">
              <img src={ClinicsDetail?.docPhoto?.imageUrl} />
            </div>
            <div className="clinic_detail_dr_box_info">
              <div className="clinic_detail_dr_box_info_name">
                {ClinicsDetail.docName}
              </div>
              <div className="clinic_detail_dr_box_info_quantity">
                {ClinicsDetail.docQualification}
              </div>
              <div className="clinic_detail_dr_box_info_doctage">
                {ClinicsDetail?.docTags?.map((el, i) => (
                  <div>{el}</div>
                ))}
              </div>
            </div>
          </div>
          <div className="clinic_detail_dr_dots">
            {ClinicsDetail?.description?.map((el) => (
              <ArticleContent content={el} />
            ))}
          </div>
        </div>
        <div className="clinic_our_take">
          <div className="clinic_detail_about_title">Arva's take</div>
          <div
            className={
              "clinic_detail_about_desc" +
              (!OurShow ? "" : " clinic_detail_about_desc_expand")
            }
          >
            {ClinicsDetail?.our_take?.map((el) => (
              <ArticleContent content={el} />
            ))}
            {/* {ClinicsDetail?.our_take[0]} */}
          </div>
          <div
            className="clinic_detail_about_show_collect"
            onClick={() => {
              setOurShow((prev) => !prev);
            }}
          >
            {!OurShow ? "Show more" : "Show less"}
          </div>
        </div>
      </div>

      {store?.checktoken_data?.customer?.clinic?.clinicId == params.slug ? (
        <div className="clinic_btn_cont">
          <div>We will be in touch with you shortly</div>
        </div>
      ) : (
        ""
      )}
      {store?.checktoken_data?.customer?.clinic?.clinicId ? (
        ""
      ) : (
        <div className="clinic_btn_cont">
          <div
            onClick={() => {
              AddClinic(
                store.checktoken_data.token,
                params.slug,
                () => {
                  setOpenPages("callback");
                },
                () => {}
              );
            }}
          >
            Request callback
          </div>
        </div>
      )}
    </>
  );
};
