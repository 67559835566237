import React from "react";
import { GuideBuyingPage } from "./GuideBuyingPage";
import { GuidePayment } from "./GuidePayment";
import { GuidePlanDetail } from "./GuidePlanDetail";
import { CommonLogin } from "../../../component/CommonLogin";

export const GuideAllPages = ({ page_name, otherData }) => {
  const page_type = {
    buyingPage: (
      <GuideBuyingPage
        {...{
          AllData: otherData.AllData,
          GuideData: otherData.GuideData,
          setAllData: otherData.setAllData,
          setPages: otherData.setPages,
          setOpenCurated: otherData.setOpenCurated,
        }}
      />
    ),
    login: (
      <CommonLogin
        {...{
          AllData: otherData.AllData,
          GuideData: otherData.GuideData,
          setAllData: otherData.setAllData,
          setPages: otherData.setPages,
          setOpenCurated: otherData.setOpenCurated,
          // PleaseLogin,
          // setPleaseLogin,
        }}
      />
    ),
    payment: (
      <GuidePayment
        {...{
          AllData: otherData.AllData,
          setAllData: otherData.setAllData,
          GuideData: otherData.GuideData,
          setPages: otherData.setPages,
          setOpenCurated: otherData.setOpenCurated,
        }}
      />
    ),
    planDetail: (
      <GuidePlanDetail
        {...{
          AllData: otherData.AllData,
          GuideData: otherData.GuideData,
          setAllData: otherData.setAllData,
          setPages: otherData.setPages,
          setOpenCurated: otherData.setOpenCurated,
        }}
      />
    ),
  };
  if (page_type[page_name]) {
    return page_type[page_name];
  } else {
    return <div>No Pages</div>;
  }
};
