import React from "react";
import { MixPanelResultSection } from "../API/automation_api";

export const HighLevel1 = ({ list, onClickHL, check, FurtherDataMixPanel }) => {
  return (
    <>
      <div className="main_high_level1">
        {list.map((el, i) => (
          <div
            key={i}
            className={
              "main_high_level_box " +
              (check === el.title ? " main_high_level_box_active" : "")
            }
            onClick={() => {
              onClickHL(el.title);
              MixPanelResultSection({
                s_name: el.name,
                remaining: FurtherDataMixPanel,
              });
            }}
          >
            <div>{el.name}</div>
          </div>
        ))}
      </div>
    </>
  );
};
