const default_appdata = {
  blog_list: [],
  updated: false,
};
export const checktoken = {
  ...default_appdata,
};

const BlogsSanity = (state = checktoken, { type, payload }) => {
  //
  switch (type) {
    case "setBlogSanity":
      return { ...state, ...payload };
    default:
      return state;
  }
};
export default BlogsSanity;
