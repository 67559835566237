import React, { useEffect, useLayoutEffect, useState } from "react";
import CustButton from "../../CustButton/CustButton";
import { CheckTokenUser, SendOtp, ResendOtp } from "../../API/api";
import { useNavigate } from "react-router-dom";
import {
  BeehivLead,
  BeehivPaid,
  BeehivPayment,
  FbDetail,
  MixPanelLogin,
  MixPanelOtp,
  MixPanelOtpEntered,
  MixPanelOtpVerified,
  MixPanelSignInComplete,
  MixPanelUsertype,
  VerifyOtp,
} from "../../API/automation_api";
import { verifyOtp } from "../../API/new_api";
import { useSelector } from "react-redux";

const OtpStand = ({
  value,
  setOtpVerified,
  pagevalues,
  setResend,
  handel_login,
  setloading,
}) => {
  const store = useSelector((state) => state);
  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const [error_otp, seterror_otp] = useState("");
  const [count, setCount] = useState(30); // Initial counter value
  const otp = otpValues.join("");
  const handleInputChange = (index, value) => {
    const updatedValues = [...otpValues];
    updatedValues[index] = value;
    setOtpValues(updatedValues);
    seterror_otp("");
    // Move focus to the next input field
    if (value && index < otpValues.length - 1) {
      const nextInput = document.getElementsByName(`otp-${index + 1}`)[0];
      nextInput.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otpValues[index] && index > 0) {
      // Move focus to the previous input field
      const previousInput = document.getElementsByName(`otp-${index - 1}`)[0];
      previousInput.focus();
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      }
    }, 1000); // Update every 1000 milliseconds (1 second)

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, [count]);
  const formattedCount = count < 10 ? `0${count}` : count;
  const fertility_main = "fertility-test-for-women";
  useEffect(() => {
    if (otpValues.join("").length == 4) {
      setloading("type1");
      verifyOtp(
        {
          prefix: store?.checktoken_data?.prefic?.code?.slice(1),
          phone: store?.checktoken_data?.phone?.replaceAll(" ", "").slice(-10),
          otp,
        },
        (success) => {
          console.log(success);
          MixPanelOtpEntered();
          if (success.status) {
            setloading(false);
            localStorage.setItem("user_token_home", success.token);
            setOtpVerified(true);
            MixPanelSignInComplete({
              is_first_time_user: !success?.user?.address?.address,
              sign_in_method: "Email/Mobile",
            });
          } else {
            setloading(false);
            seterror_otp(true);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [otpValues]);
  useLayoutEffect(() => {
    document.getElementById("otp1").focus();
  }, []);

  return (
    <>
      <div className="otpStand_main">
        <div className="opt_stand">
          {otpValues.map((value, index) => (
            <input
              key={index}
              type="number"
              maxLength="1"
              className={
                "opt_design " +
                // (otp != value ? "opt_design_active " :"") +
                (value != "" ? "opt_design_active " : "") +
                (error_otp ? " error_o" : "")
              }
              inputMode="numeric"
              id={"otp" + (index + 1)}
              name={`otp-${index}`}
              placeholder=""
              value={value}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
            />
          ))}
        </div>
        <div className="otpStand_desc">
          Please enter the correct OTP to proceed
        </div>
        {count === 0 ? (
          //   <div className="resend_btn">Resend</div>
          <CustButton
            custClass="resend_btn"
            btn_obj={{
              txt: "Resend OTP",
              onclick: () => {
                setCount(30);
                // MixPanelOtp({
                //   edit: EditPhone,
                //   resend: true,
                //   remaining: {
                //     sign_up_date: "N/A",
                //     user_id: "N/A",
                //     last_order_type: "N/A",
                //     name: "N/A",
                //     birthdate: "N/A",
                //     is_test_user: false,
                //     user_current_goal: "N/A",
                //     is_paid_user: false,
                //     reported_symptoms: "N/A",
                //     reported_conditions: "N/A",
                //     is_ultrasound_done: false,
                //     is_birth_control: false,
                //   },
                // });
                setResend(true);
                handel_login();
              },
            }}
          />
        ) : (
          <div className="otpStand_desc2">
            Resend OTP in 00:{formattedCount}
          </div>
        )}
      </div>
    </>
  );
};

export default OtpStand;
