import React from "react";
import {
  MixPanelResultClick,
  MixPanelResultSection,
} from "../API/automation_api";
import { reportColor } from "../../utils/results";

export const ListReports = ({
  list,
  setMiniNavi,
  setDataSelect,
  FurtherDataMixPanel,
  data,
}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  function asset(url) {
    if (!url) {
      return "";
    }
    let a = url?.split("-");
    return `https://cdn.sanity.io/${a[0]}s/nao51hyy/production/${a
      .slice(1, -1)
      .join("-")}.${a[a.length - 1]}`;
  }
  //
  console.log(list);
  return (
    <div className="list_report_main">
      {/* <div className="list_report_title">Insights & reports</div> */}
      <div className="list_report_data">
        {list?.map((el, i) => (
          <div key={i} className="list_report_data_box">
            <div
              className="list_report_data_box_left"
              // style={{ backgroundColor: el.color }}
            >
              {/* <img src={asset(el?.image.asset?._ref)} /> */}
              <img src={el?.image} />
            </div>
            <div className="list_report_data_box_right">
              <div>{el.name}</div>
              <div className="desc2">{el.description}</div>
              {typeof el?.chip == "string" ? (
                <div
                  style={{
                    color: el?.fcolor || "#B418D1",
                    backgroundColor: el?.bcolor || "#F8E8FA",
                  }}
                  className="list_report_data_box_right_chip"
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8.66667 4.6665H14M14 4.6665V9.99984M14 4.6665L8.66667 9.99984L6 7.33317L2 11.3332"
                      stroke={el?.fcolor || "#B418D1"}
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div>
                    Sign of <span className="inner_box_chip">{el.chip}</span>
                  </div>
                </div>
              ) : (
                el.chips
                  ?.filter((el1) => el1 != "Danger")
                  .map((el2) => (
                    <div
                      style={{
                        // color: el?.fcolor || "#B418D1",
                        // backgroundColor: el?.bcolor || "#F8E8FA",
                        color: reportColor[el2.color]?.f || "#B418D1",
                        backgroundColor: reportColor[el2.color]?.b || "#F8E8FA",
                      }}
                      className="list_report_data_box_right_chip"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8.66667 4.6665H14M14 4.6665V9.99984M14 4.6665L8.66667 9.99984L6 7.33317L2 11.3332"
                          stroke={reportColor[el2.color]?.f || "#B418D1"}
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div>
                        Sign of{" "}
                        <span className="inner_box_chip">{el2.txt}</span>
                      </div>
                    </div>
                  ))
              )}

              <div
                className="view_btn"
                onClick={() => {
                  setMiniNavi(true);
                  setDataSelect(el);
                  MixPanelResultClick({
                    name: el.name,
                    remaining: FurtherDataMixPanel,
                  });
                  scrollToTop();
                }}
              >
                View full report
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
