import React, { useEffect, useState } from 'react'
import { NavbarMain } from '../component/NavbarMain'
import { useNavigate } from 'react-router-dom';
import { BottomNav } from "../component/BottomNav";
import { CheckTokenUser } from '../component/API/api';
export const Community = ({setloading}) => {
  const [NavBarActive, setNavBarActive] = useState("");
  const navigate = useNavigate();
  const [MiniNavi, setMiniNavi] = useState(false);

  useEffect(() => {
    setloading("type1")
    const data = localStorage.getItem("user_token");
    if (data) {
      CheckTokenUser(
        {
          token: data,
        },
        (res) => {
          if(res.status){
            setloading(false)
          }
          if (!res.status) {
            setloading(false);
            localStorage.clear()
            return navigate("/login");
          }
        }
      );
    } else {
      navigate("/login");
      // if(res.status){
        setloading(false)
      // }
    }
  }, [])
  
  const [ActiveProfile, setActiveProfile] = useState(true);
  return (
    <>
     <NavbarMain  
        {...{
          setNavBarActive: setNavBarActive,
          NavBarActive: NavBarActive,
          NavDropActive: true,
          MiniNavi: MiniNavi,
          setMiniNavi: setMiniNavi,
          ActiveProfile: ActiveProfile,
          setActiveProfile: setActiveProfile,
        }}
      />
      <div className='community_main'>
        Community
      </div>
      {/* <BottomNav /> */}
    </>
  )
}
