import React from "react";
import { date_formatter } from "../../component/CustInput/CustInput";
import { useSelector } from "react-redux";
import { calculateAge, dateFormatter } from "../../component/API/new_api";

export const ProfileMedical = ({
  pagevalues,
  pagedetail,
  setPagevalues,
  singleBeginRange,
  profile,
  alldata,
}) => {
  const store = useSelector((state) => state);

  //
  const birthdateString = store?.checktoken_data?.customer.user?.dob;

  // Parse your birthdate string into a Date object
  const birthdate = new Date(birthdateString);

  // Get the current date
  const currentDate = new Date();

  // Calculate the age in years
  const ageInMilliseconds = currentDate - birthdate;
  const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);
  console.log(ageInYears);
  return (
    <>
      <div className="profilemedical_main">
        {/* <div className="profilemedical_title">Profile & medical history</div> */}
        <div className="profilemedical_section1">
          <div className="profilemedical_section1_title">Personal details</div>
          <div className="profilemedical_section1_name">
            <div className="profilemedical_section1_name2">NAME</div>
            <div className="profilemedical_section1_name3">
              {/* {pagedetail.f_name === " "
                ? "-"
                : pagedetail.f_name + " " + pagedetail.l_name} */}
              {[
                store?.checktoken_data?.customer.user?.first_name,
                store?.checktoken_data?.customer.user?.last_name,
              ]
                .join(" ")
                .trim() || "-"}
            </div>
          </div>
          <div className="profilemedical_section1_name">
            <div className="profilemedical_section1_name2">Email</div>
            <div className="profilemedical_section1_name_email3">
              {/* {pagedetail.email === " " ? "-" : pagedetail.email} */}
              {store?.checktoken_data?.customer.user?.email?.trim() || "-"}
            </div>
          </div>

          <div className="profilemedical_section1_name">
            <div className="profilemedical_section1_name2">Phone</div>
            <div className="profilemedical_section1_name3">
              <div>
                {store?.checktoken_data?.customer.user?.phone === ""
                  ? "-"
                  : `+91 ${(
                      store?.checktoken_data?.customer.user?.phone + ""
                    ).slice(-10)}`}
              </div>
            </div>
          </div>
          {/* <div className="profilemedical_section1_birthdate">
            <div className="profilemedical_section1_birthdate_title">
              BIRTHDAY1
            </div>
            <div className="profilemedical_section1_birthdate_date">
              <div>
                {!store?.checktoken_data?.customer.user?.dob
                  ? "-"
                  : dateFormatter(
                      new Date(store?.checktoken_data?.customer.user?.dob),
                      "DD/MM/YYYY"
                    )}
              </div>
              <div>
                {!pagedetail?.birth_date
                  ? "-"
                  : `${Math.floor(
                      calculateAge(store?.checktoken_data?.customer.user?.dob)
                    )} yrs`}
              </div>
            </div>
          </div>
          <div className="profilemedical_section1_body_detail">
            <div className="profilemedical_section1_body_height">
              <div className="profilemedical_section1_body_height_title">
                HEIGHT
              </div>
              <div className="profilemedical_section1_body_height_ans">
                {!store?.checktoken_data?.customer?.intake?.personalize
                  ?.height_feet
                  ? "-"
                  : `${
                      store?.checktoken_data?.customer?.intake?.personalize
                        ?.height_feet
                    } ft ${Number(
                      store?.checktoken_data?.customer?.intake?.personalize
                        ?.height_inch
                    )} in`}
              </div>
            </div>
            <div className="profilemedical_section1_body_height">
              <div className="profilemedical_section1_body_height_title">
                WEIGHT
              </div>
              <div className="profilemedical_section1_body_height_ans">
                {!store?.checktoken_data?.customer?.intake?.personalize?.weight
                  ? "-"
                  : `${store?.checktoken_data?.customer?.intake?.personalize.weight} kgs`}
              </div>
            </div>
            <div className="profilemedical_section1_body_height">
              <div className="profilemedical_section1_body_height_title">
                WAIST
              </div>
              <div className="profilemedical_section1_body_height_ans">
                {!store?.checktoken_data?.customer?.intake?.personalize
                  ?.waist_inch
                  ? "-"
                  : `${store?.checktoken_data?.customer?.intake?.personalize?.waist_inch} in`}
              </div>
            </div>
          </div> */}
        </div>

        <div className="profilemedical_section2">
          <div
            className="profilemedical_section2_title"
            style={
              store?.checktoken_data?.customer?.address?.address
                ? {}
                : {
                    margin: 0,
                  }
            }
          >
            Address
          </div>
          <div className="profilemedical_section2_desc">
            {[
              store?.checktoken_data?.customer?.address?.address,
              store?.checktoken_data?.customer?.address?.apt,
              store?.checktoken_data?.customer?.address?.landmark,
              store?.checktoken_data?.customer?.address?.city,
              store?.checktoken_data?.customer?.address?.pin_code,
              // pagedetail.address || "-",
              // pagedetail?.apt,
              // pagedetail?.city,
              // pagedetail?.landmark,
              // pagedetail?.zipcode,
            ]
              .filter((el) => el)
              .map((el) => (
                <div>{el}</div>
              ))}
          </div>
        </div>
        <div className="profilemedical_section3">
          <div className="profilemedical_section3_title">
            Personal Information
          </div>
          <div className="profilemedical_section3_symptoms">
            <div className="profilemedical_section3_symptoms_title">
              BIRTHDAY
            </div>
            <div className="profilemedical_section3_symptoms_desc item_flex">
              <div>
                {!store?.checktoken_data?.customer.user?.dob
                  ? "-"
                  : dateFormatter(
                      new Date(store?.checktoken_data?.customer.user?.dob),
                      "DD/MM/YYYY"
                    )}
              </div>
              <div>
                {!store?.checktoken_data?.customer.user?.dob
                  ? "-"
                  : `${Math.floor(
                      calculateAge(store?.checktoken_data?.customer.user?.dob)
                    )} yrs`}
              </div>
            </div>
          </div>
          <div className="profilemedical_section3_symptoms item_flex g-32">
            <div>
              <div className="profilemedical_section3_symptoms_title">
                HEIGHT
              </div>
              <div className="profilemedical_section3_symptoms_desc">
                {!store?.checktoken_data?.customer?.intake?.personalize
                  ?.height_feet
                  ? "-"
                  : `${
                      store?.checktoken_data?.customer?.intake?.personalize
                        ?.height_feet
                    } ft ${Number(
                      store?.checktoken_data?.customer?.intake?.personalize
                        ?.height_inch
                    )} in`}
              </div>
            </div>
            <div>
              <div className="profilemedical_section3_symptoms_title">
                WEIGHT
              </div>
              <div className="profilemedical_section3_symptoms_desc">
                {!store?.checktoken_data?.customer?.intake?.personalize?.weight
                  ? "-"
                  : `${store?.checktoken_data?.customer?.intake?.personalize.weight} kgs`}
              </div>
            </div>
            <div>
              <div className="profilemedical_section3_symptoms_title">
                WAIST
              </div>
              <div className="profilemedical_section3_symptoms_desc">
                {!store?.checktoken_data?.customer?.intake?.personalize
                  ?.waist_inch
                  ? "-"
                  : `${store?.checktoken_data?.customer?.intake?.personalize?.waist_inch} in`}
              </div>
            </div>
          </div>
        </div>
        <div className="profilemedical_section3">
          <div className="profilemedical_section3_title">Menstrual Health</div>
          <div className="profilemedical_section3_diagnosed">
            <div className="profilemedical_section3_diagnosed_title">
              PERIOD
            </div>
            <div className="profilemedical_section3_diagnosed_desc">
              {/* {pagevalues?.oftengetperois || "-"} */}
              {store?.checktoken_data?.customer?.intake?.menstrual_health
                ?.period_frequency || "-"}
            </div>
          </div>
          <div className="profilemedical_section3_diagnosed item_flex g-32">
            <div>
              <div className="profilemedical_section3_diagnosed_title">
                LENGTH
              </div>
              <div className="profilemedical_section3_diagnosed_desc">
                {/* {pagevalues?.averagecyclelength || "-"} */}
                {store?.checktoken_data?.customer?.intake?.menstrual_health
                  ?.cycle_length_avg || "-"}{" "}
                day
                {store?.checktoken_data?.customer?.intake?.menstrual_health
                  ?.cycle_length_avg <= 1
                  ? ""
                  : "s"}
              </div>
            </div>
            <div>
              <div className="profilemedical_section3_diagnosed_title">
                FLOW LEVEL
              </div>
              <div className="profilemedical_section3_diagnosed_desc">
                {/* {pagevalues?.descperiod || "-"} */}
                {store?.checktoken_data?.customer?.intake?.menstrual_health
                  ?.period_description || "-"}
              </div>
            </div>
          </div>
          <div className="profilemedical_section3_symptoms">
            <div className="profilemedical_section3_symptoms_title">
              SYMPTOMS
            </div>
            <div className="profilemedical_section3_symptoms_desc">
              {store?.checktoken_data?.customer.intake.menstrual_health
                .experience_symptoms?.length
                ? store?.checktoken_data?.customer.intake.menstrual_health.experience_symptoms?.map(
                    (el) => <span>{el}</span>
                  )
                : "-"}
            </div>
          </div>
          <div className="profilemedical_section3_diagnosed">
            <div className="profilemedical_section3_diagnosed_title">
              BODY HAIR
            </div>
            <div className="profilemedical_section3_diagnosed_desc">
              {!store?.checktoken_data?.customer?.intake?.menstrual_health
                ?.symptoms?.length > 0
                ? "-"
                : store?.checktoken_data?.customer.intake.menstrual_health.symptoms?.map(
                    (el) => <span>{el}</span>
                  )}
            </div>
          </div>
        </div>
        <div className="profilemedical_section3">
          <div className="profilemedical_section3_title">
            Reproductive Health
          </div>
          <div className="profilemedical_section3_diagnosed">
            <div className="profilemedical_section3_diagnosed_title">
              DIAGNOSIS
            </div>
            <div className="profilemedical_section3_diagnosed_desc">
              {store?.checktoken_data?.customer?.intake?.reproductive_health
                ?.previous_diagnosis?.length
                ? store?.checktoken_data?.customer?.intake?.reproductive_health?.previous_diagnosis?.map(
                    (el) => <span>{el}</span>
                  )
                : "-"}
            </div>
          </div>

          <div className="profilemedical_section3_diagnosed">
            <div className="profilemedical_section3_diagnosed_title">
              DIAGNOSED WITH PCOS
            </div>
            <div className="profilemedical_section3_diagnosed_desc">
              {store?.checktoken_data?.customer?.intake?.reproductive_health?.previous_diagnosis.includes(
                "PCOS"
              )
                ? "Yes"
                : "No"}
            </div>
          </div>
          <div className="profilemedical_section3_diagnosed">
            <div className="profilemedical_section3_diagnosed_title">
              ULTRASOUND
            </div>
            <div className="profilemedical_section3_diagnosed_desc">
              {store?.checktoken_data?.customer?.intake?.reproductive_health
                ?.ultrasound_diagnosis || "-"}
            </div>
          </div>
          <div className="profilemedical_section3_diagnosed">
            <div className="profilemedical_section3_diagnosed_title">
              DO YOU HAVE A PARTNER IN YOUR LIFE?
            </div>
            <div className="profilemedical_section3_diagnosed_desc">
              {store?.checktoken_data?.customer?.intake?.reproductive_health
                ?.partner?.length
                ? store?.checktoken_data?.customer?.intake?.reproductive_health?.partner?.map(
                    (el) => <span>{el}</span>
                  )
                : "-"}
            </div>
          </div>
          <div className="profilemedical_section3_diagnosed">
            <div className="profilemedical_section3_diagnosed_title">
              CAN YOU TELL US ABOUT YOUR PARTNER?
            </div>
            <div className="profilemedical_section3_diagnosed_desc">
              {store?.checktoken_data?.customer?.intake?.reproductive_health
                ?.partner_details || "-"}
            </div>
          </div>
          <div className="profilemedical_section3_diagnosed">
            <div className="profilemedical_section3_diagnosed_title">
              BIRTH CONTROL
            </div>
            <div className="profilemedical_section3_diagnosed_desc">
              {store?.checktoken_data?.customer?.intake?.reproductive_health
                ?.birth_control || "-"}
            </div>
          </div>
          <div className="profilemedical_section3_diagnosed">
            <div className="profilemedical_section3_diagnosed_title">
              MEDICAL CONDITIONS
            </div>
            <div className="profilemedical_section3_diagnosed_desc">
              {store?.checktoken_data?.customer?.intake?.reproductive_health
                ?.medical_condition || "-"}
            </div>
          </div>
          <div className="profilemedical_section3_diagnosed">
            <div className="profilemedical_section3_diagnosed_title">
              MEDICATION
            </div>
            <div className="profilemedical_section3_diagnosed_desc">
              {store?.checktoken_data?.customer?.intake?.reproductive_health
                ?.current_medication || "-"}
            </div>
          </div>
          {/* <div className="profilemedical_section3_diagnosed">
            <div className="profilemedical_section3_diagnosed_title">
              fertility goal
            </div>
            <div className="profilemedical_section3_diagnosed_desc">
              {pagevalues?.tracking_arva || "-"}
            </div>
          </div> */}
        </div>
        <div className="profilemedical_section3">
          <div className="profilemedical_section3_title">Family History</div>

          <div className="profilemedical_section3_diagnosed">
            <div className="profilemedical_section3_diagnosed_title">
              MOM - AGE OF PREGNANCY
            </div>
            <div className="profilemedical_section3_diagnosed_desc">
              {store?.checktoken_data?.customer?.intake?.family_history
                ?.mothers_age_during_your_birth || "-"}{" "}
              yrs
            </div>
          </div>
          <div className="profilemedical_section3_diagnosed">
            <div className="profilemedical_section3_diagnosed_title">
              MOM - EASE OF PREGNANCY
            </div>
            <div className="profilemedical_section3_diagnosed_desc">
              {store?.checktoken_data?.customer?.intake?.family_history
                ?.pregnancy_difficulty_level || "-"}
            </div>
          </div>
          <div className="profilemedical_section3_diagnosed">
            <div className="profilemedical_section3_diagnosed_title">
              MOM - MENOPAUSE
            </div>
            <div className="profilemedical_section3_diagnosed_desc">
              {store?.checktoken_data?.customer?.intake?.family_history
                ?.menopause_mom || "-"}{" "}
              yrs
            </div>
          </div>
        </div>
        <div className="profilemedical_section4">
          <div className="profilemedical_section4_icons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
            >
              <g clipPath="url(#clip0_2060_1069)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24.4202 4.08843C21.7885 1.45328 18.2882 0.00133696 14.5588 0C6.87397 0 0.619658 6.25364 0.616984 13.9405C0.615647 16.3978 1.25806 18.7963 2.47804 20.9101L0.5 28.135L7.89072 26.1964C9.9269 27.3075 12.2198 27.8924 14.5528 27.8931H14.5588C22.2423 27.8931 28.4974 21.6387 28.5 13.9519C28.5013 10.2265 27.0527 6.72425 24.4202 4.0891V4.08843ZM14.5588 25.5386H14.5541C12.4751 25.538 10.4356 24.9791 8.65617 23.9236L8.23299 23.6723L3.84709 24.8227L5.0176 20.5464L4.74218 20.1079C3.58236 18.2629 2.96937 16.1304 2.9707 13.9412C2.97338 7.5525 8.17149 2.35439 14.5635 2.35439C17.6586 2.35573 20.5678 3.56234 22.7557 5.75295C24.9437 7.9429 26.1476 10.8548 26.1463 13.9505C26.1436 20.3399 20.9455 25.538 14.5588 25.538V25.5386ZM20.9147 16.8604C20.5664 16.686 18.8538 15.8436 18.5343 15.7274C18.2147 15.611 17.9828 15.5529 17.7508 15.9018C17.5189 16.2508 16.8511 17.0356 16.6478 17.2675C16.4446 17.5002 16.2414 17.5289 15.8931 17.3544C15.5449 17.1799 14.4225 16.8123 13.0915 15.6257C12.0561 14.7019 11.3568 13.5615 11.1536 13.2125C10.9504 12.8636 11.1322 12.6751 11.306 12.5019C11.4624 12.3455 11.6543 12.0948 11.8287 11.8916C12.0033 11.6884 12.0607 11.5427 12.177 11.3107C12.2934 11.078 12.2352 10.8749 12.1483 10.7004C12.0614 10.5259 11.3649 8.81121 11.074 8.11401C10.7912 7.43485 10.5039 7.5271 10.2906 7.51574C10.0874 7.5057 9.85542 7.5037 9.62279 7.5037C9.39016 7.5037 9.01309 7.59061 8.69358 7.93956C8.37407 8.28847 7.47426 9.13142 7.47426 10.8454C7.47426 12.5594 8.72236 14.2166 8.89681 14.4492C9.07127 14.6819 11.3534 18.2001 14.8476 19.7095C15.6786 20.0685 16.3276 20.283 16.8336 20.4435C17.668 20.7089 18.4273 20.6715 19.0276 20.5819C19.6968 20.4816 21.0886 19.7389 21.3787 18.9254C21.6688 18.1118 21.6688 17.4139 21.5819 17.2688C21.495 17.1238 21.2624 17.0362 20.9141 16.8618L20.9147 16.8604Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2060_1069">
                  <rect
                    width="28"
                    height="28.1556"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="profilemedical_section4_title">
            Need to make changes?
          </div>
          <div
            className="profilemedical_section4_btn"
            onClick={() => {
              window.open("https://chat.whatsapp.com/HCpqQhJQLpx0vMEO6sEjT4");
            }}
          >
            Chat with us
          </div>
        </div>
      </div>
    </>
  );
};
