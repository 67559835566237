import React, { useEffect, useState } from "react";
import { ReportType } from "./ReportType";
import { Summary } from "./Summary";
import { NextStep } from "./NextStep";
import { HomeSection3 } from "../Home/HomeSection3";
import { Faq } from "./Faq";
import { LevelList } from "./LevelList";

export const ThyroidHealth = ({
  PageValue,
  data,
  DataSelect,
  FurtherDataMixPanel,
  allreport,
  reports,
}) => {
  //
  const [ControlList, setControlList] = useState({
    name: "Insulin Resistant",
    relatedBiomarkers: [],
    description:
      "Think of your ovarian reserve as a basket of eggs. We’re typically born with a basket full of eggs, and every month, one mature egg is formed and released. By the time we reach the end of our reproductive window, also known as menopause, the basket is nearly empty.",
  });
  const [ArrayForHgighLow, setArrayForHgighLow] = useState([]);
  const [FilterThyroid, setFilterThyroid] = useState([]);

  useEffect(() => {
    setControlList({ ...data?.basedOnYourResults[0] });
  }, []);

  const [FilteredDataThyroid, setFilteredDataThyroid] = useState({
    chip: [],
    bcolor: "#F8E8FA",
    fcolor: "#B418D1",
  });

  useEffect(() => {
    setFilteredDataThyroid(
      PageValue?.last_order?.order_details?.testChipdata?.thyroid
    );
  }, [PageValue]);
  const birthdateString = PageValue?.last_intake?.data?.birth_date;

  // Parse your birthdate string into a Date object
  const birthdate = new Date(birthdateString);

  // Get the current date
  const currentDate = new Date();

  // Calculate the age in years
  const ageInMilliseconds = currentDate - birthdate;
  const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);
  useEffect(() => {
    const filteredArray1 = reports[0]?.biomarker?.filter((item1) => {
      return ControlList?.relatedBiomarkers?.some(
        (item2) => item2.nameShort === item1.short_name
      );
    });
    setFilterThyroid(filteredArray1);
  }, [ControlList, reports]);
  //
  //

  return (
    <div>
      <ReportType
        {...{
          clientName: `For ${reports[0]?.user?.name}`,
          reportType: data?.name,
          desc: data?.description,
          bgcolor: "#DCE5F8",
          fcolor: "#527CDA",
        }}
      />
      {FilteredDataThyroid?.chip?.length === 0 ? (
        ""
      ) : (
        <Summary
          {...{
            img: DataSelect?.image,
            type: "Thyroid Health",
            bgcolor: "#DCE5F8",
            fcolor: "#527CDA",
            main_title: "Your Thyroid Health summary",
            summarytitle:
              reports[0]?.cards?.thyroid_health?.summaryInsight?.heading,
            chipcolor: "",
            summarydesc:
              reports[0]?.cards?.thyroid_health?.summaryInsight?.description,
            typereportlearn: reports[0]?.cards?.thyroid_health?.chips,
            physicalsymptoms: [],
          }}
        />
      )}
      {/* <Summary
        {...{
          img: "",
          type: "pcos",
          bgcolor: "#FFEFEF",
          fcolor: "#FF5C5C",
          main_title: "Your Signs of PCOS summary",
          summarytitle: "Your levels may indicate some signs of PCOS.",
          summarydesc:
            "Irregular period cycles can increase the time between ovulation cycles, therefore increasing the time period to conceive.",
          typereportlearn: ["Insulin resistant PCOS", "Adrenal PCOS"],
          physicalsymptoms: [
            "Irregular periods",
            "Acne",
            "Oily skin",
            "IBS",
            "Hairfall",
            "Not on birth control",
          ],
        }}
      /> */}
      {/* <HomeSection3
        {...{
          title: "",
          data: [
            {
              name: "Our unique way of assessing PCOS at Arva Health",
              descti: "Article • 3 min",
              img: "",
            },
          ],
        }}
      /> */}
      <div className="level_thyroid">
        <div className="level_thyroid_title">Based on your results</div>
        <LevelList
          {...{
            title: "",
            titleDate: "",
            age: Math.floor(ageInYears),
            FurtherDataMixPanel: FurtherDataMixPanel,
            screen: "Thyroid Health",
            list: ControlList.relatedBiomarkers
              ? [
                  ...FilterThyroid?.map((el, i) => ({
                    ...el,
                    // name: el.short_name,
                    desc: el.value_desc,
                    desc2: el.desc,
                    color: "",
                    count: el.value,
                    // ??
                    score: el?.score
                      ? `${el?.score[0].toUpperCase()}${el?.score.slice(1)}`
                      : "Pending",
                    insights_list: el.insights,
                    range_list: el.labRange,
                    // ??
                  })),
                ]
              : [],
            AccordainWork: false,
            setArrayForHgighLow: setArrayForHgighLow,
            ArrayForHgighLow: ArrayForHgighLow,
          }}
        />
      </div>
      {reports[0]?.cards?.thyroid_health?.summaryInsight?.relatedArticles
        ?.length > 0 ? (
        <div className="homesection3_detail_shower_all_test">
          <HomeSection3
            {...{
              title: "Explore Thyroid Health",
              FurtherDataMixPanel: FurtherDataMixPanel,
              screen: "Thyroid Health",
              data:
                reports[0]?.cards?.thyroid_health?.summaryInsight
                  ?.relatedArticles?.length > 0
                  ? [
                      ...reports[0]?.cards?.thyroid_health?.summaryInsight?.relatedArticles?.map(
                        (el) => ({
                          name: el.title,
                          descti: el.topic,
                          img: el.image,
                          slug: el.slug,
                          description: el.description,
                        })
                      ),
                    ]
                  : [],
            }}
          />
        </div>
      ) : (
        ""
      )}

      <Faq
        {...{
          titleDis: true,
          openAcc: false,
          unique: "thyroid_faq",
          data: [
            {
              que: "How does a hormone test help understand Thyroid?",
              ans: "At Arva Health, we conduct the comprehensive Thyroid panel, which includes TSH, T3, and T4—each equally important in understanding how your thyroid is functioning. The thyroid is a small but powerful gland that impacts everything from sleep to stress, and even fertility.",
            },
            {
              que: "Trying to conceive with Thyroid?",
              ans: "Thyroid problems, such as hypothyroidism and hyperthyroidism, can disrupt the menstrual cycle and alter reproductive hormones, making it more difficult to get pregnant. However, with proper treatment, a lot of women with thyroid problems are able to conceive.",
            },
            {
              que: "Is a thyroid problem curable?",
              ans: "Luckily, thyroid problems are very commonly treatable. Checking your levels can help you proactively address any potential future issues.",
            },
          ],
        }}
      />
      <NextStep />
    </div>
  );
};
