import React, { useEffect, useState } from "react";
import BottomBtnSection from "../components/BottomBtnSection";
import { MultiSelect } from "../../../component/CheckOut/Intake/MultiSelect";
import { MixPanelIntake } from "../../../component/API/automation_api";

export const TellUsAboutPartner = ({
  setRangeCount,
  setPages,
  setPagevalues,
  pagevalues,
  seterr_pagevalues,
  err_pagevalues,
  singleBeginRange,
  setBeginPage,
  lastdata,
  setlastdata,
  goto,
}) => {
  const [BirthcontrolList, setBirthcontrolList] = useState([
    "My partner has sperm",
    "My partner has ovaries",
    "None of the above",
  ]);
  const [BirthcontrolStatus, setBirthcontrolStatus] = useState("");

  useEffect(() => {
    setBirthcontrolStatus(pagevalues?.partnerknowledge);
  }, []);

  useEffect(() => {
    setPagevalues((prev) => ({
      ...prev,
      partnerknowledge: BirthcontrolStatus,
    }));
  }, [BirthcontrolStatus]);

  return (
    <>
      <div className="intake_body">
        <div className="container birthcontrol_main">
          <div className="single_or_mingle_title">
            Can you tell us about your partner?
          </div>
          <MultiSelect
            {...{
              list: BirthcontrolList,
              onMultiClick: (el) => setBirthcontrolStatus(el),
              MultiSelectData: BirthcontrolStatus,
              multisel: false,
            }}
          />
        </div>
      </div>
      <BottomBtnSection
        {...{
          control: {
            txt: "Next",
            onBtnClick: () => {
              if (pagevalues?.partnerknowledge.length <= 0) {
                return;
              }
              MixPanelIntake({
                step_name: "Can you tell us about your partner?",
                section_name: "Reproductive Health",
                value: pagevalues.partnerknowledge,
                step_index: 4,
                total_steps: 8,
                remaining: lastdata,
              });
              if (singleBeginRange) {
                setRangeCount(3);
              } else {
                setRangeCount(20);
              }
              setPages("birthcontrol");
            },
            goBackClick: () => {
              setRangeCount(18);
              setPages("singleOrMingle");
            },
          },
        }}
      />
    </>
  );
};
